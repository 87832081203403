import _ from 'underscore'
import Zepto from 'zepto-webpack'
import { mapGetters } from 'vuex'

export const PaymentPostMixin = {
  data() {
    return {
      answerData: null
    }
  },
  computed: {
    ...mapGetters(['getFormElement'])
  },
  methods: {
    /**
     * Factories a form as string to be injected on the DOM
     */
    factoryForm(postUrl, jsonToPost, method = 'POST') {
      // If the url is not defined at this point, we reject to create the form
      if (!postUrl || !postUrl.length) return Promise.reject()

      const form = [
        `<form action="${postUrl}" method="${method}" style="display:none;">`
      ]

      // Query params on the get url
      if (~postUrl.indexOf('?') && method === 'GET') {
        const paramsBlock = postUrl.split('?')[1]
        const params = paramsBlock.split('&')
        for (const param of params) {
          const [key, value] = param.split('=')
          form.push(`<input type="hidden" name="${key}" value="${value}"/>`)
        }
      }

      // Add payment response data as inputs/textarea
      for (const key in jsonToPost) {
        const innerValue = jsonToPost[key]
        if (key === 'kr-answer') {
          this.answerData = innerValue
          form.push(`<textarea style="display:none;" name="${key}"></textarea>`)
          continue
        }
        form.push(
          `<input type="hidden" name="${key}"${
            innerValue ? ` value="${innerValue}"` : ''
          }/>`
        )
      }
      form.push(`<button type="submit">Submit</button></form>`)

      return Promise.resolve(form.join(''))
    },
    /**
     * Makes the submit action when the payment is done
     */
    postData(formString) {
      if (!this.getFormElement()) return

      const $form = Zepto(formString)[0]
      // Post answer
      const $answer = $form.querySelector('textarea[name="kr-answer"]')
      if ($answer) $answer.value = this.answerData

      // Append the form to the body
      document.querySelector('body').appendChild($form)
      // Submit the form
      this.$store.dispatch('update', { postPaymentRedirecting: true })
      $form.submit()

      setTimeout(() => {
        this.$store.dispatch('finishRedirection')
      }, 500)
    }
  }
}
