import 'core-js/stable/object/assign';
import {
    defaultIntegrations,
    Hub,
    BrowserClient,
    makeFetchTransport,
    makeMain,
} from '@sentry/browser';
import lyraSentryClient from './lyra-sentry-client';

/**
 * Lyra Sentry Client main script (browser) with minimal imports
 */
export default {

    /**
     * Init Sentry SDK.
     * @param options
     */
    init(options = {}) {
        const SentrySdk = {
            defaultIntegrations,
            Hub,
            BrowserClient,
            makeFetchTransport,
            makeMain,
        };
        return lyraSentryClient.init(SentrySdk, options);
    },

};
