import { getNetworkSelectionModeByTestCard } from '@/common/util/card'

/**
 * Created to standardize test card data across the application.
 * As of now (2024-10-11) it is used to facilitate test card sending
 * between Toolbar, ApplePay & GooglePay simulators & KR._internals API.
 *
 * @since KJS-4235
 */
export default class TestCard extends Object {
  // Factory ------------------------------------------------------------------
  static fromObject(params) {
    return new TestCard({
      pan: params.pan || params.number,
      brand: params.brand || params.brandKey,
      description: params.description || params.label
    })
  }

  static create(params) {
    if (params instanceof TestCard) return params
    else if (typeof params === 'object' && params) {
      return TestCard.fromObject(params)
    }
    throw new TypeError(
      `TestCard::create: Invalid parameter, Object or TestCard`
    )
  }

  // Instance ------------------------------------------------------------------
  constructor(props) {
    super()
    this.pan = props.pan
    this.brand = props.brand.toUpperCase()
    this.description = props.description

    const expectedKeys = ['pan', 'brand', 'description']
    for (const key of expectedKeys) {
      if (!props[key]) {
        throw new Error(
          `TestCard.constructor: expected parameter ${key} not to be falsy`
        )
      }
    }
    Object.freeze(this)
  }

  /**
   * Use for payment by Cards.
   *
   * @returns {number|null} network selection mode when specified in test card
   *                        configuration.
   */
  getNetworkSelectionMode() {
    return getNetworkSelectionModeByTestCard(this.brand, this.pan)
  }

  toObject() {
    return {
      pan: this.pan,
      brand: this.brand,
      description: this.description
    }
  }
}
