import { mapGetters } from 'vuex'
import GridConfigMixin from '@/host/components/mixins/grid/Config'
import { Queue } from '@/common/model'

/**
 * Mixin for column computation
 */
export default {
  mixins: [GridConfigMixin],
  data() {
    return {
      allLabelFit: true,
      withCloneQueue: new Queue(1)
    }
  },
  computed: {
    ...mapGetters(['isSinglePaymentButton'])
  },
  mounted() {
    this.updateRadioButtonWidth()
  },
  methods: {
    updateRadioButtonWidth() {
      const radioBtn = this.$el.querySelector('.kr-smart-form-radio-button')
      this.radioBtnWidth =
        this.isSinglePaymentButton && radioBtn instanceof HTMLElement
          ? radioBtn.offsetWidth
          : 0
    },
    async computeColumns(width, gap) {
      const columns = this.computeColumnsIgnoringLabels(width, gap)

      if (this.labelVisibility === 'show') {
        return await this.capMaxColumnsWithLabels(columns)
      } else if (this.labelVisibility === 'auto') {
        this.allLabelFit = await this.everyLabelFitForGivenColumns(columns)
      }

      return columns
    },
    computeColumnsIgnoringLabels(width, gap) {
      const maxCols = this.getMaxColumns(width, gap)

      if (this.columns === 'max' || this.columns > maxCols)
        // There cannot be more columns than payment methods available
        return Math.min(maxCols, this.groupMethods)

      return Math.min(this.columns, this.groupMethods)
    },
    getMaxColumns(spaceAvailable, gap) {
      const spacePerCol = this.calculateMinSpacePerColumn(gap)

      return Math.floor(spaceAvailable / spacePerCol)
    },
    calculateMinSpacePerColumn(gap) {
      return parseInt(this.colMinWidth) + (parseInt(gap) || 0)
    },
    /**
     * Check whether all labels fit for a given number of columns.
     *
     * @param {number} columns
     * @returns {Promise<boolean>}
     * @since KJS-4113
     */
    async everyLabelFitForGivenColumns(columns) {
      return await this.withCloneQueued({ label: true }, async $clone => {
        $clone.style.gridTemplateColumns = `repeat(${columns}, 1fr)`
        await this.$nextTick()
        return this.everyLabelFitWithinElement($clone)
      })
    },
    /**
     * Given a number of columns initially computed by
     * computeColumnsIgnoringLabels method, we need to check whether every
     * single label can be displayed without overflow.
     * If not, decrease value and test it again.
     * Repeat process until the number of columns would allow it or until we
     * reach the minimum (1).
     *
     * To do this a clone of the button container is made.
     * This allows applying specific styles required for the calculation.
     *
     * @param {number} columns Column number initially computed
     * @returns {number} Max number of columns which enables displaying labels
     *                   for every method.
     * @since KJS-4162
     */
    async capMaxColumnsWithLabels(columns) {
      await this.withCloneQueued({ label: true }, async $clone => {
        while (columns > 1) {
          $clone.style.gridTemplateColumns = `repeat(${columns}, 1fr)`
          await this.$nextTick()
          if (this.everyLabelFitWithinElement($clone)) {
            break
          }
          columns -= 1
        }
      })
      return columns
    },
    /**
     * For every button within a given clone of .kr-grid-group, check whether
     * .kr-method-btn__label is large enough to contain its direct child
     * <label> and a spacing (e.g: 10px with Neon theme)
     *
     * @param {HTMLElement} $clone
     * @returns {boolean}
     * @since KJS-4162
     */
    everyLabelFitWithinElement($clone) {
      return [...$clone.querySelectorAll('.kr-method-btn__label')].every(
        btnLabel =>
          btnLabel.offsetWidth >
          btnLabel.firstChild.offsetWidth + this.configButtonPaddingRight
      )
    },
    /**
     * Queued version of withClone method.
     * Cannot be executed more than 1 at the time.
     */
    async withCloneQueued(opts, fn) {
      return this.withCloneQueue.push(() => this.withClone(opts, fn))
    },
    /**
     * Create an invisible clone of kr-grid-group and execute the given task.
     * This allow to check, in other methods, to calculate the optimal amount of
     * columns for visible labels or check whether label have enough space for
     * a fix amount of columns.
     *
     * @template T
     * @param {object}  opts Options
     * @param {boolean} opts.label Make clone's labels visible or not
     * @param {function($clone: HTMLElement) => Promise<T>} fn Task to execute.
     *    Provided with the create $clone to test.
     * @returns {Promise<T>}
     * @since KJS-4113
     */
    async withClone(opts, fn) {
      const $gridGroup = this.$refs.gridGroup
      const $clone = $gridGroup.cloneNode(true)

      $clone.setAttribute('data-testid', 'gridGroupClone') // For unit-test
      $clone.style.opacity = '0'
      $clone.style.position = 'absolute'
      // Force every direct child position to static to ensure calculation is
      // done right (e.g: absolute element will not cause an overflow when
      // visually there is not space)
      $clone.querySelectorAll('.kr-method-btn > *').forEach(el => {
        el.style.position = 'static'
      })
      $clone.querySelectorAll('.kr-method-btn__label').forEach(el => {
        if (opts.label === true) {
          el.classList.remove('kr-method-btn__label--hidden')
        } else {
          el.classList.add('kr-method-btn__label--hidden')
        }
        el.style.gridColumnStart = 'auto'
        // Necessary to force a word-breaking in order to measure with the
        // smallest width possible, allow for a real measure of offset width.
        el.firstChild.style.width = 'min-content'
      })

      $clone.style.width = `${$gridGroup.offsetWidth}px`
      $gridGroup.parentElement.appendChild($clone)

      try {
        return await fn($clone)
      } catch (e) {
        throw e
      } finally {
        $gridGroup.parentElement.removeChild($clone)
      }
    }
  }
}
