import Zepto from 'zepto-webpack'
import appLoader from '@/common/loader/app'
import { getHost } from '@/common/util/url'
import { uniqueId } from 'underscore'

/** COMPONENTS */
import KryptonRedirectIFrame from '@/ghost/components/KryptonRedirectIFrame'

const getRedirectUrl = (
  language,
  redirectUrl,
  isPoster,
  isIframe,
  width,
  height,
  postData
) => {
  let extraArgs = ''
  for (const key in postData) {
    extraArgs += `&${key}=${postData[key].replace(/\+/g, '%2B')}`
  }

  let posterUrl = window.location.href.split('checkout/checkout.html?')[0]
  if (isPoster) {
    posterUrl +=
      `poster/poster.html` +
      `?redirectUrl=${escape(redirectUrl)}` +
      `&width=${width}` +
      `&height=${height}` +
      `&lang=${language}` +
      `&language=${language}` +
      `&isIframe=${isIframe}`
  } else if (redirectUrl) {
    const spltUrl = redirectUrl.split('?')
    if (spltUrl[1]) extraArgs += `&${spltUrl[1]}`
    posterUrl += `redirect/redirectIframe.html?redirectUrl=${escape(
      redirectUrl
    )}`
  }

  return posterUrl + extraArgs
}

export default locator => {
  return redirectStore => {
    // Set the type on the store
    locator.$store.dispatch('startRedirection', redirectStore)

    const {
      is,
      redirectUrl,
      hideTimeout = 0,
      hideAtStartup = 0,
      width = 400,
      height = 500,
      postData = {}
    } = redirectStore
    const { language, smartForm } = locator.$store.state
    const {
      iframeRedirection: isIframe,
      isSmartForm,
      isCardMethodActive
    } = locator.$store.getters
    const isPoster = !(
      isSmartForm &&
      !isCardMethodActive &&
      smartForm.activeMethod
    )

    if (is('billingRedirect')) {
      const domId = uniqueId('field')
      const $el = Zepto(`#appContainer.app-ghost-container`)

      const redirectionHost = getHost(redirectUrl)
      const redirectionUrl = getRedirectUrl(
        language,
        redirectUrl,
        isPoster,
        isIframe,
        width,
        height,
        postData
      )

      // Clean the content and append template
      $el.empty().append(
        `<krypton-redirect-iframe
          id="${domId}"
          hide-timeout="${hideTimeout}"
          hide-at-startup="${hideAtStartup}"
          redirect-url="${redirectionUrl}"
          redirection-host="${redirectionHost}"
          browser-id="${uniqueId('iframe')}">
        </krypton-redirect-iframe>`
      )

      return appLoader(locator, $el, {
        el: `#${domId}`,
        components: {
          'krypton-redirect-iframe': KryptonRedirectIFrame
        }
      })
    }
  }
}
