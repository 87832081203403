<template lang="pug">
extends ../../controls/templates/FormError.pug
</template>

<script>
import FormError from '@/host/components/mixins/FormError'
import { MethodDescriptor } from '@/common/model/PaymentMethod'
import { mapGetters } from 'vuex'

export default {
  name: 'SmartFormWalletError',
  mixins: [FormError],
  computed: {
    ...mapGetters(['hasToken', 'isSmartFormOpen']),
    extraClasses() {
      return {
        'kr-smart-form-wallet-error': true
      }
    },
    showFormError() {
      return this.hasData && !this.isSmartFormOpen && this.isWalletError
    },
    // Check if the error comes from a wallet payment method
    isWalletError() {
      const paymentMethod = this.error?.paymentMethod
      // The error has no payment method attached
      if (!paymentMethod) return false
      return this.hasToken(MethodDescriptor.fromString(paymentMethod).metadata)
    }
  },
  methods: {}
}
</script>
