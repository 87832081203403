import Events from '@/configuration/Events'

/**
 * Added to handle kr-out-of-view-form class dynamically inside component.
 *
 * @since KJS-4335
 */
export default {
  data() {
    return {
      outOfView: false
    }
  },
  created() {
    this.$busOn(Events.krypton.form.showForm, () => {
      this.outOfView = false
    })

    this.$busOn(Events.krypton.form.hideForm, () => {
      this.outOfView = true
    })
  }
}
