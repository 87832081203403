import fieldTypes from '@/configuration/sources/FieldTypes.yml'
import DebitCardList from '@/configuration/sources/DebitCardList.yml'

export const getExtrasFormState = () => {
  return {
    extrasForm: {
      brand: null,
      dna: null,
      hidden: true,
      fields: []
    }
  }
}

export const extrasFormActions = ($locator, app) => {
  return {
    setupExtrasForm(
      {
        state: {
          dna,
          _internals: {
            extrasForm: { countryCheck }
          },
          testKeys
        },
        commit,
        getters
      },
      { brand, country }
    ) {
      // reset dna and store newly obtained brand
      commit('UPDATE', { extrasForm: { brand, dna: null } })

      const brandConf = dna.cards[brand.toUpperCase()]
      if (brandConf) {
        const fields = JSON.parse(JSON.stringify(brandConf.fields))
        /**
         * Do not display installment fields for foreign cards
         * @since KJS-3760
         */
        if (!testKeys || countryCheck) {
          // Only check for for country discrepancies in prod mode or if countryCheck is active
          if (country !== dna.country) {
            delete fields.installmentNumber
            delete fields.firstInstallmentDelay
          }
        }

        const filteredEntries = filterNonExtraFields(fields)

        // Store extra fields so even if the modal display is skipped we send the correct data to processPayment
        commit('UPDATE', {
          extrasForm: {
            fields: filteredEntries.map(([fieldName, config]) => fieldName)
          }
        })

        // if empty or every field is read only don't open the modal
        if (
          filteredEntries.length === 0 ||
          filteredEntries.every(getters.isReadOnlyField(brand))
        )
          return

        // If there are no additional fields, don't show the form
        const filteredFields = Object.fromEntries(filteredEntries)
        commit('UPDATE', { extrasForm: { dna: filteredFields } })
      }
    },

    showExtrasForm({ commit, getters, dispatch }) {
      // If there is no data, don't show the form
      if (getters.isExtrasFormVisible || !getters.extrasFormHasData) return
      commit('UPDATE', { extrasForm: { hidden: false } })
      if (getters.isSmartFormPopin && !getters.hasSeveralPaymentMethods)
        dispatch('openSmartFormPopin', { withHistory: false })
      if (getters.isSmartFormPopin) dispatch('navigate', 'extras')
    },
    hideExtrasForm({
      commit,
      getters,
      dispatch,
      state: { hasDynamicValues, forms }
    }) {
      if (!getters.isExtrasFormVisible) return

      if (hasDynamicValues) {
        dispatch(`cardForm_${forms.main}/resetBin`)
        dispatch(`cardForm_${forms.main}/resetBinOptions`)
      }

      dispatch('updateActiveForm', { selectedBrand: 'DEFAULT' })

      commit('UPDATE', { extrasForm: { hidden: true } })
    }
  }
}

export const extrasFormMutations = {}
export const extrasFormGetters = {
  isExtrasFormVisible: ({ extrasForm }) => {
    return !extrasForm.hidden
  },
  extrasFormHasData: ({ extrasForm }) => {
    return !!extrasForm.dna && !!extrasForm.brand
  },
  extrasFields: ({ extrasForm }) => {
    return extrasForm.dna
  },
  isReadOnlyField:
    () =>
    brand =>
    ([key, value]) => {
      // Select fields
      if (value.values) {
        // Single option or installmentNumber for debit brand
        return (
          Object.keys(value.values).length < 2 ||
          (key === 'installmentNumber' && DebitCardList.brands.includes(brand))
        )
      }

      return false
    },
  extraFieldsArePossible: ({ dna }, getters) => {
    return Object.entries(dna.cards).some(([brand, brandConf]) => {
      const fields = brandConf.fields
      const filteredEntries = filterNonExtraFields(fields)
      return (
        filteredEntries.length !== 0 &&
        !filteredEntries.every(getters.isReadOnlyField(brand))
      )
    })
  }
}

function filterNonExtraFields(fields) {
  // We should never ask for some fields in the extras modal (for the time being)
  fieldTypes.ignoredExtraFields.forEach(fieldName => {
    if (!fields[fieldName]?.hidden) delete fields[fieldName]
  })

  const filteredEntries = Object.entries(fields).filter(([key, value]) => {
    // Filter the non-additional fields (pan,cvv,expiry), hidden fields and doRegister
    if (fieldTypes.iframe.includes(key) || value.hidden || key === 'doRegister')
      return false

    return true
  })

  return filteredEntries
}
