import ScriptCleaner from '@/host/service/ScriptCleaner'
import HostnameProvider from '@/host/service/HostnameProvider'

export default {
  read: ($scriptItem, version) => {
    let scriptSrc = $scriptItem
    let hostnamePatterns = HostnameProvider.get()
    let rgxLib = hostnamePatterns.rx
    let pathMatch = hostnamePatterns.path

    // If it is a DOM element, fetch the src from the object
    if (scriptSrc && typeof $scriptItem.getAttribute == 'function')
      scriptSrc = ScriptCleaner.run($scriptItem.getAttribute('src'))
    // FIXME: Fix the error of detecting the script
    if (scriptSrc && scriptSrc.length)
      scriptSrc = scriptSrc.replace('//static/', '/static/')

    if (typeof scriptSrc === 'string') {
      if (rgxLib.test(scriptSrc)) {
        let parsedVersion

        // Special usecases /dev/stable or /VERSION-next/stable
        let parsedVersionRx = /^(V\d{1}.\d{1}).+$/
        if (/^.+\/V\d{1}\.\d{1}-next\/stable\/.+$/.test(scriptSrc)) {
          let rxNextVersion = /^.+\/(V\d{1}\.\d{1}-next)\/stable\/.+$/
          let nextVersionMatches = rxNextVersion.exec(scriptSrc)
          if (nextVersionMatches.length > 1)
            parsedVersion = nextVersionMatches[1]
        } else if (/^.+\/legacy\/stable\/.+$/.test(scriptSrc)) {
          parsedVersion = 'legacy'
        } else {
          // Regular fallback
          let parsedVersionMatches = parsedVersionRx.exec(version)
          parsedVersion =
            parsedVersionMatches && parsedVersionMatches.length > 1
              ? parsedVersionMatches[1]
              : version
        }

        let matches = pathMatch.exec(scriptSrc)
        if (matches) {
          matches.splice(0, 1)
          matches.splice(3, 1)
          scriptSrc = `${matches.join('')}/${parsedVersion}`

          return scriptSrc
        }
      }
    }

    return null
  }
}
