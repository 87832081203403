import fieldModule from '@/store/modules/namespace/field'
import cardFormModule from '@/store/modules/namespace/cardForm'
import appLoader from '@/common/loader/app'

/** COMPONENTS */
import SlaveApp from '@/slave/SlaveApp'

export default locator => {
  // URL Hash must be ready ASAP to create store cardForm module
  const hashInfo = decodeURIComponent(window.location.hash.substring(1))
  const config = JSON.parse(hashInfo)

  // Register the 'slave' module
  locator.$store.registerModule([`cardForm_${config.formId}`], cardFormModule())
  locator.$store.registerModule(['field'], fieldModule())
  locator.$store.dispatch('field/update', {
    formId: config.formId
  })

  return appLoader(locator, 'SlaveApp', {
    el: '#app',
    components: { SlaveApp }
  })
}
