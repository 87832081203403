var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        _vm._g(
          {
            staticClass: "kr-delete-button-wrapper",
            class: _vm.dynamicClasses.wrapper,
          },
          _vm.$listeners
        ),
        [
          _c(
            "div",
            {
              staticClass: "kr-delete-button",
              attrs: { title: _vm.tooltip },
              on: { click: _vm.deleteCardHandler },
            },
            [
              _c("div", {
                staticClass: "kr-delete-icon",
                class: _vm.dynamicClasses.icon,
                domProps: { innerHTML: _vm._s(_vm.icon) },
              }),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }