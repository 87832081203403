import _ from 'underscore'
import extend from 'deep-extend'
import Events from '@/configuration/Events'

export default class AbstractQueue {
  constructor($locator) {
    this.$locator = $locator
    this.proxy = $locator.proxy
    this.ready = false
    this.$bus = $locator.$bus
    this.queue = []

    this.startSubscriptions()
    this.registerEvents()
  }

  startSubscriptions() {
    const _this = this
    this.$bus.$on(Events.krypton.destroy, message => {
      _this.reset()
    })
  }

  registerEvents() {}

  send(storeToQueue) {
    // If the flag is ready, we send directly the message
    if (this.ready) {
      this.proxy.send(storeToQueue)
    }

    // The flag is not ready. We wait.
    if (storeToQueue.is('sync')) {
      this.extendPreviousStores(storeToQueue)
    } else {
      this.queue.push(storeToQueue)
    }
    return Promise.resolve(true)
  }

  sendAccumulatedMessages() {
    const proxy = this.proxy
    this.ready = true

    this.queue.forEach(store => {
      proxy.send(store)
    })
  }

  reset() {
    this.ready = false
    this.queue = []
  }

  /**
   * If the store is a sync, we group all of them in a single extended one in
   * order to avoid infinite loops
   */
  extendPreviousStores(storeToQueue) {
    const _this = this
    let hasPreviousSyncs = false
    // Check if there is a previous sync store
    _.each(this.queue, (store, index) => {
      if (
        store.is('sync') &&
        storeToQueue?.metadata?.namespace === store?.metadata?.namespace
      ) {
        // Extend the previous sync store
        extend(store.data, storeToQueue.data)
        hasPreviousSyncs = true
      }
    })
    if (!hasPreviousSyncs) this.queue.push(storeToQueue)
  }

  registerLoadListener() {}
}
