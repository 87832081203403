/**
 * A ticket used to notify pending job they can start once the worker pool
 * get a new available spot.
 *
 * @since KJS-4224
 */
class Ticket {
  constructor() {
    this.notify = () => {}
  }

  ready() {
    return new Promise(resolve => {
      this.notify = () => {
        resolve()
        this.notify = () => {}
      }
    })
  }
}

/**
 * An asynchronous task queuer with configurable concurrency.
 *
 * @since KJS-4224
 */
export default class Queue {
  constructor(concurrency = 1) {
    this.concurrency = concurrency
    this.semaphore = 0
    this.waitingRoom = []
  }

  /**
   * If semaphore isn't locked (value is strictly lower than concurrency) then
   * we execute the job, otherwise a ticket is created and added to the
   * waitingRoom (FIFO) and execution shall resume once notified by previously
   * terminated job.
   *
   * @template T
   * @param {function() => Promise<T> | T} task Job to execute
   * @returns {Promise<T>}
   * @since KJS-4224
   */
  async push(task) {
    // Pool is full, please take a ticket and gently wait to be notified
    if (this.semaphore >= this.concurrency) {
      const ticket = new Ticket()
      this.waitingRoom.push(ticket)
      await ticket.ready()
    }

    // Execute job now
    this.semaphore += 1
    const promise = this.exec(task)
    promise.finally(() => {
      // Time to get out and notify the next task
      this.semaphore -= 1
      if (this.waitingRoom.length) {
        const ticket = this.waitingRoom.shift()
        ticket.notify()
      }
    })
    return promise
  }

  /**
   * Task can be sychronous function hence a try/catch shall be used to convert
   * the result/error into a promise result.
   *
   * @template T
   * @param {function() => Promise<T> | T} task Job to execute
   * @returns {Promise<T>}
   * @since KJS-4224
   */
  exec(task) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await task()
        resolve(result)
      } catch (e) {
        reject(e)
      }
    })
  }
}
