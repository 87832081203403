import { mapGetters } from 'vuex'

/**
 * Provide fieldLabel for a given fieldName
 *
 * @since  KJS-2030
 */
export const FieldLabelMixin = {
  computed: {
    ...mapGetters(['translate']),
    fieldLabel() {
      const titleKey =
        this.fieldName === 'expiryDate' ? 'expirationDate' : this.fieldName
      return this.translate(titleKey)
    }
  }
}
