export const capitalize = str => {
  if (typeof str !== 'string') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const titleize = str => {
  if (typeof str !== 'string') return ''
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
  })
}

export const spacedToKebabCase = str => {
  return str.split(' ').join('-').toLowerCase()
}
