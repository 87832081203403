import CustomFieldMixin from '@/host/custom/Mixin'
import { mapState } from 'vuex'

export default {
  mixins: [CustomFieldMixin],
  data() {
    return {
      defaultTemplate: `
        <div @show="isVisible" @class="{'kr-visible':isVisible}" class="kr-field kr-checkbox kr-wrapper-custom-field kr-wrapper-custom-field-checkbox">
          <label class="kr-field-control kr-checkbox">
              {slot}
              <div class="kr-checkbox-control-indicator"></div>
              {attribute.label}
          </label>
        </div>
      `
    }
  },
  computed: {
    ...mapState({
      customTemplate: state => state.fields.checkbox?.template
    })
  }
}
