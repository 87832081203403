export default store => {
  // Propagate new dna data
  store.subscribe(mutation => {
    const { language, currency, amount } = mutation.payload || {}
    const { getAmountLabel, hasPartialPayments } = store.getters
    if (language || currency || amount) {
      const updateObject = { amountLabel: getAmountLabel() }

      // Only update the total amount label if it does not have partial payments
      if (!hasPartialPayments) updateObject.totalAmountLabel = getAmountLabel()

      store.dispatch('update', updateObject)
    }
  })
}
