var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "kr-card-header",
      class: _vm.dynamicClasses,
      attrs: { "kr-resource": "" },
      on: {
        click: _vm.onClick,
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c("span", { staticClass: "kr-card-header-label" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _vm.hasRadioButton
        ? _c("SmartFormRadioButton", {
            attrs: { checked: _vm.isSelected, hover: _vm.hover },
          })
        : _vm._e(),
      _c("SmartFormCardIcons", {
        attrs: { brands: _vm.cardBrands, margin: -10 },
        on: { "num-of-brands": _vm.syncLabel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }