import { mapState } from 'vuex'

/** MIXINS */
import BrandMixin from '@/common/components/mixins/Brand'

export const InstallmentMixin = {
  inject: ['context'],
  mixins: [BrandMixin],
  computed: {
    ...mapState(['amount', 'currency', 'formToken']),
    ...mapState({
      dnaAmount: state => state.dna.amount
    }),
    isInstallNum() {
      return this.fieldName === 'installmentNumber'
    }
  },
  methods: {
    setAvailableOptions() {
      if (this.configuration?.values) {
        const conf = JSON.parse(JSON.stringify(this.configuration))
        this.addDynamicOptions(conf.values)
        this.addOptions(conf.values)
      }
      if (this.isInstallNum) this.sortOptions()
    },

    /**
     * If there is a DYNAMIC value and binCuotas is defined
     */
    addDynamicOptions(values) {
      if (values.DYNAMIC) {
        const binValue = this.isInstallNum ? this.binCuotas : this.binDelay
        if (binValue) {
          for (let i = 0; i < binValue; i++) {
            const value = this.isInstallNum
              ? `${i + 1}:${this.amount / (i + 1)}::::${this.amount}`
              : `${i + 1}`
            values[i + 1] = value
          }
        }
        delete values.DYNAMIC
      }
    }
  },
  watch: {
    isReadOnly(val) {
      this[!val ? 'setPermanentFocus' : 'clearPermanentFocus']()
    }
  }
}
