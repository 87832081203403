<template lang="pug">
  .kr-opm-group(v-onresize="onResize")
    SmartFormGridBackButton(
      v-if="hasBack"
      :method="group"
      @back="$emit('back')"
    )
    .kr-grid-group(ref="gridGroup" :style="gridStyles")
      SmartFormGridOpmButton(
        v-for="method in filteredMethods"
        v-on:selected="onSelected"
        :key="method.key"
        :method="method"
        :locked="allLocked"
        :group="group"
        :group-methods="groupMethods"
        :group-position="filteredMethods.findIndex(item => item.key === method.key)"
        :group-columns="visibleColumns"
        :show-label="showLabels"
        :margin-btns="splitMethods"
      )
</template>

<script>
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import SmartFormGridOpmButton from '@/host/components/smartform/grid/opm/Button'
import SmartFormGridBackButton from '@/host/components/smartform/grid/controls/BackButton'
import GridGroupMixin from '@/host/components/mixins/grid/Group'
import DomReactMixin from '@/host/components/mixins/DomReact'

export default {
  name: 'SmartFormGridOpmGroup',
  components: {
    SmartFormGridOpmButton,
    SmartFormGridBackButton
  },
  mixins: [GridGroupMixin, DomReactMixin],
  props: {
    type: {
      type: String,
      required: true
    },
    methods: {
      type: Array,
      required: true
    },
    allLocked: {
      type: Boolean,
      default: false
    },
    group: {
      type: String,
      default: '',
      required: false
    },
    hasBack: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    filteredMethods() {
      if (!this.splitMethods || this.showLabels) return this.methods
      // Has multiple paypal methods, show only one when labels are hidden
      let methodsToFilter = []
      for (const splitConf of paymentMethodsConf.splitMethods) {
        // Add methods to filter
        methodsToFilter = methodsToFilter.concat(
          this.methods.filter(method => {
            const regex = new RegExp(splitConf.excluded, 'g')
            return regex.test(method.key)
          })
        )
      }

      // Exclude the filtered methods
      return this.methods.filter(
        method =>
          !methodsToFilter.some(
            methodToFilter => methodToFilter.key === method.key
          )
      )
    },
    // Count the number of split methods
    splitMethods() {
      let splitMethods = 0
      for (const splitConf of paymentMethodsConf.splitMethods) {
        const splitMethodsGroup = this.methods.filter(method => {
          const regex = new RegExp(splitConf.methods, 'g')
          return regex.test(method.key)
        })
        if (splitMethodsGroup.length > 1) splitMethods++
      }

      return splitMethods
    }
  },
  methods: {
    onSelected(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
