/**
 * If the given attribute has the format kr-..
 * return the camelCase version of it
 */
export const camelCaseFormat = attribute => {
  if (!~attribute.indexOf('kr-')) return attribute
  const parts = attribute.substr(3).split('-')
  return parts.slice(1).reduce((acc, x) => {
    return `${acc}${x.charAt(0).toUpperCase()}${x.slice(1)}`
  }, parts[0])
}
