var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "kr-method-btn kr-method-btn--brand-cards",
      class: [_vm.walletDynamicClasses, _vm.brandClass],
      attrs: {
        "data-card": _vm.value,
        "data-token": _vm.method.token,
        type: "button",
      },
      on: {
        click: _vm.clickHandler,
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _vm.hasRadioButton
        ? _c("SmartFormRadioButton", {
            attrs: {
              checked: _vm.isSelected,
              hover: _vm.hover || _vm.hoverDelete,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "kr-icon" },
        [_c("BrandIcon", { attrs: { method: _vm.method.brand } })],
        1
      ),
      _c("SmartFormMaskedCardLabel", {
        staticClass: "kr-method-btn__label",
        attrs: { value: _vm.formatCardNumber(_vm.value, _vm.method.brand) },
      }),
      !_vm.isLoading
        ? _c("SmartFormGridWalletDelete", {
            attrs: {
              token: _vm.method.token,
              method: "CARDS:" + _vm.method.brand,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.onDeleteClick($event)
              },
              mouseover: function ($event) {
                $event.stopPropagation()
                _vm.hoverDelete = true
              },
              mouseleave: function ($event) {
                $event.stopPropagation()
                _vm.hoverDelete = false
              },
            },
          })
        : _c("KryptonLoadingAnimation"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }