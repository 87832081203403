var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [_vm.flexMode ? "flex-mode" : ""], attrs: { id: "inputWrapper" } },
    [
      _c(
        "div",
        {
          staticClass: "kr-outer-wrapper",
          class: [
            _vm.selectedBrand.toLowerCase(),
            _vm.fieldNameClass,
            _vm.isDisabled ? "kr-disabled" : "",
          ],
          style: [
            _vm.fieldCss.outerDefault,
            _vm.hasError ? _vm.fieldCss.outerError : "",
            _vm.isDisabled ? _vm.fieldCss.outerDisabled : "",
          ],
          attrs: { id: "wrapper" },
        },
        [
          _c(
            "div",
            {
              staticClass: "kr-inner-wrapper",
              class: { "kr-error": _vm.hasError },
              style: [
                _vm.fieldCss.innerDefault,
                _vm.hasError ? _vm.fieldCss.innerError : "",
                _vm.isDisabled ? _vm.fieldCss.innerDisabled : "",
              ],
            },
            [
              _c(
                "div",
                {
                  staticClass: "kr-field-container",
                  class: _vm.containerClasses,
                  style: _vm.fieldCss.fieldContainer,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "kr-field-relative-wrapper",
                      style: _vm.fieldCss.fieldRelativeWrapper,
                    },
                    [
                      _vm.isPan ? _c("Pan", { ref: "pan" }) : _vm._e(),
                      _vm.isExpiryDate
                        ? _c("ExpiryDate", { ref: "expiryDate" })
                        : _vm._e(),
                      _vm.isSecurityCode
                        ? _c("SecurityCode", { ref: "securityCode" })
                        : _vm._e(),
                      _c("input", {
                        style: {
                          position: "absolute",
                          top: "-9999px",
                          left: "-9999px",
                          color: "transparent",
                          "background-color": "transparent",
                          "z-index": 0,
                          border: "none",
                          outline: "none",
                          display: "block",
                        },
                        attrs: {
                          id: "endInputHook",
                          tabindex: "10",
                          "aria-hidden": "true",
                        },
                        on: { focus: _vm.tabEndReached },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }