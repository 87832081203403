import Events from '@/configuration/Events'
import { isBoolean } from 'underscore'

export default ({ $bus }) => {
  return store => {
    // Form enable / disable
    $bus.$on(Events.krypton.form.enable, () => {
      store.dispatch('enableForm')
    })

    $bus.$on(Events.krypton.form.disable, () => {
      store.dispatch('disableForm')
    })

    $bus.$on(Events.krypton.error, error => {
      store.dispatch('error', error)
    })

    $bus.$on(Events.krypton.warning, message => {
      store.dispatch('warning', message)
    })

    // Payment start / end events
    store.subscribe(({ payload }, state) => {
      if (isBoolean(payload?.processingPayment)) {
        const event = state.processingPayment ? 'start' : 'end'
        $bus.$emit(Events.krypton.payment[event])
      }
    })

    // Error propagation
    $bus.$on(Events.krypton.form.error.extraContent, message => {
      store.dispatch('update', {
        form: { error: { extra: { content: message } } }
      })
    })
  }
}
