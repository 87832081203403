import ReplaceRule from './ReplaceRule';

/**
 * Remove Rule.
 */
export default class RemoveRule extends ReplaceRule {
    /**
     * Constructor.
     * @param definition the ruleDefinition
     */
    constructor(definition) {
        super(definition);
        this.replacement = '';
    }
}
