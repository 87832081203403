import { clone, omit, isNull, isObject } from 'underscore'

const recLookup = (obj, path) => {
  let parts = path.split('.')
  if (!obj) obj = {}
  if (parts.length == 1) {
    return obj[parts[0]]
  }
  return recLookup(obj[parts[0]], parts.slice(1).join('.'))
}

export const getPathValue = function (obj, path) {
  return recLookup(obj, path)
}

const setPath = (partial, keys, val) => {
  const key = keys.shift()
  let newExtend = {}

  if (keys.length === 0) {
    partial[key] = val
  } else {
    if (typeof partial[key] == 'undefined') {
      partial[key] = {}
      newExtend[key] = partial[key]
    }
    partial[key] = partial[key] || {}
    setPath(partial[key] || {}, keys, val)
  }

  return newExtend
}

const set = (target, path, val) => {
  const keys = path.split('.')
  let cloned = clone(target)

  setPath(cloned, keys, val)

  return cloned
}

export const parseStringToObject = function (path, value) {
  return set({}, path, value)
}

export const setValueToPath = function (obj, path, value) {
  return set(obj, path, value)
}

export const cleanupNulls = function (obj) {
  return omit(obj, function (value, key, obj) {
    return isNull(value)
  })
}

export const cleanupObjects = function (obj) {
  return omit(obj, function (value, key, obj) {
    return isObject(value)
  })
}

/**
 *  Filters JS object removing all the props specified in the blacklist
 *  Modifies original object!
 *
 *  Blacklist format needs to be of the following type:
 *
 *  type BlackList = Array<string | BlackListElement>;
 *  interface BlackListElement {
 *      [propName: string]: BlackList // single propery with any name
 *  }
 *
 */
export const filterObjectByBlacklist = (object, blacklistSection) => {
  blacklistSection.forEach(blElem => {
    if (typeof blElem === 'string') {
      delete object[blElem]
    } else {
      const blKey = Object.keys(blElem)[0]
      if (object[blKey]) {
        filterObjectByBlacklist(object[blKey], blElem[blKey])
      }
    }
  })
}

/**
 *  Fills the cleanObject with all the props specified in the whitelist
 *  Modifies the cleanObject object
 *
 *  Whitelist format needs to be of the following type:
 *
 *  type Whitelist = Array<string | WhitelistElement>;
 *  interface WhitelistElement {
 *      [propName: string]: Whitelist // single propery with any name
 *  }
 *
 */

export const filterObjectByWhitelist = (
  object,
  whitelistSection,
  cleanObject = {}
) => {
  whitelistSection.forEach(wlElem => {
    if (typeof wlElem === 'string') {
      if (object.hasOwnProperty(wlElem)) {
        cleanObject[wlElem] = object[wlElem]
      }
    } else {
      const wlKey = Object.keys(wlElem)[0]
      if (object.hasOwnProperty(wlKey)) {
        cleanObject[wlKey] = {}
        filterObjectByWhitelist(
          object[wlKey],
          wlElem[wlKey],
          cleanObject[wlKey]
        )
        if (Object.keys(cleanObject[wlKey]).length === 0) {
          // no properties have been added, remove
          delete cleanObject[wlKey]
        }
      }
    }
  })
}

export const isPlainObject = value => value?.constructor === Object
