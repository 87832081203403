import _ from 'underscore'

/**
 * Not propagated, isolated in each slave
 */
export default () => {
  return {
    namespaced: true,
    state: {
      awareId: null,
      formId: '',
      formTokenPrefix: '01',
      name: null,
      required: true,
      parentDomain: null,
      configuration: {},
      focus: false,
      // Communication
      commEstablished: false,
      // ios
      isUIWebView: false,
      fakeCursorPosition: 0,
      touchedPosition: 0,
      mockTypeCarte: null,
      hasDynamicValues: false,
      ready: false,
      // Autofill (Safari) | card scanning
      autofill: {
        expiryDate: null,
        securityCode: null
      }
    },
    actions: {
      update({ commit }, data) {
        commit('UPDATE', data)
      },
      communicationEstablished({ commit }) {
        commit('COMMUNICATION_ESTABLISHED')
      },

      /**
       * Boot action
       * Will set the base values of the store and will (only the first
       * time)
       */
      boot({ commit, state }, configuration) {
        let updateObject = {}
        // basic data
        updateObject.name = `${configuration.name}`
        updateObject.formId = `${configuration.formId}`
        updateObject.awareId = `${configuration.awareId}`
        updateObject.parentDomain = `${configuration.parentDomain}`
        updateObject.disabled = configuration.disabled
        updateObject.mockTypeCarte = configuration.mockTypeCarte
        updateObject.hasDynamicValues = configuration.hasDynamicValues

        updateObject.booted = true

        commit('BOOT', updateObject)
      },
      ready({ commit }) {
        commit('READY')
      },
      autofill({ commit }, data) {
        commit('UPDATE', { autofill: data })
      }
    },
    mutations: {
      UPDATE: (state, updateObject) => {
        Object.keys(updateObject).forEach(key => {
          state[key] = updateObject[key]
        })
      },
      BOOT: (state, updateObject) => {
        Object.keys(updateObject).forEach(key => {
          state[key] = updateObject[key]
        })
      },
      COMMUNICATION_ESTABLISHED: state => {
        state.commEstablished = true
      },
      READY: state => {
        state.ready = true
      }
    }
  }
}
