<template lang="pug">
    .kr-layer(:class="dynamicClasses", v-show="shouldBeDisplayed", ref="content")
      .kr-layer-background
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import DetachableMixin from '@/host/components/mixins/Detachable'
import AnimatedModalMixin from '@/host/components/mixins/AnimatedModal'

export default {
  name: 'KryptonLayer',
  mixins: [DetachableMixin, AnimatedModalMixin],
  props: {
    mode: { type: String, default: 'box' },
    method: { type: String, default: '' }
  },
  computed: {
    ...mapState({
      isModalClosing: state => state.modal.isClosing,
      isModalOpen: state => state.modal.isOpen,
      activeMethod: state => state.smartForm.activeMethod,
      defaultMethod: state => state.smartForm.defaultMethod
    }),
    ...mapGetters([
      'translate',
      'isSmartFormPopin',
      'isActiveMethod',
      'hasSeveralPaymentMethods',
      'isSmartFormOpen'
    ]),
    dynamicClasses() {
      return {
        'kr-layer--opening': this.animation.open.active,
        'kr-layer--closing': this.animation.close.active
      }
    },
    // Box mode with popin open or unified mode for the rest
    shouldBeDisplayed() {
      if (!this.show) return false
      // If no redirection open, then layer should not be displayed
      if (!this.isModalOpen && !this.isModalClosing) return false

      // Then, check every mode types
      switch (this.mode) {
        case 'box':
          return (
            this.isSmartFormOpen ||
            this.isModalOpen ||
            (!this.hasSeveralPaymentMethods &&
              this.isActiveMethod(this.defaultMethod))
          )
        case 'unified':
          return (
            !this.isSmartFormPopin ||
            (this.method && this.isActiveMethod(this.method))
          )
        default:
          return false
      }
    }
  }
}
</script>
