import Events from '@/configuration/Events'

/**
 * This class manages the event listeners that should be removed when
 * the dom is destroyed in order to set off the listeners
 *
 * The idea is to use this class always through the methods added with the general mixin HostListenerMixin.js
 * This way we store the listeners by componentId and we can clear the listeners on component destroy as well
 *
 * There are a few exceptions where this methods may be called directly from a service such as AllReadyQueue.js
 * The "componentId" in this case may be any non numeric string. "NO-COMPONENT" by convention.
 *
 * @see KJS-3109
 * $on & $once methods can take an optional parameter "options" which supports
 * the following keys:
 *  - options.preventOff (Array): list of the events which will NOT cause the
 *    the listener to be removed. By default any listener in registry is
 *    remove when krypton.destroy or krypton.form.relocation is emitted.
 */
export default class BusListener {
  constructor({ $bus }) {
    this.$bus = $bus
    this.registry = {}
    this.setupDestroyListener(Events.krypton.destroy)
    this.setupDestroyListener(Events.krypton.form.relocation)
  }

  setupDestroyListener(event) {
    this.$bus.$on(event, msg => {
      Object.keys(this.registry).forEach(componentId => {
        this.$offComponent(componentId, event)
      })

      this.registry = {}
    })
  }

  $on(event, callback, componentId, options = {}) {
    this._addToRegistry(event, callback, componentId, options)
    this.$bus.$on(event, callback)
  }

  $once(event, callback, componentId, options = {}) {
    this._addToRegistry(event, callback, componentId, options)
    this.$bus.$once(event, callback)
  }

  $emit(event, msg) {
    this.$bus.$emit(event, msg)
  }

  _addToRegistry(event, callback, componentId, options = {}) {
    if (!this.registry[componentId]) this.registry[componentId] = []
    this.registry[componentId].push({ event, callback, options })
  }

  $offComponent(componentId, originEvent = null) {
    this.registry[componentId]?.forEach(({ event, callback, options }) => {
      if (
        originEvent === null ||
        !options.preventOff ||
        (Array.isArray(options.preventOff) &&
          !options.preventOff.includes(originEvent))
      )
        this.$bus.$off(event, callback)
    })

    delete this.registry[componentId]
  }
}
