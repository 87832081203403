import { permissionPolicies } from '@/configuration/sources/Security.yml'

/**
 * Finds and return the parent domain if can be reached
 * In node environment there is no parent domain, and there is no iframes
 *
 * Extracts the domain with port if defined. For example for the next
 * url: http://krypton.local/specs/usecases/001_simple.html returns 172.17.0.1:11412
 * and for https://www.youtube.com return www.youtube.com
 *
 * @method getParentDomain
 * @returns {string}
 */
export const getParentDomain = () => {
  const location = window.location
  let domain = `${location.host}`
  if (location.protocol) domain = `${location.protocol}//${domain}`

  return domain
}

/**
 * Gets the permission policies for the iframe
 *
 * @param {String} domain
 * @returns
 */
export const getPermissionPolicies = domain => {
  return permissionPolicies
    .map(
      ({ directive, allowList }) =>
        `${directive} ${allowList.replace('$$domain$$', domain)}`
    )
    .join('; ')
}
