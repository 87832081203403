<template lang="pug">
include templates/SmartButton.pug
+wrapper({ type: 'regular'})
</template>

<script>
import { titleize } from '@/common/util/string'
import { SmartButtonMixin } from '@/host/components/mixins/SmartButton'

export default {
  name: 'SmartButton',
  mixins: [SmartButtonMixin],
  props: {
    method: { type: String, required: true },
    brands: { type: String, default: '' },
    customLabel: { type: String, default: '' },
    brandFilter: { type: String, default: '' }
  },
  computed: {
    paymentMethod() {
      return this.method
    },
    /**
     * The payment method must be in the DNA unless it has the always
     * visible prop.
     */
    isVisible() {
      return this.isMethodAvailableInDna(this.cleanPaymentMethod)
    },
    isSelected() {
      return this.isSelectedMethod(this.method)
    },
    showAmount() {
      return !this.hasCustomLabelWithAmount
    },
    hasRegularLabel() {
      return (
        ((this.customLabel && this.brands) ||
          this.cleanPaymentMethod !== 'CARDS') &&
        !this.showLoadingAnimation
      )
    },
    hasCustomLabelWithAmount() {
      return this.customLabel && !!~this.customLabel.indexOf('%amount%')
    },
    hasSingleBrand() {
      return this.brands.split(',').filter(value => !!value).length === 1
    },
    singleBrandLabel() {
      return titleize(
        this.brands
          .split(',')[0]
          .toLowerCase()
          .split('_')
          .join(' ')
          .split('-')
          .join(' ')
          .trim()
      )
    },
    methodLabel() {
      if (this.customLabel)
        return this.customLabel.replace('%amount%', this.amountLabel)

      return this.defaultMethodLabel
    },
    regularMethodLabel() {
      return (
        this.getCustomPaymentMethodLabel(this.cleanPaymentMethod) ||
        this.methodLabel
      )
    },
    displayedBrands() {
      return this.brands ? this.brands.split(',') : []
    }
  }
}
</script>
