<template lang="pug">  
  .kr-smart-form-methods
    SmartFormMethodsListLabel(
      v-if="renderLabels"
      :mode="isGroupingActivated && mode === 'groups' ? 'groups' : 'methods'"
      :all-locked="paymentDone || hasAnyActiveMethod"
      :is-modal="true"
      v-on:group-selected="loadGroup"
      v-on:selected="setMethod"
      v-on:back="goBack"
    )
    SmartFormMethodsListContent(
      v-if="renderContents"
      mode="method"
      v-on:back="goBack"
    )
      slot(name="cardForm")
    SmartFormFinishedPayment(v-show="paymentDone && isFormPopin")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SmartFormMethodsListLabel from '@/host/components/smartform/MethodsListLabel'
import SmartFormMethodsListContent from '@/host/components/smartform/MethodsListContent'
import SmartFormFinishedPayment from '@/host/components/smartform/FinishedPayment'

import { SmartFormMethodsList } from '@/host/components/mixins/SmartFormMethodsList'

export default {
  name: 'SmartFormModalMethodsList',
  components: {
    SmartFormMethodsListLabel,
    SmartFormMethodsListContent,
    SmartFormFinishedPayment
  },
  mixins: [SmartFormMethodsList],
  props: {
    mode: {
      type: String,
      default: 'list',
      validator: value => ['list', 'method', 'groups'].includes(value)
    }
  },
  computed: {
    ...mapState({
      paymentDone: state => state.smartForm.paymentDone,
      activeGroup: state => state.smartForm.activeGroup
    }),
    ...mapGetters(['hasAnyActiveMethod', 'isFormPopin', 'isGroupingActivated']),
    renderLabels() {
      return this.mode === 'list' || this.mode === 'groups'
    },
    renderContents() {
      return this.mode === 'method' && !(this.paymentDone && this.isFormPopin)
    }
  }
}
</script>
