import { mapState, mapGetters } from 'vuex'
import { compact } from 'underscore'
import { dynamicMapGetters } from '@/common/util/store'

export default {
  inject: ['context'],
  computed: {
    ...mapState(['forcedBrand']),
    ...mapGetters(['availableBrands', 'isBrandAvailable', 'isBrandForced']),
    ...dynamicMapGetters('context.namespace', ['isDefaultBrand']),
    selectedBrand: {
      get() {
        return this.$store.state[this.context.namespace].selectedBrand
      },
      set(brand = 'DEFAULT') {
        if (brand === 'DEFAULT') brand = this.detectedBrands[0]
        this.$store.dispatch(`${this.context.namespace}/selectBrand`, brand)
      }
    },
    paymentBrand: {
      get() {
        return this.$store.state[this.context.namespace].paymentBrand
      },
      set(brand = 'DEFAULT') {
        this.$store.dispatch(`${this.context.namespace}/setPaymentBrand`, brand)
      }
    },
    detectedBrands: {
      get() {
        return this.$store.state[this.context.namespace].detectedBrands
      },
      set(brands) {
        this._setBrands('detectedBrands', brands)
      }
    },
    unfilteredBrands: {
      get() {
        return this.$store.state[this.context.namespace].unfilteredBrands
      },
      set(brands) {
        this._setBrands('unfilteredBrands', brands)
      }
    },
    isDebitBrand() {
      return this.$store.getters[`${this.context.namespace}/isDebitBrand`]()
    },
    bankName() {
      if (
        this.selectedBrand.indexOf('VISA_') === 0 ||
        this.selectedBrand.indexOf('MC_') === 0
      ) {
        const bankName = this.selectedBrand.toLowerCase().split('_')[1]
        if (!~['electron', 'debit'].indexOf(bankName)) {
          return bankName.charAt(0).toUpperCase() + bankName.slice(1)
        }
      }
      return undefined
    }
  },
  methods: {
    _setBrands(type, brands = ['DEFAULT']) {
      brands = compact(brands)
      if (!brands) brands = ['DEFAULT']
      if (this.isBrandForced) brands = [this.forcedBrand]
      this.$store.dispatch(`${this.context.namespace}/update`, {
        [type]: brands
      })
    }
  }
}
