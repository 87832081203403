import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

/** MIXINS */
import BrandMixin from '@/common/components/mixins/Brand'

export const PaymentTokenMixin = {
  mixins: [BrandMixin],
  computed: {
    ...mapState({
      cards: state => state.dna?.tokens?.cards || []
    }),
    ...dynamicMapState('context.namespace', {
      paymentMethodToken: state => state.nonSensitiveValues.paymentMethodToken
    })
  },
  watch: {
    cards: {
      deep: true,
      handler: 'setInputValue'
    },
    paymentMethodToken: 'setInputValue'
  },
  methods: {
    setInputValue(inputValue) {
      if (this.paymentMethodToken) {
        if (this.cards.length) {
          const isSingleToken = this.cards.length === 1
          const { brand, fields } = isSingleToken
            ? this.cards[0]
            : this.getSelectedToken(this.cards)
          const { value } = fields[this.fieldName]
          if (value) {
            //Set input value
            this.inputValue = this.formatValue(value)
            this.fieldEvent('permanentFocus')
          }

          if (isSingleToken) {
            // Store new brands
            this.unfilteredBrands = [brand]
            this.detectedBrands = [brand]
          }
        }
      } else if (!inputValue && inputValue == this.paymentMethodToken) {
        // If the paymentToken has been disabled, set the initial state
        this.inputValue = ''
      }
    },
    formatValue(value) {
      // The expiry date value needs to be formatted
      if (this.fieldName === 'expiryDate') {
        const splittedDate = value.split('/')
        if (splittedDate[1].length === 4) {
          splittedDate[1] = splittedDate[1].substr(2)
          return splittedDate.join('/')
        }
      }
      return value
    },
    getSelectedToken(cards) {
      for (const card of cards) {
        if (card.token === this.paymentMethodToken) return card
      }
    }
  }
}
