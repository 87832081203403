var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "kr-sf-cards", class: _vm.dynamicClasses },
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasLabel,
              expression: "hasLabel",
            },
          ],
          staticClass: "kr-sf-cards__label",
        },
        [_vm._v(_vm._s(_vm._f("colon")(_vm._f("capitalize")(_vm.label))))]
      ),
      _vm.hasCardFormExpanded && !_vm.onlyButton
        ? [_vm._t("default")]
        : _c("SmartFormGridCardsButton", {
            attrs: { locked: _vm.allLocked },
            on: { selected: _vm.loadMethod },
          }),
      _vm.hasErrorComponent ? _c("SmartFormGridCardsError") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }