var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label
    ? _c("div", {
        staticClass: "kr-label default",
        domProps: { innerHTML: _vm._s(_vm.contentHTML) },
        on: { click: _vm.handleClick, touchstart: _vm.handleClick },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }