import { isFunction } from 'underscore'
import Events from '@/configuration/Events'

/**
 * This class handles the calls of configuration event methods
 */
export default class EventNotifier {
  constructor({ $bus, $store }) {
    this.$bus = $bus
    this.$store = $store

    this.$bus.$on(Events.krypton.form.ready, () => {
      this.startFieldsSubscriptions()
    })
  }

  startFieldsSubscriptions() {
    // Update - Test Card
    this.$store.subscribe(({ type, payload }, state) => {
      const formId =
        type.indexOf('cardForm_') === 0
          ? type.split('/')[0].split('_')[1]
          : null
      if (formId && payload?.testCard) {
        const fieldsEvents = this.$store.state.fields.events
        this.callEvent(fieldsEvents.onUpdate, [formId])
      }
    })
    // Length change - input
    this.$bus.$on(Events.krypton.field.length, ({ formId, name, length }) => {
      const fieldsEvents = this.$store.state.fields.events
      this.callEvent(fieldsEvents.onInput, [formId, name, length])
      const action = length > 0 ? 'setInputFilled' : 'unsetInputFilled'
      this.$store.dispatch(action, name)
    })

    // Interaction events
    this.$bus.$on(Events.krypton.field.event, ({ formId, type, name }) => {
      const fieldsEvents = this.$store.state.fields.events

      switch (type) {
        case 'focus':
        case 'triggerFocusActions':
          this.callEvent(fieldsEvents.onFocus, [formId, name])
          break
        case 'blur':
        case 'triggerBlurActions':
          this.callEvent(fieldsEvents.onBlur, [formId, name])
          break
        case 'blurAll':
          this.callEvent(fieldsEvents.onBlur, [formId, 'all'])
          break
        case 'select':
          this.callEvent(fieldsEvents.onSelect, [formId, name])
          break
        case 'permanentFocus':
          this.callEvent(fieldsEvents.onAutoFocus, [formId, name])
          break
        case 'clearPermanentFocus':
          this.callEvent(fieldsEvents.onBlur, [formId, name, true])
          break
      }
    })

    this.$store.subscribe(mutation => {
      const formId =
        mutation.type.indexOf('cardForm_') === 0
          ? mutation.type.split('/')[0].split('_')[1]
          : null
      if (formId) {
        const invalidFields = mutation.payload?.invalidFields
        if (invalidFields) {
          const fieldsEvents = this.$store.state.fields.events
          for (const fieldName of invalidFields) {
            this.callEvent(fieldsEvents.onValidate, [formId, true, fieldName])
          }
        }
      }
    })
  }

  callEvent(method, params) {
    if (isFunction(method)) method(...params)
  }
}
