var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "onresize",
          rawName: "v-onresize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "kr-smart-form-wallet",
      class: _vm.dynamicClasses,
    },
    [
      _vm._l(_vm.cardTokens, function (card, key) {
        return _c("SmartFormWalletCard", {
          key: card.token,
          attrs: { card: card, locked: _vm.allLocked },
          on: { selected: _vm.onSelected },
        })
      }),
      _vm._l(_vm.smartFormTokens, function (method, key) {
        return _c("SmartFormWalletMethod", {
          key: method.token,
          attrs: { method: method, locked: _vm.allLocked },
          on: { selected: _vm.onSelected },
        })
      }),
      _c("SmartFormWalletError"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }