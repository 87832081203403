var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible && !_vm.isThereOnlyOneCard,
          expression: "isVisible && !isThereOnlyOneCard",
        },
      ],
      staticClass: "kr-wallet-card-list",
      attrs: { "kr-resource": "" },
    },
    [
      _vm.isVisible && !_vm.isThereOnlyOneCard
        ? _c(
            "div",
            { staticClass: "kr-card-list-wrapper" },
            _vm._l(_vm.cards, function (card) {
              return _c(
                "div",
                {
                  key: card.token,
                  staticClass: "kr-card-element",
                  class: [
                    "kr-" + card.brand.toLowerCase(),
                    _vm.isDeletedCard(card) ? "kr-deleted" : "",
                  ],
                  attrs: { "data-card": card.fields.pan.value },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "kr-card-label",
                      on: {
                        click: function ($event) {
                          _vm.selectedCard = card
                        },
                      },
                    },
                    [
                      _c("input", {
                        attrs: {
                          type: "radio",
                          id: card.token,
                          name: card.token,
                        },
                        domProps: {
                          value: card.token,
                          checked: _vm.selectedCard.token === card.token,
                        },
                      }),
                      _c("label", { attrs: { for: card.token } }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formatCardNumber(
                                card.fields.pan.value,
                                card.brand
                              )
                            )
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("BrandIcon", {
                    attrs: { method: card.brand },
                    on: {
                      click: function ($event) {
                        _vm.selectedCard = card
                      },
                    },
                  }),
                  _c("krypton-delete-button", {
                    attrs: { token: card.token, method: "CARDS:" + card.brand },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }