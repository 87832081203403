import _ from 'underscore'

export default class BaseStore {
  init(initialValues = {}) {
    const fields = this.getFields()
    const defaults = this.getDefaults()

    for (const field of fields) {
      this[field] = null
    }

    // Set defaults if defined
    for (const defaultKey in defaults) {
      if (this.hasOwnProperty(defaultKey)) {
        this[defaultKey] = defaults[defaultKey]
      }
    }

    for (const initKey in initialValues) {
      if (~fields.indexOf(initKey)) this[initKey] = initialValues[initKey]
    }

    this.__store = true
  }

  getFields() {
    return []
  }

  getDefaults() {
    return {}
  }

  /**
   * Returns a JSON representation of the object
   * only using the inner fields declared on method
   * getFields of the object
   *
   * @return {object}
   */
  json() {
    const fields = this.getFields()
    const json = { _name: this._name }

    for (const field of fields) {
      json[field] = this[field]
    }

    return json
  }
}
