var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-smart-form-modal-view", class: _vm.dynamicClasses },
    [
      _c("SmartFormTabGuard", { attrs: { position: "first" } }),
      _vm._t("default"),
      _c("SmartFormTabGuard", { attrs: { position: "last" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }