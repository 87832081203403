/* istanbul ignore file */
import Zepto from 'zepto-webpack'

/**
 * Injects the given styles into the head
 */
export const injectIntoHead = style => {
  const $tag = Zepto(
    `<style type="text/css" id="kr-base-styles">${style}</style>`
  )
  Zepto('head').prepend($tag)
}
