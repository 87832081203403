var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "kr-sf-opm" },
    [
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showOpmLabel,
              expression: "showOpmLabel",
            },
          ],
          staticClass: "kr-sf-opm__label",
        },
        [_vm._v(_vm._s(_vm._f("colon")(_vm._f("capitalize")(_vm.label))))]
      ),
      _c(
        "div",
        { staticClass: "kr-sf-opm__methods" },
        [
          _c("SmartFormGridOpmGroup", {
            class: _vm.dynamicClasses("main"),
            attrs: {
              type: "main",
              methods: _vm.mainPaymentMethods,
              locked: _vm.allLocked,
            },
            on: { selected: _vm.onSelected },
          }),
          _c("SmartFormGridOpmGroup", {
            class: _vm.dynamicClasses("group"),
            attrs: {
              type: "group",
              group: _vm.group,
              methods: _vm.groupPaymentMethods,
              locked: _vm.allLocked,
            },
            on: {
              selected: _vm.onSelected,
              back: function ($event) {
                return _vm.goBack()
              },
            },
          }),
        ],
        1
      ),
      _c("SmartFormGridError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }