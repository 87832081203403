/**
 * @access public
 * @desc Class to handle web requests through native window "fetch" method
 */
export default class BrowserRequests {
  constructor({ $store, browser, storeFactory, logger }) {
    this.$store = $store
    this.browser = browser
    this.storeFactory = storeFactory
    this.logger = logger
  }

  /**
   * Performs a POST request on the given url or specific GET request for JSONP
   *
   * @public
   * @example
   * const testObj = {
   *   a: 1,
   *   b: 2
   * };
   * requests.post('http://172.17.0.1:7000', testObj).then((response) => {
   *     assert.ok(_.isEqual(response.toString(), testObj.toString()));
   *     done();
   * }, (error) => _.noop);
   * @param requestData
   * @param {{returnResponseOnError?: boolean}} options
   * @return {Promise} Promised response
   */
  async post(requestData, options = {}) {
    const { returnResponseOnError } = options
    const { url, objectData, headers } = requestData
    let { timeout } = requestData
    if (!timeout) timeout = this.$store.state._internals.serverRequestTimeout

    try {
      const controller = new AbortController()
      const timeoutId = setTimeout(() => controller.abort(), timeout)
      const fetchData = {
        method: 'POST',
        body: JSON.stringify(objectData),
        signal: controller.signal
      }
      if (headers) fetchData.headers = headers

      this.logger.logRequest(requestData, url)
      const response = await window.fetch(url, fetchData)
      clearTimeout(timeoutId)

      // Headers
      let responseHeaders = {}
      if (response.headers.forEach) {
        response.headers.forEach((key, value) => {
          responseHeaders[value] = key
        })
      } else {
        for (const key of response.headers.keys()) {
          responseHeaders[key] = response.headers.get(key)
        }
      }

      // Body
      if (response.status !== 200) {
        if (response.status >= 500) {
          const additionalInfo = returnResponseOnError ? { response } : {}
          return Promise.reject({ errorCode: 'CLIENT_104', ...additionalInfo })
        }
        const error = new Error(response.statusText)
        error.response = response
        throw error
      }

      const data = await response.json()
      const requestResponse = this.storeFactory.create('requestResponse', {
        response: data,
        statusCode: 200,
        timestamp: new Date().getTime(),
        headers: responseHeaders
      })
      this.logger.logResponse(requestResponse, url)
      return Promise.resolve(requestResponse)
    } catch (error) {
      const additionalInfo = returnResponseOnError
        ? { response: error?.response }
        : {}
      return Promise.reject({
        errorCode: this._getErrorCode(error),
        ...additionalInfo
      })
    }
  }

  _getErrorCode(error) {
    if (error instanceof DOMException) {
      if (error.name === 'AbortError') return 'CLIENT_103'
    }
    return error.message === 'TypeError: Failed to fetch'
      ? 'PSP_997'
      : 'PSP_998'
  }
}
