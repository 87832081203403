/* istanbul ignore file */
import Zepto from 'zepto-webpack'
import _ from 'underscore'
import Events from '@/configuration/Events'

/**
 * Class dedicated to trigger actions related to iframe
 */
export default class IFrameController {
  constructor($locator) {
    this.$locator = $locator
    this.ghostContainer = null
  }

  /**
   * Waits until the checkout iframe can be created
   */
  createGhost(skip = false) {
    const { iframeBuilder, $bus, $store } = this.$locator
    const { isSmartForm } = $store.getters
    const legacySelector = 'body > .kr-popin-redirection-wrapper'

    return new Promise((resolve, reject) => {
      // Wait until the page is fully loaded
      let loadCheckoutCheckInterval = setInterval(() => {
        if (document.readyState === 'complete') {
          clearInterval(loadCheckoutCheckInterval)

          // Check if the legacy poisition exists
          const $legacyIframe = document.querySelector(legacySelector)

          // Only for legacy widget, the smartform will be in another location
          if (!isSmartForm && !$legacyIframe && !skip) {
            iframeBuilder
              .createCheckoutHTML()
              .then(([popinId, iframeHTML]) => {
                Zepto('body').first().append(iframeHTML)
                $bus.$emit(Events.krypton.data.newPopinRedirection, { popinId })
                resolve()
              })
              .catch(reject)
          } else {
            /**
             * For Smartform, the iframe will be in another location, so we need to remove the legacy iframe
             * See: KJS-4293
             */
            if (isSmartForm && $legacyIframe) $legacyIframe.remove()

            resolve()
          }
        }
      }, 20)
    })
  }

  /**
   * Register Ghost Vue instance to give access everywhere.
   * This allows to control iframe visibility and styles.
   *
   * @param {VueComponent} ghostContainer
   * @since KJS-2508
   */
  registerGhostContainer(ghostContainer) {
    this.ghostContainer = ghostContainer
  }

  removeGhostContainer() {
    this.ghostContainer = null
  }
}
