/* istanbul ignore file */
import Zepto from 'zepto-webpack'
import LukesLazyLoader from '@/host/service/loaders/LukesLazyLoader'

export default class LazyLoaderService {
  /**
   * Returns a Promise that resolves when the file is loaded.
   *
   * @param {String} file
   * @returns {Promise}
   */
  load(file) {
    let solved = false

    return new Promise((resolve, reject) => {
      Zepto(document).ready(() => {
        const resolver = () => {
          if (!solved) {
            solved = true
            resolve()
          }
        }

        new LukesLazyLoader(file, resolver)

        // Solve at 150ms
        setTimeout(() => {
          resolver()
        }, 150)
      })
    })
  }
}
