<template lang="pug">
  .kr-extras-form-modal-wrapper.kr-smart-form-modal-wrapper
      .kr-extras-form-modal.kr-smart-form-modal(:class="dynamicClasses.modal")
        KryptonPopinHeader(:is-smart-form="true", v-on:close="closeModal()")
        slot
        KryptonPopinFooter
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { dynamicMapActions } from '@/common/util/store'
import KryptonPopinHeader from '@/host/components/popin/KryptonPopinHeader'
import KryptonPopinFooter from '@/host/components/popin/KryptonPopinFooter'

export default {
  name: 'ExtrasFormModal',
  components: {
    KryptonPopinHeader,
    KryptonPopinFooter
  },
  data() {
    return {
      modalOpen: false
    }
  },
  computed: {
    ...mapState(['redirectIframeOpen']),
    ...mapGetters([
      'isExtrasFormVisible',
      'isSmartFormPopin',
      'isSmallScreen',
      'requireFullScreenRedirect'
    ]),
    dynamicClasses() {
      const classes = {
        background: {
          'kr-modal-open': this.modalOpen
        },
        modal: {
          'kr-modal-open': this.modalOpen,
          'kr-secure-iframe-visible': this.redirectIframeOpen,
          'kr-mobile': this.isSmallScreen,
          'kr-fullscreen':
            this.redirectIframeOpen && this.requireFullScreenRedirect
        }
      }
      return classes
    }
  },
  created() {
    setTimeout(() => {
      this.modalOpen = true
    }, 100)
  },
  methods: {
    ...mapActions(['hideExtrasForm', 'error', 'closeMethod', 'closePopin']),
    ...dynamicMapActions('context.namespace', ['update']),
    closeModal() {
      this.error('CLIENT_101')
      // must reset brand wen closing modal, to reset installments select on card form
      this.update({ selectedBrand: 'DEFAULT' })
      this.modalOpen = false
      setTimeout(() => {
        this.closeMethod(true)
        this.hideExtrasForm()
        this.closePopin()
      }, 100)
    }
  }
}
</script>
