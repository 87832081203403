import Zepto from 'zepto-webpack'
import appLoader from '@/common/loader/app'

/** COMPONENTS */
import KryptonPopinRedirection from '@/host/components/popin/KryptonPopinRedirection'

export default locator => {
  return ({ popinId }) => {
    const el = `#${popinId}.kr-popin-redirection-wrapper`

    return appLoader(locator, Zepto(el), {
      el,
      components: {
        KryptonPopinRedirection
      }
    })
  }
}
