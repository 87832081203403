import Zepto from 'zepto-webpack'
import { innerText } from '@/common/util/dom'

export const getCleanValue = (self, value) => {
  // Clean possible parenthesis
  if (value.indexOf('(') !== -1) {
    value = value.match(/\((.*)\)/)[1]
  }

  // 2 values with an or
  if (value.indexOf('||') !== -1) {
    const values = value.split('||')
    if (values.length === 2)
      return self[values[0].trim()] || self[values[1].trim()]
  }

  return self[value]
}

// Extract the key and value of a smple object
export const extractAttribute = (self, object, value) => {
  const regex = new RegExp(`{\s?'(.*)'\s?:\s?(.*)\s?}`)
  const match = value.match(regex)
  if (match.length === 3) {
    object[match[1]] = getCleanValue(self, match[2])
  }
}

export const extractObjectAttribute = (self, value) => {
  const object = {}
  // Simple object
  if (value.indexOf(',') === -1) {
    // Get the data from object like bind
    extractAttribute(self, object, value)
    // Complex object
  } else {
    // Clean global {}
    value = value.match(/\{(.*)\}/)[1]
    value.split(',').forEach(attr => {
      // Add a {} to parse it as a simple object
      const simObj = `{${attr.trim()}}`
      extractAttribute(self, object, simObj)
    })
  }

  return object
}

export const parseToRenderConfig = (self, $element, config = {}) => {
  if (!$element[0]) return config
  for (let i = 0; i < $element[0].attributes.length; i++) {
    const attr = $element[0].attributes[i]
    switch (attr.name) {
      case 'class':
        config.staticClass = attr.value
        break
      case 'v-bind:class':
        // Check if the attribute is an object
        const regex = new RegExp('{.*}')
        const match = attr.value.match(regex)
        if (match.length) {
          config.class = extractObjectAttribute(self, attr.value)
        }
        break
      case 'v-show':
        if (!config.directives) config.directives = []
        config.directives.push({
          name: 'show',
          rawName: 'v-show',
          value: self[attr.value],
          expression: attr.value
        })
        break
      case 'v-model':
        if (!config.directives) config.directives = []
        config.directives.push({
          name: 'model',
          rawName: 'v-model',
          value: self[attr.value],
          expression: attr.value
        })
        // Model bind needs the change event listener
        if (!config.on) config.on = {}
        config.on.change = $event => {
          const $el = $event.target
          if ($el.hasOwnProperty('checked')) {
            const checked = $el.checked ? true : false
            self[attr.value] = checked
          } else {
            self[attr.value] = $el.value
          }
        }
        break
      case 'v-on:input':
        if (!config.on) config.on = {}
        config.on.input = self[attr.value]
        break
      case 'v-on:focus':
        if (!config.on) config.on = {}
        config.on.focus = self[attr.value]
        break
      case 'v-on:blur':
        if (!config.on) config.on = {}
        config.on.blur = self[attr.value]
        break
      case 'v-slot':
        const slotName = attr.value ?? 'default'
        config.slot = slotName
        break
      default:
        if (attr.name.indexOf('v-bind:') === 0) {
          if (!config.attrs) config.attrs = {}
          const attrName = attr.name.replace('v-bind:', '')
          config.attrs[attrName] = self[attr.value]
        } else {
          if (!config.attrs) config.attrs = {}
          config.attrs[attr.name] = attr.value
        }
        break
    }
  }

  return config
}

export const parseChildrenToRender = (
  self,
  $element,
  cEl,
  childrenConf = []
) => {
  if (!$element[0]) return childrenConf

  // When passed a <template> the content is inserted in a DocumentFragment
  if ('content' in $element[0] && !$element[0].childNodes.length)
    $element = [$element[0].content]

  for (let i = 0; i < $element[0].childNodes.length; i++) {
    const son = $element[0].childNodes[i]
    // Text node
    if (son.nodeName === '#text') {
      childrenConf.push(son.nodeValue)
    } else if (son.nodeName.indexOf('#') === -1 && son.tagName) {
      // Dom element node
      const $el = Zepto(son)
      const text = innerText(son)
      const content = text ? text.trim() : ''
      const hasFragment = !!(
        son.content &&
        son.content.children &&
        son.content.children.length
      )
      const hasChildren = !!(
        (son.children && son.children.length) ||
        hasFragment
      )
      const isAttr = son.getAttribute('is')
      const hasIf = !!son.attributes['v-if']
      if (hasIf && !self[son.attributes['v-if']]) continue

      childrenConf.push(
        cEl(
          isAttr || son.tagName.toLowerCase(),
          parseToRenderConfig(self, $el),
          hasChildren ? parseChildrenToRender(self, $el, cEl) : content || ''
        )
      )
    }
  }

  return childrenConf
}

export const render = (self, cEl, $element) => {
  const isAttr = $element[0].getAttribute('is')
  return cEl(
    isAttr || 'div',
    parseToRenderConfig(self, $element),
    parseChildrenToRender(self, $element, cEl)
  )
}
