import { has, clone } from 'underscore'
import extend from 'deep-extend'
import { updatedDiff } from 'deep-object-diff'
import PreloadedAssets from '@/configuration/PreloadedAssets'

/**
 * Second config load - Now we know which kind of form we are rendering, we
 * override the main config if it's necessary
 *
 * @param {Partial<Locator>}
 * @see KJS-3565 Update function prototype
 */
export const setupConfiguration = ({ $store, themeHandler }) => {
  const { isFormPopin, isSmartForm } = $store.getters
  const isRegularPopin = isFormPopin && !isSmartForm
  if (has(window, 'KR_CONFIGURATION')) {
    // Get the unlinked data of object
    let config = clone(window.KR_CONFIGURATION)
    themeHandler.checkConflicts(config)

    // If there is a default config, reset the values
    if ($store.state.defaultConfig) {
      extend(config, $store.state.defaultConfig)
    }

    // Popin specific config - override the main props
    if (isRegularPopin && config.popin) {
      // First, save the default values overrided by specific popin config
      config.defaultConfig = updatedDiff(config.popin, config)
      extend(config, config.popin)
    }

    // Smartform specific config - override the main props
    if (isSmartForm && config.smartform) {
      // First, save the default values overrided by specific smartform config
      config.defaultConfig = updatedDiff(config.smartform, config)
      extend(config, config.smartform)
    }

    if (config.fields && config.fields.icons) {
      extend(PreloadedAssets, config.fields.icons)
    }

    // Extend the store default state with the data defined in KR_CONFIGURATION
    $store.dispatch('update', config)
  }
}

export const setupConfigurationEvents = ($bus, eventsConfig) => {
  for (const event in eventsConfig) {
    // Deduce it from the root evens
    const splittedKey = event.replace(/([A-Z])/g, ' $1').split(' ')
    // Remove the 'on' part
    splittedKey.shift()
    // Set the listener
    $bus.$on(`krypton.${splittedKey.join('.').toLowerCase()}`, message => {
      eventsConfig[event](message, Zepto)
    })
  }
}
