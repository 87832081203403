export const debounce = (fn, delay) => {
  let timeoutRef

  return (...args) => {
    const laterCall = () => {
      clearTimeout(timeoutRef)
      fn(...args)
    }
    clearTimeout(timeoutRef)
    timeoutRef = setTimeout(laterCall, delay)
  }
}
