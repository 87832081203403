/**
 * This mixins is currently extended by CardIcons & MethodsGroup components.
 * It sorts icons between those directly displayed and the ones in the tooltip.
 * It also provided methods to evaluate the maximum number of icons that can
 * be displayed given a limit width, an absolute maximum and a function
 * that calculate the width of the icons container.
 *
 * @since KJS-2357
 */
export const IconContainerMixin = {
  data() {
    return {
      icons: {
        all: [],
        number: 0,
        main: [],
        outstanding: []
      }
    }
  },
  computed: {
    hasOutstandingIcons() {
      return this.icons.outstanding.length > 0
    },
    tooltipLabel() {
      return this.hasOutstandingIcons ? `+${this.icons.outstanding.length}` : ''
    }
  },
  methods: {
    setIconsNumber(nb) {
      this.icons.number = nb
      this.icons.main = this.icons.all.slice(0, nb)
      this.icons.outstanding = this.icons.all.slice(nb)
    },
    calculateMaximumIcons(maxWidth, absoluteMax, calcFn) {
      let n = absoluteMax
      while (n > 1) {
        const width = calcFn(n)
        if (width <= maxWidth) {
          return n
        }
        n -= 1
      }
      return 1
    },
    /**
     * Dynamically measures gap between icons.
     * Require final component to render icons with ref="icons".
     *
     * @since KJS-2357
     */
    calculateGap(iconWidth) {
      if (!this.$refs.icons[0] || !this.$refs.icons[1]) {
        return 6
      }
      const i1left = this.$refs.icons[0].getBoundingClientRect().left
      const i2left = this.$refs.icons[1].getBoundingClientRect().left
      return Math.max(i2left - i1left - iconWidth, 6)
    }
  }
}
