/**
 * Mixin to manage the button label visibility
 */
export default {
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    marginBtns: {
      type: Number,
      default: 0
    }
  },
  computed: {
    labelClasses() {
      return {
        'kr-method-btn__label--hidden': !this.showLabel
      }
    },
    buttonLabelClasses() {
      return {
        'kr-method-btn--no-label': !this.showLabel
      }
    }
  }
}
