import { mapState } from 'vuex'

import Events from '@/configuration/Events'

/**
 * Listen to bus events, on krypton.smartform.warningFlash event, switch
 * "applyWarningStyle" value for the duration specified in theme config
 * in order to remove class after CSS animation is over.
 *
 * @since KJS-2614
 */
export const FlashWarningSubscriberMixin = {
  data() {
    return {
      applyWarningStyle: false
    }
  },
  computed: {
    ...mapState({
      flashDuration: state => state.form.smartform.methodLabel.flashDuration
    })
  },
  created() {
    this.$busOn(Events.krypton.smartform.warningFlash, () => this.flash())
  },
  methods: {
    flash() {
      this.applyWarningStyle = true
      setTimeout(() => {
        this.applyWarningStyle = false
      }, this.flashDuration * 1000)
    }
  }
}
