var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (!_vm.hasValidToken && !_vm.isSmartFormPopin) || _vm.forceSkeleton
    ? _c(
        "div",
        { staticClass: "kr-skeleton-wrapper" },
        [
          _c("div", { staticClass: "kr-skeleton-header kr-skeleton" }),
          _vm._l(4, function (n) {
            return _c("div", {
              key: n,
              staticClass: "kr-skeleton-method-label kr-skeleton",
            })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }