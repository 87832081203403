/**
 * Handles the deletion at a given position of the
 * cursor.
 *
 * @param {Number} position The position of the cursor
 * @param {String} value The current value of the field
 * @return {Object} The result of the operation as {prevent:true, newString: 'XX'}
 */
export const deleteHandler = function (position, value) {
  let newString = ''
  if (!value) value = '' // Security check to be sure that value is a string

  if (position == 1) {
    // Remove the first char but keep space on the second char
    newString = `${value.substring(1, 2)} ${value.substring(2)}`

    // If the original value was length 2, then remove the spaces
    if (value.replace(' ', '').length <= 2)
      newString = newString.replace(' ', '').replace('/', '')

    return [newString, position - 1]
  } else if (position == 2 || position == 3) {
    // Replace the second char by a " " char
    newString = `${value.substring(0, 1)} ${value.substring(2)}`

    // If the original value was length 2, then remove the spaces
    if (value.length <= 2) newString = newString.replace(' ', '')

    return [newString, 1]
  } else if (position == 4) {
    // Remove the char at position 4 and remove the last blank space
    if (value.length > 4) {
      newString = `${value.substring(0, 3)}${value.substring(4, 5)}`

      return [newString, position - 1]
    } else {
      // We are removing the first digit of year and there is not
      // more digits on the year. Remove the slash and position the
      // cursor after the second digit of month
      newString = value.substring(0, 2)
      return [newString, 2]
    }
  } else if (position == 5) {
    // Just remove the last character
    newString = value.substring(0, 4)
    return [newString, position - 1]
  }

  return [value, position]
}

export const inputHandler = function (position, value, digit, magic) {
  position = parseInt(position, 10)
  if (value.length == position) {
    // No inner edit, just logic to add values
    if (position == 1) {
      // Special usecase , we should to use a valid month
      let firstInteger = parseInt(digit, 10)
      if (magic && firstInteger > 1) {
        return [`0${firstInteger}/`, 3]
      } else {
        return [digit, 1]
      }
    } else if (position == 2) {
      if (magic) {
        // Check if greater or not than 12 the result
        let firstDigit = parseInt(value[0], 10)
        let nextDigit = parseInt(digit, 10)
        // 0+any -> 0{any}/[cursor]
        if (firstDigit == 0 && nextDigit != 0) return [value + '/', 3]
        // Do not allow : 00
        if (firstDigit == 0 && nextDigit == 0) return [`${firstDigit}`, 1]
        if (firstDigit == 1 && nextDigit < 3) return [value + '/', 3]
        return [`0${firstDigit}/${nextDigit}`, 4]
      } else {
        return [value + '/', 3]
      }
    } else if (position == 4) {
      return [value, 4]
    } else if (position == 3) {
      // Put the slash and move the input char to the first digit of year
      return [value.substring(0, 2) + '/' + digit, 4]
    } else {
      return [value, position]
    }
  } else {
    /**
     * Edit inline : It works different depending on the position
     * due to month validation and override
     */
    if (position == 2 && value.length > 2 && value[2] == ' ') {
      // Trying to override the space before slash
      // remove the space, will be filled by the char
      return [value.replace(/\s/g, ''), 2]
    } else if (position == 1 && value[1] == ' ' && value[2] == ' ') {
      if (magic) {
        let numberDigit = parseInt(digit, 10)
        if (numberDigit < 2) {
          // Allow to rewrite the first char
          return [`${digit} /` + value.substring(4), 1]
        } else {
          // We rewrite the output as 0{digit} and put the cursor at
          // position 2
          return [`0${digit}/` + value.substring(4), 2]
        }
      } else {
        return [`${digit} /` + value.substring(4), 1]
      }
    } else if (position == 1 && (value[0] == '1' || value[0] == '0')) {
      return [`${value.substring(0, 2)}/${value.substring(4)}`, 1]
    } else if (position == 1 && /^\d{2}.*/.test(value)) {
      return [`${value.substring(0, 2)}/${value.substring(4)}`, 1]
    } else if (position == 2 && /^\d{3}.+/.test(value)) {
      return [`${value.substring(0, 2)}/${value.substring(4)}`, 2]
    } else if (position == 3 && value.length > 4 && value[3] == '/') {
      // Overwrite the first digit of year
      return [`${value.substring(0, 2)}/${digit}${value[5]}`, 4]
    }

    return [value, position]
  }
}

export const leftArrowHandler = (position, value) => {
  if (position == 0) {
    return [value, 0]
  } else if (0 < position) {
    return [value, position - 1]
  }
}
