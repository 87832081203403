<template lang="pug">
button(
  ref="content"
  kr-resource
  type="button"
  v-show="isVisible"
  :class="dynamicClasses"
  :style="styles"
  :disabled="isDisabled"
  v-html="payBtnHTML"
  @click="btnClick"
  @keyup.9="tabReport"
  @keydown.9="tabReport"
  tabindex=1
)
</template>

<script>
import { FormPaymentButtonMixin } from '@/host/components/mixins/FormPaymentButton'
import { StandalonePaymentButtonMixin } from '@/host/components/mixins/StandalonePaymentButton'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'

import { mapGetters } from 'vuex'
import Events from '@/configuration/Events'

export default {
  name: 'KryptonPaymentButton',
  mixins: [
    FormPaymentButtonMixin,
    StandalonePaymentButtonMixin,
    SmartFormClickMixin
  ],
  computed: {
    ...mapGetters([
      'activeFormId',
      'isExtrasFormVisible',
      'cardsFormExpanded',
      'outsideCardsForm'
    ]),
    isLoading() {
      return (
        !this.hasError &&
        this.activeFormId === this.context?.formId &&
        (this.processingPayment === true || this.spinnerVisible) &&
        !this.isExtrasFormVisible
      )
    },
    canDetach() {
      return (
        !this.isSinglePaymentButton &&
        this.cardsFormExpanded &&
        !this.outsideCardsForm
      )
    }
  },
  created() {
    // Payment trigger
    this.$busOn(Events.krypton.form.submit, ({ formId }) => {
      if (!this.isDisabled && this.context.formId === formId) {
        this.disableButton()
        this.cleanError()
        this.$bus.$emit(Events.krypton.form.pay, this.context.formId)
      }
    })
  },
  methods: {
    async btnClick(e) {
      // Resolve interceptors first
      try {
        await this.intercept({
          name: 'paymentButton',
          args: [this.context.formId]
        })
      } catch (e) {
        return false
      }

      if (
        await this.interruptsExecution(
          'CARDS',
          'beforePaymentStart',
          this.context.formId
        )
      )
        return false

      e.preventDefault()
      if (!this.isDisabled) {
        this.disableButton()
        this.cleanError()
        this.$bus.$emit(Events.krypton.form.pay, this.context.formId)
        this.focusHelper()
      }
    }
  }
}
</script>
@/host/components/mixins/StandalonePaymentButton
