import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

export const ExpiryValidator = {
  computed: {
    ...mapState('field', ['required', 'focus'])
  },
  data() {
    return {
      MAX_YEARS: 30
    }
  },
  watch: {
    inputValue: 'validate',
    required: 'validate',
    focus(focus) {
      if (!focus) this.hasError = !this.validate()
    }
  },
  methods: {
    validate() {
      const value = this.inputValue

      // isViable if value presseted or value is not too long and date is valid
      const isViable =
        (!this.required && !value.length) ||
        !!this.paymentMethodToken ||
        (!this.required && !value.length) ||
        (value.replace(/\s/g, '').length === 5 && this.dateValidation(value))

      this.isViable = !!isViable

      if (value.replace(/\s/g, '').length === 5) this.hasError = !isViable
      else this.hasError = false

      return !!isViable
    },
    dateValidation(value) {
      // Check the format
      if (!/^\d{2}\/\d{2}$/.test(value)) return false

      // Value validation
      let [month, year] = value.split('/')
      let today = new Date()
      let currentMonth = today.getMonth() + 1
      let currentYear = `${today.getFullYear()}`

      if (`${year}`.length > 2) return false
      if (`${year}`.length == 2) {
        year = `${currentYear.substring(0, 2)}${year}`
      }

      currentMonth = parseInt(currentMonth, 10)
      currentYear = parseInt(currentYear, 10)

      let capYear = currentYear + this.MAX_YEARS

      month = parseInt(month, 10)
      year = parseInt(year, 10)

      // If there are more than 30 years of difference then is not valid (in the future)
      if (year > capYear) return false
      if (year < currentYear) return false

      return (
        this.isValidMonth(month) &&
        (year > currentYear || (year == currentYear && month >= currentMonth))
      )
    },
    isValidMonth(month) {
      return month >= 1 && month <= 12
    }
  }
}

export const SecurityCodeValidator = {
  inject: ['context'],
  computed: {
    ...mapState('field', ['required']),
    ...mapState(['currency']),
    ...dynamicMapState('context.namespace', [
      'selectedBrand',
      'cvvSize',
      'testCard'
    ])
  },
  watch: {
    inputValue: 'validate',
    required: 'validate',
    focus(focus) {
      if (!focus) this.hasError = !this.validate()
    }
  },
  methods: {
    /**
     * Validates the CVV value
     */
    validate() {
      const value = this.inputValue

      const isViable =
        (!this.required && !value.length) ||
        (!this.isTooShort(value) && this.isValueValid(value)) ||
        this.testCard

      this.isViable = !!isViable
      return !!isViable
    },
    /**
     * Checks if the value length is too short
     */
    isTooShort(value) {
      const length = value.length
      const shorterThanAcceptable = length <= 2

      return this.selectedBrand == 'AMEX'
        ? length < this.cvvSize - 1 || shorterThanAcceptable
        : length !== this.cvvSize || shorterThanAcceptable
    },

    isValueValid(value) {
      return parseInt(value) > 0 || this.currency !== 'PEN'
    }
  }
}
