<template lang="pug">
    extends templates/TextField.pug
</template>

<script>
import { mapState } from 'vuex'
/** MIXINS */
import { TextFieldMixin } from '@/host/components/mixins/TextField'

export default {
  name: 'KryptonCardHolderMail',
  mixins: [TextFieldMixin],
  data() {
    return {
      fieldName: 'cardHolderMail',
      autocomplete: 'email'
    }
  },
  computed: {
    ...mapState(['shopId'])
  },
  methods: {
    // HACK: KJS-2096. Email validation
    isEmailInvalid() {
      const shopIds = ['63935953']
      if (!~shopIds.indexOf(this.shopId)) return false // Not in the list - no validation

      // No match - the email is invalid
      if (
        !this.fieldValue.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      )
        return true
    },

    cleanValue() {
      this.fieldValue = this.fieldValue.trim()
    }
  }
}
</script>
