var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.value,
        expression: "value",
      },
    ],
    staticClass: "autofill",
    staticStyle: {
      position: "fixed",
      top: "-9999px",
      left: "-9999px",
      display: "inline",
    },
    attrs: {
      name: _vm.name,
      autocomplete: _vm.autocomplete,
      type: "text",
      tabindex: "0",
    },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        if ($event.target.composing) {
          return
        }
        _vm.value = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }