var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "kr-method-btn kr-method-btn--cards",
      class: _vm.dynamicClasses,
      style: _vm.styles.button,
      attrs: { type: "button", "kr-payment-method": "cards" },
      on: {
        click: function ($event) {
          return _vm.openMethod()
        },
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _vm.hasRadioButton
        ? _c("SmartFormRadioButton", {
            attrs: { checked: _vm.isSelected, hover: _vm.hover },
          })
        : _vm._e(),
      _c("SmartFormCardIcons", {
        attrs: { margin: 10, brands: _vm.cardBrands },
      }),
      _vm.isLoading ? _c("KryptonLoadingAnimation") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }