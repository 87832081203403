export const ClickOutsideMixin = {
  directives: {
    // Manage the click outside an element
    'click-outside': {
      bind(el, binding, vNode) {
        // Define Handler and cache it on the element
        const handler = e => {
          if (!el.contains(e.target) && el !== e.target) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler

        // add Event Listeners
        document.addEventListener('click', handler)
        document.addEventListener('touchstart', handler)
      },
      unbind(el, binding) {
        // Remove Event Listeners
        document.removeEventListener('click', el.__vueClickOutside__)
        document.removeEventListener('touchstart', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    }
  }
}
