import { clone, isFunction, isArray, isObject } from 'underscore'
import configuration from '@/configuration/StoreConfiguration'
import BaseStore from '@/common/base/BaseStore'

export default class StoreFactory {
  /**
   * Creates a new store from the pattern of the store configuration
   * always based on the same prototype: BaseStore
   */
  create(name, values = {}) {
    const storeConf = name in configuration ? configuration[name] : {}
    const store = new BaseStore()
    store._name = name

    // anonymous checker
    store.is = checkName => checkName === name

    if (storeConf?.fields) {
      store.getFields = () => storeConf.fields
    }

    if (isArray(storeConf) && storeConf.length) {
      store.getFields = () => clone(storeConf)
    }

    // Read default values
    if (storeConf?.defaults) {
      store.getDefaults = () => clone(storeConf['defaults'])
    }

    store.init(values)

    // Inject methods of the configuration
    if (storeConf?.methods && isObject(storeConf.methods)) {
      for (const methodKey in storeConf.methods) {
        store[methodKey] = (...args) => {
          return storeConf.methods[methodKey](store, ...args)
        }
      }
    }

    // Some bootstrap to trigger?
    if (storeConf?.created && isFunction(storeConf.created)) {
      storeConf.created(store)
    }

    return store
  }
}
