var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "kr-method-tooltip",
      class: [_vm.tooltipVisible ? "kr-hover" : ""],
      on: {
        click: _vm.clickLabel,
        mouseover: function ($event) {
          return _vm.setMouseOver("activator")
        },
        mouseleave: function ($event) {
          return _vm.unsetMouseOver("activator")
        },
      },
    },
    [
      _vm._v(_vm._s(_vm.label)),
      _c(
        "div",
        {
          ref: "content",
          staticClass: "kr-method-tooltip-content",
          class: _vm.dynamicClasses,
          style: _vm.tooltipContentStyles,
          on: {
            mouseover: function ($event) {
              return _vm.setMouseOver("content")
            },
            mouseleave: function ($event) {
              return _vm.unsetMouseOver("content")
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }