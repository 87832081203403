<template lang="pug">
  extends ../../../controls/templates/FormError.pug
  </template>

<script>
import FormError from '@/host/components/mixins/FormError'
import { MethodDescriptor } from '@/common/model/PaymentMethod'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SmartFormGridCardsError',
  mixins: [FormError],
  computed: {
    ...mapGetters(['isSmartForm', 'isSmartFormOpen', 'hasToken']),
    ...mapState({
      paymentMethods: state => state.smartForm.availablePaymentMethods
    }),
    showFormError() {
      return (
        this.hasData &&
        !this.isSmartFormOpen &&
        !this.isWalletError &&
        (this.hasOnlyCards || this.isCardsError)
      )
    },
    hasOnlyCards() {
      return (
        (this.paymentMethods.length === 1 &&
          this.paymentMethods[0] === 'CARDS') ||
        !this.isSmartForm
      )
    },
    isCardsError() {
      const paymentMethod = this.error?.paymentMethod
      if (!paymentMethod) return true
      return MethodDescriptor.fromString(paymentMethod).name === 'CARDS'
    },
    // Check if the error comes from a wallet payment method
    isWalletError() {
      const paymentMethod = this.error?.paymentMethod
      // The error has no payment method attached
      if (!paymentMethod) return false
      return this.hasToken(MethodDescriptor.fromString(paymentMethod).metadata)
    }
  },
  methods: {}
}
</script>
