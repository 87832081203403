import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['isPopinOpen', 'redirectIframeOpen']),
    ...mapGetters(['isAnyDialogOpen']),
    ...mapState({
      isModalOpen: state => state.modal.isOpen,
      isModalClosing: state => state.modal.isClosing,
      isSmartFormOpen: state => state.smartForm.isOpen
    }),
    scrollLock() {
      return (
        this.isPopinOpen ||
        this.redirectIframeOpen ||
        this.isModalOpen ||
        this.isModalClosing ||
        this.isSmartFormOpen ||
        this.isAnyDialogOpen
      )
    }
  },
  watch: {
    scrollLock(isHidden) {
      document.querySelector('body').style.overflow = isHidden ? 'hidden' : null
    }
  }
}
