import { MethodDescriptor } from '@/common/model/PaymentMethod'
import FormError from '@/host/components/mixins/FormError'

export default {
  mixins: [FormError],
  computed: {
    // Check if the error has no metadata attached
    isRegularError() {
      // The error has no payment method attached
      const paymentMethod = this.error?.paymentMethod
      if (!paymentMethod) return false
      return !MethodDescriptor.fromString(paymentMethod).metadata
    }
  }
}
