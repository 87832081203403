<template lang="pug">
.kr-redirect(:class="[formType]")
  .kr-redirect-modal.kr-fullscreen(
    v-if="redirectionType === 'iframe'",
    :style="dynamicStyles.modal"
  )
    .kr-redirect-container(v-if="showingIFrame", :style="dynamicStyles.container")
      .kr-iframe-container(v-if="iframeReady", :style="dynamicStyles.container")
        div(v-if="isV1", :style="dynamicStyles.container")
          iframe(
            :id="browserId",
            :style="dynamicStyles.iframe",
            :src="redirectUrl",
            scrolling="yes",
            allowtransparency="true",
            frameborder="0",
            name="browser-frame",
            :allow="allowedPermissions"
          )
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Events from '@/configuration/Events'
import { getPermissionPolicies } from '@/common/util/location'

/** MIXINS */
import PopinMixin from '@/common/components/mixins/styles/Popin'

/** COMPONENTS */
import CloseButton from '@/common/components/CloseButton'

export default {
  name: 'KryptonRedirectIframe',
  components: { CloseButton },
  mixins: [PopinMixin],
  props: {
    redirectUrl: {
      type: String,
      default: ''
    },
    redirectionHost: {
      type: String,
      default: ''
    },
    browserId: {
      type: String,
      default: ''
    },
    hideAtStartup: {
      type: String,
      default: ''
    },
    hideTimeout: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showingIFrame: false,
      iframeReady: false,
      hasCloseButton: false
    }
  },
  computed: {
    ...mapGetters(['cappedRedirectSize']),
    ...mapState(['formType', 'redirectVersion', 'redirectionType']),
    ...mapState({
      windowConf: state => state.window,
      activeMethod: state => state.smartForm.activeMethod
    }),
    ...mapState({
      isV1: ({ redirectVersion }) => redirectVersion === 'v1'
    }),
    dynamicStyles() {
      return {
        modal: {
          ...this.modalStyles,
          height: '100%',
          width: '100%'
        },
        container: { width: '100%', height: '100%' },
        iframe: {
          width: '100%',
          height: '100%',
          position: 'relative'
        }
      }
    },
    allowedPermissions() {
      return getPermissionPolicies(this.redirectionHost)
    }
  },
  created() {
    this.$bus.$on(Events.krypton.popin.load, this.onLoad)
  },
  mounted() {
    this.iframeReady = true

    // Hide at startup logic
    const isHided = parseInt(this.hideAtStartup, 10) == 1
    if (isHided) {
      setTimeout(this.showIFrame, parseInt(1000 * this.hideTimeout, 10))
    } else {
      this.showIFrame()
    }
  },
  methods: {
    ...mapActions(['finishRedirection', 'error']),
    onLoad() {
      this.$proxy.send(this.$storeFactory.create('popinLoad'))
    },
    showIFrame() {
      this.showingIFrame = true
    }
  }
}
</script>
