var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.redirectIframeOpen,
          expression: "!redirectIframeOpen",
        },
      ],
      staticClass: "kr-popin-modal-footer",
      class: { "kr-secure-iframe-visible": _vm.redirectIframeOpen },
      attrs: { "kr-resource": "" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isLogoVisible,
              expression: "isLogoVisible",
            },
          ],
          staticClass: "kr-whitelabel-logo",
          attrs: { "aria-hidden": "true" },
        },
        [
          _vm.logo.name
            ? _c("img", {
                class: "kr-logo-" + _vm.logo.name,
                attrs: {
                  src: _vm.baseAddress + "/logos/footer/" + _vm.logo.file,
                },
              })
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }