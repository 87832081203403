import { mapGetters } from 'vuex'

/**
 * Used to set popin width and height.
 *
 * @since KJS-2512
 */
export const PopinSizeMixin = {
  computed: {
    ...mapGetters(['cappedRedirectSize']),
    popinSize() {
      const { height, width } = this.cappedRedirectSize
      return {
        height,
        width
      }
    }
  }
}
