<template lang="pug">
    .kr-label.default(
        v-if="label"
        v-html="contentHTML"
        v-on:click="handleClick"
        v-on:touchstart="handleClick"
    )
</template>

<script>
import Events from '@/configuration/Events'
import { fieldNameAsTranslationKey } from '@/common/util/fieldName'
import { mapState, mapGetters } from 'vuex'
import { object } from 'underscore'

/** MIXINS */
import BrandMixin from '@/common/components/mixins/Brand'

export default {
  name: 'KryptonLabel',
  mixins: [BrandMixin],
  props: {
    fieldName: {
      type: String,
      default: ''
    },
    availableOptions: {
      type: Array,
      default: () => []
    },
    forcedVisibility: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentHTML: '',
      content: '',
      previousLabel: '',
      sentUpdate: ''
    }
  },
  computed: {
    ...mapState(['language', 'contentLabels', 'labels']),
    ...mapState({
      formLabels: state => state.form.labels,
      formLabelsContent: state => state.form.labels.content,
      formLabel(state) {
        return state.form.labels[this.fieldName]
      },
      formPlaceholder(state) {
        return state.form.placeholders[this.fieldName]
      }
    }),
    ...mapGetters(['translate', 'isIos']),
    label() {
      return this.formLabel || this.translationLabel || ''
    },
    translationLabel() {
      return this.translate(
        `label_${fieldNameAsTranslationKey(this.fieldName)}`
      )
    },
    hasNoOptions() {
      return (
        this.availableOptions.length === 1 && this.availableOptions[0].id < 1
      )
    }
  },
  watch: {
    formLabelsContent(newVal) {
      if (newVal && newVal.length) this.content = newVal
      this.recalculateLabel()
    },
    selectedBrand: 'recalculateLabel',
    formLabel: 'recalculateLabel',
    language: 'recalculateLabel',
    availableOptions: 'recalculateLabel'
  },
  created() {
    this.startSubscriptions()
  },
  mounted() {
    // Only for doRegister label
    this.content = this.formLabelsContent
    if (this.forcedVisibility && !this.formLabelsContent.length) {
      this.content = '<label class="kr-checkbox-label">{label}</label>'
    }
    this.recalculateLabel()
  },
  methods: {
    startSubscriptions() {
      this.$busOn(Events.krypton.form.label.content, message => {
        if (message.content) {
          this.$store.dispatch('update', {
            form: { labels: { content: message.content } }
          })
        }
      })

      // Installments label update
      this.$busOn(Events.krypton.field.event, message => {
        if (
          message.type === 'select' &&
          message.name === 'installmentNumber' &&
          message.name === this.fieldName &&
          message.formId === this.context.formId
        ) {
          this.updateStore(this.translate(`label_installment_number`))
        }
      })
    },
    recalculateLabel() {
      let label = this.label

      // Only for installments, we have
      if (this.fieldName === 'installmentNumber') {
        if (this.isDefaultBrand) {
          // With default brand, fill the card number label
          label =
            this.formPlaceholder.default ||
            this.translate('placeholder_default_installment_number')
        } else if (this.hasNoOptions) {
          // With debit brand, Single payment label
          label =
            this.formPlaceholder[
              `singlePayment${!this.isDebitBrand ? 'Credit' : ''}`
            ] ||
            this.translate(
              `installment_single_payment${!this.isDebitBrand ? '_credit' : ''}`
            )
        } else {
          // Brand detected and it's not debit, use the regular installments label
          label = this.translate('label_installment_number')
        }
        // For first installment delay
      } else if (this.fieldName === 'firstInstallmentDelay') {
        // If has no options but the brand's been detected, use 'without' label
        if (!this.isDefaultBrand && this.hasNoOptions) {
          label =
            this.contentLabels[this.fieldName].without ||
            this.translate('first_installment_delay_without')
        } else if (this.isDefaultBrand) {
          label =
            this.formPlaceholder ||
            this.translate('placeholder_first_installment_delay')
        } else {
          label =
            this.labels[this.fieldName] ||
            this.translate('label_first_installment_delay')
        }
      }

      this.updateStore(label)
      this.contentHTML = `${this.content}`.replace('{label}', label)
    },
    updateStore(value) {
      if (!this.fieldName) return
      if (this.isTranslation(value)) return
      const labels = object([[this.fieldName, value]])
      const toBeSentUpdate = JSON.stringify(labels)
      // Check if the store should be updated
      if (
        this.formLabels[this.fieldName] !== value &&
        !this.paymentMethodToken &&
        toBeSentUpdate != this.sentUpdate
      ) {
        this.$store.dispatch('update', { form: { labels } })
        // the sent data is stored on the instance to avoid sent multiple times the same update
        this.sentUpdate = JSON.stringify(labels)
      }
    },
    isTranslation(value) {
      return value === this.translationLabel
    },
    /**
     * Forces a focus on the proper field to avoid issues with the cursor
     */
    handleClick(event) {
      if (this.isIos) {
        if (event.type === 'touchstart' || event.type === 'click') {
          this.$bus.$emit(Events.krypton.field.focus, {
            formId: this.context.formId,
            name: this.fieldName
          })
        }
      }
    }
  }
}
</script>
