import appLoader from '@/common/loader/app'

/** COMPONENTS */
import SmartButton from '@/host/components/smartbutton/SmartButton'
import SmartButtonError from '@/host/components/smartbutton/Error'
import SimpleModal from '@/host/components/controls/SimpleModal'
import KryptonLayer from '@/host/components/controls/KryptonLayer'

export default locator => {
  return ({ $el, id }) => {
    return appLoader(locator, $el, {
      el: `.kr-smart-button-wrapper[kr-smart-button-id="${id}"]`,
      components: { SmartButton, SimpleModal, KryptonLayer, SmartButtonError }
    })
  }
}
