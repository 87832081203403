var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-partial-payment", class: [_vm.hover ? "kr-hover" : ""] },
    [
      _c(
        "div",
        {
          staticClass: "kr-partial-payment-remove-icon-container",
          attrs: { role: "button" },
          on: {
            mouseover: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
            click: function ($event) {
              return _vm.cancelPartialPayment()
            },
          },
        },
        [
          _c("div", {
            staticClass: "kr-partial-payment-remove-icon",
            domProps: { innerHTML: _vm._s(_vm.removeIcon) },
          }),
        ]
      ),
      _c("BrandIcon", { attrs: { method: _vm.transaction.brand } }),
      _vm.transaction.paymentMethodType === "CARD"
        ? _c("SmartFormMaskedCardLabel", {
            attrs: {
              value: _vm.formatCardNumber(
                _vm.transaction.id,
                _vm.transaction.brand
              ),
            },
          })
        : _c("label", { staticClass: "kr-partial-payment-opm-label" }, [
            _c("span", [_vm._v(_vm._s(_vm.transaction.id))]),
          ]),
      _c("span", { staticClass: "kr-partial-payment-amount" }, [
        _vm._v(_vm._s(_vm.getAmountLabel(_vm.transaction.amount))),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }