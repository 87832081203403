import _ from 'underscore'
import Events from '@/configuration/Events'
import { unzip } from '@/common/util/zip'
import { findWhere } from 'underscore'

import endpointMap from '@/configuration/sources/EndpointMap.yml'
import tokens from '@/configuration/sources/Tokens.yml'
export default class TokenReader {
  constructor($locator) {
    this.$locator = $locator
    this.$bus = $locator.$bus
    this.$store = $locator.$store
    this.regexMetadata =
      /^([0-9a-z]{2})([^:]{22})([\d]{2}[0-9A-Z]{1})([^:]{17,})+$/
  }

  decode(token) {
    const _this = this
    const logger = this.$locator.logger

    return new Promise((resolve, reject) => {
      // Extracts metadata from token
      const match = _this.regexMetadata.exec(token)

      // The last 2 chars are the version
      let stringTail = match[4]
      // all chars except last 4
      const rawDna = JSON.parse(
        unzip(stringTail.substring(0, stringTail.length - 4))
      )
      // Global with the DNA
      window.KR_RAW_DNA = JSON.parse(JSON.stringify(rawDna))
      // log dna set
      logger.log('info', `DNA set`, { dna: rawDna })

      resolve(rawDna)
    })
  }
  /**
   * @param {{invalidToken?: string}} customErrors
   */
  verifyToken(formToken, acceptEmpty = true, customErrors = {}) {
    const { endpointDetector } = this.$locator
    const { testKeys } = this.$store.state

    // This string will be replaced by the default demo token, so we skip the verification
    formToken = this.tokenReplacements(formToken, acceptEmpty)

    // Token verification
    const hasPrefix = /^^[0-9A-Za-z]{2}.*$/.test(formToken)
    const isToken = this.regexMetadata.exec(formToken)

    if (!hasPrefix || !isToken) {
      const error = customErrors?.invalidToken || 'CLIENT_100'
      this.$store.dispatch('error', error)
      testKeys && this.$bus.$emit(Events.krypton.data.errorAlert)
      throw error
    } else if (!endpointDetector.detect(formToken)) {
      this.$store.dispatch('error', 'CLIENT_102')
      throw 'CLIENT_102'
    } else {
      endpointDetector.setEndpointDomain(formToken)
      return formToken
    }
  }

  setToken(formToken) {
    this.setWhiteLabel(formToken)
    this.$store.dispatch('setToken', formToken)

    return formToken
  }

  setWhiteLabel(formToken) {
    const prefix = formToken.substring(0, 2)
    const endpoint = findWhere(endpointMap.items, { prefix })
    if (endpoint)
      this.$store.dispatch('update', {
        whitelabel: endpoint.endpoint.whitelabel
      })
  }

  tokenReplacements(formToken, acceptEmpty = true) {
    // replace specific string with the default demoToken
    if (formToken === tokens.demoTokenReplacement) return tokens.demoToken
    // replace empty token by the one configured as noToken
    else if ((formToken === '' || formToken === undefined) && acceptEmpty)
      return tokens.noToken
    else if (!formToken.indexOf(tokens.demoTokenPrefix)) {
      // tokens starting by the specified prefix are also demo
      // slice the token after checking demo
      this.$store.dispatch('update', { prefixedDemoToken: true })
      return formToken.slice(tokens.demoTokenPrefix.length)
    }

    this.$store.dispatch('update', { prefixedDemoToken: false })
    // no replacements to be done to the formToken
    return formToken
  }
}
