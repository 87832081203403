<template lang="pug">
  .kr-delete-button-wrapper(v-if="isVisible", v-on="$listeners", :class="dynamicClasses.wrapper")
    .kr-delete-button(@click="deleteCardHandler" :title="tooltip")
      .kr-delete-icon(:class="dynamicClasses.icon" v-html="icon")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { dynamicMapGetters } from '@/common/util/store'
import PreloadedAssets from '@/configuration/PreloadedAssets'

/** MIXINS */
import ConfigurationMixin from '@/host/components/mixins/Configuration'
import DeleteButtonMixin from '@/host/components/mixins/DeleteButtonMixin'

export default {
  name: 'KryptonDeleteButton',
  mixins: [ConfigurationMixin, DeleteButtonMixin],
  inject: ['context'],
  props: {
    method: { type: String, default: 'CARDS' },
    token: { type: String, default: '', required: true },
    location: { type: String, default: 'walletList' }
  },
  computed: {
    ...mapGetters(['isSmartForm', 'translate']),
    ...dynamicMapGetters('context.namespace', ['isWalletMyCards']),
    dynamicClasses() {
      return {
        wrapper: {
          'kr-visible': this.isVisible,
          'kr-disabled': this.isDisabled,
          'kr-card-list': this.location === 'walletList'
        },
        icon: {
          'kr-error': this.isInvalid
        }
      }
    },
    isVisible() {
      const onWalletList = this.location === 'walletList'
      if (this.isSmartForm) return onWalletList

      const onPan = this.location === 'pan'
      return this.isWalletMyCards && (onPan || onWalletList)
    },
    icon() {
      return PreloadedAssets.delete
    },
    tooltip() {
      return this.translate('tooltip_delete')
    }
  },
  methods: {
    deleteCardHandler() {
      this.deleteCard(this.method, this.token)
    }
  }
}
</script>
