import _ from 'underscore'
import IFrameStrategy from '@/ghost/workflow/strategy/IFrameStrategy'
import RegularStrategy from '@/ghost/workflow/strategy/RegularStrategy'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'

export default class StrategyDetector {
  constructor($locator) {
    this.$locator = $locator
  }

  /**
   * Return the Strategy instance from a given field and actionNewForm store
   */
  get(fieldName, formId) {
    const $locator = this.$locator
    const iframeHandler = this.$locator.iframeHandler
    const detectedStrategy = this.detect(fieldName)

    switch (detectedStrategy) {
      case 'iframe':
        return new IFrameStrategy(fieldName, $locator, formId)
      case 'regular':
      case 'boolean':
        return new RegularStrategy(fieldName, iframeHandler, $locator, formId)
    }
  }

  /**
   * Returns the detected strategy name
   */
  detect(fieldName) {
    const regularFields = [
      'installmentNumber',
      'firstInstallmentDelay',
      'identityDocumentType',
      'identityDocumentNumber',
      'cardHolderName',
      'cardHolderMail'
    ]
    const booleanFields = ['doRegister']
    let isHosted = ~fieldTypes.iframe.indexOf(fieldName)

    // Detects the strategy
    let strategy = 'memory'
    if (isHosted) strategy = 'iframe'
    if (regularFields.indexOf(fieldName) !== -1) strategy = 'regular'
    if (booleanFields.indexOf(fieldName) !== -1) strategy = 'boolean'

    return strategy
  }
}
