const prop = 'value'
const event = 'input'

/**
 * Allow to parent component to provide a v-model variable to bind its
 * property to the inner isActive property while both (the parent and the
 * toggelable components) may modify the same value and react accordingly.
 *
 * @since KJS-2162 (2022-09-15)
 */
export default {
  model: {
    prop,
    event
  },
  props: {
    [prop]: {
      required: false
    }
  },
  watch: {
    [prop](val) {
      this.isActive = !!val
    },
    isActive(val) {
      !!val !== this[prop] && this.$emit(event, val)
    }
  }
}
