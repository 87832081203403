<template lang="pug">
  .kr-extras-form-content.kr-embedded(krvue="")
    div(v-for="(conf, fieldName) in dna", :key="fieldName", :is="getComponentName(fieldName)", kr-resource)
    ExtrasFormError
    ExtrasPaymentButton
</template>

<script>
import { mapState } from 'vuex'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'
import KryptonInstallments from '@/host/components/fields/KryptonInstallments'
import KryptonFirstInstallmentDelay from '@/host/components/fields/KryptonFirstInstallmentDelay'
import KryptonDoRegister from '@/host/components/fields/KryptonDoRegister'
import KryptonIdentityDocType from '@/host/components/fields/KryptonIdentityDocType'
import KryptonIdentityDocNumber from '@/host/components/fields/KryptonIdentityDocNumber'
import KryptonCardHolderName from '@/host/components/fields/KryptonCardHolderName'
import KryptonCardHolderMail from '@/host/components/fields/KryptonCardHolderMail'
import ExtrasFormError from '@/host/components/extrasform/Error'
import ExtrasPaymentButton from '@/host/components/extrasform/PaymentButton'

export default {
  name: 'ExtrasFormContent',
  components: {
    KryptonInstallments,
    KryptonFirstInstallmentDelay,
    KryptonDoRegister,
    KryptonIdentityDocType,
    KryptonIdentityDocNumber,
    KryptonCardHolderName,
    KryptonCardHolderMail,
    ExtrasFormError,
    ExtrasPaymentButton
  },
  computed: {
    ...mapState({
      dna: state => state.extrasForm.dna
    })
  },
  methods: {
    getComponentName(fieldName = '') {
      return fieldTypes.components[fieldName] || 'KryptonField'
    }
  }
}
</script>
