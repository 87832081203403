import _ from 'underscore'
import { CoBrands, SubBrands } from '@/configuration/sources/Brands.yml'

export const normalize = function (value) {
  let normalizedValue = value.toLowerCase()
  return normalizedValue
}

export const normalizeDictionary = function (dict) {
  let keys = _.keys(dict)
  let cleanedDictionary = {}

  _.each(keys, key => {
    cleanedDictionary[key] = dict[key]
  })

  return cleanedDictionary
}

export const normalizeList = function (values) {
  let splittedValues = _.isArray(values) ? values : values.split('|')
  let normalizedValues = []
  splittedValues.forEach(splittedVal => {
    normalizedValues.push(splittedVal)
  })

  return normalizedValues
}

export function resolveBrand(brand, filterSubBranded) {
  let finalBrand = CoBrands.brands[brand] || CoBrands.onlyOneBrand[brand]

  // If no CoBrand resolution found search in SubBrands (if asked) or return the original brand
  if (!finalBrand)
    finalBrand = filterSubBranded ? SubBrands[brand] || brand : brand

  return finalBrand
}
