<template lang="pug">
    .kr-wallet-card-list(kr-resource, v-show="isVisible && !isThereOnlyOneCard")
      .kr-card-list-wrapper(v-if="isVisible && !isThereOnlyOneCard")
        .kr-card-element(
          v-for="card of cards",
          :key="card.token",
          :class="[`kr-${card.brand.toLowerCase()}`, isDeletedCard(card) ? 'kr-deleted' : '']",
          :data-card="card.fields.pan.value"
        )
          .kr-card-label(@click="selectedCard = card")
            input(type="radio", :id="card.token", :name="card.token", :value="card.token", :checked="selectedCard.token === card.token")
            label(:for="card.token")
              span {{formatCardNumber(card.fields.pan.value, card.brand)}}
          BrandIcon(:method="card.brand" @click="selectedCard = card")
          krypton-delete-button(:token="card.token", :method="`CARDS:${card.brand}`")
</template>

<script>
import { difference } from 'underscore'
import { mapState, mapGetters } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import { formatCardNumber } from '@/common/util/card'

/** COMPONENTS */
import BrandIcon from '@/host/components/controls/BrandIcon'
import KryptonDeleteButton from '@/host/components/controls/KryptonDeleteButton'

/** MIXINS */
import { FieldMixin } from '@/host/components/mixins/Field'
import BrandMixin from '@/common/components/mixins/Brand'

export default {
  name: 'KryptonWalletCardList',
  components: { BrandIcon, KryptonDeleteButton },
  mixins: [FieldMixin, BrandMixin],
  inject: ['context'],
  data() {
    return {
      fieldName: 'walletCardList',
      selectedCard: {},
      cards: [],
      deletedCards: []
    }
  },
  computed: {
    ...mapState(['formMode']),
    ...mapGetters(['isCardFormVisible']),
    ...mapState({
      dnaCards: state => state.dna?.tokens?.cards || [],
      isThereOnlyOneCard: state => state.dna?.tokens?.cards?.length === 1,
      isThereNoCard: state => state.dna?.tokens?.cards?.length === 0
    }),
    ...dynamicMapState('context.namespace', ['walletMode'])
  },
  watch: {
    selectedCard({ brand, token }) {
      if (this.formMode === 'default') return
      this.detectedBrands = [brand || 'DEFAULT']
      this.updateNonSensitiveValue(token ? { paymentMethodToken: token } : {})
    },
    isVisible(isVisible) {
      if (!isVisible && this.isCardFormVisible(this.context.formId)) {
        this.updateNonSensitiveValue({ paymentMethodToken: null })
      }
    },
    dnaCards: ['resetData', 'onDeleteCard'],
    walletMode: ['resetData', 'updateCards']
  },
  created() {
    this.resetData()
    this.updateCards()
  },
  methods: {
    formatCardNumber(card, brand) {
      return formatCardNumber(card, brand)
    },
    resetData() {
      if (this.walletMode === 'newCard') this.selectedCard = {}
      else if (this.isThereOnlyOneCard) this.selectedCard = this.dnaCards[0]
      else if (this.isThereNoCard) {
        this.$store.dispatch(`${this.context.namespace}/update`, {
          walletMode: 'newCard'
        })
      }
    },

    updateCards() {
      this.cards = this.dnaCards
      this.deletedCards = []
    },

    onDeleteCard(newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        newVal = newVal.map(x => x.token)
        oldVal = oldVal.map(x => x.token)
        this.deletedCards.push(difference(oldVal, newVal)[0])
      } else {
        this.updateCards()
      }
    },

    isDeletedCard({ token }) {
      return !!~this.deletedCards.indexOf(token)
    },

    focus() {},
    blur() {},
    onFocus() {},
    onBlur() {}
  }
}
</script>
