import { mapState, mapGetters } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import { intersection } from 'underscore'
import TreeReader from '@/slave/service/TreeReader.js'
import { logSentryException } from '@/common/util/sentry'

export const BrandDetectorMixin = {
  computed: {
    ...mapState('field', ['formTokenPrefix', 'mockTypeCarte']),
    ...mapState(['dna']),
    ...mapGetters(['brandSorter', 'brandsWithPriority', 'availableBrands']),
    ...dynamicMapState('context.namespace', ['testCard'])
  },
  watch: {
    async dna() {
      await this.setBrandDetectionMode()
      this.detectBrandsFromNumber(this.inputValue)
    },
    formTokenPrefix: 'resetBrandDetectionMode',
    forcedBrand() {
      this.detectBrandsFromNumber(this.inputValue)
    },
    mockTypeCarte() {
      this.resetBrandDetectionMode()
    }
  },
  async created() {
    await this.setBrandDetectionMode()
  },
  methods: {
    resetBrandDetectionMode() {
      const tokenPrefix = this.formTokenPrefix
      this.setBrandDetectionMode(tokenPrefix)
    },
    async setBrandDetectionMode(tokenPrefix = null) {
      const source = this.getTreeLocation(tokenPrefix)
      if (source) await this.loadTreeSource(source)
    },
    getTreeLocation(tokenPrefix = null) {
      const tree = TreeReader.getInstance(this.$locator)

      if (!tokenPrefix) tokenPrefix = this.formTokenPrefix

      // Load tree if defined
      const host = window.location.host
      const origin = window.location.origin
      if (
        tokenPrefix &&
        !tree.isTreeNeedToBeReloaded(host, origin, tokenPrefix)
      )
        return

      return tree.load(host, origin, tokenPrefix)
    },
    async loadTreeSource(source) {
      try {
        const tree = TreeReader.getInstance(this.$locator)
        // Loads the tree
        await tree.hydrate(source)
        // Tree loaded, replaces "detectBrandsFromNumber"
        this.setupBrandDetector(tree)
      } catch (error) {
        logSentryException(error, 'slave/components/Pan')
        console.error('An error occured while loading tree', error)
      }
    },
    // Reorder the brand list to preset the selected brand
    resolveTestCardBrand(brands) {
      if (this.testCard) {
        const testCardBrand = this.testCard.split('.')[0].toUpperCase()
        return brands.sort((a, b) => {
          if (a === testCardBrand) return -1
          if (b === testCardBrand) return 1
          return 0
        })
      }

      return brands
    },
    // Sets up the brand detection method
    setupBrandDetector() {
      const formId = this.context.formId
      const tree = TreeReader.getInstance(this.$locator)
      const parents = tree.getParents()
      this.detectBrandsFromNumber = (number, silent = false) => {
        if (this.paymentMethodToken) return
        let detectedBrands
        try {
          this.unfilteredBrands = tree.find(formId, number) || []

          let brands = intersection(this.unfilteredBrands, this.availableBrands)
          brands = tree.filterCoBrandedCard(brands)

          // If none of the detected brands are available, look on the parents
          if (!brands.length) {
            for (const brand of this.unfilteredBrands) {
              if (brand in parents) brands.push(parents[brand])
            }
          }
          // If the brands are oBrand sorting
          brands = this.brandsWithPriority(brands)
            ? brands.sort(this.brandSorter)
            : brands

          // For test cards, reorder the brands to preselect the one that has been set
          brands = this.resolveTestCardBrand(brands)

          detectedBrands = brands.length ? brands : ['DEFAULT']
        } catch (error) {
          // If something goes wrong, detect the brand by RegExp
          detectedBrands = this.cardTypeFromNumber(
            number,
            this.networkSelectionMode
          )
        }
        // Silent mode - avoid setting the brand in the store
        if (!silent) this.detectedBrands = detectedBrands
        return Promise.resolve(detectedBrands)
      }

      // Refresh the brand detection
      if (this.inputValue && !this.paymentMethodToken) {
        this.detectBrandsFromNumber(this.inputValue)
      }
    }
  }
}
