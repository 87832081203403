export default KR => {
  return {
    show: () => setOverlayForcedStatus(KR, true),
    hide: () => setOverlayForcedStatus(KR, false),
    restoreDefaultVisibility: () => setOverlayForcedStatus(KR, null)
  }
}

// true == open, false == closed, null == default logic
const setOverlayForcedStatus = (KR, status) => {
  KR.$store.dispatch('update', {
    form: { smartform: { overlay: { forcedDisplayState: status } } }
  })
}
