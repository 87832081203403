import Events from '@/configuration/Events'

export default ({ $bus }) => {
  return store => {
    // Force brand
    $bus.$on(Events.krypton.form.brand.forced, ({ brand }) => {
      store.dispatch('selectBrand', brand)
    })

    // Brand icon visibility
    $bus.$on(Events.krypton.form.icon.brand.visible, () => {
      const form = store.state.form
      form.brand.visibility = true
      store.dispatch('update', { form })
    })
    $bus.$on(Events.krypton.form.icon.brand.invisible, () => {
      const form = store.state.form
      form.brand.visibility = false
      store.dispatch('update', { form })
    })

    // Propagate new detected brands
    store.subscribe((mutation, state) => {
      if (
        mutation.type.indexOf('cardForm_') === 0 &&
        mutation.payload?.detectedBrands
      ) {
        const namespace = mutation.type.split('/')[0]
        store.dispatch(
          `${namespace}/selectBrand`,
          state[namespace].detectedBrands[0]
        )
      }
    })
  }
}
