var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-smart-form-radio-button", class: _vm.dynamicClasses },
    [
      _c("span", [
        _c(
          "svg",
          { attrs: { width: "12px", height: "9px", viewbox: "0 0 12 9" } },
          [_c("polyline", { attrs: { points: "1 5 4 8 11 1" } })]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }