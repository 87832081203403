import PiiInstance from './PiiInstance';

/**
 * The PII Factory.
 */
export default {

    /**
     * Build a PiiInstance.
     * @param rules the rues to load
     * @returns {PiiInstance}
     */
    build(rules) {
        const piiInstance = new PiiInstance();

        // Load all patterns
        piiInstance.piiPatternService.addPatterns(rules.patterns);

        // Load all rules
        piiInstance.piiRuleService.addRules(rules.rules);
        return piiInstance;
    },
};
