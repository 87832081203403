import _ from 'underscore'

export const getIosState = () => {
  return {
    // Action properties
    fakeCursorLocked: false,
    fakeCursorAnimation: true,
    fakeFocusedField: ''
  }
}

export const iosActions = {
  lockFakeCursor({ commit }) {
    commit('UPDATE', { fakeCursorLocked: true })
  },
  setFakeFocus({ commit }, fieldName) {
    commit('UPDATE', { fakeFocusedField: fieldName })
  }
}
export const iosMutations = {}
export const iosGetters = {}
