<template lang="pug">  
  .kr-partial-payment-panel(v-if="hasPartialPayments")
    PartialPaymentSeparator(:label="translate('partial_payment_label')")
    TransitionGroup(name="test" tag="div" class="kr-partial-payments") 
      PartialPaymentEntry(
        v-for="transaction, i in transactions" 
        :key="i"
        :transaction="transaction"
      )
    .kr-partial-payment-remaining
      span.kr-partial-payment-remaining-label {{translate("partial_payment_left")}}
      span.kr-partial-payment-remaining-amount {{getAmountLabel(pendingAmount)}}
    SmartFormPartialPaymentError
    PartialPaymentSeparator(:label="translate('partial_payment_next')")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PartialPaymentEntry from '@/host/components/smartform/partialPayment/PartialPaymentEntry'
import PartialPaymentSeparator from '@/host/components/smartform/partialPayment/PartialPaymentSeparator'
import SmartFormPartialPaymentError from '@/host/components/smartform/partialPayment/Error'

export default {
  name: 'PartialPaymentPanel',
  components: {
    PartialPaymentEntry,
    SmartFormPartialPaymentError,
    PartialPaymentSeparator
  },
  computed: {
    ...mapGetters(['getAmountLabel', 'translate', 'hasPartialPayments']),
    ...mapState({
      transactions: state => state.partialPayment.transactions,
      pendingAmount: state => state.amount
    })
  }
}
</script>
