<template lang="pug">
.kr-input-relative-wrapper(:style="fieldCss.inputRelativeWrapper")
  input#inputField.kr-input-field(
    ref="input"
    name="cardnumber"
    autocomplete='cc-number'
    autocorrect="off"
    spellcheck="false"
    :placeholder="placeholder"
    :disabled="isDisabled || !ready"
    :class="{'kr-on-error': hasError, 'on-error-animation': errorAnimation}"
    :style="inputCSS"
    :type="type"
    :pattern="pattern"
    v-model="inputValue"
    @keyup.9="tabReport"
    @keydown.9="tabReport"
    tabindex="1"
    :aria-label="fieldLabel"
    :aria-invalid="hasError ? 'true' : 'false'"
    required
  )
  AutofillInput(
    name="exp-date"
    autocomplete="cc-exp"
    field-name="expiryDate"
    v-on:autofill="onAutofill"
  )
  AutofillInput(
    name="cvc"
    autocomplete="cc-csc"
    field-name="securityCode"
    v-on:autofill="onAutofill"
  )
  AutofillInput(
    name="name"
    autocomplete="cc-name"
    field-name="cardHolderName"
    v-on:autofill="onAutofill"
  )
  CursorMirror(:input-value="inputValueCaret", ref="caret", v-if="!isUIWebView && !paymentMethodToken")
  input.kr-focus-hunter(
    v-if="isIos"
    tabindex="-1"
    aria-hidden="true"
    :pattern="pattern"
    style="position:fixed;top:0;left:0;width:0px;height:0px;background-color:transparent;border:0px solid transparent;padding:1px;display:inline-block;"
  )
</template>

<script type="text/javascript">
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import CursorMirror from '@/slave/components/CursorMirror'
import { mapGetters, mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import { slaveConfig } from '@/slave/components/Configuration'
import AutofillInput from '@/slave/components/AutofillInput'

/** MIXINS */
import { LuhnValidatorMixin } from '@/slave/components/mixins/LuhnValidator'
import { ClearMixin } from '@/slave/components/mixins/Clear'
import { StyleMixin } from '@/slave/components/mixins/Style'
import { KeyboardTypeMixin } from '@/slave/components/mixins/KeyboardType'
import { FocusHelperMixin } from '@/slave/components/mixins/FocusHelper'
import { FocusReporterMixin } from '@/slave/components/mixins/FocusReporter'
import { PanInputEditionMixin } from '@/slave/components/mixins/InputEdition'
import {
  KeysMixin,
  BlurMixin,
  FocusMixin,
  PreventEventsMixin,
  EventsMixin
} from '@/slave/components/mixins/Event'
import { PanLogicMixin } from '@/slave/components/mixins/Logic'
import { PlaceholderMixin } from '@/slave/components/mixins/Placeholder'
import { FieldLabelMixin } from '@/slave/components/mixins/FieldLabel'
import { ShakeMixin } from '@/slave/components/mixins/Shake'
import { TestCardsMixin } from '@/slave/components/mixins/TestCards'
import { PaymentTokenMixin } from '@/slave/components/mixins/PaymentToken'
import { RequiredMixin } from '@/slave/components/mixins/Required'
import { StateMixin } from '@/slave/components/mixins/State'
import { BinMixin } from '@/slave/components/mixins/Bin'
import { BrandDetectorMixin } from '@/slave/components/mixins/BrandDetector'
import { AutofillMixin } from '@/slave/components/mixins/Autofill'

// Expiry date component
export default {
  /**
   * Name of the component and the html tag as : <Pan />
   */
  name: 'Pan',
  components: { CursorMirror, AutofillInput },
  mixins: [
    BlurMixin,
    FocusMixin,
    ClearMixin,
    KeyboardTypeMixin,
    FocusHelperMixin,
    FocusReporterMixin,
    PanInputEditionMixin,
    KeysMixin,
    PreventEventsMixin,
    EventsMixin,
    PanLogicMixin,
    PlaceholderMixin,
    FieldLabelMixin,
    ShakeMixin,
    StyleMixin,
    TestCardsMixin,
    LuhnValidatorMixin,
    PaymentTokenMixin,
    RequiredMixin,
    StateMixin,
    BinMixin,
    BrandDetectorMixin,
    AutofillMixin
  ],
  inject: ['context'],
  data() {
    return {
      fieldName: 'pan',
      inputValueCaret: '',
      inputValue: '',
      lockedValidation: false,
      networkSelectionGrid: {},
      networkSelectionMode: null,
      settedValue: false,
      innerDisabledStyle: {},
      tree: undefined,
      expiryDateValue: '',
      securityCodeValue: '',
      cardHolderNameValue: ''
    }
  },
  computed: {
    ...mapState('field', ['valid', 'isUIWebView', 'formId', 'ready']),
    ...mapState(['formToken', 'css', 'browser']),
    ...dynamicMapState('context.namespace', ['testCard']),
    ...mapGetters(['isIos']),
    cardPattern() {
      if (this.selectedBrand === 'AMEX') return '#### ###### #####'
      return '#### #### #### #### ###'
    }
  },
  watch: {
    async inputValue(value) {
      if (value.length) this.cleanPlaceholder()
      // Value not set by token
      if (!this.paymentMethodToken) {
        this.inputValueCaret = value
        const cleanValue = value.replace(/\s/g, '')

        this.$bus.$emit(Events.slave.field.change, {
          value,
          cleanValue,
          el: this.$el,
          unreadableKeyboard: this.unreadableKeyboard
        })

        // Async detect of brand
        this.detectBinOptionsFromNumber(value)
        this.detectBrandsFromNumber(value)

        this.hasError = false
      } else {
        if (this.isIos) {
          this.fieldEvent('permanentFocus')
        }
      }
    },
    testCard(testCard) {
      if (testCard) {
        this.settedValue = true
        // Check if it's disabled
        if (!this.isDisabled) {
          let panValue = this.getPanValue()
          this.cleanPlaceholder()
          if (panValue) {
            // Locks the validation while updating data
            this.lockedValidation = true

            // Broadcast to fill the slices and put the cursor at
            // the last possible block
            this.inputValue = ''
            this.detectBrandsFromNumber(panValue)
            this.inputValue = panValue

            setTimeout(() => {
              this.lockedValidation = false
            }, 1)
          }
        }
      }
    }
  },
  created() {
    // NetworkSelection
    this.networkSelectionGrid = slaveConfig.networkSelectionMode
    const config = this.$parent.configuration
    this.networkSelectionMode = config.networkSelectionMode || null
  },
  mounted() {
    const $input = this.$refs.input

    $input.addEventListener('change', evt => {
      // Force input event on iOS to format the card number
      if (this.isIos || this.browser.name === 'Safari')
        Zepto($input).trigger('input', ['scan'])
    })
  }
}
</script>
