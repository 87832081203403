import CustomFieldMixin from '@/host/custom/Mixin'
import { mapState } from 'vuex'

export default {
  mixins: [CustomFieldMixin],
  data() {
    return {
      defaultTemplate: `
        <div @show="isVisible" @class="{'kr-visible':isVisible}" class="kr-field kr-radio">
          {slot}
          <label for="{attribute.id}"> {attribute.label} </label>
        </div>
      `
    }
  },
  computed: {
    ...mapState({
      customTemplate: state => state.fields.radio?.template
    })
  }
}
