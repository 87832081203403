import { mapGetters, mapState } from 'vuex'
import { dynamicMapActions, dynamicMapState } from '@/common/util/store'

export const BinMixin = {
  computed: {
    ...mapState({
      binUpdateNotificationUrl: state => state.binUpdateNotification.url,
      binOptionsMap: state => state.binOptionsMap
    }),
    ...mapGetters(['isApplePayActive']),
    ...dynamicMapState('context.namespace', ['binOptions', 'bin8', 'bin'])
  },
  methods: {
    ...dynamicMapActions('context.namespace', ['resetBinOptions', 'setBin']),
    detectBinOptionsFromNumber(value) {
      if (this.paymentMethodToken || !value) {
        this.resetBinOptions()
        return
      }

      // Search for the bin config and set it into the store
      const bin = value.replace(/\s/g, '').substr(0, 8)
      this.setBin(bin)
    }
  }
}
