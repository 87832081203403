<template lang="pug">
  section.kr-sf-opm
    .kr-sf-opm__methods
      SmartFormGridOpmGroup.kr-opm-group--active(
        type="group",
        :group="group",
        :methods="groupPaymentMethods",
        :locked="allLocked"
        :has-back="false"
        @selected="onSelected"
      )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import SmartFormGridOpmGroup from '@/host/components/smartform/grid/opm/Group'

export default {
  name: 'SmartFormGridModalOpmGroup',
  components: {
    SmartFormGridOpmGroup
  },
  props: {
    group: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'isWalletSmartForm',
      'hasTokens',
      'hasAnyActiveMethod',
      'activeGroupPaymentMethods'
    ]),
    ...mapState({}),
    ...mapState({
      groupPaymentMethods({ smartForm }) {
        if (this.group) {
          return this.activeGroupPaymentMethods.map(method => {
            return {
              type: 'method',
              key: method
            }
          })
        }
        return []
      },
      otherMethodsLabel: ({ smartForm }) =>
        smartForm.labels.otherPaymentMethods,
      registerMode: state => state.amount === 0,
      paymentDone: ({ smartForm }) => smartForm.paymentDone
    }),
    walletMode() {
      return this.isWalletSmartForm && this.hasTokens
    },
    allLocked() {
      return this.paymentDone || this.hasAnyActiveMethod
    }
  },
  methods: {
    ...mapActions(['cleanError']),
    onSelected(method) {
      this.cleanError()
      this.$emit('selected', method.key)
    }
  }
}
</script>
