<template lang="pug">
    extends templates/TextField.pug
</template>

<script>
import { TextFieldMixin } from '@/host/components/mixins/TextField'

export default {
  name: 'KryptonCardHolderName',
  mixins: [TextFieldMixin],
  data() {
    return {
      fieldName: 'cardHolderName',
      autocomplete: 'cc-name'
    }
  }
}
</script>
