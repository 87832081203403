<template lang="pug">
    button.kr-popin-button(
        type="button"
        v-if="isVisible"
        v-show="showing"
        @click="open()"
        :class="[(disabled) ? 'kr-disabled' : '']"
        v-html="payBtnHTML"
        :style="styles"
    )
</template>

<script>
import _ from 'underscore'
import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import Events from '@/configuration/Events'

import tokens from '@/configuration/sources/Tokens.yml'

export default {
  name: 'KryptonPopinButton',
  props: ['krVisible'],
  data() {
    return {
      showing: true
    }
  },
  computed: {
    ...mapState(['payBtnHTML', 'formToken', 'formId']),
    ...mapState({
      buttonConfig: state => state.form.button,
      isVisible: state => state.form.popin.button
    }),
    ...dynamicMapState('context.namespace', ['disabledCardForm']),
    styles() {
      return {
        color: this.buttonConfig.textColor,
        backgroundColor: this.buttonConfig.backgroundColor,
        borderColor: this.buttonConfig.borderColor
      }
    },
    disabled() {
      return this.disabledCardForm || this.formToken === tokens.noToken
    }
  },
  watch: {
    formToken: 'checkPopinPosition'
  },
  created() {
    this.startSubscriptions()
  },
  methods: {
    startSubscriptions() {
      this.$busOn(Events.krypton.popin.open, message => {
        this.open()
      })

      this.$busOn(Events.krypton.form.hideForm, message => {
        this.showing = false
      })

      this.$busOn(Events.krypton.form.showForm, message => {
        this.showing = true
      })
    },
    open() {
      if (!this.disabled) {
        this.$store.dispatch('openPopin')
      }
    }
  }
}
</script>
