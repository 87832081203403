var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !(_vm.fieldName === "pan" && !_vm.isVisible)
    ? _c(
        "div",
        {
          directives: [
            {
              name: "click-outside",
              rawName: "v-click-outside",
              value: _vm.closeBrandSelector,
              expression: "closeBrandSelector",
            },
          ],
          staticClass: "kr-icon-wrapper-root",
          class: {
            "kr-open": _vm.selectorVisible,
            "kr-multi-brand": _vm.hasMultipleBrands && _vm.fieldName === "pan",
          },
          style: { display: _vm.isVisible ? "flex" : "none" },
          attrs: { "aria-hidden": "true" },
          on: {
            click: function ($event) {
              return _vm.focusHelper()
            },
          },
        },
        [
          _vm.hasCustomIcon
            ? _c(
                "div",
                { staticClass: "kr-icon kr-custom-icon", style: _vm.css },
                [
                  _c("div", { staticClass: "kr-icon-wrapper" }, [
                    _c("div", { staticClass: "kr-icon-wrapper-content" }, [
                      _c("i", { class: _vm.iconClass }),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.selectedBrand && !_vm.hasCustomIcon
            ? _c(
                "div",
                {
                  staticClass: "kr-icon",
                  class: [
                    _vm.isDisabled ? "kr-disabled" : "",
                    _vm.isInvalid ? "kr-on-error" : "",
                    _vm.selectedBrand !== "DEFAULT" ? "kr-brand-detected" : "",
                  ],
                  style: _vm.css,
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "kr-icon-wrapper",
                      class: [
                        _vm.selectedBrand ? "brand" : "",
                        _vm.fieldName === "pan"
                          ? _vm.selectedBrand.toLowerCase()
                          : "",
                      ],
                    },
                    [
                      _c("div", { staticClass: "kr-icon-inner-wrapper" }, [
                        _c("div", {
                          staticClass: "kr-icon-wrapper-content",
                          domProps: { innerHTML: _vm._s(_vm.icon) },
                        }),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.hasMultipleBrands,
                              expression: "hasMultipleBrands",
                            },
                          ],
                          staticClass: "kr-icon-wrapper-layer",
                          on: { click: _vm.toggleBrandSelector },
                        }),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.hasSelector
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectorVisible,
                      expression: "selectorVisible",
                    },
                  ],
                  staticClass: "kr-brand-selector",
                },
                [
                  _c("div", { staticClass: "kr-brand-selector-wrapper" }, [
                    _c("div", { staticClass: "kr-arrow" }),
                    _c(
                      "ul",
                      { staticClass: "kr-brand-selector-list" },
                      _vm._l(_vm.brandObjects, function (brandObject) {
                        return _c("li", {
                          class: [
                            brandObject.brand === _vm.selectedBrand
                              ? "kr-selected-brand"
                              : "",
                            "kr-selector-" + brandObject.brand.toLowerCase(),
                          ],
                          attrs: {
                            "data-forced-brand":
                              brandObject.brand.toLowerCase(),
                          },
                          domProps: { innerHTML: _vm._s(brandObject.icon) },
                          on: {
                            click: function ($event) {
                              _vm.selectedBrand = brandObject.brand
                            },
                            touchend: function ($event) {
                              _vm.selectedBrand = brandObject.brand
                            },
                          },
                        })
                      }),
                      0
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.hasSelector
            ? _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasMultipleBrands,
                    expression: "hasMultipleBrands",
                  },
                ],
                staticClass: "kr-icon-arrow",
                class: [
                  _vm.hasMultipleBrands && _vm.selectorVisible
                    ? "kr-icon-arrow-turned"
                    : "",
                ],
                domProps: { innerHTML: _vm._s(_vm.caret) },
                on: { click: _vm.toggleBrandSelector },
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }