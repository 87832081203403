import Events from '@/configuration/Events'
import { isObject } from 'underscore'
import { logSentryException } from '@/common/util/sentry'

export default class AbstractTrigger {
  constructor($locator, event) {
    this.$locator = $locator
    this.$bus = $locator.$bus
    this.$store = $locator.$store
    this.restAPI = $locator.restAPI
    this.endpointDetector = $locator.endpointDetector
    this.errorHandler = $locator.errorHandler
    this.proxy = $locator.proxy
    this.logger = $locator.logger
    this.storeFactory = $locator.storeFactory

    // Triggers takes precedence over store actions
    this.$bus.$off(Events.krypton.message[event])
    this.$bus.$on(Events.krypton.message[event], message => {
      this.onEvent(message)
    })
  }

  async onEvent(action) {
    const { forcedDomain } = this.$store.getters
    let endpoint = this.$locator.ghost?.form?.endpoint

    if (forcedDomain) endpoint = forcedDomain

    // If the form has not an endpoint defined, we detect endpoint
    if (!endpoint) {
      const state = this.$store.state
      const formToken = action.formToken || state.formToken
      endpoint = this.endpointDetector.detect(formToken)
    }

    // remoteId
    this.$store.dispatch('setRemoteId')

    try {
      await this.endpointDetector.validateEndpoint(endpoint)
      this.endpoint = endpoint
    } catch (error) {
      this.onError(error)
    }
  }

  onError(
    error = 'CLIENT_999',
    path = 'ghost/service/AbstractTrigger.onEvent'
  ) {
    this.logger.logPromiseError(error, path)
    if (isObject(error)) error.path = path
    else logSentryException(error, path) // Unexpected error

    const originalError = error
    if (error?.answer) error = error.answer
    // Extra properties
    if (error.msg) error.detailedErrorMessage = error.msg
    if (originalError.serverDate) error.serverDate = originalError.serverDate
    if (this.paymentMethod) error.paymentMethod = this.paymentMethod
    if (this.formId) error.formId = this.formId
    this.$store.dispatch('error', error)
  }
}
