var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "kr-wallet-tabs", attrs: { "kr-resource": "" } },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "div",
            {
              staticClass: "kr-wallet-tab",
              class: [
                tab.class,
                tab.id === _vm.walletMode ? "kr-selected" : "",
                _vm.isThereNoCard && tab.id !== _vm.walletMode
                  ? "kr-disabled"
                  : "",
              ],
              attrs: { disabled: _vm.isThereNoCard },
              on: {
                click: function ($event) {
                  return _vm.changeWalletTab(tab.id)
                },
              },
            },
            [_c("span", [_vm._v(_vm._s(_vm.labels[tab.label]))])]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }