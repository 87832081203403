import KryptonIcon from '@/host/components/controls/KryptonIcon'
import CustomFieldMixin from '@/host/custom/Mixin'
import { mapState } from 'vuex'

export default {
  components: { KryptonIcon },
  mixins: [CustomFieldMixin],
  data() {
    return {
      defaultTemplate: `
        <div @show="isVisible" @class="{'kr-visible':isVisible}" class="kr-field kr-wrapper-custom-field kr-wrapper-custom-field-text">
            $SLOT
        </div>
      `
    }
  },
  computed: {
    ...mapState({
      customTemplate: state => state.fields.custom?.template
    }),
    hasText() {
      return this.value && this.value.length
    }
  }
}
