import _ from 'underscore'
import Events from '@/configuration/Events'

/**
 * @public
 * @desc Class to handle calls and services between iframes
 */
export default class ProxyBridge {
  constructor(locator) {
    this.locator = locator
    this.parentDomain = null
    this.enableCommunicator()
  }

  /**
   * @desc Enables a single communicator openining a channel to resolve the message
   * @public
   * @return void
   */
  enableCommunicator() {
    const _this = this
    if (!this.locator.communicator) return
    this.locator.communicator.addListener(message => {
      if (!_.isEmpty(message)) _this.resolveStringMessage(message)
    })
  }

  /**
   * Emits the store as an event on the current application
   *
   * This method transforms and resends to Gateway all string
   * messages received. The process to send this message to the Gateway
   * service is using the events, and accessing the registered listener
   * for each class on the **gateway_configuration** on the application
   * configuration file
   *
   * @param {string} stringMessage
   * @return {Promise}
   */
  resolveStringMessage(stringMessage) {
    if (
      !_.isString(stringMessage) ||
      stringMessage.indexOf('/*krypton-client*/ ') !== 0
    )
      return
    // Remove the client identifier
    stringMessage = stringMessage.replace('/*krypton-client*/ ', '')
    const jsonMessage = JSON.parse(stringMessage)
    const store = this.locator.storeFactory.create(
      jsonMessage._name,
      jsonMessage
    )

    if (_.has(Events.krypton.message, jsonMessage._name)) {
      this.locator.$bus.$emit(
        Events.krypton.message[`${jsonMessage._name}`],
        store
      )
    }
  }

  /**
   * Main sending object method
   *
   * Sends a message through the proxy from the specified source
   * to all listener applications. The channel is used to level the security of
   * the information. If the channel is less secure the message is filtered.
   *
   * You can send a full object if its *Fragmentalizer* is on the current container
   *
   * @param {mixed} msg
   */
  send(msg, toChildWin = false, delegate = null) {
    if (!this.locator.communicator) return
    this.sendLog(msg)
    return this.locator.communicator.send(
      `/*krypton-client*/ ${JSON.stringify(msg.json())}`,
      toChildWin,
      delegate
    )
  }

  sendLog(msg) {
    if (!msg.is('log')) {
      this.locator.logger.log(
        'info',
        `postMessage from ${this.locator.application.toUpperCase()}`,
        msg.json()
      )
    }
  }
}
