var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "kr-sf-opm" }, [
    _c(
      "div",
      { staticClass: "kr-sf-opm__methods" },
      [
        _c("SmartFormGridOpmGroup", {
          staticClass: "kr-opm-group--active",
          attrs: {
            type: "group",
            group: _vm.group,
            methods: _vm.groupPaymentMethods,
            locked: _vm.allLocked,
            "has-back": false,
          },
          on: { selected: _vm.onSelected },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }