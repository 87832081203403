<template lang="pug">
extends ../controls/templates/FormError.pug
</template>

<script>
import FormError from '@/host/components/mixins/FormError'

export default {
  name: 'ExtrasFormError',
  mixins: [FormError],
  computed: {
    showFormError() {
      return this.hasData
    }
  }
}
</script>
