export default class IFrameHandler {
  constructor({ storeFactory, proxy, $bus }) {
    this.storeFactory = storeFactory
    this.proxy = proxy
    this.$bus = $bus
  }

  getFrameWindow(name, formId, forced = false) {
    const frames = window.parent.frames

    /**
     * Use the property length to get the proper values.
     * If not, it will loop across everythin inside frames object
     */
    for (let i = 0; i < frames.length; i++) {
      const frame = frames[i]
      try {
        if (frame.FIELD) {
          if (forced || (frame.FORM_ID && formId == frame['FORM_ID'])) {
            if (frame.FIELD == name) {
              return frame
            }
          }
        }
      } catch (error) {
        /**
         * NO SENTRY LOGS HERE
         * Disabled to avoid the SPAM on sentry, when there are iframes
         * in the page with different domains, it triggers an error for
         * each one trying to access to frame.FIELD
         */
      }
    }

    return null
  }
}
