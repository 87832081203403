import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import { mapGetters, mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

export const CardFormThemeMixin = {
  data() {
    return {
      defaultConf: true,
      halfSize: false
    }
  },
  computed: {
    ...mapState({
      formFieldsOrder: state => state.form.fields.order,
      formControlsOrder: state => state.form.controls.order,
      isCompact: state => state.form.layout === 'compact',
      defaultConfiguration: state => state.dna?.cards?.DEFAULT?.fields || {}
    }),
    ...mapGetters(['isSmartForm', 'isCardMethodActive']),
    ...dynamicMapState('context.namespace', ['visibleFields', 'walletMode']),
    dynamicThemeClasses() {
      // Don't compute the classes configuration if there are no changes in the layout configuration
      if (this.defaultConf) return {}

      const classes = this.isCompact
        ? 'kr-embedded-flex-display-row-wrap kr-embedded-flex-display kr-embedded-flex-direction-row'
        : `kr-embedded-flex-display kr-embedded-flex-direction-${
            this.halfSize && this.walletMode !== 'myCards' ? 'row' : 'column'
          }`

      return Object.fromEntries(
        classes.split(' ').map(classItem => [classItem, true])
      )
    }
  },
  watch: {
    halfSize() {
      this.defaultConf = false
    },
    formFieldsOrder: 'orderFields',
    formControlsOrder: 'orderControls',
    isCompact: 'setLayout',
    visibleFields(visibleFields) {
      if (this.isCompact) this.halfSize = ~visibleFields.indexOf('securityCode')
    },
    walletMode(walletMode) {
      if (this.isCompact) this.halfSize = walletMode !== 'myCards'
      this.clearPermanentFocus()
    },
    isCardMethodActive() {
      this.setLayout(this.isCompact)
    }
  },
  mounted() {
    if (this.isCompact || this.isSmartForm) this.setLayout(this.isCompact)
  },
  methods: {
    /** Order dom elements */
    order(elements, type = 'field', firstIndex = 0) {
      this.defaultConf = false
      for (const i in elements) {
        const $el = Zepto(this.parseToDomSelector(elements[i], type))
        this.setFlexOrder($el, parseInt(i) + firstIndex)
      }
    },
    orderFields(elements, previous) {
      if (elements.toString() === previous.toString()) return
      return this.order(elements)
    },
    orderControls(elements, previous) {
      if (elements.toString() === previous.toString()) return
      return this.order(elements, 'control', 30)
    },

    /**
     * Set the proper classes for the layout
     */
    setLayout(isCompact) {
      this.defaultConf = false
      this.halfSize = isCompact
    },

    /**
     * Sets the flexbox order css properties
     */
    setFlexOrder($el, order) {
      const propOrder = order + 1
      const propOrdinalGroup = propOrder + 1
      $el.css('-webkit-box-ordinal-group', `${propOrdinalGroup}`)
      $el.css('-webkit-order', `${propOrder}`)
      $el.css('-moz-order', `${propOrder}`)
      $el.css('-ms-flex-order', `${propOrder}`)
      $el.css('order', `${propOrder}`)
      $el.attr('kr-order', `${propOrder}`)
    },

    /**
     * Parse the given element names to DOM selector names
     */
    parseToDomSelector(elName, type = 'field') {
      const domParser = {
        field: {
          pan: ['pan', 'kr-pan'],
          'security-code': [
            'securityCode',
            'security-code',
            'kr-security-code',
            'cvv'
          ],
          expiry: ['expiryDate', 'expiry', 'kr-expiry', 'exp']
        },
        control: {
          'form-error': ['error', 'formError', 'kr-error', 'kr-form-error'],
          'payment-button': [
            'paymentButton',
            'formButton',
            'kr-payment-button',
            'payment-button'
          ]
        }
      }

      for (const name in domParser[type]) {
        if (~domParser[type][name].indexOf(elName)) {
          return `.kr-${name}`
        }
      }

      // Custom field
      if (type === 'field') {
        const $customField = Zepto(`.kr-theme[name="${elName}"]`)
        if (
          $customField.length &&
          $customField.parents('div[id^="kr-field-app-"]').length
        ) {
          return `#${$customField
            .parents('div[id^="kr-field-app-"]')
            .attr('id')}`
        }
      }

      return elName
    },

    clearPermanentFocus() {
      for (const fieldName in this.defaultConfiguration) {
        this.$bus.$emit(Events.krypton.field.event, {
          name: fieldName,
          type: 'clearPermanentFocus',
          formId: this.context.formId
        })
      }
    }
  }
}
