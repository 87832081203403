var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "onresize",
          rawName: "v-onresize",
          value: _vm.onResize,
          expression: "onResize",
        },
      ],
      staticClass: "kr-wallet-group",
      class: _vm.dynamicClasses,
    },
    _vm._l(_vm.methods, function (conf) {
      return _c("SmartFormGridWalletButton", {
        key: conf.token,
        attrs: { method: conf, locked: _vm.allLocked },
        on: { selected: _vm.onSelected },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }