import Events from '@/configuration/Events'
import { DomValidationError } from '@/common/errors/DomValidationError'

import {
  getFieldElement,
  getFormElement,
  getSmartButtonElements,
  getSmartButtonWrapperElements,
  getStandalonePaymentButton,
  hasStandalonePaymentButton,
  getSmartFormElement,
  hasSmartElements,
  isFormRendered,
  castToHTMLElements
} from '@/common/util/dom'

export const getDomState = () => {
  return {
    dom: {
      onlyTaggedElements: false
    }
  }
}

export const domActions = $locator => {
  return {
    // $elements: string | string[] | HTMLElement | HTMLElement[]
    parseRenderElements({ dispatch, state }, $elements) {
      try {
        $elements = castToHTMLElements($elements)

        // check fo valid HTMLElements
        const $validHTMLElements = $elements.filter(
          $el => $el instanceof HTMLElement
        )

        // no valid elements found
        if ($validHTMLElements.length === 0)
          throw new DomValidationError('CLIENT_721')

        dispatch('update', { dom: { onlyTaggedElements: true } })
        return $validHTMLElements
      } catch (error) {
        let errorCode
        // Invalid selector string provided by the merchant such as "." or ",selector"
        if (error instanceof DOMException) errorCode = 'CLIENT_720'
        else if (error instanceof DomValidationError) errorCode = error.message

        dispatch('error', {
          errorCode,
          metadata: { console: true }
        })
        if (state.testKeys) $locator.$bus.$emit(Events.krypton.data.errorAlert)

        return false
      }
    }
  }
}
export const domMutations = {}
export const domGetters = {
  getFormElement: getMethod(getFormElement),
  getSmartFormElement: getMethod(getSmartFormElement),
  getSmartButtonElements: getMethod(getSmartButtonElements),
  getSmartButtonWrapperElements: getMethod(getSmartButtonWrapperElements),
  getStandalonePaymentButton: getMethod(getStandalonePaymentButton),
  hasStandalonePaymentButton: getMethod(hasStandalonePaymentButton),
  hasSmartElements: getMethod(hasSmartElements),
  getFieldElement:
    ({ dom: { onlyTaggedElements } }) =>
    fieldName =>
      getFieldElement(fieldName, onlyTaggedElements),
  isFormRendered:
    ({ dom: { onlyTaggedElements } }) =>
    () =>
      isFormRendered(onlyTaggedElements)
}

function getMethod(fn) {
  return ({ dom: { onlyTaggedElements } }) => {
    return ($el = document) => fn($el, onlyTaggedElements)
  }
}
