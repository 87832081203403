var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-app-container", attrs: { "kr-resource": "" } },
    [
      _c("KryptonLayerManager", {
        attrs: { "z-reference": "10000", attach: "body" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }