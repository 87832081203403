var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isVisible,
        expression: "isVisible",
      },
    ],
    staticClass: "kr-payment-button",
    class: _vm.dynamicClasses,
    style: _vm.styles,
    attrs: {
      "kr-resource": "",
      type: "button",
      disabled: _vm.isDisabled,
      tabindex: "1",
    },
    domProps: { innerHTML: _vm._s(_vm.payBtnHTML) },
    on: {
      click: _vm.btnClick,
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
          return null
        }
        return _vm.tabReport($event)
      },
      keydown: function ($event) {
        if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
          return null
        }
        return _vm.tabReport($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }