import _ from 'underscore'
import I18nConfiguration from '@/configuration/I18nConfiguration'

const C = Object.freeze({
  REGEX: {
    WORKABLE_TAG: /^[a-z]{2}($|-)/i, // "fr" or Starting by "fr-"
    EXTRACTABLE_LANGUAGE: /^[a-z]{2}-/i, // Starting by "fr-"
    LANGUAGE: /^[a-z]{2}$/i, // "fr" - ISO 639-1
    LOCALE: /^[a-z]{2}(-\w+)+/ // "fr-FR" - <ISO 639-1>-<ISO 3166-1>
  }
})

/**
 * For a given locale, return its parent.
 * For e.g:
 * es-AR -> es-ES
 * es-ES -> null (no parent, eldest of its family)
 * fr-CH -> fr-FR
 * fr-FR -> null (same as es-ES)
 *
 * See unit-test tests for extensive examples.
 *
 * @param {string} locale
 * @returns {string|null} Parent locale
 * @since KJS-4303
 */
export const getParentLocale = locale => {
  if (typeof locale !== 'string' || !C.REGEX.LOCALE.test(locale)) return null

  const language = locale.substring(0, 2)
  const parent = I18nConfiguration.alias[language]
  // If
  if (!parent || parent.toLowerCase() === locale.toLocaleLowerCase())
    return null
  return parent
}

/**
 * For a given locale, normalize its format.
 * Resolve alias if full local is not supported but language is recognized.
 * Fallback to default language if language is not recognized.
 *
 * See unit-test for extensive examples.
 *
 * @param {string} locale Any string starting with at least to character in
 *                        order to extract the ISO 639-1 language code.
 * @returns {string} Locale following the format <ISO 639-1>-<ISO 3166-1>
 *                   (e.g: fr-FR, es-AR, etc....)
 * @since KJS-4303
 */
export const normalizeLocale = locale => {
  if (typeof locale !== 'string' || !C.REGEX.WORKABLE_TAG.test(locale)) {
    return I18nConfiguration.fallback
  }

  // Normalize case to strictly have 'fr' or 'fr-FR' from now on
  if (C.REGEX.EXTRACTABLE_LANGUAGE.test(locale)) {
    const spl = locale.split('-')
    locale = `${spl[0].toLowerCase()}-${spl[1].toUpperCase()}`
  } else {
    locale = locale.toLowerCase()
  }

  // Best case - Locale supported
  if (I18nConfiguration.supportedLocales.includes(locale)) return locale

  // Extract language then resolve locale
  const language = locale.substring(0, 2)
  return I18nConfiguration.alias[language] ?? I18nConfiguration.fallback
}
