<template lang="pug">
  header.kr-sf-header(v-if="renderGenericEmbeddedHeader || hasCardHeader")
    SmartFormEmbeddedHeader(v-if="renderGenericEmbeddedHeader")
    SmartFormCardHeader(v-else-if="hasCardHeader")
</template>

<script>
import { mapGetters } from 'vuex'

import SmartFormCardHeader from '@/host/components/smartform/CardHeader'
import SmartFormEmbeddedHeader from '@/host/components/smartform/EmbeddedHeader'

export default {
  name: 'SmartFormHeader',
  components: {
    SmartFormCardHeader,
    SmartFormEmbeddedHeader
  },
  computed: {
    ...mapGetters(['renderGenericEmbeddedHeader', 'hasCardHeader'])
  }
}
</script>
