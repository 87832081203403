import LyraPii from '@lyra-network/lyra-common-pii';

const piiInstance = LyraPii.build();

function stripPiiData(event) {
    const eventToSend = event;
    if (eventToSend.message) {
        eventToSend.message = piiInstance.piiRuleService.applyRules(
            eventToSend.message,
        );
    }
    if (eventToSend.exception && eventToSend.exception.values) {
        eventToSend.exception.values.forEach((value) => {
            if (value.value) {
                const exceptionSanitized = value;
                exceptionSanitized.value = piiInstance.piiRuleService.applyRules(
                    value.value,
                );
                return exceptionSanitized;
            }
            return value;
        });
    }
    return eventToSend;
}

export default {
    /**
   * Init Sentry.
   *
   * @param SentrySdk SentrySDK (nodejs / browser).
   * @param options Sentry regular options
   * @returns {*}
   */
    init(SentrySdk, options = {}) {
    // Keep original beforeSend if any
        const beforeSend = options.beforeSend
            ? options.beforeSend
            : (event) => event;
        // Add hook to ensure PII stripping before sending events
        const mergedOptions = {
            ...options,
            beforeSend(event) {
                // Apply original beforeSend
                const eventFromOriginalBeforeSend = beforeSend(event);

                // Anyway... strip PII data
                return stripPiiData(eventFromOriginalBeforeSend);
            },
        };

        // Default global instance
        let hub = SentrySdk;
        // Create another sentry instance only if dsn is defined
        if (options.dsn) {
            const { Hub, defaultIntegrations } = SentrySdk;
            const Client = SentrySdk.BrowserClient || SentrySdk.NodeClient;
            let transport;
            if (SentrySdk.BrowserClient) {
                transport = SentrySdk.makeFetchTransport;
            }
            if (SentrySdk.NodeClient) {
                transport = SentrySdk.makeNodeTransport;
            }

            // Separated instance to avoid conflicts
            const client = new Client({
                ...mergedOptions,
                integrations: [...defaultIntegrations],
                transport,
            });
            hub = new Hub(client);
            hub.options = mergedOptions;

            // Temporary manual registration due to Sentry SDK limitations
            // (do not work when multiple Sentry instances loaded)
            // (see https://github.com/getsentry/sentry-javascript/issues/3271)
            if (options.unique === undefined || options.unique === false) {
                SentrySdk.makeMain(hub);
            }
        }
        return hub;
    },

    stripPiiData,
};
