/**
 * Listener to check if the opened window has been close and change
 * the state of the main form
 */
export const closeListener = (window, dispatch) => {
  const closeCheckInt = setInterval(() => {
    if (window?.closed) {
      dispatch('redirection', false)
      dispatch('closeWindow')
      clearInterval(closeCheckInt)
    }
  }, 200)
}

/**
 * Listener to manage the focus on the new window when we click on the main one
 */
export const focusListener = window => {
  document.addEventListener('click', ev => {
    if (window) window.focus()
  })
}
