import _ from 'underscore'
import { mapGetters, mapState } from 'vuex'
import Events from '@/configuration/Events'

import masker from '@/slave/components/mask/masker'

/**
 * Stuff related with iOS issues. DOC [krypton/tech/ios/index.rst]
 */
export const FocusHelperMixin = {
  data() {
    return {
      fakeCursorFocused: false
    }
  },
  computed: {
    ...mapState('field', [
      'name',
      'fakeCursorPosition',
      'isUIWebView',
      'touchedPosition'
    ]),
    ...mapState(['fakeFocusedField', 'os']),
    ...mapGetters(['isIos']),
    needsHelp() {
      return this.isIos && parseInt(this.os.version.split('.')[0]) < 13
    }
  },
  watch: {
    fakeFocusedField(newVal) {
      if (newVal !== this.name) {
        this.fakeCursorFocused = false
      }
    }
  },
  mounted() {
    // Setup focus helper for iOS devices
    if (this.needsHelp) this.setupFocusHelper()
    this.$store.dispatch('field/update', {
      isUIWebView: /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(
        navigator.userAgent
      )
    })
    if (this.isUIWebView) {
      const lte9 = /constructor/i.test(window.HTMLElement)
      const idb = !!window.indexedDB
      if ((!idb && lte9) || !window.statusbar.visible) {
        this.$store.dispatch('field/update', { isUIWebView: true })
      } else if (window.webkit?.messageHandlers || !lte9 || idb) {
        this.$store.dispatch('field/update', { isUIWebView: false })
      }
    }
  },
  methods: {
    setupFocusHelper() {
      if (!this.isUIWebView) {
        const _this = this
        const $focusHunter = document.querySelector('input.kr-focus-hunter')

        /**
         * Listen to the focus on the hidden input (focusHunter) and redirects
         * the focus to the main input.
         */
        $focusHunter.addEventListener('focus', event => {
          if (!_this.fakeCursorFocused) {
            document.querySelector('#inputField').focus()
            _this.fakeCursorFocused = true
          }
        })
      }
    },

    /**
     * On iOS devices, the only events recieved every time the user
     * touch the input, is the touch type events (touchstart, touchend).
     * We use the touchstart event to focus by JS (only responds when it comes
     * from an event listener) the hidden input.
     */
    helpedFocusReport(evt) {
      if (!this.isUIWebView) {
        let touchPosition = this.touchedPosition
        if (evt.type.indexOf('touchstart') !== -1) {
          touchPosition = evt.layerX
          // If the field is already focus, we keep it focused
          if (!this.fakeCursorFocused) {
            document.activeElement.blur()
            const $focusHunter = document.querySelector('input.kr-focus-hunter')
            if ($focusHunter) $focusHunter.focus()
          }
        }
        // Show the cursor
        this.$store.dispatch('field/update', {
          touchedPosition: touchPosition
        })
        // Disable the default browser behaviour
        evt.preventDefault()
      }
    },
    helpedBlurReport(evt) {
      this.fakeCursorFocused = false
      evt.target.blur()
      // Hide the cursor
      this.$bus.$emit(Events.slave.field.hideFakeCaret, {})
      // Disable the default browser behaviour
      evt.preventDefault()
    },

    /**
     * Generate the value considering the oldValue and the position of the
     * cursor
     */
    addValue(oldVal, char) {
      let splitValue = oldVal.split('')
      // Add the char in the proper position
      splitValue.splice(this.fakeCursorPosition, 0, char)
      // Increase the cursor position
      this.$store.dispatch('field/update', {
        fakeCursorPosition: parseInt(this.fakeCursorPosition) + 1
      })
      return splitValue.join('')
    },

    /**
     * Remove the proper value considering the oldValue and the position of
     * the cursor
     */
    removeValue(oldVal) {
      let splitValue = oldVal.split('')
      if (this.fakeCursorPosition > 0) {
        let numOfrem = 1
        // Check if it's a mask char -> remove 2
        if (
          [' ', '/'].indexOf(splitValue[this.fakeCursorPosition - numOfrem]) !==
          -1
        )
          numOfrem = 2
        // Remove the char/s of the proper position
        splitValue.splice(this.fakeCursorPosition - numOfrem, numOfrem)
        // Decrease the cursor position
        this.$store.dispatch('field/update', {
          fakeCursorPosition: parseInt(this.fakeCursorPosition) - numOfrem
        })
      }
      return splitValue.join('')
    },

    helpedEdition(el, evt, fieldPattern, tokens) {
      let key = evt.which || evt.keyCode || 0

      let unformattedValue
      let editionType

      // ADD
      if (
        (evt.key &&
          ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(
            evt.key
          ) !== -1) ||
        [48, 49, 50, 51, 52, 53, 54, 55, 56, 57].indexOf(key) !== -1
      ) {
        let char = evt.key || String.fromCharCode(key)
        unformattedValue = this.addValue(el.value, char)
        editionType = 'add'
        // REMOVE
      } else if (key === 8) {
        evt.preventDefault()
        unformattedValue = this.removeValue(el.value)
        editionType = 'remove'
      }

      // Calculate format length difference
      let unformattedLength
      let formattedLength
      if (editionType) {
        // Format the value
        el.value = masker(unformattedValue, fieldPattern, true, tokens)
        // Calculate if there are new chars on the value after the format
        let untilCursorValue = unformattedValue.substring(
          0,
          this.fakeCursorPosition
        )
        unformattedLength = untilCursorValue.length
        formattedLength = masker(
          untilCursorValue,
          fieldPattern,
          true,
          tokens
        ).length
      }

      // If there are differences, set the new cursor position
      if (unformattedLength !== formattedLength) {
        if (editionType === 'add') {
          // Check how long has increased
          let lengthsDiff = formattedLength - unformattedLength
          // Move the cursor if there are new spaces
          this.$store.dispatch('field/update', {
            fakeCursorPosition: parseInt(this.fakeCursorPosition) + lengthsDiff
          })
        } else if (editionType === 'remove') {
          // Check how long has decreased
          let lengthsDiff = unformattedLength - formattedLength
          // Move the cursor if there are less spaces
          this.$store.dispatch('field/update', {
            fakeCursorPosition: parseInt(this.fakeCursorPosition) - lengthsDiff
          })
        }
      }

      // Set the value
      if (editionType) {
        this.inputValue = el.value
        // Block the regular workflow
        return false
      }

      return true
    }
  }
}
