<template lang="pug">
    .kr-icon-wrapper-root(
        :class="{ 'kr-open':selectorVisible, 'kr-multi-brand':(hasMultipleBrands && fieldName === 'pan') }",
        v-if="!(fieldName === 'pan' && !isVisible)"
        :style="{ display: isVisible ? 'flex' : 'none' }"
        v-click-outside="closeBrandSelector",
        @click="focusHelper()",
        aria-hidden="true"
    )
        // Custom icon
        .kr-icon.kr-custom-icon(
            v-if="hasCustomIcon",
            :style="css"
        )
            .kr-icon-wrapper
                .kr-icon-wrapper-content
                    i(:class="iconClass")
        // Selected icon (brand button)
        .kr-icon(
            :class="[isDisabled ? 'kr-disabled' : '', isInvalid ? 'kr-on-error': '', (selectedBrand !== 'DEFAULT') ? 'kr-brand-detected' : '']",
            :style="css"
            v-if="selectedBrand && !hasCustomIcon",
        )
            .kr-icon-wrapper(:class="[(selectedBrand) ? 'brand' : '', (fieldName === 'pan') ? selectedBrand.toLowerCase() : '']")
                .kr-icon-inner-wrapper
                    .kr-icon-wrapper-content(v-html="icon")
                    .kr-icon-wrapper-layer(
                        v-show="hasMultipleBrands"
                        @click="toggleBrandSelector"
                    )
        // Selector
        .kr-brand-selector(v-show="selectorVisible", v-if="hasSelector")
            .kr-brand-selector-wrapper
                .kr-arrow
                ul.kr-brand-selector-list
                    li(
                        :data-forced-brand="brandObject.brand.toLowerCase()"
                        :class="[(brandObject.brand === selectedBrand) ? 'kr-selected-brand':'', 'kr-selector-' + brandObject.brand.toLowerCase()]"
                        v-for="brandObject in brandObjects"
                        v-html="brandObject.icon"
                        @click="selectedBrand = brandObject.brand"
                        @touchend="selectedBrand = brandObject.brand"
                    )
        // Arrow caret
        .kr-icon-arrow(
            v-html="caret"
            v-show="hasMultipleBrands"
            v-if="hasSelector"
            :class="[(hasMultipleBrands && selectorVisible) ? 'kr-icon-arrow-turned':'']",
            @click="toggleBrandSelector"
        )
</template>

<script>
import Events from '@/configuration/Events'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import { loadAssets } from '@/common/loader/assets'
import { ensureUniqId } from '@/common/util/svg'
import { isValidUrl } from '@/common/util/url'
import { mapGetters, mapState } from 'vuex'
import { isBoolean } from 'underscore'

/** MIXINS */
import ConfigurationMixin from '@/host/components/mixins/Configuration'
import BrandMixin from '@/common/components/mixins/Brand'
import { ClickOutsideMixin } from '@/host/components/mixins/ClickOutside'
import { MultipleBrandMixin } from '@/host/components/mixins/MultipleBrand'
import { FocusHelperMixin } from '@/host/components/mixins/FocusHelper'
import { resolveBrand } from '@/common/util/brand.js'

export default {
  name: 'KryptonIcon',
  mixins: [
    ConfigurationMixin,
    ClickOutsideMixin,
    MultipleBrandMixin,
    FocusHelperMixin,
    BrandMixin
  ],
  props: {
    iconClass: {
      type: String,
      default: '',
      required: false
    },
    fieldName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      setup: false,
      brandObjects: [{ brand: 'DEFAULT' }],
      icon: '',
      selectorVisible: false,
      brandSelectionLocked: false,
      assetsLoaded: false
    }
  },
  computed: {
    ...mapGetters(['isDefaultBrand']),
    ...mapState(['isUnitaryTest', 'form']),
    ...mapState({
      fieldIcons: state => state.fields.icons,
      isVisible(state) {
        if (!this.fieldName) return true
        let fieldIconConf =
          state.fields[this.fieldName]?.default?.icon?.visibility
        // Use specific field configuration
        if (isBoolean(fieldIconConf)) return fieldIconConf
        // Use general form config
        return state.form.brand.visibility
      }
    }),
    hasCustomIcon() {
      return !!this.iconClass?.length
    },
    hasSelector() {
      return !this.hasCustomIcon && this.fieldName === 'pan'
    },
    // Configuration
    css() {
      const field = this.$store.state.fields[this.fieldName]
      if (field) {
        const type = this.isInvalid ? 'error' : 'default'
        const { backgroundColor, iconColor } = field[type]
        return { backgroundColor, color: iconColor }
      }
      return {}
    },
    caret() {
      return (
        this.fieldIcons.miscellaneous.caret ||
        PreloadedAssets.miscellaneous.caret
      )
    }
  },
  watch: {
    css: 'setIcon',
    isInvalid: 'setIcon',
    fieldIcons: {
      deep: true,
      async handler() {
        await this.setIcon()
      }
    },
    detectedBrands: 'buildBrandObjects',
    selectedBrand: ['closeBrandSelector', 'setIcon']
  },
  async created() {
    this.checkCustomFieldIcon()
    await this.setIcon()

    // Start subscriptions
    this.$busOn(Events.krypton.field.event, ({ type }) => {
      if (type === 'triggerFocusActions' || type === 'focus')
        this.closeBrandSelector()
    })
    // Necessary to know when it is all setup for in test
    this.setup = true
  },
  methods: {
    toggleBrandSelector() {
      this.selectorVisible = !this.selectorVisible
    },
    closeBrandSelector() {
      this.selectorVisible = false
    },
    /**
     * If it's a custom field, it does some checks
     */
    checkCustomFieldIcon() {
      if (this.hasCustomIcon) {
        const customClasses = this.iconClass.split(' ')
        for (const customClass of customClasses) {
          this.$bus.$emit(Events.krypton.util.verification.class, customClass)
        }
      }
    },

    async buildBrandObjects() {
      if (this.fieldName === 'pan') {
        const { regular } = await loadAssets()
        this.brandObjects = this.detectedBrands.map(brand => {
          let icon = regular.branded[brand] || PreloadedAssets.regular.pan
          icon = ensureUniqId(icon)
          return { brand, icon }
        })
      }
    },

    /**
     * Set the proper svg icon
     */
    async setIcon() {
      if (this.isUnitaryTest) return

      // Only default icons are preloaded, check which one we need
      const type = this.isInvalid ? 'error' : 'regular'
      const defAssets = this.isInvalid ? await loadAssets() : PreloadedAssets
      let icon = ''

      if (this.fieldName === 'pan') {
        const brand = resolveBrand(this.selectedBrand, false)

        // Show default icon meantime assets load
        if (!this.assetsLoaded) this.icon = PreloadedAssets.regular.pan
        const assets = await loadAssets()
        this.assetsLoaded = true
        icon = assets[type].branded[brand] || defAssets[type].pan
        if (this.isDefaultBrand) icon = this.fieldIcons[type]?.pan || icon
      } else {
        // Other fields
        icon =
          this.fieldIcons[type][this.fieldName] ||
          defAssets[type][this.fieldName]

        // Replace the fill color of the svg icon
        if (this.css.color) {
          icon = icon.replace(/fill=\"([^"]*)\"/g, `fill="${this.css.color}"`)
        }
      }
      icon = isValidUrl(icon) ? this.urlToSvg(icon) : ensureUniqId(icon)
      this.icon = icon
    },
    urlToSvg(url) {
      return `<svg xmlns="http://www.w3.org/2000/svg"><image href="${url}" width="100%" height="100%"></image></svg>`
    }
  }
}
</script>
