/**
 * Util function to get all matching groups.
 * @param par the regex
 * @returns {number}
 * @constructor
 */
export default function MultiRegExp(par) {
    let regex;
    if (par.source !== undefined) {
        regex = par;
    } else {
        let exp = par;
        let opts = '';
        if (par.substring(0, 1) === '/') {
            const l = par.lastIndexOf('/');
            opts = par.substring(l + 1, par.length);
            exp = par.substring(1, l);
        }
        regex = new RegExp(exp, opts);
    }
    const expandSource = (braces, indexer) => {
        let ret = '';
        for (let i = 0; i < braces.length; i += 1) {
            if (braces[i].type === 'raw') {
                ret += `(${braces[i].text})`;
                indexer.next();
            } else if (braces[i].type === 'brace' && braces[i].containsCapture) {
                ret += braces[i].pre + expandSource(braces[i].children, indexer) + braces[i].post;
            } else if (braces[i].type === 'brace' && !braces[i].isCapture) {
                ret += `(${braces[i].text.substring(braces[i].pre.length, braces[i].text.length - braces[i].post.length)})`;
                indexer.next();
            } else if (braces[i].type === 'brace') {
                ret += braces[i].text;
                indexer.next(true);
            } else {
                ret += braces[i].text;
            }
        }
        return ret;
    };

    const captureScan = (braces, parent) => {
        const bracesToCapture = braces;
        const parentToCapture = parent;
        let containsCapture = false;
        for (let i = 0; i < braces.length; i += 1) {
            captureScan(braces[i].children, braces[i]);
            bracesToCapture[i].isCapture = braces[i].text.indexOf('(?:') !== 0;
            if (braces[i].isCapture) {
                containsCapture = true;
            }
            if (braces[i].isCapture && braces[i].containsCapture) {
                throw new Error('nested captures not permitted, use (?:...) where capture is not intended');
            }
        }
        if (parent) {
            parentToCapture.containsCapture = containsCapture;
        }
    };

    const fillGaps = (braces, text) => {
        let pre = /^\((\?.)?/.exec(text);
        pre = pre == null ? '' : pre[0];
        let post = /\)$/.exec(text);
        post = post == null ? '' : post[0];
        let i = 0;
        if (braces.length > 0) {
            fillGaps(braces[0].children, braces[0].text);
        }
        if (braces.length > 0 && braces[0].pos > pre.length) {
            braces.splice(0, 0, {
                type: 'raw', pos: pre.length, length: braces[0].pos, text: text.substring(pre.length, braces[0].pos),
            });
            i += 1;
        }
        for (i += 1; i < braces.length; i += 1) {
            fillGaps(braces[i].children, braces[i].text);
            if (braces[i].pos > braces[i - 1].pos + braces[i - 1].length) {
                braces.splice(i, 0, {
                    type: 'raw',
                    pos: braces[i - 1].pos + braces[i - 1].length,
                    length: braces[i].pos - (braces[i - 1].pos + braces[i - 1].length),
                    text: text.substring(
                        braces[i - 1].pos + braces[i - 1].length,
                        braces[i].pos,
                    ),
                });
                i += 1;
            }
        }
        if (braces.length === 0) {
            braces.push({
                type: 'raw', pos: pre.length, length: text.length - post.length - pre.length, text: text.substring(pre.length, text.length - post.length),
            });
        } else if (
            braces[braces.length - 1].pos + braces[braces.length - 1].length
            < text.length - post.length) {
            const pos = braces[braces.length - 1].pos + braces[braces.length - 1].length;
            const txt = text.substring(pos, text.length - post.length);
            braces.push({
                type: 'raw', pos, length: txt.length, text: txt,
            });
        }
    };

    const GetBrace = (text) => {
        const ret = {
            pos: -1, length: 0, text: '', children: [], type: 'brace',
        };
        const openExp = /^(?:\\.|[^)\\(])*\(\?./;
        let pre = 3;
        let post = 1;
        let m = openExp.exec(text);
        if (m == null) {
            m = /^(?:\\.|[^)\\(])*\(/.exec(text);
            pre = 1;
        }
        if (m != null) {
            ret.pos = m[0].length - pre;

            // eslint-disable-next-line
            ret.children = GetBraces(text.substring(m[0].length));
            for (let i = 0; i < ret.children.length; i += 1) {
                ret.children[i].pos += pre;
            }
            const closeExp = /^(?:\\.|[^\\()])*\)/;
            const closeExpAlt = /^(?:\\.|[^\\()])*\)\?/;
            const from = ret.children.length <= 0 ? ret.pos + pre
                : ret.children[ret.children.length - 1].pos
                + ret.children[ret.children.length - 1].length
                + m[0].length - pre;
            let m2 = closeExp.exec(text.substring(from));
            const m3 = closeExpAlt.exec(text.substring(from));
            if (m3 !== null && m3.length - 1 <= m2.length) {
                m2 = m3;
                post = 2;
            }
            if (m2 == null) {
                return null;
            }
            ret.length = m2[0].length + from - ret.pos;
            ret.text = text.substring(ret.pos, ret.pos + ret.length);
        }
        if (ret.text === '()' || /^\(\?.\)$/.test(ret.text)) {
            throw new Error('empty braces not permitted');
        }
        if (ret.pos !== -1) {
            ret.pre = ret.text.substring(0, pre);
            ret.post = ret.text.substring(ret.text.length - post, ret.text.length);
        }
        return ret.pos === -1 ? null : ret;
    };

    const GetBraces = (text) => {
        const ret = [];
        let shift = 0;
        let brace;
        do {
            brace = GetBrace(text);
            if (brace == null) {
                break;
            } else {
                // eslint-disable-next-line
                text = text.substring(brace.pos + brace.length);
                const del = brace.pos + brace.length;
                brace.pos += shift;
                shift += del;
                ret.push(brace);
            }
        } while (brace);
        return ret;
    };

    const fixOrs = (bracesWRaw) => {
        const braces = bracesWRaw;
        const orFind = /^(\\.|[^\\|])*\|/;
        for (let i = 0; i < braces.length; i += 1) {
            if (braces[i].type === 'raw') {
                const fullText = braces[i].text;
                const m = orFind.exec(fullText);
                if (m != null) {
                    const or = {
                        type: 'or', pos: m[0].length - 1 + braces[i].pos, length: 1, text: '|',
                    };
                    const raw = {
                        type: 'raw',
                        pos: m[0].length + bracesWRaw[i].pos,
                        length: fullText.length - m[0].length,
                        text: fullText.substring(m[0].length, fullText.length),
                    };
                    braces[i].text = fullText.substring(0, m[0].length - 1);
                    braces[i].length = braces[i].text.length;
                    braces.splice(i + 1, 0, or, raw);
                    i += 1;
                }
            } else if (braces[i].type === 'brace') {
                fixOrs(braces[i].children, braces[i].text);
            }
        }
    };

    let { source } = regex;
    const braces = GetBraces(source);
    captureScan(braces);
    fillGaps(braces, source);
    fixOrs(braces);
    const indexer = {
        i: 1,
        next(realPoint) {
            if (realPoint) {
                this.points.push(this.i);
            }
            this.i += 1;
            return this.i;
        },
        points: [],
    };
    source = expandSource(braces, indexer);
    this.dataPoints = indexer.points;
    const options = (regex.ignoreCase ? 'i' : '') + (regex.global ? 'g' : '') + (regex.multiline ? 'm' : '');
    this.regex = new RegExp(source, options);
    this.exec = (text) => {
        const m = this.regex.exec(text);
        if (m == null) {
            return {};
        }
        const ret = {};
        let ch = 0;
        for (let i = 1; i < m.length; i += 1) {
            if (m[i] !== null && m[i] !== undefined) {
                const pos = this.dataPoints.indexOf(i);
                if (pos !== -1) {
                    ret[pos] = { index: ch, text: m[i] };
                }
                ch += m[i].length;
            }
        }
        for (let i = 0; i < this.dataPoints.length; i += 1) {
            if (ret[i] === undefined) {
                ret[i] = null;
            }
        }
        return ret;
    };
}
