<template lang="pug">
  .kr-smart-form-wrapper(v-show="hasValidToken || isSmartFormPopin", :class="dynamicClasses.wrapper")
    PaymentTokenSmartButton( v-if="isSmartFormForcedToken"
      :paymentMethodToken="smartFormPaymentToken"
    )
    SmartFormModalButton(v-if="hasModalButton", v-on:open="openSmartFormPopinWrapper")
    SmartFormEmbeddedMethodsList(v-else-if="hasRootMethodsList")
      template(v-slot:cardForm="")
        slot(name="cardForm", v-if="renderEmbeddedForm")
    SmartFormLoadingOverlay
    SmartFormModal(:open="isSmartFormOpen", :view="currentView")
      template(v-slot:full="")
        SmartFormModalMethodsList(v-if="isSmartFormPopin", :mode="isGroupingActivated ? 'groups' : 'list'")
          template(v-slot:cardForm="")
            slot(name="cardForm", v-if="!renderEmbeddedForm")
      template(v-slot:group="")
        SmartFormModalBackButton(v-if="isSmartFormPopin && isGroupActive", :visible="currentView === 'group'")
        SmartFormModalMethodsList(v-if="isGroupingActivated && isGroupActive", mode="list")
          template(v-slot:cardForm="")
            slot(name="cardForm", v-if="!renderEmbeddedForm")
      template(v-slot:content="")
        SmartFormModalMethodsList(mode="method", v-on:back="navigateBack")
          template(v-slot:cardForm="")
            slot(name="cardForm", v-if="!renderEmbeddedForm")
      template(v-slot:extras="")
        ExtrasForm(v-if="isSmartFormPopin", :display-mode="'embedded'")
      template(v-slot:extra="")
        .kr-smart-form-methods
          .kr-methods.kr-method-active.kr-method-cards
            .kr-method-content.kr-disabled-back-button
              slot(name="extra")
    SimpleModal
    ExtrasForm(v-if="!isSmartFormPopin", :display-mode="'modal'")
    SmartFormLoadingOverlay(:only-window-redirection="true")
    KryptonLayer(:mode="isSmartFormPopin ? 'box' : 'unified'")
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import Events from '@/configuration/Events'
import SmartFormLoadingOverlay from '@/host/components/smartform/LoadingOverlay'
import SmartFormModalBackButton from '@/host/components/smartform/ModalBackButton'
import SmartFormModalButton from '@/host/components/smartform/ModalButton'
import SmartFormModal from '@/host/components/smartform/Modal'
import SmartFormModalMethodsList from '@/host/components/smartform/ModalMethodsList'
import SmartFormEmbeddedMethodsList from '@/host/components/smartform/EmbeddedMethodsList'
import SimpleModal from '@/host/components/controls/SimpleModal'
import ExtrasForm from '@/host/components/extrasform/ExtrasForm'
import KryptonLayer from '@/host/components/controls/KryptonLayer'
import PaymentTokenSmartButton from '@/host/components/smartbutton/PaymentToken'

/** MIXINS */
import { CardSelectorMixin } from '@/host/components/mixins/smartform/CardSelector'
import { RedirectCancelMixin } from '@/host/components/mixins/smartform/RedirectCancel'
import PaymentMethodGroup from '@/host/components/mixins/PaymentMethodGroup'

/**
 * KJS-2043
 * The standard syntax [template(v-slot:full)] fails with JEST and PUG compilation
 * It gets compiled as [<template v-slot:full="v-slot:full">]
 * (see PUG attributes mirroring: https://pugjs.org/language/attributes.html#boolean-attributes)
 */
export default {
  name: 'SmartFormLegacy',
  components: {
    SmartFormLoadingOverlay,
    SmartFormModalBackButton,
    SmartFormModalButton,
    SmartFormModal,
    SmartFormModalMethodsList,
    SmartFormEmbeddedMethodsList,
    SimpleModal,
    ExtrasForm,
    KryptonLayer,
    PaymentTokenSmartButton
  },
  mixins: [CardSelectorMixin, PaymentMethodGroup, RedirectCancelMixin],
  data() {
    return {
      modalHistory: ['full'],
      modalView: 'full'
    }
  },
  computed: {
    ...mapState(['redirecting', 'redirectIframeOpen', 'parsingToken']),
    ...mapGetters([
      'translate',
      'isCardMethod',
      'isSmartFormPopin',
      'isSmartFormOpen',
      'isSinglePaymentButton',
      'isActiveMethod',
      'numOfPaymentMethods',
      'hasSeveralPaymentMethods',
      'hasPaymentMethods',
      'hasCardMethodOption',
      'hasValidToken',
      'isMethodAvailableInDna',
      'isGroupingActivated',
      'cardsFormExpanded',
      'outsideCardsForm',
      'hasCardHeader',
      'hasSmartButton',
      'isGroupActive',
      'hasCardMethodAvailable',
      'isWallet',
      'isSmartFormForcedToken',
      'smartFormPaymentToken',
      'renderEmbeddedForm'
    ]),
    ...mapState({
      errorCode: state => state.error.errorCode,
      paymentMethods: state => state.smartForm.availablePaymentMethods,
      activeMethod: state => state.smartForm.activeMethod,
      views: state => state.smartForm.navigation.views,
      currentView: state => state.smartForm.navigation.currentView,
      history: state => state.smartForm.navigation.history,
      hidden: state => state.smartForm.hidden,
      forcedOverlay: state => state.form.smartform.overlay.forcedDisplayState,
      cardTokens: state => state.dna?.tokens?.cards || []
    }),
    dynamicClasses() {
      return {
        wrapper: {
          'kr-type-popin': this.isSmartFormPopin,
          'kr-type-embedded': !this.isSmartFormPopin,
          'kr-several-payment-methods': this.hasSeveralPaymentMethods
        }
      }
    },
    hasModalButton() {
      return (
        this.isSmartFormPopin &&
        this.hasPaymentMethods &&
        !this.hidden &&
        !this.isSmartFormForcedToken
      )
    },
    hasRootMethodsList() {
      return (
        (this.renderEmbeddedForm ||
          this.hasSeveralPaymentMethods ||
          this.isWallet) &&
        !this.hidden &&
        !this.isSmartFormForcedToken
      )
    }
  },
  watch: {
    errorCode(val) {
      if (val) {
        if (
          this.modalView == 'content' &&
          !this.isCardMethod(this.activeMethod)
        ) {
          this.goBack()
        }
      }
    },
    /**
     * Emit below event only if it is not singlePaymentButton mode since
     * the event has already been emitted when calling "selectMethod" action.
     *
     * @since KJS_2643
     */
    activeMethod(method) {
      if (!this.isSinglePaymentButton && method) {
        this.$bus.$emit(Events.krypton.smartform.paymentMethodSelected, method)
      }
    }
  },
  created() {
    this.setNavigableElements()
    const opts = {
      preventOff: [Events.krypton.form.relocation]
    }
    this.$busOn(
      Events.krypton.message.payment,
      () => {
        if (!this.redirectIframeOpen) {
          this.paymentProcessed()
          this.closeMethod()
        }
      },
      opts
    )
  },
  methods: {
    ...mapActions([
      'paymentProcessed',
      'openSmartFormPopinWrapper',
      'closeMethod',
      'navigateBack',
      'setNavigableElements'
    ])
  }
}
</script>
