import _ from 'underscore'

import domainsWhitelist from '@/configuration/sources/DomainsWhitelist.yml'

export default class Url {
  explainUrl(url) {
    const splitRegExp = new RegExp(
      '^' +
        '(?:' +
        '([^:/?#.]+)' + // scheme - ignore special characters
        // used by other URL parts such as :,
        // ?, /, #, and .
        ':)?' +
        '(?://' +
        '(?:([^/?#]*)@)?' + // userInfo
        '([\\w\\d\\-\\u0100-\\uffff.%]*)' + // domain - restrict to letters,
        // digits, dashes, dots, percent
        // escapes, and unicode characters.
        '(?::([0-9]+))?' + // port
        ')?' +
        '([^?#]+)?' + // path
        '(?:\\?([^#]*))?' + // query
        '(?:#(.*))?' + // fragment
        '$'
    )

    const split = url.match(splitRegExp)

    return {
      url,
      scheme: split[1],
      userInfo: split[2],
      domain: split[3],
      port: split[4],
      path: split[5],
      queryData: split[6],
      fragment: split[7]
    }
  }

  get(operation, url = null) {
    let parsedUrl

    if (_.isNull(url)) {
      url = g.location.href
    }
    if (operation === 'path') {
      parsedUrl = this.explainUrl(url)
      return parsedUrl.scheme + '://' + parsedUrl.domain + parsedUrl.path
    } else if (operation === 'host') {
      parsedUrl = this.explainUrl(url)
      return parsedUrl.domain
    } else if (operation.charAt(0) === '?') {
      const queryVar = operation.substring(1)
      return this.getVar(url, queryVar)
    }

    return null
  }

  clean(url) {
    return this.get('path', url)
  }

  getVar(url, queryVar) {
    const parsedUrl = this.explainUrl(url)
    let querys = []
    if (parsedUrl.queryData) querys = parsedUrl.queryData.split('&')
    let result = null
    querys.forEach(element => {
      let line = element.split('=')
      if (line[0] === queryVar) {
        result = line[1]
      }
    })
    return result
  }

  checkIfWhitelisted(endpoint) {
    let domains = domainsWhitelist.js_white_list
    const endpointExplained = this.explainUrl(endpoint)
    const domain = endpointExplained.domain

    let isWhitelisted = false

    domains.forEach(domainRegex => {
      let dmRx = new RegExp(domainRegex)
      if (dmRx.test(domain)) isWhitelisted = true
    })

    if (!isWhitelisted)
      return Promise.reject({
        code: 'CLIENT_997',
        message: `Endpoint ${endpoint} not allowed`
      })

    return Promise.resolve(true)
  }
}
