import _ from 'underscore'
import Events from '@/configuration/Events'

export default class Logger {
  constructor({ application, $bus, storeFactory, proxy }) {
    this.app = application
    this.$bus = $bus
    this.storeFactory = storeFactory
    this.proxy = proxy
    this.initialLogs = []
    if (this.app === 'host') this.startSubscriptions()
  }

  /**
   * Listen to the logs from the iframes
   */
  startSubscriptions() {
    // Logs from iframes
    this.$bus.$on(Events.krypton.message.log, ({ type, message, data }) => {
      this.log(type, message, data)
    })
  }

  log(type, message, data = {}) {
    switch (this.app) {
      case 'host':
        this.$bus.$emit(Events.krypton.log, {
          type,
          timestamp: Date.now(),
          message,
          data
        })
        break
      case 'ghost':
        const log = this.storeFactory.create('log', { type, message, data })
        this.proxy.send(log)
        break
      case 'slave':
        this.$bus.$emit('proxy.send', { _name: 'log', type, message, data })
        break
    }
  }

  initialLog() {
    for (const msg of this.initialLogs) this.log('info', msg.header, msg.logs)
  }

  addInitialInfo(msg) {
    this.initialLogs.push(msg)
  }

  logPromiseError(data, path = '') {
    if (process.env.NODE_ENV === 'development') console.error(data)
    this.log('error', `Promise rejection: [${path}]`, data)
  }

  logRequest(reqData, url) {
    const data = _.clone(reqData)
    if (data?.objectData?.paymentForm) {
      let pan = data.objectData.paymentForm.pan
      if (pan) {
        pan = `${pan.slice(0, 6)}${pan
          .slice(6, -4)
          .replace(/./g, 'X')}${pan.slice(-4)}`
        data.objectData.paymentForm.pan = pan
      }
      let securityCode = data.objectData.paymentForm.securityCode
      if (securityCode) {
        securityCode = `${securityCode.replace(/./g, 'X')}`
        data.objectData.paymentForm.securityCode = securityCode
      }
    }
    this.log('httpRequest', url, data.objectData)
  }

  logResponse(resData, url) {
    this.log('httpAnswer', url, resData.response)
  }
}
