import { pick } from 'underscore'
import { logSentryException } from '@/common/util/sentry'
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'

export default {
  methods: {
    readConfiguration() {
      try {
        const hashInfo = decodeURIComponent(window.location.hash.substring(1))
        this.parseConfiguration(JSON.parse(hashInfo))
      } catch (error) {
        logSentryException(error, 'slave/components/BootMixin')
      }

      this.$bus.$on(Events.slave.configuration.update, this.parseConfiguration)
    },
    parseConfiguration(json) {
      const propsWhitelist = [
        'awareId',
        'formId',
        'name',
        'order',
        'parentDomain',
        'placeholder',
        'formTokenPrefix',
        'fontFamily',
        'disabled',
        'mockTypeCarte',
        'hasDynamicValues'
      ]
      this.configuration = pick(json, propsWhitelist)
      this.$bus.$emit(Events.slave.configuration.parsed, this.configuration)
      this.flexAdaptation()
    },
    flexAdaptation() {
      Zepto('body').addClass('flex-slave')
    }
  }
}
