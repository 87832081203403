import BinOptionsConfig from '@/configuration/sources/BinOptionsConfig.yml'

export const getBinOptionsState = () => {
  return {
    binOptionsMap: [],
    mockBinOptions: null
  }
}

export const binOptionsActions = ($locator, app) => {
  return {
    async loadBinOptions({
      state: { hasDynamicValues, mockBinOptions },
      commit
    }) {
      if (!hasDynamicValues) return

      const origin = location.origin
      let source = `${origin}${BinOptionsConfig.filePath.production.default}`

      if (~BinOptionsConfig.localDomains.indexOf(location.host)) {
        source = `${origin}${BinOptionsConfig.filePath.local.default}`
      }

      if (mockBinOptions) {
        source = `${origin}/binoptions/${mockBinOptions}.json`
      }

      try {
        // Get the map
        const response = await fetch(source)
        if (response.status === 200) {
          const binOptionsMap = await response.json()
          commit('UPDATE', { binOptionsMap })
        }
      } catch (error) {
        console.error('An error ocurred while loading bin options', error)
      }
    }
  }
}
export const binOptionsMutations = {}
export const binOptionsGetters = {}
