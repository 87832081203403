import _ from 'underscore'
import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import { getTestCardFromDebugTestCards } from '@/common/util/card'

/**
 * Test cards manager
 */

export const TestCardsMixin = {
  computed: {
    ...dynamicMapState('context.namespace', ['testCard']),
    ...mapState('field', {
      autofilledExpiryDate: state => state.autofill.expiryDate,
      autofilledSecurityCode: state => state.autofill.securityCode
    })
  },
  methods: {
    /**
     * Search the test card number and return it formatted
     */
    getPanValue() {
      if (!this.testCard) return null
      const card = this._getTestCardFromDebugTestCards()
      return this.formatCardNumber(card.pan, card.brand)
    },

    /**
     * Return the expiry date
     */
    getExpiryDateValue() {
      if (!this.testCard) return null
      const card = this._getTestCardFromDebugTestCards()
      return card.expiryDate
        ? `${card.expiryDate}`
        : this.autofilledExpiryDate || '12/25'
    },

    getSecurityCodeValue() {
      if (!this.testCard) return null
      const card = this._getTestCardFromDebugTestCards()
      return card.cvv
        ? `${card.cvv}`
        : this.autofilledSecurityCode || card.brand === 'AMEX'
        ? '1234'
        : '123'
    },

    /**
     * Return the given card number formatted
     *
     * @param {String} cardNumber
     * @param {String} brand
     */
    formatCardNumber(cardNumber, brand) {
      if (typeof cardNumber !== 'string' && typeof cardNumber !== 'number')
        return cardNumber
      cardNumber = `${cardNumber}`

      if (brand == 'AMEX') {
        const match = /(.{4})(.{6})(.{5})/g.exec(cardNumber)
        return match.slice(1, match.length).join(' ')
      } else {
        return cardNumber.match(/.{1,4}/g).join(' ')
      }
    },

    _getTestCardFromDebugTestCards() {
      if (!this.testCard) return null
      return getTestCardFromDebugTestCards(this.testCard)
    }
  }
}
