<template lang="pug">
  iframe(
    v-show="visible"
    kr-resource
    :style="styles"
    :src="iframeSrc"
    :id="identifier"
    class=""
    scrolling="no"
    allowtransparency="true"
    frameborder="0"
    :kr-aware-id="awareId"
    :name="identifier"
    :title="identifier"
    :allow="allow"
  )
</template>

<script>
import { uniqueId } from 'underscore'
import { mapState } from 'vuex'

import { getPermissionPolicies } from '@/common/util/location'
import modalStyles from '@/configuration/sources/ModalStyles.yml'

/**
 * @since KJS-2508
 */
export default {
  name: 'KryptonGhostContainer',
  props: {
    identifier: {
      type: String,
      default: 'krcheckout'
    }
  },
  data() {
    return {
      awareId: null,
      iframeSrc: null,
      visible: false,
      styles: {
        position: 'relative',
        top: 'unset',
        left: 'unset',
        right: 'unset',
        bottom: 'unset',
        width: '100%',
        height: '100%',
        zIndex: 99999
      }
    }
  },
  computed: {
    ...mapState(['baseDomain']),
    allow() {
      return getPermissionPolicies(`https://${this.baseDomain}`)
    }
  },
  created() {
    const { iframeController, iframeBuilder } = this.$locator

    this.awareId = uniqueId('aware-')
    this.iframeSrc = iframeBuilder.generateGhostUrl(this.awareId)
    iframeController.registerGhostContainer(this)
    this.css(modalStyles.checkout.popin)
  },
  methods: {
    hide() {
      this.visible = false
      return this
    },
    show() {
      this.visible = true
      return this
    },
    css(styles) {
      for (const property in styles) {
        this.styles[property] = styles[property]
      }
      return this
    },
    parent() {
      return this.$el.parentElement
    }
  }
}
</script>
