var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.renderSinglePaymentButton
    ? _c("button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isVisible,
            expression: "isVisible",
          },
        ],
        ref: "content",
        staticClass: "kr-payment-button kr-smart-form-single-payment-button",
        class: _vm.dynamicClasses,
        style: _vm.styles,
        attrs: { type: "button", disabled: _vm.isDisabled, tabindex: "1" },
        domProps: { innerHTML: _vm._s(_vm.payBtnHTML) },
        on: {
          click: _vm.pay,
          keyup: function ($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
              return null
            }
            return _vm.tabReport($event)
          },
          keydown: function ($event) {
            if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
              return null
            }
            return _vm.tabReport($event)
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }