<template lang="pug">
  SmartFormGridWalletCardButton(
    v-if="isCardToken", 
    v-on:selected="onSelected",
    :method="method",
    :locked="locked"
    )
  SmartFormGridWalletMethodButton(
    v-else,
    v-on:selected="onSelected",
    :method="method",
    :locked="locked"
    )
</template>

<script>
import { mapState } from 'vuex'

import SmartFormGridWalletCardButton from '@/host/components/smartform/grid/wallet/CardButton'
import SmartFormGridWalletMethodButton from '@/host/components/smartform/grid/wallet/MethodButton'

export default {
  name: 'SmartFormGridWalletButton',
  components: {
    SmartFormGridWalletCardButton,
    SmartFormGridWalletMethodButton
  },
  props: {
    method: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      cardTokens: state => state.dna?.tokens?.cards || []
    }),
    isCardToken() {
      return this.cardTokens.some(token => token.token === this.method.token)
    }
  },
  methods: {
    onSelected(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
