import PiiPatterService from './PiiPatternService';
import PiiRuleService from './PiiRuleService';

/**
 * The PiiInstance with all its patterns/rules loaded.
 */
export default class PiiInstance {
    /**
     * Constructor.
     */
    constructor() {
        this.piiPatternService = new PiiPatterService();
        this.piiRuleService = new PiiRuleService(this.piiPatternService);
    }
}
