export function isWallet(tokens) {
  return !!(
    tokens?.cards?.length &&
    tokens?.cards.find(card => card.token) &&
    !tokens?.forced
  )
}

export const getTokenHelper = (allTokens, token) =>
  token && allTokens?.find(conf => conf.token === token)
