import { mapActions, mapGetters, mapState } from 'vuex'

export const EscapeMixin = {
  computed: {
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod,
      formMode: state => state.formMode
    }),
    ...mapGetters([
      'isFormPopin',
      'isSmartFormPopin',
      'isAnyPopinOpen',
      'isSmartButtonActive'
    ])
  },
  created() {
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        this.escape()
      }
    })
  },
  methods: {
    ...mapActions(['closeMethod']),
    escape() {
      if (this.isAnyPopinOpen) {
        if (
          !this.activeMethod ||
          this.isSmartButtonActive ||
          (this.activeMethod === 'CARDS' && this.formMode === 'token') ||
          (!this.isFormPopin && !this.isSmartFormPopin)
        )
          this.onClose()
        else this.closeMethod()
      }
    }
  }
}
