import { mapGetters } from 'vuex'

import DetachableMixin from '@/host/components/mixins/Detachable'

/**
 * Allow payment button component DOM to be detached to an external container
 *
 * @since KJS-3660
 */
export const StandalonePaymentButtonMixin = {
  mixins: [DetachableMixin],
  props: {
    // Override default value so button do not detach until DOM is fully read
    attach: {
      type: [Boolean, String, Element],
      default: true
    }
  },
  computed: {
    ...mapGetters(['getStandalonePaymentButton']),
    canDetach() {
      throw new Error(
        `Component ${this.$options.name} missing implementation for computed value "canDetach"`
      )
    }
  },
  mounted() {
    const container = this.getStandalonePaymentButton()
    // canDetach implementation is specific to final component
    // SinglePaymentButton & KryptonPaymentButton have different logic
    if (container && this.canDetach) {
      this.detachTo(container)
    }
  }
}
