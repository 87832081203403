import { v4 } from 'uuid'
import { isString } from 'underscore'

/**
 * Modifiy svg to ensure ID are uniq.
 *
 * @param  {String} svg Image content
 * @return {String}     Modified image content
 * @since  KJS-2030
 * @see    KJS-2162 | Move from KryptonIcon to separate file.
 */
export const ensureUniqId = svg => {
  if (isString(svg) && /kr-svg/.test(svg)) {
    const uuid = v4()
    svg = svg.replace(/kr-svg(?=__)/g, uuid)
  }
  return svg
}
