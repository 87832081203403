import { logSentryException } from '@/common/util/sentry'
import { isElementVisible } from '@/common/util/dom'
import { isPrestashop } from '@/common/util/environment'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'
import security from '@/configuration/sources/Security.yml'

export const setupSecurityCheck = () => {
  // Only make the security checks if the env can be considered frail
  if (!isFrail()) return
  // Observe mutations on the page
  const observer = new MutationObserver(check)
  // Observe body changes
  observer.observe(document.body, {
    attributes: true,
    childList: true,
    subtree: true
  })
}

/**
 * Checks if the environment can be cosidered frail
 */
const isFrail = () => {
  return isPrestashop()
}

const check = (mutationsList, observer) => {
  // Wait until the iframes are ready to make the checks
  if (!KR.$store.state.synced) return
  if (!isElementVisible(KR.$store.getters.getFormElement())) return

  const location = 'common/util/security.check'
  if (hasVisibilityInconsistency())
    logSentryException('Security : Iframe has been hidden', location)
  if (hasUnexpectedInput())
    logSentryException(
      'Security : Unexpected input inside field wrapper',
      location
    )

  // Disconnect the dom observer
  if (hasVisibilityInconsistency() || hasUnexpectedInput()) {
    observer.disconnect()
  }
}

/**
 * Checks if any of the fields with sensitive data has visiblity inconsistency
 */
const hasVisibilityInconsistency = () => {
  for (const field of fieldTypes.iframe) {
    const $field = KR.$store.getters.getFieldElement(field)
    // If field wrapper is visible, so it should be the iframe and it's wrappers
    if ($field && isElementVisible($field)) {
      const $iframe = $field.querySelector(`iframe[name="krfield-${field}"]`)
      const $iframeParent = $iframe?.parentElement
      const $iframeWrap = $iframe?.parentElement?.parentElement
      if (
        ($iframe && !isElementVisible($iframe)) ||
        ($iframeParent && !isElementVisible($iframeParent)) ||
        ($iframeWrap && !isElementVisible($iframeWrap))
      ) {
        return true
      }
    }
  }
}

/**
 * Checks if any of the fields with sensitive data has an input or textarea out
 * of the iframe
 */
const hasUnexpectedInput = () => {
  for (const field of fieldTypes.iframe) {
    const $field = KR.$store.getters.getFieldElement(field)
    if ($field && $field.querySelector('input, textarea')) return true
  }
}

export const securityReview = dispatch => {
  // Suspicious Dom Elements
  for (const detected in security.domElements) {
    const selector = security.domElements[detected]
    if (document.querySelector(selector)) {
      dispatch('update', { _internals: { integrationCase: detected } })
    }
  }
}
