export const checkHighConflict = () => {
  // Prototype 1.7.0.0
  if (window.hasOwnProperty('Prototype') && window.Prototype.Version === '1.7')
    return {
      conflict: true,
      message:
        'Conflict: Krypton client cannot be loaded with current version of Prototype (1.7.0.0), please upgrade to version >= 1.7.2.0'
    }
  return { conflict: false }
}

export const callWhenReady = () => {
  const scripts = Zepto('[kr-when-ready]')

  for (let i = 0; i < scripts.length; i++) {
    let whenReadyMethod = Zepto(scripts[i]).attr('kr-when-ready')
    if (window[whenReadyMethod]) window[whenReadyMethod]()
  }
}

export const whenDefined = (context, variableName, cb, interval = 150) => {
  let checkVariable = () => {
    if (context[variableName]) {
      cb()
    } else {
      setTimeout(checkVariable, interval)
    }
  }
  setTimeout(checkVariable, 0)
}
