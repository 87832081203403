<template lang="pug">
  #inputWrapper(:class="[(flexMode) ? 'flex-mode' : '']")
    #wrapper.kr-outer-wrapper(
        :class="[selectedBrand.toLowerCase(), fieldNameClass, isDisabled ? 'kr-disabled': '']",
        :style="[fieldCss.outerDefault, hasError ? fieldCss.outerError : '', isDisabled ? fieldCss.outerDisabled : '']"
      )
      .kr-inner-wrapper(
          :class="{'kr-error': hasError}",
          :style="[fieldCss.innerDefault, hasError ? fieldCss.innerError : '', isDisabled ? fieldCss.innerDisabled : '']"
        )
        .kr-field-container(
            :class="containerClasses",
            :style="fieldCss.fieldContainer"
          )
          .kr-field-relative-wrapper(
              :style="fieldCss.fieldRelativeWrapper"
            )
            Pan(v-if="isPan" ref="pan")
            ExpiryDate(v-if="isExpiryDate" ref="expiryDate")
            SecurityCode(v-if="isSecurityCode" ref="securityCode")
            input(
              id="endInputHook",
              :style="{position:'absolute',top:'-9999px',left:'-9999px',color:'transparent','background-color':'transparent','z-index':0,border:'none',outline:'none',display:'block'}",
              tabindex="10",
              @focus="tabEndReached",
              aria-hidden="true"
            )
</template>

<script>
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import { isString, union, contains } from 'underscore'

/** COMPONENTS */
import Pan from '@/slave/components/Pan'
import ExpiryDate from '@/slave/components/ExpiryDate'
import SecurityCode from '@/slave/components/SecurityCode'

/** MIXINS */
import BootMixin from '@/slave/components/BootMixin'
import PublicInterface from '@/slave/PublicInterface'
import { EventsMixin } from '@/slave/components/mixins/Event'
import { StateMixin } from '@/slave/components/mixins/State'

export default {
  name: 'SlaveApp',
  components: { Pan, ExpiryDate, SecurityCode },
  mixins: [BootMixin, PublicInterface, EventsMixin, StateMixin],
  provide() {
    return {
      context: this.context
    }
  },
  data() {
    return {
      containerClasses: {},
      outerClasses: {},
      computeCss: false,
      context: {
        formId: null,
        namespace: null
      }
    }
  },
  computed: {
    ...mapState('field', ['name', 'formId']),
    ...mapState(['css', 'flexMode', 'browser', 'os']),
    ...dynamicMapState('context.namespace', ['selectedBrand', 'testCard']),
    fieldNameClass() {
      if (this.isExpiryDate) {
        return 'kr-expiry'
      } else if (this.isSecurityCode) {
        return 'kr-security-code'
      }
      return `kr-${this.name}`
    },
    fieldCss() {
      // Forces a refreshment of the styles (vue doesn't listen to object changes)
      const computeCss = this.computeCss
      return this.css[this.name]
    },
    isPan() {
      return this.name === 'pan'
    },
    isExpiryDate() {
      return this.name === 'expiryDate'
    },
    isSecurityCode() {
      return this.name === 'securityCode'
    }
  },
  watch: {
    /**
     * Whenever a test card is selected, its value need to be reset after
     * next tick in order for watcher in sub-components to be triggered if
     * the same card is selected again.
     */
    testCard(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$store.dispatch(`${this.context.namespace}/update`, {
            testCard: ''
          })
        })
      }
    },
    browser: { handler: 'tagBrowser', deep: true }
  },
  created() {
    this.initializeContext()
    this.startSubscriptions()
    this.readConfiguration()
  },
  methods: {
    initializeContext() {
      this.context.formId = this.formId
      this.context.namespace = `cardForm_${this.formId}`
    },
    tagBrowser({ name, version }) {
      const browserName = name.replace(/\s/, '-')
      Zepto('body').addClass(
        `${browserName} ${browserName}-${parseInt(version, 10)}`
      )
    },

    startSubscriptions() {
      this.$bus.$on(Events.slave.configuration.parsed, configuration => {
        this.loadRemoteWebfontIfNeeded(configuration.fontFamily)
        this.$store.dispatch('field/boot', configuration)
      })

      this.$store.subscribe(mutation => {
        if (mutation.payload?.css && mutation.payload.css[this.name]) {
          this.computeCss = !this.computeCss
        }
      })
    },
    loadRemoteWebfontIfNeeded(family) {
      if (!family || !isString(family)) return
      let firstOption = family
        .split(',')[0]
        .replace(/\'/g, '')
        .replace(/\"/g, '')

      let websafe = [
        'Arial',
        'Georgia',
        '"Palatino Linotype"',
        'Palatino',
        '"Book Antiqua"',
        '"Times New Roman"',
        'Times',
        'Helvetica',
        '"Arial Black"',
        'Gadget',
        '"Comic Sans MS"',
        'Impact',
        'Charcoal',
        '"Lucida Sans Unicode"',
        '"Lucida Grande"',
        'Tahoma',
        'Geneva',
        '"Trebuchet MS"',
        'Verdana',
        '"Courier New"',
        'Courier',
        '"Lucida Console"',
        'Monaco',
        'monospace',
        'serif',
        'sans-serif'
      ]

      // Default macOS fonts
      if (this.os.name === 'macOS') websafe = union(websafe, ['system-ui'])

      if (!contains(websafe, firstOption)) {
        let googleFontUrl = `https://fonts.googleapis.com/css?family=${firstOption.replace(
          /\s/g,
          '+'
        )}`

        let element = document.createElement('link')
        element.setAttribute('rel', 'stylesheet')
        element.setAttribute('type', 'text/css')
        element.setAttribute('href', googleFontUrl)
        document.getElementsByTagName('head')[0].appendChild(element)
      }
    },

    tabEndReached(e) {
      this.fieldEvent('tab')
    }
  }
}
</script>
