<template lang="pug">
  .kr-popin-wrapper(:class="dynamicClasses", :style="dynamicStyles")
    slot
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import modalStyles from '@/configuration/sources/ModalStyles.yml'

/**
 * Added to render .kr-popin-wrapper as a Vue components in order to properly
 * handle dynamic classes, avoid vanilla JS and improve debugging.
 *
 * @since KJS-4240
 */
export default {
  name: 'KryptonPopinWrapper',
  data() {
    return {
      largeForm: false
    }
  },
  computed: {
    ...mapGetters(['getViewport']),
    ...mapState(['isPopinOpen', 'redirectIframeOpen']),
    dynamicClasses() {
      return {
        'kr-popin-wrapper--large-form':
          this.largeForm && !this.redirectIframeOpen
      }
    },
    dynamicStyles() {
      const config = this.isPopinOpen
        ? modalStyles.popin.opened
        : modalStyles.popin.closed
      const styles = {}
      Object.assign(styles, config)
      return styles
    }
  },
  watch: {
    isPopinOpen() {
      if (this.isPopinOpen) {
        this.checkCardFormOffset()
      }
    }
  },
  methods: {
    checkCardFormOffset() {
      // If popin is too big, reposition it
      this.largeForm = this.getCardFormOffsetTop() < 45
    },
    /**
     * Externalized to simplify mocking in unit-tests.
     * @return {number} .kr-embedded offset top
     */
    getCardFormOffsetTop() {
      const vpHeight = Math.floor(this.getViewport().height)
      const cardForm = this.$locator.cardFormController.getByLabel('main')
      return (vpHeight - cardForm.getProperty('offsetHeight')) / 2
    }
  }
}
</script>
