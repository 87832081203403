import { mapGetters, mapActions } from 'vuex'

import Events from '@/configuration/Events'

/**
 * Shared logic between Grid's SmartForm & SmartFormLegacy in regards to
 * CARDS methods selection with SinglePaymentButton + card form expanded.
 * Allow to auto-select CARDS when:
 *  - Any card form input get focused
 *  - Card header is clicked
 *  - Form is filled (e.g: with debug toolbar test card selection)
 *
 * @since KJS-3986
 */
export const CardSelectorMixin = {
  computed: {
    ...mapGetters([
      'cardsFormExpanded',
      'hasCardInRoot',
      'isFormFilled',
      'isSinglePaymentButton',
      'isWalletSmartForm'
    ])
  },
  watch: {
    isFormFilled(val) {
      if (val) {
        this.onFormFilled()
      }
    }
  },
  created() {
    this.startExpandedFormSubscriptions()
    if (
      this.isSinglePaymentButton &&
      this.hasCardInRoot &&
      !this.isWalletSmartForm
    ) {
      this.selectMethod('CARDS')
    }
  },
  methods: {
    ...mapActions(['selectMethod']),
    /**
     * For a SmartForm with expanded card form and single payment button,
     * the following event must cause the card to be seleted:
     * - a focus on input
     * - a click on the checkbox or select field
     *
     * If the form loses focus and is empty, CARDS should be unselected.
     *
     * @since KJS-2643
     * @see KJS-3986 | Move to mixin
     */
    startExpandedFormSubscriptions() {
      this.$busOn(Events.krypton.field.event, ({ type }) => {
        if (this.isSinglePaymentButton && this.cardsFormExpanded) {
          switch (type) {
            case 'focusReport':
            case 'focus':
            case 'triggerFocusActions':
            case 'userClick':
              this.selectMethod('CARDS')
              break
          }
        }
      })
    },
    /**
     * In case the form is filled without any of the event mentionned above
     * (e.g: by selecting a testcard on the toolbar) we should detect the form
     * as been (even partially) filled and set CARDS a the selected method.
     *
     * @since KJS-2643
     * @see KJS-3986 | Move to mixin
     */
    onFormFilled() {
      if (this.isSinglePaymentButton && this.cardsFormExpanded) {
        this.selectMethod('CARDS')
      }
    }
  }
}
