/* eslint no-underscore-dangle: 0 */
/* eslint class-methods-use-this: 0 */
/* eslint no-param-reassign: 0 */

export default class Lyngle {
    constructor(options) {
        const defaults = {
            selector: 'body',
            onClose: null,
            onOpen: null,
        };

        this.opts = this._extend({}, defaults, options);

        this.init();
    }

    init() {
        this.content = document.createElement('div');
        this.content.style.width = '100%';
        this.content.style.height = '100%';
        this.buildModal();

        // Insert the modal
        document.querySelector(this.opts.selector).appendChild(this.modal);
    }

    buildModal() {
        // wrapper
        this.modal = document.createElement('div');
        this.modal.style.width = '100%';
        this.modal.style.height = '100%';
        this.modal.classList.add('lyngle');

        // Initially not displayed
        this.modal.style.display = 'none';

        // Add content
        this.modal.appendChild(this.content);
    }

    setContent(content) {
        // check type of content : String or Node
        if (typeof content === 'string') {
            this.content.innerHTML = content;
        } else {
            this.content.innerHTML = '';
            this.content.appendChild(content);
        }
    }

    open() {
        if (this.modal.style.removeProperty) {
            this.modal.style.removeProperty('display');
        } else {
            this.modal.style.removeAttribute('display');
        }

        this.modal.classList.add('lyngle--visible');

        if (this.opts.onOpen) this.opts.onOpen();
    }

    close() {
        this.modal.classList.remove('lyngle--visible');
        this.modal.style.display = 'none';

        if (this.opts.onClose) this.opts.onClose();
    }

    isOpen() {
        return !!this.modal.classList.contains('lyngle--visible');
    }

    destroy() {
        if (this.modal === null) {
            return;
        }
        if (this.isOpen()) {
            this.close();
        }
        // remove modal from dom
        document.querySelector(this.opts.selector).removeChild(this.modal);
        this.modal = null;
    }

    /**
     * @private
     */
    /* eslint no-plusplus: 0 */
    _extend(...args) {
        for (let i = 1; i < args.length; i++) {
            Object.keys(args[i]).forEach((key) => {
                args[0][key] = args[i][key];
            });
        }
        return args[0];
    }
}
