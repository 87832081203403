<template lang="pug">
  .kr-cursor-mirror(
    :style="{ position: 'fixed', top: '-10000px', left: '-10000px' }",
    v-if="enabled",
    :class="animated ? 'animated' : ''"
  )
    .kr-input-mirror
      span(v-for="cleanedInputValue in cleanedInputValues", :style="$parent.inputCSS", v-html="cleanedInputValue")
    span.mirror-initial(:style="$parent.inputCSS") A
    .fake-cursor(:style="caretStyle")
</template>

<script type="text/javascript">
import Events from '@/configuration/Events'
import Zepto from 'zepto-webpack'
import { mapState, mapGetters } from 'vuex'
import { defer, pick } from 'underscore'

export default {
  name: 'CursorMirror',
  props: ['inputValue'],
  data() {
    return {
      letterAccumulator: {},
      inputValueCleaned: '',
      enabled: false,
      animated: true,
      selectionStart: null,
      offset: false,
      renderTrigger: false,
      caretStyle: {
        backgroundColor: '#3A74ED',
        position: 'fixed',
        top: '1px',
        left: '1px',
        width: '2px',
        height: '17px',
        display: 'none'
      },
      cleanedInputValues: []
    }
  },
  computed: {
    ...mapState('field', [
      'name',
      'fakeCursorPosition',
      'isUIWebView',
      'touchedPosition'
    ]),
    ...mapState([
      'fakeCursorAnimation',
      'fakeFocusedField',
      'fakeCursorLocked',
      'os'
    ]),
    ...mapGetters(['isIos'])
  },
  watch: {
    renderTrigger: 'cursorRedraw',
    inputValue: 'cleanInputValue',
    inputValueCleaned() {
      if (!this.enabled) return
      this.$nextTick(() => {
        this.cursorRedraw()
      })
    },
    fakeFocusedField(name) {
      if (name === this.name) this.cursorFakeFocus()
      else this.cursorFakeBlur()
    },
    fakeCursorAnimation(animated) {
      this.animated = animated
    },
    fakeCursorLocked(newVal) {
      if (newVal) {
        this.cursorFakeBlur()
        setTimeout(() => {
          this.$store.dispatch('update', { fakeCursorLocked: false })
        }, 200)
      }
    },
    touchedPosition(newVal) {
      this.cursorFakeFocus(newVal)
    }
  },
  mounted() {
    if (this.browserToOverrideCursor()) {
      this.enabled = true

      // Change the color of the caret
      this.ensureCaretStyle(true)

      // Initial calculation of the size
      this.$nextTick(() => {
        const initialHeight = Zepto('.mirror-initial').height()
        this.caretStyle.height = this.fixHeight(initialHeight)
      })

      // Create iteration to check the position of the caret always
      // to recalculate the caret position
      let $input = document.getElementById('inputField')
      setInterval(() => {
        let currentSelectionStart = $input.selectionStart
        let storedSelectionStart = this.selectionStart
        // There should be value on the field, and also the selectio of the current
        // element should diverge from the previous stored (store on value change)
        if (
          this.inputValue &&
          this.inputValue.length &&
          currentSelectionStart !== storedSelectionStart
        ) {
          this.cleanInputValue(this.inputValue, this.inputValue)
        }
      }, 30)
    }

    this.$bus.$on(Events.slave.field.hideFakeCaret, () => {
      this.cursorFakeBlur()
    })
  },
  methods: {
    browserToOverrideCursor() {
      return this.isIos && parseInt(this.os.version.split('.')[0]) < 13
    },
    forceRender() {
      this.renderTrigger = !this.renderTrigger
    },
    ensureCaretStyle(checkBlurred = false) {
      if (!this.enabled) return
      const $body = Zepto('body')
      if (!$body.hasClass('caret-invisible')) $body.addClass('caret-invisible')
      if (!$body.hasClass('caret-blink')) $body.addClass('caret-blink')
      if (checkBlurred) {
        if (!$body.hasClass('caret-blurred')) $body.addClass('caret-blurred')
      }
    },
    cursorFakeBlur() {
      if (!this.enabled) return

      this.caretStyle.display = 'none'

      this.forceRender()
    },
    cursorFakeFocus(position = null) {
      if (!this.enabled) return
      this.ensureCaretStyle()
      this.animated = false
      defer(() => {
        this.animated = true
      })

      this.cursorRedraw(position)
      this.caretStyle.display = 'block'
    },
    fixHeight(val) {
      return `${val - 2}px`
    },
    cursorRedraw(position = null) {
      const $inputField = Zepto('#inputField')
      this.ensureCaretStyle()
      // Offset calculation
      if (!this.offset) {
        let offset = $inputField.offset()

        // Correction with padding left, if the input has any
        let paddingLeftInput = $inputField.css('padding-left')
        if (/^\d+px$/.test(paddingLeftInput)) {
          let paddingLeftInputInt = parseInt(paddingLeftInput, 10)
          offset.left -= paddingLeftInputInt
        }

        this.offset = pick(offset, ...['top', 'left'])
      }

      if (!this.$el || !this.$el.children) return
      let $span = Zepto(this.$el.children[1])
      let height = $span.height()

      // Position calc
      let width = this.calculateCursorPosition(position)

      // the padding on top and bottom should be removed from the calculated height
      let paddingTopInput = $inputField.css('padding-top')
      let paddingBottomInput = $inputField.css('padding-bottom')
      let topCorrection = 0
      let overflowCorrection = 0

      if (/^\d+px$/.test(paddingTopInput)) {
        let paddingTopInputInt = parseInt(paddingTopInput.replace('px', ''), 10)
        topCorrection += paddingTopInputInt
        height -= paddingTopInputInt
        height += 1
      }
      if (/^\d+px$/.test(paddingBottomInput)) {
        height -= parseInt(paddingBottomInput.replace('px', ''), 10)
        height += 1
      }

      this.caretStyle.left = `${width + this.offset.left - 1}px`
      this.caretStyle.top = `${
        this.offset.top + overflowCorrection + topCorrection
      }px`
      this.caretStyle.height = this.fixHeight(height)
    },
    cleanInputValue(newVal) {
      if (!this.enabled) return
      let $input = document.getElementById('inputField')
      let selectionStart = $input.selectionStart
      this.selectionStart = selectionStart

      // Generate the list of values
      this.cleanedInputValues = []
      for (let i = 0; i < newVal.length; i++) {
        this.cleanedInputValues.push(
          `${newVal.substring(0, i + 1)}`.replace(/\s/g, '&nbsp;')
        )
      }

      let spacesVal = `${newVal.substring(0, this.selectionStart)}`.replace(
        /\s/g,
        '&nbsp;'
      )

      // If selectinStart on input is different from the current position
      // then we should cut the span text on the cursor position
      this.inputValueCleaned = spacesVal
    },
    /**
     * Calculate where the cursor should be placed
     */
    calculateCursorPosition(position = null) {
      let $spans = Zepto(this.$el).find('.kr-input-mirror span')

      if ($spans.length) {
        // Get posible lengths
        let widths = []
        $spans.each((index, el) => {
          let $el = Zepto(el)
          widths.push($el.width())
        })

        // Only for > iOS11.2 (caret-color support)
        const osv = this.os.version.split('.')
        const version = osv.length > 1 ? `${osv[0]}.${osv[1]}` : `${osv[0]}`
        if (parseFloat(version) > 11.2 && !this.isUIWebView) {
          if (position) {
            // Calculate which one is closer to the touched position
            let closer = null
            for (let i = 0; i < widths.length; i++) {
              let width = widths[i]
              let distance = Math.abs(position - width)
              if (!closer || closer.distance > distance) {
                closer = { width, distance, charPos: parseInt(i) + 1 }
              }
            }

            // Check if it's closer to the initial point
            let distanceToZero = Math.abs(position - 0)
            if (closer.distance > distanceToZero) {
              closer = {
                width: 0,
                distance: distanceToZero,
                charPos: 0
              }
            }

            this.$store.dispatch('field/update', {
              fakeCursorPosition: parseInt(closer.charPos)
            })
            return closer.width
          } else {
            // No specific position -> use the fakeCursorPosition
            let cursorPos = parseInt(this.fakeCursorPosition) - 1
            if (cursorPos < 0) return 0
            return widths[cursorPos]
          }
        } else {
          // Not-compatible -> At the end
          return widths[widths.length - 1]
        }
      } else {
        // No spans -> it's empty
        return 0
      }
    }
  }
}
</script>
