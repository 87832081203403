import Events from '@/configuration/Events'
import redirectLoader from '@/ghost/redirectLoader'

export default class GhostBlender {
  constructor($locator, unitTest = false) {
    this.$locator = $locator
    this.proxy = $locator.proxy
    this.$bus = $locator.$bus
    this.storeFactory = $locator.storeFactory
    this.$store = $locator.$store
    this.ghostReadyInterval
    this.configuration = null
    this.unitTest = unitTest

    if (window?.FIELD_CONFIGURATION) {
      this.configuration = window.FIELD_CONFIGURATION
      this.proxy.parentDomain = this.configuration.parentDomain
    }
  }

  boot() {
    this.registerListeners()
    this.reportAware()
  }

  /**
   * Sends the aware message to the host because the configuration has
   * been parsed already and the input has been rendered
   *
   * @return {Promise}
   */
  reportAware() {
    const configuration = this.configuration
    const storeFactory = this.storeFactory
    const proxy = this.proxy
    const echoObj = {
      awareId: configuration.awareId,
      source: 'ghost'
    }

    // First send
    proxy.send(storeFactory.create('echo', echoObj))
    // We set an interval to send the echo until we receive the response
    this.ghostReadyInterval = setInterval(() => {
      proxy.send(storeFactory.create('echo', echoObj))
    }, 1000)
  }

  /**
   * Register all the needed listeners to be used by DOM elements
   * like form
   *
   * @returns {Promise}
   */
  registerListeners() {
    this.$bus.$on(Events.krypton.message.echo, ({ source }) => {
      if (source === 'ghost') clearInterval(this.ghostReadyInterval)
    })

    this.$bus.$on(
      Events.krypton.payment.redirect,
      redirectLoader(this.$locator)
    )

    this.$store.subscribe(({ payload }) => {
      if (payload?.redirection === false) this.onDestroyRedirection()
    })

    this.$bus.$on(Events.krypton.message.startGooglePay, () => {
      this.$store.dispatch('startGooglePayPayment')
    })
  }

  onDestroyRedirection() {
    Zepto('.app-ghost-container .kr-iframe-container').empty()
  }
}
