import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import { setupSecurityCheck } from '@/common/util/security'

// Loaders
import appLoader from '@/common/loader/app'
import errorAlertLoader from '@/host/loaders/ErrorAlert'
import formLoader from '@/host/loaders/Form'
import smartFormLoader from '@/host/loaders/SmartForm'
import popinLoader from '@/host/loaders/Popin'
import popinRedirectionLoader from '@/host/loaders/PopinRedirection'
import brandButtonsLoader from '@/host/loaders/BrandButtons'
import smartButtonLoader from '@/host/loaders/SmartButton'

/** COMPONENTS */
import HostApp from '@/host/HostApp'
import { uniqueId } from 'underscore'

export default locator => {
  // Security
  setupSecurityCheck()

  // Throw an alert error if the token is wrong in any way
  locator.$bus.$on(Events.krypton.data.errorAlert, errorAlertLoader(locator))

  Zepto('body')
    .first()
    .append(`<div kr-application="1" kr-resource id="krapp"></div>`)

  return appLoader(locator, 'HostApp', {
    el: '#krapp[kr-application="1"][kr-resource]',
    components: { HostApp },
    data() {
      return {
        apps: { form: null, smartForm: null, smartButtons: {} }
      }
    },
    mounted() {
      const $bus = this.$locator.$bus
      // Destroy the form app to avoid multiple instances
      $bus.$on(Events.krypton.destroy, this.onDestroy)

      // Initiate apps
      $bus.$on(Events.krypton.form.ready, this.registerForm)
      $bus.$on(Events.krypton.smartform.ready, this.registerSmartForm)
      $bus.$on(Events.krypton.data.newPopin, msg => popinLoader(locator)(msg)) // Don't use $bus.$on(event, callback) it breaks jest mock
      $bus.$on(Events.krypton.data.newPopinRedirection, msg =>
        popinRedirectionLoader(locator)(msg)
      )
      $bus.$on(Events.krypton.data.newBrandButtons, msg =>
        brandButtonsLoader(locator)(msg)
      )
      $bus.$on(Events.krypton.data.newSmartFormButton, this.registerSmartButton)
    },
    methods: {
      registerForm(msg) {
        this.apps.form = formLoader(locator)(msg)
      },
      registerSmartForm(msg) {
        this.apps.smartForm = smartFormLoader(locator)(msg)
      },
      registerSmartButton(msg) {
        const key = uniqueId('smartbutton_')
        this.apps.smartButtons[key] = smartButtonLoader(locator)(msg)
      },
      onDestroy(apps = null) {
        apps = apps || this.apps
        for (const app in apps) {
          if (app === 'smartButtons') this.onDestroy(apps[app])
          else if (apps[app]) {
            apps[app].$destroy()
            apps[app] = null
          }
        }
      }
    }
  })
}
