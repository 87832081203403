import Vue from 'vue'
import { render } from '@/common/util/templateCompiler'
import { isString } from 'underscore'

export default (locator, element, options) => {
  return new Vue({
    locator,
    bus: locator.$bus,
    store: locator.$store,
    ...options,
    render(createElement) {
      if (isString(element)) return createElement(element)
      else return render(this, createElement, element)
    }
  })
}
