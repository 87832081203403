import regeneratorRuntime from 'regenerator-runtime' // NECESSARY to use async

export const loadRedirectServices = async locator => {
  const RedirectBlender = await import(
    /* webpackChunkName: "kr-redirect" */ '@/redirect/Blender'
  )
  const WindowRedirectTrigger = await import(
    /* webpackChunkName: "kr-redirect" */ '@/redirect/Trigger'
  )
  const BrowserRequests = await import(
    /* webpackChunkName: "kr-redirect" */ '@/ghost/service/Requests/BrowserRequests'
  )
  const RestAPI = await import(
    /* webpackChunkName: "kr-redirect" */ '@/ghost/service/Requests/RestAPI'
  )

  locator.trigger = new WindowRedirectTrigger.default(locator)
  locator.browserRequests = new BrowserRequests.default(locator)
  locator.restAPI = new RestAPI.default(locator)
  locator.blender = new RedirectBlender.default(locator)

  return Promise.resolve()
}
