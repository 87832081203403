import { extend, isString } from 'underscore'

import { capitalize } from '@/common/util/string'
import { includes } from '@/common/util/array'
import clientErrorWhitelist from '@/configuration/sources/ClientErrorWhitelist.yml'
import ErrorsConfig from '@/configuration/sources/ErrorsConfiguration.yml'
import SentryConf from '@/configuration/sources/Sentry.yml'

// Check if the error is in the whitelist of code/message
export const isFullWhitelisted = code => {
  for (const pattern of clientErrorWhitelist['codes_and_messages_whitelist']) {
    const re = new RegExp(pattern)
    if (re.test(code)) return true
  }
  return false
}

// Check if the error is in the code whitelist (keep the code and )
export const isCodeWhitelisted = code => {
  for (const pattern of clientErrorWhitelist['codes_whitelist']) {
    const re = new RegExp(pattern)
    if (re.test(code)) return true
  }
  return false
}

/**
 * Check if error is included in blacklist:
 * - If blacklist item is a string: check it matches error.errorCode
 * - If blacklist item is an object: check code & detailedErrorCode match error
 *
 * @param {Object} error
 * @param {string} error.errorCode
 * @param {string} [error.detailedErrorCode]
 * @since KJS-3809
 */
export const isBlackListed = error => {
  const { blacklist } = SentryConf.errors

  return blacklist.some(item => {
    if (typeof item === 'string') {
      return error.errorCode === item
    } else if (typeof item === 'object') {
      return Object.keys(item).every(key => error[key] === item[key])
    }
    return false
  })
}

export const shouldLogSentry = error => {
  // Added for retro-compatibility
  if (typeof error === 'string') {
    error = { errorCode: error }
  }
  const { errorCode: code } = error
  const { whitelist } = SentryConf.errors
  const clientError = includes(code, 'CLIENT')
  const clientAllowed = includes(code, 'CLIENT_9')
  return (
    !isBlackListed(error) &&
    (!clientError || clientAllowed || includes(whitelist, code))
  )
}

// Gets the error info from metadata
export const getMetadata = error => {
  const codes = ErrorsConfig.codes
  if (error.metadata) {
    // By default, error data in metadata root
    let metadata = error.metadata
    // Payment refused format - error in transaction object
    if (metadata?.answer?.clientAnswer?.transactions[0])
      metadata = metadata.answer.clientAnswer.transactions[0]
    // Payment refused fallback - error in answer object
    else if (metadata?.answer?.clientAnswer)
      metadata = metadata.answer.clientAnswer
    // Payment with error fallback - metadata in answer object
    else if (metadata?.answer) metadata = metadata.answer

    // Check error code fallback
    if (codes[metadata.errorCode]) error.errorCode = codes[metadata.errorCode]
    // Set error code directly - if not present, PSP_996 (Empty error code in server response)
    else error.errorCode = metadata.errorCode || 'PSP_996'
    if (metadata.detailedErrorCode)
      error.detailedErrorCode = metadata.detailedErrorCode
    if (metadata.detailedErrorMessage)
      error.detailedErrorMessage = metadata.detailedErrorMessage
    const orderCycle = error.metadata?.answer?.clientAnswer?.orderCycle
    if (orderCycle) error.orderCycle = orderCycle
    const cardOrderCycle = error.metadata?.answer?.clientAnswer?.cardOrderCycle
    if (cardOrderCycle) error.cardOrderCycle = cardOrderCycle
  }

  return error
}

// Checks if the error object has a valid transaction attached
export const hasTransaction = error => {
  const answer = error.metadata?.answer
  const transactions = answer?.clientAnswer?.transactions
  const mode = answer?.clientAnswer?.orderDetails?.mode

  return (
    answer &&
    transactions &&
    mode &&
    transactions[0]?.uuid &&
    transactions[0]?.status &&
    answer?.hash
  )
}

// Returns the transaction from the given error
export const getTransaction = error => {
  const answer = error.metadata.answer

  const transaction = {
    clientAnswer: answer.clientAnswer,
    hash: answer.hash,
    hashAlgorithm: answer.hashAlgorithm,
    _type: answer._type,
    serverDate: answer.clientAnswer.serverDate,
    rawClientAnswer: JSON.stringify(answer.clientAnswer)
  }
  if (answer.hashKey) transaction['hashKey'] = answer.hashKey

  return transaction
}

// Apply changes to the error if it's necessary (FIXME)
export const preFormat = error => {
  if (
    error.errorCode === 'PSP_002' &&
    error.detailedErrorMessage &&
    ~error.detailedErrorMessage.indexOf('identityDocumentNumber')
  ) {
    error._errorKey = 'PSP_002_ID_NUMER'
  } else if (error.metadata?.stacktrace) {
    // If it has a stacktrace attached, it's a JS error
    error.errorCode = 'CLIENT_993'
  }
}

// Format an error/warning message, performing replacements if it's necessary
export const formatMsg = (
  { getters },
  msgKey,
  code,
  intError,
  paymentMethod = null
) => {
  const { translate } = getters
  if (!paymentMethod) paymentMethod = getters.paymentMethod
  let msg = translate(msgKey)
  // If there is no translation for the error and it has a defined errorMessage, use it
  if (msg === msgKey) msg = intError?.errorMessage || msgKey

  const metadata = intError.metadata || {}
  const replacements = ErrorsConfig.replacements
  // Check for replacements configuration
  const replConf = replacements[code]
  if (replConf && metadata[replConf.data]) {
    msg = msg.replace(replConf.replace, metadata[replConf.data])
  } else if (code === 'CLIENT_304' && intError.field) {
    msg = `${capitalize(intError.field)}: ${translate(msgKey)}`
  } else if (ErrorsConfig.withPaymentMethod.includes(code) && paymentMethod) {
    msg = `${getters.getPaymentMethodLabel(paymentMethod)} - ${msg}`
  } else if (msgKey === msg && intError.detailedErrorMessage) {
    msg =
      intError.detailedErrorMessage.charAt(0).toUpperCase() +
      intError.detailedErrorMessage.slice(1)
  }

  /**
   * Moved this part from `warning` action to share the mecanic with `error`.
   * This allows to define a specific errorMessage containing %translation%.
   * %translation shall be replaced by regular error code translation.
   *
   * E.g:
   *  given errorMessage=`%translation%: this is a sample error message`
   *  AND errorCode=ACQ_001
   *  AND language=es
   *
   * The output will be: `Pago rechazado: this is a sample error message`
   *
   * @since KJS-3830
   */
  if (
    intError.errorMessage &&
    ~intError.errorMessage.indexOf('%translation%')
  ) {
    msg = intError.errorMessage.replace('%translation%', msg)
  }
  return msg
}

// Format an error
export const format = ({ getters, state }, error, child = false) => {
  const formId = error.formId ?? state.forms[state.activeForm] ?? null

  let paymentMethod = error.paymentMethod
  // Preformat for temporal hacks
  preFormat(error)
  // Code
  let errorCode = error
  if (!isString(error)) errorCode = error.errorCode

  // Error key not defined - use code
  const _errorKey = error._errorKey || error.errorCode

  // Message - default - get from the key if not provided
  const errorMessage = formatMsg(
    { getters },
    _errorKey,
    errorCode,
    error,
    paymentMethod
  )
  const orderCycle = error.orderCycle
  const cardOrderCycle = error.cardOrderCycle
  // Detailed code
  let detailedErrorCode = error.detailedErrorCode
  // Detailed message
  let detailedErrorMessage = error.detailedErrorMessage
  const detTransKey = `E_${detailedErrorCode}`
  const { translate } = getters
  if (translate(detTransKey) !== detTransKey)
    detailedErrorMessage = translate(detTransKey)

  // Children format
  const children = []
  if (error.children?.length)
    for (const son of error.children)
      children.push(format({ getters, state }, son, true))

  // Metadata
  const savedProps = [
    'answer',
    'isHiddenOnForm',
    'console',
    'prefix',
    'stacktrace',
    'stack'
  ]
  const metadata = { formToken: state.formToken }
  if (error.metadata) {
    savedProps.forEach(prop => {
      if (error.metadata.hasOwnProperty(prop)) {
        metadata[prop] = error.metadata[prop]
      }
    })
  }
  const selectedBrand = formId
    ? state[`cardForm_${formId}`].selectedBrand
    : null
  if (selectedBrand && selectedBrand !== 'DEFAULT')
    metadata.brand = selectedBrand.toLowerCase()
  if (metadata.answer?.clientAnswer) {
    const rawAnswer = JSON.stringify(metadata.answer.clientAnswer)
    metadata.answer.rawClientAnswer = rawAnswer
  }
  // Payment method
  const { paymentMethodMetadata } = getters
  if (!paymentMethod) paymentMethod = getters.paymentMethod
  if (paymentMethodMetadata)
    paymentMethod = `${paymentMethod}:${paymentMethodMetadata}`

  // Formatted error
  const formError = {
    cardOrderCycle,
    orderCycle,
    errorCode,
    errorMessage,
    detailedErrorCode,
    detailedErrorMessage,
    field: error.field,
    _errorKey,
    paymentMethod
  }

  // Props for the parent error only (not for children)
  if (!child)
    extend(formError, {
      children,
      metadata,
      formId,
      type: 'error',
      _type: 'krypton/error'
    })

  return formError
}

export const logError = error => {
  if (error.metadata?.console) {
    let errorString = formatErrorMessage(error)

    // Show logs for child errors
    error.children.forEach(childError => {
      errorString += `\n\t · ${formatErrorMessage(childError)}`
    })

    console.error(errorString)
  }
}

export const logWarning = (warn, commit) => {
  if (warn.metadata?.console) {
    let msg = `${warn.errorCode} : ${warn.errorMessage}`

    if (warn.metadata?.console.extraMessage) {
      msg += '\n\n' + warn.metadata.console.extraMessage
    }

    console.warn(msg)
  }

  commit('UPDATE', { warning: warn })
}

function formatErrorMessage(error) {
  let errorString = ''

  if (error.metadata?.prefix) errorString += '[' + error.metadata.prefix + ']: '
  errorString += `${error.errorCode} : ${error.errorMessage}`

  return errorString
}
