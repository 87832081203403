import InitialState from '@/ghost/workflow/payment/state/InitialState'

export default class PaymentContext {
  constructor($locator, form) {
    this.$locator = $locator
    this.$bus = $locator.$bus
    this.form = form
    this.state = null
    this.storeFactory = $locator.storeFactory
    this.proxy = $locator.proxy
    this.browserRequests = $locator.browserRequests

    this.init()
  }

  init() {
    this.changeState(new InitialState(this.$locator))
    this.state.context = this
  }

  getArgs() {
    let stateArgs = [
      this,
      this.$locator.storeFactory,
      this.$locator.proxy,
      this.$locator.browserRequests
    ]

    return stateArgs
  }

  /**
   * Sets the state into the context
   *
   * @param {AbstractState} state
   */
  changeState(state) {
    this.state = state
  }

  /**
   * Validates the current payment workflow. It returns a promise
   * to check if has been valid or not the payment data to be able to send it.
   *
   * @return {Promise}
   */
  async validate(...args) {
    return this.state.validate(...args)
  }

  /**
   * Format the current payment data. Returns a promise.
   *
   * @return {Promise}
   */
  async format(...args) {
    return this.state.format(...args)
  }

  /**
   * Final payment process
   *
   * @return {Promise}
   */
  async processPayment(formObject) {
    return this.state.processPayment(formObject)
  }

  /**
   * Report host with POST form submit
   *
   * @return {Promise}
   */
  async reportForm() {
    return this.state.reportForm()
  }
}
