import { render, staticRenderFns } from "./ModalButton.vue?vue&type=template&id=7b14223c&lang=pug&"
import script from "./ModalButton.vue?vue&type=script&lang=js&"
export * from "./ModalButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/VAD_KJS_build_hotfix/proxy-purebilling/krypton-js-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b14223c')) {
      api.createRecord('7b14223c', component.options)
    } else {
      api.reload('7b14223c', component.options)
    }
    module.hot.accept("./ModalButton.vue?vue&type=template&id=7b14223c&lang=pug&", function () {
      api.rerender('7b14223c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/host/components/smartform/ModalButton.vue"
export default component.exports