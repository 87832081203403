<template lang="pug">
  .kr-modal-content(:class="wrapperDynamicClasses")
    .kr-modal-content__view(
      v-for="(_, name) in $scopedSlots",
      :class="dynamicClasses(name)",
    )
      slot(:name="name")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SmartFormGridModalContent',
  props: {
    activeView: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    activeLevel() {
      return Object.keys(this.$scopedSlots).indexOf(this.activeView)
    },
    wrapperDynamicClasses() {
      return {
        [`kr-modal-content--${this.activeView.toLowerCase()}`]:
          !!this.activeView
      }
    },
    dynamicClasses() {
      return view => ({
        [`kr-modal-content__view--${view.toLowerCase()}`]: !!view,
        [`kr-modal-content__view--level-${this.activeLevel}`]: true,
        'kr-modal-content__view--active': this.activeView === view
      })
    }
  },
  methods: {
    ...mapActions(['openMethod'])
  }
}
</script>
