<template lang="pug">
extends templates/FormError.pug
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import FormError from '@/host/components/mixins/FormError'
import { MethodDescriptor } from '@/common/model/PaymentMethod'

export default {
  name: 'KryptonFormError',
  mixins: [FormError],
  inject: {
    context: {
      default: null
    }
  },
  computed: {
    ...mapGetters(['isSmartForm']),
    ...mapState({
      paymentMethods: state => state.smartForm.availablePaymentMethods
    }),
    showFormError() {
      return this.hasData && (this.hasOnlyCards || this.isCardsError)
    },
    hasOnlyCards() {
      return (
        (this.paymentMethods.length === 1 &&
          this.paymentMethods[0] === 'CARDS') ||
        !this.isSmartForm
      )
    },
    isCardsError() {
      const paymentMethod = this.error?.paymentMethod
      if (!paymentMethod) return true
      return MethodDescriptor.fromString(paymentMethod).name === 'CARDS'
    }
  }
}
</script>
