<template lang="pug">  
  .kr-finish-page(:class="'kr-' + orderStatus")
    .kr-animation(v-if="orderStatus === 'paid'")
      .kr-check-icon
        .kr-icon-circle
        span.kr-icon-line.kr-line-tip
        span.kr-icon-line.kr-line-long
        .kr-icon-fix
    KryptonLoadingAnimation(v-else-if="orderStatus === 'running'")
    .kr-finish-label
      label {{translate(label)}}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'

export default {
  name: 'SmartFormFinishedPayment',
  components: { KryptonLoadingAnimation },
  computed: {
    ...mapGetters(['translate']),
    ...mapState(['orderStatus']),
    label() {
      return this.orderStatus === 'running'
        ? 'smartform_payment_in_progress'
        : 'smartform_payment_accepted'
    }
  }
}
</script>
