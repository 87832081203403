/**
 * Used as an abstraction layer to ensure cross-browser compatibility in order
 * to get left and right border position, width/height, etc... of a
 * VisualViewport or DOMRect object.
 *
 * @since KJS-2523
 * @see KJS-3895 | Use in SecurityCode slave to check placeholder space
 */
export default class ViewArea {
  /**
   * @param {DOMRect|null} area
   */
  static create(area = null) {
    if (area instanceof DOMRect) {
      return new ViewAreaDOMRect(area)
    } else if (area === null) {
      return ViewArea.isVisualViewportDefined()
        ? new ViewAreaViewport(window.visualViewport)
        : new ViewAreaDOMRect(document.documentElement.getBoundingClientRect())
    }
    throw new TypeError(
      `ViewArea.create: expected area to be VisualViewport or DOMRect`
    )
  }

  static isVisualViewportDefined() {
    return (
      typeof VisualViewport === 'function' &&
      window.visualViewport instanceof VisualViewport
    )
  }

  constructor(area) {
    this.area = area
  }

  height() {
    return this.area.height
  }

  width() {
    return this.area.width
  }
}

class ViewAreaViewport extends ViewArea {
  left() {
    return this.area.pageLeft
  }

  right() {
    return this.area.pageLeft + this.area.width
  }
}

class ViewAreaDOMRect extends ViewArea {
  left() {
    return this.area.left
  }

  right() {
    return this.area.left + this.area.width
  }
}
