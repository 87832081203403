import { mapState } from 'vuex'

/**
 * Render apple pay button.
 *
 * Main component should have the following properties defined:
 *   - this.method
 *   - this.language
 *
 * @since KJS-2311
 */
export const ApplePayMixin = {
  computed: {
    ...mapState({
      applePayTestMode: state => state.applePay.testMode
    }),
    isApplePay() {
      const method = this.method || this.paymentMethod
      return method === 'APPLE_PAY'
    },
    applePaySimulator() {
      return this.isApplePay && this.applePayTestMode
    }
  }
}
