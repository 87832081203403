import { dynamicMapGetters, dynamicMapActions } from '@/common/util/store'
import { mapGetters } from 'vuex'

export default {
  inject: ['context'],
  computed: {
    ...mapGetters(['isCardFormVisible']),
    ...dynamicMapGetters('context.namespace', [
      'isFieldVisible',
      'isFieldDisabled',
      'isFieldInvalid'
    ]),
    isVisible: {
      get() {
        return (
          this.isCardFormVisible(this.context.formId) &&
          this.isFieldVisible(this.fieldName)
        )
      },
      set(isVisible) {
        this[isVisible ? 'showField' : 'hideField'](this.fieldName)
      }
    },
    isDisabled: {
      get() {
        if (!this.isFieldDisabled) return false
        return this.isFieldDisabled(this.fieldName)
      },
      set(isDisabled) {
        this[isDisabled ? 'disableField' : 'enableField'](this.fieldName)
      }
    },
    isInvalid: {
      get() {
        if (!this.isFieldInvalid) return false
        return this.isFieldInvalid(this.fieldName)
      },
      set(isInvalid) {
        this[isInvalid ? 'invalidateField' : 'validateField'](this.fieldName)
      }
    }
  },
  methods: {
    ...dynamicMapActions('context.namespace', [
      'showField',
      'hideField',
      'disableField',
      'enableField',
      'invalidateField',
      'validateField'
    ])
  }
}
