import { findIndex, sortBy, uniq } from 'underscore'
import { mapState, mapGetters } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import { fieldNameAsSelector } from '@/common/util/fieldName'
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'

export default {
  computed: {
    ...mapState(['browser']),
    ...mapGetters(['isAnyPopinOpen', 'getFormElement', 'isCardFormRendered']),
    ...dynamicMapState('context.namespace', [
      'forcedFieldBlur',
      'visibleFields',
      'fieldsOrder'
    ])
  },
  watch: {
    forcedFieldBlur(newVal) {
      if (newVal) this.$bus.$emit('krypton.field.blurAll', {})
    },
    isAnyPopinOpen(val) {
      if (val) this.orderDetection()
    }
  },
  created() {
    this.$busOn(Events.krypton.field.event, ({ formId, name, type }) => {
      if (!this.isCardFormRendered(this.context.formId)) return

      switch (type) {
        case 'tab':
        case 'reverseTab':
          this.handleTab({ formId, name, backwards: type === 'reverseTab' })
          break
        case 'focus':
          this.handleFocus({ formId, name })
          break
      }
    })

    this.$busOn(Events.krypton.form.loaded, msg => this.orderDetection())
  },
  methods: {
    handleTab({ formId, name, backwards = false }) {
      let index = findIndex(this.fieldsOrder, x => {
        return x === name
      })

      index = backwards ? index - 1 : index + 1

      let tabName = this.fieldsOrder[index]
      if (index >= this.fieldsOrder.length) {
        // tab to next element on the page
        tabName = 'last'
      } else if (index < 0) {
        // Reverse tab to previous page element
        tabName = 'first'
      }
      this.handleFocus({ formId, name: tabName, backwards })
    },

    handleFocus({ formId, name, backwards }) {
      if (~fieldTypes.iframe.indexOf(name)) {
        this.$locator.proxy.send(
          this.$locator.storeFactory.create('fieldEvent', {
            formId,
            name,
            type: 'focus'
          })
        )
      } else {
        this.$bus.$emit(Events.krypton.field.focus, { formId, name, backwards })
      }
    },

    orderDetection() {
      // Skip if the card form is not rendered
      if (!this.isCardFormRendered(this.context.formId)) return
      // Detect the order of the fields
      const $form = Zepto(this.getFormElement())
      const fields = {}
      const fieldsOrder = []
      let tops = []
      let lefts = []

      for (const element of this.visibleFields) {
        const field = Zepto(fieldNameAsSelector(element))
        // Additional field
        if (!field.size()) continue
        const { top, left } = field.offset()
        tops.push(top)
        lefts.push(left)

        fields[element] = { left, top }
      }

      // Order it (first assign row to each height)
      tops = sortBy(uniq(tops))
      lefts = sortBy(uniq(lefts))

      // Iterate to decide the order of the fields
      for (const topField of tops) {
        for (const leftField of lefts) {
          for (const key in fields) {
            const { top, left } = fields[key]
            if (top === topField && left === leftField) fieldsOrder.push(key)
          }
        }
      }

      // Additional fields
      const additionalFields = {}
      const $additionalFields = $form.find('.kr-theme')
      if ($additionalFields.length) {
        $additionalFields.each((index, $field) => {
          const tabOrder = $field.getAttribute('kr-tab-order')
          const name = $field.getAttribute('name')
          if (tabOrder && name) {
            additionalFields[parseInt(tabOrder) - 1] = name
          } else if (name !== 'kr-do-register') {
            console.warn(
              `Additional field has no ${
                !tabOrder && !name
                  ? '"name" and "kr-tab-order"'
                  : !name
                  ? '"name"'
                  : '"kr-tab-order"'
              } declared`
            )
          }
        })

        for (const position in additionalFields) {
          fieldsOrder.splice(position, 0, additionalFields[position])
        }

        // If the payment button is visible, add it on last position
        const $paymentBtn = $form.find('.kr-payment-button')
        const btnVisible =
          $paymentBtn.css('display') !== 'none' &&
          $paymentBtn.css('visibility') !== 'hidden'
        if (btnVisible) fieldsOrder.push('paymentButton')
      }

      this.$store.dispatch(`${this.context.namespace}/update`, { fieldsOrder })
    }
  }
}
