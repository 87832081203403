/**
 * Make components detachable. This means onces mounted, it shall move its own
 * content to a [kr-detachment-area] element. The main point being to be able to
 * move a DOM subtree up in the DOM in order to handle z-index and stacking
 * context regardless of the parent components styles.
 *
 * UPDATE KJS-2425:
 *   - Added "attach" property to specify and element or selector to which
 *     content should be attached.
 *   - "attach" propert can be true to allow deferred detachment with method
 *     "detachTo"
 *
 * UPDATE KJS-2834:
 *   - Added "testMode" property to not dettach elements by default (used by unit tests)
 *
 * @since KJS-2162 (2022-09-15)
 * @see KJS-2425
 */
export default {
  props: {
    attach: {
      type: [Boolean, String, Element],
      default: false
    },
    testMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasDetached: false,
      deferredAttach: false
    }
  },
  watch: {
    attach: 'initDetach',
    deferredAttach: 'initDetach'
  },
  mounted() {
    this.initDetach()
  },
  beforeDestroy() {
    if (this.$refs.content && this.$refs.content.parentNode) {
      this.$refs.content.parentNode.removeChild(this.$refs.content)
    }
  },
  methods: {
    detachTo(element) {
      this.deferredAttach = element
    },
    initDetach() {
      const attach = this.deferredAttach || this.attach
      if (
        this.testMode ||
        this._isDestroyed ||
        !this.$refs.content ||
        this.hasDetached ||
        attach === '' || // If used as a boolean prop (<kr-component attach>)
        attach === true || // If bound to a boolean (<kr-component :attach="true">)
        attach === 'attach' // If bound as boolean prop in pug (kr-component(attach))
      )
        return
      let target
      if (attach === false) {
        // Default, detach to [kr-detachment-area]
        target = document.querySelector('[kr-detachment-area]')
      } else if (typeof attach === 'string') {
        // CSS Selector
        target = document.querySelector(attach)
      } else {
        // DOM Element
        target = attach
      }

      if (!target) {
        console.warn(
          `Unable to locate target ${attach || '[kr-detachment-area]'} in DOM`
        )
      }
      target?.appendChild(this.$refs.content)
      this.hasDetached = true
    }
  }
}
