export default class AbstractStrategy {
  constructor(fieldName) {
    this.fieldName = fieldName
    this.value = ''
  }

  // Interface
  echo() {}
  validate() {}

  getValue() {
    return this.value
  }

  focus() {}

  sync(payload) {}
  autofill(callback) {}
  setAutofillValue(data) {}
}
