import { dynamicMapState } from '@/common/util/store'

import SmartFormMaskedCardLabel from '@/host/components/smartform/MaskedCardLabel'
import SmartFormGridWalletDelete from '@/host/components/smartform/grid/wallet/Delete'

import DeleteButtonMixin from '@/host/components/mixins/DeleteButtonMixin'
import MethodButtonMixin from '@/host/components/mixins/MethodButton'
import { mapState } from 'vuex'

export default {
  components: {
    SmartFormMaskedCardLabel,
    SmartFormGridWalletDelete
  },
  mixins: [MethodButtonMixin, DeleteButtonMixin],
  props: {
    method: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hoverDelete: false,
      applyAlertStyle: false
    }
  },
  computed: {
    ...dynamicMapState('context.namespace', {
      paymentMethodToken: state => state?.nonSensitiveValues?.paymentMethodToken
    }),
    ...dynamicMapState('context.namespace', [
      'disabledForm',
      'disabledCardForm'
    ]),
    ...mapState(['allIFramesReady', 'redirectTransaction', 'amount']),
    ...mapState({
      flashDuration: state => state.form.smartform.methodLabel.flashDuration
    }),
    walletDynamicClasses() {
      return {
        ...this.dynamicClasses,
        'kr-method-btn--hover-delete': this.hoverDelete,
        'kr-method-btn--create-token': !this.amount,
        'kr-method-btn--alert': this.applyAlertStyle,
        'kr-method-btn--create-token-selected':
          (this.isLoading || this.hover || this.hoverDelete) && !this.amount
      }
    },
    isDisabled() {
      return (
        !this.allIFramesReady ||
        this.locked ||
        this.disabledForm ||
        this.disabledCardForm ||
        (this.paymentMethodToken &&
          this.paymentMethodToken !== this.method.token)
      )
    },
    isLoading() {
      return (
        !this.errorCode &&
        this.redirectTransaction === null &&
        this.isActiveMethod(this.methodType) &&
        this.paymentMethodMetadata === this.method.token &&
        !!this.amount
      )
    },
    isSelected() {
      return this.isSelectedMethod(this.methodKey)
    }
  },
  methods: {
    onDeleteClick() {
      this.applyAlertStyle = true
      setTimeout(() => {
        this.applyAlertStyle = false
      }, this.flashDuration * 1000)
    }
  }
}
