<template lang="pug">
  span.kr-delete(
    @click="deleteCardHandler",
    v-on="$listeners",
    :title="tooltip",
    v-html="icon"
  )
</template>

<script>
import { mapGetters } from 'vuex'

import PreloadedAssets from '@/configuration/PreloadedAssets'
import DeleteButtonMixin from '@/host/components/mixins/DeleteButtonMixin'

export default {
  name: 'SmartFormGridWalletDelete',
  mixins: [DeleteButtonMixin],
  inject: ['context'],
  props: {
    method: { type: String, default: 'CARDS' },
    token: { type: String, default: '', required: true }
  },
  computed: {
    ...mapGetters(['translate']),
    icon() {
      return PreloadedAssets.delete
    },
    tooltip() {
      return this.translate('tooltip_delete')
    }
  },
  methods: {
    deleteCardHandler() {
      this.deleteCard(this.method, this.token)
    }
  }
}
</script>
