import _ from 'underscore'

export default class AbstractState {
  constructor($locator) {
    this.$locator = $locator
    /** @type {PaymentContext} */
    this.context = $locator.context
    this.storeFactory = $locator.storeFactory
    this.proxy = $locator.proxy
    this.browserRequests = $locator.browserRequests
    this.restAPI = $locator.restAPI

    this.init()
  }

  getArgs() {
    return this.$locator
  }

  init() {}
  validate(raisePromiseException = false) {}
  processPayment(formObject) {}
  reportForm() {}
}
