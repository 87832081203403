import { each, isString, isArray, isUndefined, uniqueId } from 'underscore'
import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'

/**
 * Class to handle style verifications
 */
class StyleChecker {
  /**
   * @public
   * @param {locator} locator
   */
  constructor({ $bus, $store }) {
    this.$store = $store
    this.$bus = $bus

    $bus.$on(Events.krypton.util.verification['class'], data => {
      const verification = this.verifyClass(data)
      if (verification?.undefinedStyle) {
        $store.dispatch('warning', 'CLIENT_704')
      }
    })
  }

  /**
   * Verify if the class exists on the current stylesheet of
   * the document. Raises a client warning if this class does
   * not exist.
   *
   * @private
   * @param {string} classString The class to check
   */
  verifyClass(classString) {
    // no verification if not on test/dev environment
    if (!this.$store.state.test) return null

    const classList = classString.split(' ')

    let undefinedStyle = true
    const notDefinedClasses = []

    each(classList, classItem => {
      let classClean = classItem.replace(/\s/g, '')
      classClean = `.${classClean}`
      if (classClean.length) {
        if (this.checkIfDefined(classClean)) {
          undefinedStyle = false
        } else {
          notDefinedClasses.push(classItem)
        }
      }
    })

    return { undefinedStyle, notDefinedClasses }
  }

  checkIfDefined(classString) {
    // do not check it for nodejs
    if (isUndefined(document)) return true
    let isDefined = false

    // generic rules seek
    each(document.styleSheets, ({ rules }) => {
      try {
        each(rules, ({ selectorText }) => {
          if (isString(selectorText)) {
            selectorText = selectorText.replace(/\s/g, '')
            let selectorRules = selectorText.split(',')
            if (isArray(selectorRules)) {
              each(selectorRules, selectorRule => {
                if (selectorRule.indexOf(classString) !== -1) isDefined = true
              })
            }
          }
        })
      } catch (error) {
        logSentryException(error, 'host/service/StyleChecker')
      }
    })

    // Font package check (if it looks like a font, check the font package)
    // Supporting libraries: font-awesome
    const $links = Zepto('link')
    if (/^\.fa(|-[a-z]+)$/.test(classString)) {
      $links.each((index, element) => {
        if (/font?(.)awesome/i.test(element.getAttribute('href'))) {
          isDefined = true
        }
      })
    }

    // computed style for a given element
    const spanId = uniqueId('krspan')
    Zepto('body')
      .first()
      .append(
        `<span class="${classString}" style="visibility:hidden;position:fixed;top:-10000px;left:-10000px;" id="${spanId}"></span>`
      )

    // Check computed
    const fontFamily = window
      .getComputedStyle(document.getElementById(spanId), null)
      .getPropertyValue('font-family')
    if (/^fa(|-[a-z]+)$/.test(classString)) {
      if (/font.*awesome/i.test(fontFamily)) isDefined = true
    }

    return isDefined
  }
}

export default StyleChecker
