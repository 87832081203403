<template lang="pug">
  span.kr-method-tooltip(
    :class="[tooltipVisible ? 'kr-hover' : '']"
    @click="clickLabel"
    @mouseover="setMouseOver('activator')"
    @mouseleave="unsetMouseOver('activator')"
  ) {{ label }}
    .kr-method-tooltip-content(
      ref="content"
      :class="dynamicClasses"
      :style="tooltipContentStyles"
      @mouseover="setMouseOver('content')"
      @mouseleave="unsetMouseOver('content')"
    )
      slot
</template>

<script>
import { mapGetters } from 'vuex'
import { ViewArea } from '@/common/model'
import DetachableMixin from '@/host/components/mixins/Detachable'

export default {
  name: 'SmartFormMethodTooltip',
  mixins: [DetachableMixin],
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      mouseOver: {
        activator: false,
        content: false
      },
      tooltipOnTop: false,
      tooltipContentStyles: {},
      outerReference: null
    }
  },
  computed: {
    ...mapGetters(['isSmartFormPopin']),
    dynamicClasses() {
      return {
        'kr-visible': this.tooltipVisible,
        'kr-on-top': this.tooltipOnTop
      }
    },
    tooltipVisible() {
      return this.mouseOver.activator || this.mouseOver.content
    }
  },
  watch: {
    tooltipVisible: 'setPosition'
  },
  created() {
    this.setOuterReference()
  },
  methods: {
    /**
     * @param {MouseEvent} e
     */
    clickLabel(e) {
      e.stopPropagation()
      this.setMouseOver('activator')
    },
    setMouseOver(name) {
      this.mouseOver[name] = true
    },
    unsetMouseOver(name) {
      this.mouseOver[name] = false
    },
    /**
     * If isSmartFormPopin, components will be detached to the layer manager.
     * In that case we need to set a fixed position in order to fully display
     * the tooltip content on top of the modal and calculate the
     * position accordingly.
     *
     * Otherwise we keep the position defined in CSS (absolute) and check for
     * eventual overflow before adjust the best position.
     *
     * @see KJS-2553
     */
    setPosition() {
      if (!this.$el || !this.tooltipVisible) return
      if (this.hasDetached) {
        this.setFixedPosition()
      } else {
        this.setVerticalPosition()
        this.setHorizontalPosition()
      }
    },
    setFixedPosition() {
      const activatorRect = this.$el.getBoundingClientRect()
      const content = this.$refs.content
      const contentWidth =
        content.firstChild && content.firstChild.offsetWidth
          ? content.firstChild.offsetWidth
          : 0
      this.tooltipContentStyles.position = 'fixed'
      this.tooltipContentStyles.top = `${activatorRect.top}px`
      const left = activatorRect.left + (activatorRect.width - contentWidth) / 2
      this.tooltipContentStyles.left = `${left}px`
    },
    setVerticalPosition() {
      const activatorRect = this.$el.getBoundingClientRect()

      // Vertical position
      let innerHeight = window.innerHeight
      let position = activatorRect.y + activatorRect.height

      if (this.testKeys) {
        const $toolbar = document.querySelector('#krtoolbar')
        if ($toolbar) {
          const toolbarHeight = $toolbar.getBoundingClientRect().height
          innerHeight -= toolbarHeight
        }
      }

      this.tooltipOnTop = position > innerHeight - 100
    },
    setHorizontalPosition() {
      const activatorRect = this.$el.getBoundingClientRect()
      const referenceRect = this.outerReference.$el.getBoundingClientRect()

      const view = ViewArea.create(this.isSmartFormPopin ? referenceRect : null)

      // Default centered with activator
      this.tooltipContentStyles = { left: `0px`, right: `0px` }

      if (this.detectLeftOverflow(activatorRect, view)) {
        // Stick left to left to the outer reference
        const deltaLeft = activatorRect.x - referenceRect.x
        this.tooltipContentStyles = {
          justifyContent: 'flex-start',
          left: `-${deltaLeft}px`
        }
      } else if (this.detectRightOverflow(activatorRect, view)) {
        // Stick right to right to the outer reference
        const deltaRight =
          referenceRect.x +
          referenceRect.width -
          activatorRect.x -
          activatorRect.width
        this.tooltipContentStyles = {
          justifyContent: 'flex-end',
          right: `-${deltaRight}px`
        }
      }
    },
    /**
     * @param {DOMRect} activatorRect
     * @returns {boolean}
     * @since KJS-2523
     */
    detectLeftOverflow(activatorRect, view) {
      const content = this.$refs.content
      if (content.firstChild === null || !content.firstChild.offsetWidth) {
        return false
      }
      return activatorRect.x - content.firstChild.offsetWidth / 2 < view.left()
    },
    /**
     * @param {DOMRect} activatorRect
     * @returns {boolean}
     * @since KJS-2523
     */
    detectRightOverflow(activatorRect, view) {
      const content = this.$refs.content
      if (content.firstChild === null || !content.firstChild.offsetWidth) {
        return false
      }
      return (
        activatorRect.x +
          activatorRect.width +
          content.firstChild.offsetWidth / 2 >
        view.right()
      )
    },
    /**
     * Find the outer reference: Closest SmartForm or SmartButton parent.
     * If none is found the Vue app $root shall be used as a backup.
     *
     * @since KJS-2523
     */
    setOuterReference() {
      let component = this.$parent
      while (!this.isValidReference(component) && component !== this.$root) {
        component = component.$parent
      }
      this.outerReference = component
    },
    /**
     * Return true if given component is a valid outer reference,
     * meaning the component can be used to position the tooltip content
     * if it is not centered with the activator.
     *
     * @param {Vue} component
     * @returns {boolean}
     * @since KJS-2523
     */
    isValidReference(component) {
      const name = component.$options.name
      if (this.isSmartFormPopin) {
        return name === 'SmartFormMethodLabel'
      }
      return name === 'SmartForm' || name === 'SmartButton'
    }
  }
}
</script>
