var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "kr-field-element",
      class: _vm.dynamicClasses.element,
    },
    [
      _c(
        "div",
        {
          staticClass: "kr-field-wrapper kr-text-wrapper",
          class: [
            _vm.wrapperClassName,
            _vm.errorAnimation ? "kr-error-animation" : "",
            _vm.isDisabled ? "kr-disabled" : "",
          ],
        },
        [
          _c("krypton-icon", {
            attrs: { "data-name": _vm.fieldName, "field-name": _vm.fieldName },
          }),
          _c("krypton-help-button", {
            attrs: { "data-name": _vm.fieldName, "field-name": _vm.fieldName },
          }),
          _c("krypton-label", {
            attrs: { "data-name": _vm.fieldName, "field-name": _vm.fieldName },
          }),
          _c(
            "div",
            {
              staticClass: "kr-field-component",
              attrs: { "data-field-name": _vm.fieldName },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fieldValue,
                    expression: "fieldValue",
                  },
                ],
                staticClass: "kr-input-field",
                class: [_vm.isInvalid ? "kr-on-error" : ""],
                attrs: {
                  "kr-field": "",
                  placeholder: _vm.placeholder,
                  disabled: _vm.isDisabled,
                  tabindex: "1",
                  autocomplete: _vm.autocomplete,
                },
                domProps: { value: _vm.fieldValue },
                on: {
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                  input: [
                    function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.fieldValue = $event.target.value
                    },
                    _vm.onInput,
                  ],
                  keyup: function ($event) {
                    if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                      return null
                    }
                    return _vm.tabReport($event)
                  },
                  keydown: function ($event) {
                    if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                      return null
                    }
                    return _vm.tabReport($event)
                  },
                },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }