import { extend } from 'underscore'
import regeneratorRuntime from 'regenerator-runtime' // NECESSARY to use async
import { SafeLoader } from '@/common/service/SafeLoader'

export const loadErrorTranslations = async translations => {
  const errorsDE = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/de-DE.js'
  )
  const errorsEN = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/en-EN.js'
  )
  const errorsCL = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/es-CL.js'
  )
  const errorsES = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/es-ES.js'
  )
  const errorsFR = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/fr-FR.js'
  )
  const errorsIT = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/it-IT.js'
  )
  const errorsJP = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/ja-JP.js'
  )
  const errorsNL = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/nl-NL.js'
  )
  const errorsPL = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/pl-PL.js'
  )
  const errorsPT = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/pt-PT.js'
  )
  const errorsRU = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/ru-RU.js'
  )
  const errorsSE = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/sv-SE.js'
  )
  const errorsTR = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/tr-TR.js'
  )
  const errorsCN = await import(
    /* webpackChunkName: "kr-trans-error" */ '@/configuration/i18n/errors/zh-CN.js'
  )

  translations['zh-CN'] = extend(translations['zh-CN'], errorsCN.default)
  translations['tr-TR'] = extend(translations['tr-TR'], errorsTR.default)
  translations['sv-SE'] = extend(translations['sv-SE'], errorsSE.default)
  translations['ru-RU'] = extend(translations['ru-RU'], errorsRU.default)
  translations['pt-PT'] = extend(translations['pt-PT'], errorsPT.default)
  translations['pl-PL'] = extend(translations['pl-PL'], errorsPL.default)
  translations['nl-NL'] = extend(translations['nl-NL'], errorsNL.default)
  translations['ja-JP'] = extend(translations['ja-JP'], errorsJP.default)
  translations['it-IT'] = extend(translations['it-IT'], errorsIT.default)
  translations['fr-FR'] = extend(translations['fr-FR'], errorsFR.default)
  translations['es-ES'] = extend(translations['es-ES'], errorsES.default)
  translations['es-CL'] = extend(translations['es-CL'], errorsCL.default)
  translations['en-EN'] = extend(translations['en-EN'], errorsEN.default)
  translations['de-DE'] = extend(translations['de-DE'], errorsDE.default)

  return Promise.resolve()
}

/**
 * Allow safe loading of the error translations.
 * @since KJS-2413
 */
export class ErrorTranslationsLoader extends SafeLoader {
  /**
   * @param {Object} translations
   */
  constructor(translations) {
    super(() => loadErrorTranslations(translations))
  }
}
