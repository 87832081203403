var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasValidToken,
          expression: "hasValidToken",
        },
      ],
      staticClass: "kr-sf-wrapper",
      class: _vm.dynamicClasses,
    },
    [
      _vm.isSmartFormForcedToken
        ? _c("PaymentTokenSmartButton", {
            attrs: { paymentMethodToken: _vm.smartFormPaymentToken },
          })
        : _vm._e(),
      !_vm.displayPopinButton && !_vm.isFallbackSmartButtonVisible
        ? [
            _c("SmartFormHeader"),
            _c("PartialPaymentPanel"),
            _vm.hasWallet
              ? _c("SmartFormGridWallet", { on: { selected: _vm.setMethod } })
              : _vm._e(),
            _vm.hasCards
              ? _c(
                  "SmartFormGridCards",
                  { on: { selected: _vm.setMethod } },
                  [_vm._t("cardForm")],
                  2
                )
              : _vm._e(),
            _vm.hasOtherMethods
              ? _c("SmartFormGridOtherMethods", {
                  on: { selected: _vm.setMethod },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.displayPopinButton
        ? _c("SmartFormModalButton", {
            on: { open: _vm.openSmartFormPopinWrapper },
          })
        : _vm._e(),
      _c("SmartFormLoadingOverlay"),
      _c("SmartFormGridModalWrapper", {
        attrs: { open: _vm.isSmartFormOpen },
        on: { selected: _vm.setMethod },
        scopedSlots: _vm._u(
          [
            {
              key: "cardForm",
              fn: function () {
                return [_vm._t("cardForm")]
              },
              proxy: true,
            },
            {
              key: "extra",
              fn: function () {
                return [_vm._t("extra")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("SimpleModal"),
      !_vm.isSmartFormPopin
        ? _c("ExtrasForm", { attrs: { "display-mode": "modal" } })
        : _vm._e(),
      _c("SmartFormLoadingOverlay", {
        attrs: { "only-window-redirection": true },
      }),
      _c("KryptonLayer", {
        attrs: { mode: _vm.isSmartFormPopin ? "box" : "unified" },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }