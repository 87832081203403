<template lang="pug">  
    .kr-partial-payment-separator
        .kr-partial-payment-line
        span.kr-partial-payment-label {{label}}
        .kr-partial-payment-line
</template>

<script>
export default {
  name: 'PartialPaymentSeparator',
  props: {
    label: { type: String, required: true }
  }
}
</script>
