<template lang="pug">
  button.kr-smart-form-wallet-card.kr-method-label(
    v-on:click="clickHandler"
    v-on:mouseover="hover = true"
    v-on:mouseleave="hover = false"
    :class="dynamicClasses"
    :data-card="pan"
    :data-token="card.token"
    type="button"
  )
    SmartFormRadioButton(v-if="hasRadioButton", :checked="isSelected", :hover="hover || hoverDelete")
    BrandIcon(:method="card.brand")
    SmartFormMaskedCardLabel(:value="formatCardNumber(pan, card.brand)")
    KryptonDeleteButton(
      v-if="!showLoading"
      v-on:click.stop="onClick"
      v-on:mouseover.stop="hoverDelete = true"
      v-on:mouseleave.stop="hoverDelete = false"
      :token="card.token"
      :method="`CARDS:${card.brand}`"
    )
    KryptonLoadingAnimation(v-else)
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import SmartFormMaskedCardLabel from '@/host/components/smartform/MaskedCardLabel'
import SmartFormRadioButton from '@/host/components/smartform/RadioButton'
import BrandIcon from '@/host/components/controls/BrandIcon'
import KryptonDeleteButton from '@/host/components/controls/KryptonDeleteButton'
import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'
import { formatCardNumber } from '@/common/util/card'
import DeleteButtonMixin from '@/host/components/mixins/DeleteButtonMixin'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'

export default {
  name: 'SmartFormWalletCard',
  components: {
    SmartFormMaskedCardLabel,
    SmartFormRadioButton,
    BrandIcon,
    KryptonDeleteButton,
    KryptonLoadingAnimation
  },
  mixins: [DeleteButtonMixin, SmartFormClickMixin],
  provide() {
    return {
      context: this.context
    }
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      hoverDelete: false,
      applyAlertStyle: false,
      context: {
        formId: null,
        namespace: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'isSelectedMethod',
      'isActiveMethod',
      'isSinglePaymentButton',
      'paymentMethodMetadata'
    ]),
    ...mapState(['allIFramesReady', 'redirectTransaction', 'amount']),
    ...mapState({
      errorCode: state => state.error.errorCode,
      flashDuration: state => state.form.smartform.methodLabel.flashDuration,
      radioButtonConfig: state => state.form.smartform.radioButton
    }),
    ...dynamicMapState('context.namespace', [
      'disabledForm',
      'disabledCardForm'
    ]),
    ...dynamicMapState('context.namespace', {
      paymentMethodToken: state => state.nonSensitiveValues.paymentMethodToken
    }),
    pan() {
      return this.card.fields.pan.value
    },
    dynamicClasses() {
      return {
        [`kr-brand--${this.card.brand.toLowerCase()}`]: true,
        'kr-method-label--spbtn': this.isSinglePaymentButton,
        'kr-method-label--selected': this.isSelected,
        'kr-smart-form-wallet-card--hover-delete': this.hoverDelete,
        'kr-delete-button--create-token': !this.amount,
        'kr-smart-form-wallet-card--alert': this.applyAlertStyle,
        'kr-locked': this.isDisabled,
        'kr-loading': this.showLoading,
        'kr-method-label--create-token-selected':
          (this.isLoading || this.hover || this.hoverDelete) && !this.amount
      }
    },
    isDisabled() {
      return (
        !this.allIFramesReady ||
        this.locked ||
        this.disabledForm ||
        this.disabledCardForm ||
        (this.paymentMethodToken && this.paymentMethodToken !== this.card.token)
      )
    },
    isLoading() {
      return (
        !this.errorCode &&
        this.redirectTransaction === null &&
        this.isActiveMethod('CARDS') &&
        this.paymentMethodMetadata === this.card.token
      )
    },
    showLoading() {
      return this.isLoading && !!this.amount
    },
    isSelected() {
      return this.isSelectedMethod(this.method)
    },
    hasRadioButton() {
      return (
        this.isSinglePaymentButton && this.radioButtonConfig.visibility === true
      )
    },
    method() {
      return `CARDS:${this.card.token}`
    }
  },
  created() {
    const { forms } = this.$store.state
    if (this.cardsFormExpanded) {
      this.context.formId = forms.clone
    } else {
      this.context.formId = forms.main
    }
    this.context.namespace = `cardForm_${this.context.formId}`
  },
  methods: {
    formatCardNumber(card, brand) {
      return formatCardNumber(card, brand)
    },
    openCard() {
      if (this.isDisabled || this.isLoading) return
      this.$emit('selected', this.method)
    },
    onClick() {
      this.applyAlertStyle = true
      setTimeout(() => {
        this.applyAlertStyle = false
      }, this.flashDuration * 1000)
    },
    deleteCardHandler() {
      this.deleteCard(`CARDS:${this.card.brand}`, this.card.token)
    },
    async clickHandler() {
      if (!this.amount) {
        this.deleteCardHandler()
        return
      }

      if (await this.interruptsExecution('WALLET_CARDS', 'openPopin'))
        return false

      this.openCard()
    }
  }
}
</script>
