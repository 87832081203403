import AbstractTrigger from '@/ghost/service/Triggers/AbstractTrigger'

/**
 * This class handles the reception of the Delete token store action
 * from any of the iframes and handles validation on the ghost
 * and executing the workflow
 */
export default class ApplePayTokenTrigger extends AbstractTrigger {
  constructor($locator) {
    super($locator, 'getApplePayToken')
    this.browserRequests = $locator.browserRequests
  }

  /**
   * Get ApplePay token
   *
   * @method onEvent
   * @param {Apple_Pay_Token_Action} applePayTokenAction get Apple Pay token store action
   */
  async onEvent({ validationURL }) {
    try {
      await super.onEvent({ validationURL })
      const { response } = await this.callGetApplePayToken(validationURL)
      if (response.status === 'ERROR') {
        this.onError(response.answer)
      } else {
        const { applePayToken } = response.answer
        this.proxy.send(
          this.storeFactory.create('applePayMerchantSession', {
            merchantSession: applePayToken
          })
        )
      }
    } catch (error) {
      this.onError(error)
    }
  }

  onError(error, path = 'ghost/service/ApplePayTokenTrigger.onEvent') {
    error.paymentMethod = 'APPLE_PAY'
    super.onError(error, path)
    this.$store.dispatch('closeMethod')
    this.proxy.send(this.storeFactory.create('abortApplePaySession'))
  }

  /**
   * Calls the ProcessPayment
   */
  async callGetApplePayToken(validationUrl) {
    const { dna, merchant, publicKey, formToken, remoteId } = this.$store.state

    const objectData = {
      agreementUuid: dna.smartForm.APPLE_PAY.metadata.mids[0].agreementUuid,
      domainName: merchant.domain,
      publicKey,
      validationUrl,
      formToken,
      remoteId,
      clientVersion: this.restAPI.getClientVersion(),
      device: this.restAPI.getDeviceData(),
      wsUser: this.restAPI.getWSUser()
    }
    const url = this.restAPI.addJSessionID(
      this.restAPI.getGetApplePayTokenUrl(this.endpoint)
    )
    const requestData = this.storeFactory.create('requestData', {
      url,
      objectData,
      headers: {},
      options: {}
    })
    return await this.browserRequests.post(requestData)
  }
}
