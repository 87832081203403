import Zepto from 'zepto-webpack'

import Events from '@/configuration/Events'

/**
 * This class handles the form configuration
 */
export default class PluginManager {
  constructor($locator) {
    this.$locator = $locator
    this.$store = $locator.$store
    this.pluginsCalled = false

    $locator.$bus.$on(Events.krypton.vue.ready, message => {
      this.load()
    })
  }

  load() {
    const { browser } = this.$store.state
    const $bus = this.$locator.$bus
    const plugins = window.KR._plugins

    // Call the registerd Plugins
    if (!this.pluginsCalled) {
      this.pluginsCalled = true
      for (const plugin of plugins) {
        plugin(Zepto, $bus, browser, [])
      }
    }
  }
}
