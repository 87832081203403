export default KR => {
  return {
    onClick: cb => {
      console.warn(
        'KR.button.onClick() only works with cards and will be deprecated. Please use KR.smartForm.onClick() instead.'
      )
      return KR._addCallback('button.onClick')(cb)
    },

    /** To set a label */
    setLabel: KR._wrapper('button.setLabel', label => {
      KR.$store.dispatch('update', { button: { label } })
    }),

    /** show the spinner */
    showSpinner: KR._wrapper('button.showSpinner', () => {
      KR.$store.dispatch('update', { button: { spinnerVisible: true } })
    }),

    /** hide the spinner */
    hideSpinner: KR._wrapper('button.hideSpinner', () => {
      KR.$store.dispatch('update', { button: { spinnerVisible: false } })
    }),

    /** button is disabled */
    disable: KR._wrapper('button.disable', () => {
      KR.$store.dispatch('update', { button: { disabled: true } })
    }),

    /** button is enabled */
    enable: KR._wrapper('button.enable', () => {
      KR.$store.dispatch('update', { button: { disabled: false } })
    }),

    hide: KR._wrapper('button.hide', () => {
      KR.$store.dispatch('update', { form: { button: { visibility: false } } })
    }),

    show: KR._wrapper('button.show', () => {
      KR.$store.dispatch('update', { form: { button: { visibility: true } } })
    })
  }
}
