<template lang="pug">
extends ../controls/templates/FormError.pug
</template>

<script>
import FormError from '@/host/components/mixins/FormError'
import { mapGetters } from 'vuex'

export default {
  name: 'SmartButtonError',
  mixins: [FormError],
  props: {
    // Reference linked to the smart button
    reference: {
      type: String,
      default: 'generic'
    }
  },
  computed: {
    ...mapGetters(['isAnyPopinOpen']),
    extraClasses() {
      return {
        'kr-smart-button': true
      }
    },
    hasReference() {
      return this.reference !== 'generic'
    },
    showFormError() {
      return this.hasData && !this.isAnyPopinOpen && this.matchReference
    },
    matchReference() {
      const paymentMethod = this.error?.paymentMethod
      if (!this.hasReference) return true
      return paymentMethod === this.reference
    }
  }
}
</script>
