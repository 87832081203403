import TestCards from '@/configuration/sources/TestCards.yml'
import DebugBarTestCards from '@/toolbar/configuration/DebugBarTestCards.yml'

export const formatCardNumber = (cardNumber, brand) => {
  if (typeof cardNumber !== 'string' && typeof cardNumber !== 'number')
    return cardNumber

  cardNumber = `${cardNumber}`

  if (brand === 'AMEX') {
    const match = /(.{4})(.{6})(.{5})/g.exec(cardNumber)
    return match.slice(1, match.length).join(' ')
  } else {
    return cardNumber.match(/.{1,4}/g).join(' ')
  }
}

/**
 * Based on @/configuration/sources/TestsCard.yml configuration:
 *
 * Given a testcard path (e.g: visa.acceptedWithStrongAuthentication),
 * return an object with:
 *  - The uppercase brand (e.g: VISA)
 *  - The pan
 *  - The associated test CVV if provided in the configuration, else undefined.
 *  - The associated expiry test if provided, else undefined
 *
 * This method was originally written in the Slave mixin TestCards but has been
 * extracted here to be unitary testable, and eventually reused elsewhere.
 *
 * @see KJS-2422
 * @see KJS-4235 | Add "key" & "currency" to returned object
 */
export const getTestCardFromDebugTestCards = testCard => {
  const splittedPath = testCard.split('.')
  const brand = splittedPath[0].toUpperCase()
  const status = splittedPath[1]
  // Invalid format
  if (typeof splittedPath[2] !== 'string') return null

  const subStatus = splittedPath[2].replace(/-/g, '_')
  const cardBrand = splittedPath[3]
  const currency = splittedPath[4]
  const cardNumbers = TestCards['brands'][brand][status]

  for (const cardNum in cardNumbers) {
    const card = cardNumbers[cardNum]
    const key = typeof card === 'string' ? card : card.translation
    if (
      key === subStatus &&
      card.currency === currency &&
      card.brand === cardBrand
    ) {
      return {
        key,
        brand: cardBrand ? cardBrand : brand,
        currency: card.currency,
        pan: cardNum,
        cvv: card.cvv,
        expiryDate: card.expiryDate
      }
    }
  }
}

/**
 * Was moved from KryptonPanelCards to the present file in order to be reused
 * by common/model/TestCard class.
 *
 * @param {string} brand  Card Brand
 * @param {string} number Card Number
 * @returns {number|null} network selection
 * @since KJS-4235
 */
export const getNetworkSelectionModeByTestCard = (brand, number) => {
  const compactNumber = number.replace(/ /g, '')
  const brandObject = DebugBarTestCards.brands[brand.toUpperCase()]
  let networkSelectionMode

  if (
    brandObject?._options &&
    brandObject._options.hasOwnProperty('networkSelectionMode')
  ) {
    networkSelectionMode = brandObject['_options'].networkSelectionMode

    for (const optionProperty of Object.keys(brandObject)) {
      if (brandObject[optionProperty].hasOwnProperty(compactNumber))
        return networkSelectionMode
    }
  }

  return null
}
