var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "kr-input-relative-wrapper",
      style: _vm.fieldCss.inputRelativeWrapper,
    },
    [
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "input",
            staticClass: "kr-input-field",
            class: {
              "kr-on-error": _vm.hasError,
              "on-error-animation": _vm.errorAnimation,
            },
            style: _vm.inputCSS,
            attrs: {
              id: "inputField",
              name: "cardnumber",
              autocomplete: "cc-number",
              autocorrect: "off",
              spellcheck: "false",
              placeholder: _vm.placeholder,
              disabled: _vm.isDisabled || !_vm.ready,
              pattern: _vm.pattern,
              tabindex: "1",
              "aria-label": _vm.fieldLabel,
              "aria-invalid": _vm.hasError ? "true" : "false",
              required: "",
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.inputValue)
                ? _vm._i(_vm.inputValue, null) > -1
                : _vm.inputValue,
            },
            on: {
              keyup: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                  return null
                }
                return _vm.tabReport($event)
              },
              keydown: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                  return null
                }
                return _vm.tabReport($event)
              },
              change: function ($event) {
                var $$a = _vm.inputValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.inputValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.inputValue = $$c
                }
              },
            },
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "input",
            staticClass: "kr-input-field",
            class: {
              "kr-on-error": _vm.hasError,
              "on-error-animation": _vm.errorAnimation,
            },
            style: _vm.inputCSS,
            attrs: {
              id: "inputField",
              name: "cardnumber",
              autocomplete: "cc-number",
              autocorrect: "off",
              spellcheck: "false",
              placeholder: _vm.placeholder,
              disabled: _vm.isDisabled || !_vm.ready,
              pattern: _vm.pattern,
              tabindex: "1",
              "aria-label": _vm.fieldLabel,
              "aria-invalid": _vm.hasError ? "true" : "false",
              required: "",
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.inputValue, null) },
            on: {
              keyup: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                  return null
                }
                return _vm.tabReport($event)
              },
              keydown: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                  return null
                }
                return _vm.tabReport($event)
              },
              change: function ($event) {
                _vm.inputValue = null
              },
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "input",
            staticClass: "kr-input-field",
            class: {
              "kr-on-error": _vm.hasError,
              "on-error-animation": _vm.errorAnimation,
            },
            style: _vm.inputCSS,
            attrs: {
              id: "inputField",
              name: "cardnumber",
              autocomplete: "cc-number",
              autocorrect: "off",
              spellcheck: "false",
              placeholder: _vm.placeholder,
              disabled: _vm.isDisabled || !_vm.ready,
              pattern: _vm.pattern,
              tabindex: "1",
              "aria-label": _vm.fieldLabel,
              "aria-invalid": _vm.hasError ? "true" : "false",
              required: "",
              type: _vm.type,
            },
            domProps: { value: _vm.inputValue },
            on: {
              keyup: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                  return null
                }
                return _vm.tabReport($event)
              },
              keydown: function ($event) {
                if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                  return null
                }
                return _vm.tabReport($event)
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputValue = $event.target.value
              },
            },
          }),
      _c("AutofillInput", {
        attrs: {
          name: "exp-date",
          autocomplete: "cc-exp",
          "field-name": "expiryDate",
        },
        on: { autofill: _vm.onAutofill },
      }),
      _c("AutofillInput", {
        attrs: {
          name: "cvc",
          autocomplete: "cc-csc",
          "field-name": "securityCode",
        },
        on: { autofill: _vm.onAutofill },
      }),
      _c("AutofillInput", {
        attrs: {
          name: "name",
          autocomplete: "cc-name",
          "field-name": "cardHolderName",
        },
        on: { autofill: _vm.onAutofill },
      }),
      !_vm.isUIWebView && !_vm.paymentMethodToken
        ? _c("CursorMirror", {
            ref: "caret",
            attrs: { "input-value": _vm.inputValueCaret },
          })
        : _vm._e(),
      _vm.isIos
        ? _c("input", {
            staticClass: "kr-focus-hunter",
            staticStyle: {
              position: "fixed",
              top: "0",
              left: "0",
              width: "0px",
              height: "0px",
              "background-color": "transparent",
              border: "0px solid transparent",
              padding: "1px",
              display: "inline-block",
            },
            attrs: {
              tabindex: "-1",
              "aria-hidden": "true",
              pattern: _vm.pattern,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }