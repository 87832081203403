import HashRule from './rules/HashRule';
import MaskRule from './rules/MaskRule';
import RemoveRule from './rules/RemoveRule';
import ReplaceRule from './rules/ReplaceRule';

/**
 * Return a Rule Class by its name.
 * @param ruleKind the ruleKind
 * @returns {MaskRule|ReplaceRule|RemoveRule|HashRule}
 */
function getRuleClass(ruleKind) {
    switch (ruleKind.toLowerCase()) {
    case 'hash':
        return HashRule;
    case 'mask':
        return MaskRule;
    case 'remove':
        return RemoveRule;
    case 'replace':
        return ReplaceRule;
    default:
        throw new Error(`Unsupported rule ${ruleKind}`);
    }
}

/**
 * The Pii Rule Service.
 * Responsible for rules management
 */
export default class PiiRuleService {
    /**
     * Constructor.
     * @param piiPatternService the piiPatternService
     */
    constructor(piiPatternService) {
        this.piiPatternService = piiPatternService;
        this.rules = {};
    }

    /**
     * Add an array of rules.
     * @param rulesToAdd the rulesToAdd
     */
    addRules(rulesToAdd) {
        Object.keys(rulesToAdd)
            .forEach((ruleToAddName) => {
                const ruleToAdd = rulesToAdd[ruleToAddName];

                // Replace pattern name by real pattern
                const allPatternsToApply = ruleToAdd.patterns
                    .filter((patternName) => this.piiPatternService.patterns[patternName].kind === 'regex')
                    .map((patternName) => `(\\b${this.piiPatternService.patterns[patternName].pattern.pattern}\\b)`);

                // Join all patterns with |
                ruleToAdd.pattern = allPatternsToApply.length > 0 ? new RegExp(allPatternsToApply.join('|'), 'g') : undefined;

                // Replace pattern name by real jsonPath
                ruleToAdd.jsonPaths = ruleToAdd.patterns
                    .filter((patternName) => this.piiPatternService.patterns[patternName].kind === 'json')
                    .map((patternName) => this.piiPatternService.patterns[patternName].path);

                const ruleKind = ruleToAdd.kind.charAt(0).toUpperCase() + ruleToAdd.kind.slice(1);
                const RuleClass = getRuleClass(ruleKind);
                this.rules[ruleToAddName] = new RuleClass(ruleToAdd);
            });
    }

    /**
     * Apply all the rules to a String.
     * @param stringToProcess the stringToProcess
     * @returns {*}
     */
    applyRules(stringToProcess) {
        let stringToReturn = stringToProcess;
        Object.keys(this.rules).forEach((ruleName) => {
            stringToReturn = this.rules[ruleName].apply(stringToReturn);
        });
        return stringToReturn;
    }

    /**
     * Return a rule by name.
     * @param ruleName the ruleName
     * @returns {*}
     */
    getRule(ruleName) {
        return this.rules[ruleName];
    }

    /**
     * Return all rules matching tags.
     * @param tags the tags
     * @returns {*[]} rules
     */
    getRules(tags = []) {
        const rulesFiltered = {};
        Object.keys(this.rules)
            .filter((ruleName) => {
                const rule = this.rules[ruleName];
                return rule.tags
                    && rule.tags.find((tagToMatch) => tags.find((tag) => tagToMatch === tag));
            }).forEach((ruleNameFiltered) => {
                rulesFiltered[ruleNameFiltered] = this.rules[ruleNameFiltered];
            });
        return rulesFiltered;
    }
}
