import { mapState, mapGetters } from 'vuex'
import { dynamicMapState, dynamicMapGetters } from '@/common/util/store'

export const VisibilityMixin = {
  computed: {
    ...mapState(['formMode', 'formToken', 'dna', 'redirectIframeOpen']),
    ...mapState({
      dnaCards: state => state.dna?.tokens?.cards || [],
      defaultConfiguration(state) {
        return state.dna?.cards?.DEFAULT?.fields[this.fieldName] || {}
      }
    }),
    ...mapGetters(['isBrandAvailable', 'isAnyPopinOpen', 'isWalletSmartForm']),
    ...dynamicMapGetters('context.namespace', ['isWalletMyCards']),
    ...dynamicMapState('context.namespace', [
      'bin8',
      'selectedBrand',
      'walletMode'
    ]),
    ...dynamicMapState('context.namespace', {
      payToken: state => state.nonSensitiveValues.paymentMethodToken
    })
  },
  watch: {
    selectedBrand: 'resolveVisibility',
    dna: {
      deep: true,
      handler: 'resolveVisibility'
    },
    formMode: 'resolveVisibility',
    walletMode: 'resolveVisibility',
    redirectIframeOpen: 'resolveVisibility',
    availableOptions: 'resolveVisibility'
  },
  created() {
    this.resolveVisibility()
  },
  methods: {
    shouldBeDisplayed() {
      if (this.fieldType === 'select') {
        return !!(this.availableOptions.length || this.isReadOnly)
      }

      return true
    },
    isReadOnlyInstallments(brand) {
      if (this.fieldType === 'select') {
        return !!(this.hasInstallments && this.isReadOnly)
      }

      return false
    },
    resolveVisibility() {
      if (this.isWalletMyCards) {
        this.myCardsVisibility()
      } else if (this.formMode === 'token') {
        this.paymentTokenVisibility()
      } else {
        this.isDisabled = false
        this.defaultVisibility()
      }
    },

    /**
     * Default check, dna main conf
     */
    defaultVisibility() {
      let isVisible = false
      if (this.defaultConfiguration) {
        const conf = this.configuration || this.defaultConfiguration
        isVisible =
          this.shouldBeDisplayed() &&
          (this.isReadOnlyInstallments(this.selectedBrand) ||
            conf.hidden === false)
      }
      this.isVisible = isVisible
    },

    /**
     * My Cards Visibility (wallet) - Check the token conf
     */
    myCardsVisibility() {
      // Wallet card list - always visible
      if (this.fieldName === 'walletCardList') {
        this.isVisible = true
        return
      }

      // Check the visibility in the tokens
      const card = this.dnaCards.find(card => card.token === this.payToken)
      if (
        this.isWalletMyCards &&
        !this.isWalletSmartForm &&
        ((this.fieldName === 'pan' && this.dnaCards.length > 1) ||
          this.fieldName === 'expiryDate' ||
          this.fieldName === 'doRegister')
      ) {
        // Legacy Wallet, with more than one cards, pan & doRegister are
        // invisible and expiry date is visible and disabled by default
        // @see KJS-4122
        if (this.fieldName === 'expiryDate') {
          this.isVisible = true
        } else {
          this.isVisible = false
        }
        this.isDisabled = true
      } else if (card) {
        const field = card.fields[this.fieldName]
        this.isVisible = field?.hidden === false
        // If has value and is not inherited, disable the field
        this.isDisabled = !!(field?.value && !field?.inherit)
      } else {
        // If there is no token selected, use the default system
        this.isDisabled = false
        this.defaultVisibility()
      }
    },

    /**
     * Payment token Visibility (forced token)
     */
    paymentTokenVisibility() {
      if (this.dnaCards) {
        const card = this.dnaCards[0]
        const field = card?.fields[this.fieldName]
        this.isVisible = field?.hidden === false
        // If has value and is not inherited, disable the field
        this.isDisabled = !!(field?.value && !field?.inherit)
      } else {
        this.defaultVisibility()
      }
    }
  }
}
