var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fieldName === "securityCode"
    ? _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasContent,
            expression: "hasContent",
          },
        ],
        staticClass: "kr-visibility-button",
        class: { "kr-visible": _vm.hasContent },
        attrs: { "aria-hidden": "true" },
        domProps: { innerHTML: _vm._s(_vm.iconSVG) },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggle($event)
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }