<template lang="pug">
    extends templates/TextField.pug
</template>

<script>
import { TextFieldMixin } from '@/host/components/mixins/TextField'

export default {
  name: 'KryptonIdentityDocNumber',
  mixins: [TextFieldMixin],
  data() {
    return { fieldName: 'identityDocumentNumber' }
  }
}
</script>
