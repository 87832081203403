var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasCurrencyConversion
    ? _c("div", { staticClass: "kr-currency-conversion" }, [
        _c("span", {
          staticClass: "kr-currency-conversion__label",
          domProps: { innerHTML: _vm._s(_vm.label) },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }