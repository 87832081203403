var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    _vm._g(
      {
        staticClass: "kr-delete",
        attrs: { title: _vm.tooltip },
        domProps: { innerHTML: _vm._s(_vm.icon) },
        on: { click: _vm.deleteCardHandler },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }