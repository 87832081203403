var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasPartialPayments
    ? _c(
        "div",
        { staticClass: "kr-partial-payment-panel" },
        [
          _c("PartialPaymentSeparator", {
            attrs: { label: _vm.translate("partial_payment_label") },
          }),
          _c(
            "TransitionGroup",
            {
              staticClass: "kr-partial-payments",
              attrs: { name: "test", tag: "div" },
            },
            _vm._l(_vm.transactions, function (transaction, i) {
              return _c("PartialPaymentEntry", {
                key: i,
                attrs: { transaction: transaction },
              })
            }),
            1
          ),
          _c("div", { staticClass: "kr-partial-payment-remaining" }, [
            _c("span", { staticClass: "kr-partial-payment-remaining-label" }, [
              _vm._v(_vm._s(_vm.translate("partial_payment_left"))),
            ]),
            _c("span", { staticClass: "kr-partial-payment-remaining-amount" }, [
              _vm._v(_vm._s(_vm.getAmountLabel(_vm.pendingAmount))),
            ]),
          ]),
          _c("SmartFormPartialPaymentError"),
          _c("PartialPaymentSeparator", {
            attrs: { label: _vm.translate("partial_payment_next") },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }