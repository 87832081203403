import _ from 'underscore'

export default class ApiBase {
  /**
   * Class constructor
   *
   * @constructor
   */
  constructor($locator) {
    this.application = $locator.application
    this.router = {
      post: {},
      get: {},
      // regex
      postRegex: [],
      getRegex: []
    }
  }

  /**
   * @method create200
   */
  create200(response) {
    return Promise.resolve({
      response,
      statusCode: 200
    })
  }

  /**
   * @method create500
   */
  create500(error = null) {
    return Promise.resolve({
      statusCode: 500,
      response: { error }
    })
  }

  /**
   */
  execute(method, route, data = null) {
    let args = [route]

    if (!this.router[method].hasOwnProperty(route)) {
      // Try to find by regex
      let regexRoutes = this.router[method + `Regex`]
      let regexRouteToUse

      regexRoutes.forEach(regexRoute => {
        if (regexRoute.regex.test(route)) regexRouteToUse = regexRoute
      })

      if (regexRouteToUse) {
        let matches = regexRouteToUse.regex.exec(route)
        return regexRouteToUse.controller(route, matches)
      } else {
        return Promise.reject({
          route: route,
          statusCode: 404,
          response: {}
        })
      }
    }

    if (data) args.push(data)
    return this.router[method][route](...args)
  }

  get(route) {
    return this.execute('get', route)
  }

  post(route, data) {
    return this.execute('post', route, data)
  }
}
