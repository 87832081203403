<template lang="pug">
    #appContainer.app-ghost-container
      krypton-redirect-iframe
</template>

<script>
import _ from 'underscore'
import Zepto from 'zepto-webpack'
import FormMaster from '@/ghost/mixins/FormMaster'
import KryptonRedirectIFrame from '@/ghost/components/KryptonRedirectIFrame'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'GhostApp',
  components: {
    'krypton-redirect-iframe': KryptonRedirectIFrame
  },
  mixins: [FormMaster],
  data() {
    return { $body: null }
  },
  computed: {
    ...mapState(['browser', 'hasDynamicValues', 'mockBinOptions']),
    ...mapGetters(['isMobile', 'isIphone', 'isAndroid'])
  },
  watch: {
    browser: { handler: 'updateBrowser', deep: true },
    async hasDynamicValues(val) {
      if (val) await this.loadBinOptions()
    }
  },
  created() {
    // Register the instance as app on the $locator
    this.$locator.ghost = this

    // receive a possible window.KR_MOCK_BINOPTIONS
    const { mockBinOptions } = JSON.parse(
      decodeURIComponent(window.location.hash.substring(1))
    )
    if (mockBinOptions) this.$store.dispatch('update', { mockBinOptions })
  },
  mounted() {
    this.$body = Zepto('body')

    // Start the application
    this.$locator.blender.boot()
  },
  methods: {
    ...mapActions(['loadBinOptions']),
    updateBrowser({ name, version }) {
      // Add the browser class to the body
      this.$body.addClass(name.split(' ').join(''))

      // Only add version if defined
      if (version) this.$body.addClass('v' + version.split('.')[0])

      if (this.isMobile) {
        if (this.isIphone) {
          this.$body.addClass('iphone')
        } else if (this.isAndroid) {
          this.$body.addClass('android')
        }
      }
    }
  }
}
</script>
