/**
 * Used to access CardForm instance by label instead of searching for element
 * in DOM.
 * Originally added to access instance from KryptonPopinButton.
 *
 * @since KJS-4240
 */
export default class CardFormController {
  constructor($locator) {
    this.$store = $locator.$store
    this.forms = new Map()
  }

  /**
   * Register CardForm Vue instance to give access everywhere.
   * This allows access its properties the right way, not running a DOM query.
   * Called by CardForm instance at creation.
   *
   * @param {VueComponent} form CardForm instance
   */
  registerForm(form) {
    const id = form.context.formId
    this.forms.set(id, form)
  }

  /**
   * @param {string} label Card Form label as used in $store.state.forms
   * @returns {Proxy & { getProperty: Function }} CardForm Proxy to limit accessible properties
   */
  getByLabel(label) {
    const id = this.$store.state.forms[label]
    return this.forms.get(id).proxy()
  }

  /**
   * @param {string} id Card Form ID
   * @returns {Proxy & { getProperty: Function }} CardForm Proxy to limit accessible properties
   */
  getById(id) {
    return this.forms.get(id).proxy()
  }
}
