export const fieldStyle = {
  default: {
    backgroundColor: null,
    color: null,
    iconColor: null,
    icon: {
      visibility: null
    }
  },
  error: {
    backgroundColor: null,
    color: null,
    iconColor: null
  }
}
