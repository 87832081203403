var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showFormError,
          expression: "showFormError",
        },
      ],
      staticClass: "kr-form-error",
      class: [_vm.dynamicClasses, _vm.extraClasses],
      attrs: { "kr-resource": "", role: "alert", "aria-live": "assertive" },
    },
    [
      _vm.hasError
        ? _c("span", { staticStyle: { color: "red" } }, [
            _vm.errorExtraContent
              ? _c("span", {
                  staticClass: "kr-extra-content",
                  domProps: { innerHTML: _vm._s(_vm.errorExtraContent) },
                })
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.error.errorMessage))]),
          ])
        : _c("span", [
            _vm.errorExtraContent
              ? _c("span", {
                  staticClass: "kr-extra-content",
                  domProps: { innerHTML: _vm._s(_vm.errorExtraContent) },
                })
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.warning.errorMessage))]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }