import _ from 'underscore'
import { mapGetters, mapState } from 'vuex'

import { fieldNameAsTranslationKey } from '@/common/util/fieldName'

/**
 * @see KJS-3895 | For SecurityCode (fr) "Code de sécurité", revert to "CVV" if
 *  it does not fit.
 */
export const PlaceholderMixin = {
  data() {
    return {
      forcedPlaceholder: null
    }
  },
  computed: {
    ...mapState({
      placeholders: state => state.placeholders,
      formPlaceholders: state => state.form.placeholders,
      placeholdersMinWidth: state => state.form.placeholdersMinWidth
    }),
    ...mapState(['os']),
    ...mapGetters(['translate']),
    placeholder() {
      // If there is a forced value, has preference
      if (!_.isNull(this.forcedPlaceholder)) return this.forcedPlaceholder
      const placeholder =
        this.formPlaceholders[this.fieldName] ||
        this.placeholders[this.fieldName] ||
        this.translate(
          `placeholder_${fieldNameAsTranslationKey(this.fieldName)}`
        )
      if (this.placeholderCapped(placeholder)) {
        return 'CVV'
      }
      return placeholder
    }
  },
  methods: {
    /**
     * Determine whether securityCode placeholder would fit or should revert
     * to "CVV".
     * Initial change made for "Code de sécurité" specifically, can be improve
     * by adding a calculation so it works for any CVV.
     *
     * @since KJS-3895
     */
    placeholderCapped(placeholder) {
      return (
        this.fieldName === 'securityCode' &&
        typeof this.placeholdersMinWidth[this.fieldName] === 'number' &&
        this.viewportWidth < this.placeholdersMinWidth[this.fieldName] &&
        placeholder === 'Code de sécurité'
      )
    },
    cleanPlaceholder() {
      // If iOS10 or lower, reset the placeholder
      const os = this.os
      if (os.name === 'iOS' && parseInt(os.version.split('.')[0]) <= 10) {
        const _this = this
        this.forcedPlaceholder = ''
        _.defer(() => {
          _this.forcedPlaceholder = null
        })
      }
    }
  }
}
