import _ from 'underscore'

export const domAsFieldName = domName => {
  if (!domName || !_.isString(domName)) return null
  const splitedName = domName.substr(3).split('-')
  const name = splitedName.slice(1).reduce((acc, x) => {
    return acc + x.charAt(0).toUpperCase() + x.slice(1)
  }, splitedName[0])

  // Exceptions
  return name === 'expiry' ? 'expiryDate' : name
}

export const fieldNameAsClassName = fieldName => {
  if (!fieldName || !_.isString(fieldName)) return null
  const splitedName = fieldName.split(/(?=[A-Z])/)
  const name = splitedName.slice(1).reduce((acc, x) => {
    return acc + '-' + x.charAt(0).toLowerCase() + x.slice(1)
  }, splitedName[0])

  // Exceptions
  return name === 'expiry-date' ? 'expiry' : name
}

export const fieldNameAsSelector = fieldName => {
  return '.kr-' + fieldNameAsClassName(fieldName)
}

export const fieldNameAsComponent = fieldName => {
  return (
    'krypton-' +
    {
      pan: 'field',
      expiryDate: 'field',
      securityCode: 'field',
      identityDocumentType: 'identity-doc-type',
      identityDocumentNumber: 'identity-doc-number',
      cardHolderName: 'card-holder-name',
      cardHolderMail: 'card-holder-mail',
      installmentNumber: 'installments',
      firstInstallmentDelay: 'first-installment-delay',
      doRegister: 'do-register'
    }[fieldName]
  )
}

export const fieldNameAsTranslationKey = fieldName => {
  return {
    pan: 'pan',
    expiryDate: 'expiry',
    securityCode: 'security_code',
    identityDocumentType: 'identity_document_type',
    identityDocumentNumber: 'identity_document_number',
    cardHolderName: 'card_holder_name',
    cardHolderMail: 'card_holder_mail',
    installmentNumber: 'installment_number',
    firstInstallmentDelay: 'first_installment_delay',
    doRegister: 'do_register'
  }[fieldName]
}
