import _ from 'underscore'
import { mapState } from 'vuex'
import Zepto from 'zepto-webpack'
import extend from 'deep-extend'
import { parseStringToObject } from '@/common/util/object'

export const StyleMixin = {
  data() {
    return {
      fieldName: '',
      computeCss: false
    }
  },
  computed: {
    ...mapState(['css']),
    fieldCss() {
      // Forces a refreshment of the styles (vue doesn't listen to object changes)
      const computeCss = this.computeCss
      return this.css[this.fieldName]
    },
    inputCSS() {
      return [
        this.fieldCss.inputDefault,
        this.hasError ? this.fieldCss.inputError : '',
        this.isDisabled ? this.fieldCss.inputDisabled : ''
      ]
    }
  },
  watch: {
    fieldCss(newVal) {
      if (newVal.inputDefault && Object.keys(newVal.inputDefault).length) {
        // We wait until the styles are received to show the input
        _.defer(() => Zepto('input#inputField').css('display', 'inline-block'))
      }
    }
  },
  created() {
    this.fieldName = this.$parent.name
    this.startSubscriptions()
  },
  methods: {
    startSubscriptions() {
      const fieldName = this.fieldName
      this.$store.subscribe((mutation, state) => {
        if (mutation.payload?.fields && mutation.payload.fields[fieldName]) {
          const style = mutation.payload.fields[fieldName]
          let styleUpdate = {}

          if (style.default?.color) {
            extend(
              styleUpdate,
              parseStringToObject(
                `css.${fieldName}.inputDefault.color`,
                style.default.color
              )
            )
          }
          if (style.default?.backgroundColor) {
            extend(
              styleUpdate,
              parseStringToObject(
                `css.${fieldName}.innerDefault.background-color`,
                style.default.backgroundColor
              )
            )
          }
          if (style.error?.color) {
            extend(
              styleUpdate,
              parseStringToObject(
                `css.${fieldName}.inputError.color`,
                style.error.color
              )
            )
          }
          if (style.error?.backgroundColor) {
            extend(
              styleUpdate,
              parseStringToObject(
                `css.${fieldName}.innerError.background-color`,
                style.error.backgroundColor
              )
            )
          }

          this.$store.dispatch('update', styleUpdate)
        }
        if (mutation.payload?.css && mutation.payload.css[fieldName]) {
          this.computeCss = !this.computeCss
        }
      })
    }
  }
}
