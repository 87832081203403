import { uniqueId } from 'underscore'

import { getParentDomain } from '@/common/util/location'

export default class IFrameBuilder {
  /**
   * @param  {Object} Locator
   */
  constructor({ $store, url, timerManager }) {
    this.$store = $store
    this.url = url
    this.timerManager = timerManager
    this.libraryVersion = '%%library_version%%'
  }

  /**
   * Extract ghost URL generation to use it both in this class and in
   * KryptonGhostContainer component.
   *
   * @since KJS-2508 | Ghost unification
   */
  generateGhostUrl(awareId) {
    const { applicationPath, baseAddress } = this.$store.state

    // Attach basic configuration to json Url
    const jsonUrl = {
      awareId,
      applicationPath,
      parentDomain: getParentDomain(),
      mockBinOptions: window?.KR_MOCK_BINOPTIONS
    }

    // Create iFrame source
    return `${baseAddress}/checkout/checkout.html?assets=%%build_number%%#${encodeURIComponent(
      JSON.stringify(jsonUrl)
    )}`
  }

  /**
   * Creates a new iframe HTML
   *
   * @see KJS-2030  Add title & hidden attribute on iframe for accessibility
   */
  createCheckoutHTML() {
    // Start timer for iframe creation
    this.timerManager.register(
      'start',
      `ghostIframeLoad`,
      `Loading ghost iframe`
    )

    // No need to generate awareId here, only format must be validated
    const iframeSrc = this.generateGhostUrl('aware-0')

    return new Promise((resolve, reject) => {
      this.url
        .checkIfWhitelisted(iframeSrc)
        .then(isWhitelisted => {
          if (isWhitelisted) {
            const popinId = uniqueId('kr-popin-redirection-')
            resolve([
              popinId,
              `<div id="${popinId}" class="kr-popin-redirection-wrapper">
                <krypton-popin-redirection></krypton-popin-redirection>
              </div>`
            ])
          } else {
            resolve('')
          }
        })
        .catch(reject)
    })
  }

  /**
   * Creates a new iframe for the embedded field.
   */
  createEmbeddedHTML(formId, fieldName, sizeResults, fontFamily) {
    const {
      baseAddress,
      formToken,
      placeholders,
      applicationPath,
      hasDynamicValues
    } = this.$store.state
    const awareId = uniqueId('aware')
    const buildNumber = '%%build_number%%'

    let attributes = {}

    return new Promise((resolve, reject) => {
      this.timerManager.register(
        'start',
        `${fieldName}IframeLoad`,
        `Loading ${fieldName} iframe`
      )

      // Main necessary data to slave initial load
      const jsonUrl = {
        formId,
        awareId,
        name: fieldName,
        formTokenPrefix: formToken.substring(0, 2),
        // The placeholder maybe its customized on the embedded attributes, check it
        placeholder: placeholders[fieldName],
        parentDomain: getParentDomain() || null,
        applicationPath,
        disabled: this.$store.getters.isFieldDisabled(fieldName),
        fontFamily,
        mockTypeCarte: window?.KR_MOCK_TYPECARTE,
        hasDynamicValues
      }

      let iframeSrc = `${baseAddress}/slave/index.html?assets=${buildNumber}#${encodeURIComponent(
        JSON.stringify(jsonUrl)
      )}`

      const style = `width:100%;height:${sizeResults.height};visibility:hidden;display:block;z-index:10;border:none;`

      // Generate the attributes of the iframe
      attributes['style'] = style
      attributes['src'] = iframeSrc
      attributes['class'] = ''
      attributes['kr-aware-id'] = awareId
      attributes['scrolling'] = 'no'
      attributes['allowtransparency'] = 'true'
      attributes['frameborder'] = '0'
      attributes['name'] = `krfield-${fieldName}`
      attributes['kr-form-slave'] = formId

      this.url
        .checkIfWhitelisted(iframeSrc)
        .then(isWhitelisted => {
          if (isWhitelisted) {
            resolve(
              `<div style="display:none;">${JSON.stringify(attributes)}</div>`
            )
          } else {
            resolve('')
          }
        })
        .catch(reject)
    })
  }
}
