import _ from 'underscore'
import { normalizeList } from '@/common/util/brand'
import TestCards from '@/configuration/sources/TestCards.yml'
import BrandLookupFile from '@/configuration/sources/BrandLookupFile.yml'
import { CoBrands } from '@/configuration/sources/Brands.yml'

let _instance = null

class TreeReader {
  constructor($locator) {
    this.$locator = $locator
    this.$store = $locator.$store
    this.tree = {
      ranges: {}
    }
    this.types = {}
    this.debugCards = []
    this.environment = undefined
    this.cache = {
      hydrate: {}
    }

    this.parseTestCards()
  }

  static getInstance($store) {
    if (_instance === null) {
      _instance = new TreeReader($store)
    }
    return _instance
  }

  // Parse test debug cards
  parseTestCards() {
    for (const brand in TestCards.brands) {
      const brandConf = TestCards.brands[brand]
      const targetBrand = brandConf._brand || brand

      for (const cardType in brandConf) {
        if (cardType === '_brand') continue
        for (const pan in brandConf[cardType]) {
          if (!(pan in this.debugCards)) {
            const brand = brandConf[cardType][pan].brand
              ? brandConf[cardType][pan].brand
              : targetBrand
            this.debugCards[pan] = normalizeList(brand)
          }
        }
      }
    }
  }

  parseTree(json) {
    this.tree = json
  }

  /**
   * Gets the environment based on the token prefix or shopId
   *
   * @param {string} prefix
   * @returns {string} environment { 'default', 'latam', 'sg'}
   */
  getEnvironment(prefix, shopId = null) {
    for (const env in BrandLookupFile.prefixes) {
      if (BrandLookupFile.prefixes[env].includes(prefix)) return env
    }

    if (!shopId) shopId = this.$store.state.shopId
    if (!shopId) return 'default'
    for (const env in BrandLookupFile.shopIds) {
      if (BrandLookupFile.shopIds[env].includes(shopId)) return env
    }

    return 'default'
  }

  isTreeNeedToBeReloaded(host, origin, prefix) {
    const source = this.load(host, origin, prefix)
    if (!this.cache.hydrate.hasOwnProperty(source)) return true

    return this.getEnvironment(prefix) !== this.environment
  }

  load(host, origin, prefix = '01') {
    const mockTypeCarte = this.$locator.$store.state.field.mockTypeCarte

    // Get the environment
    this.environment = this.getEnvironment(prefix)

    if (mockTypeCarte) return `${origin}/typecartes/${mockTypeCarte}.json`

    // Get the proper path
    if (~BrandLookupFile.localDomains.indexOf(host)) {
      return `${origin}${BrandLookupFile.filePath.local[this.environment]}`
    }

    return `${origin}${BrandLookupFile.filePath.production[this.environment]}`
  }

  hydrate(source) {
    if (source in this.cache.hydrate) return this.cache.hydrate[source]

    return (this.cache.hydrate[source] = new Promise((resolve, reject) => {
      fetch(source)
        .then(resp => {
          if (resp.status === 200) return resp.json()
        })
        .then(data => {
          this.parseTree(data)
          resolve(data)
        })
        .catch(error => {
          delete this.cache.hydrate[source]
          reject(error)
        })
    }))
  }

  translate(value) {
    return value.split('*')[0]
  }

  filterCoBrandedCard(unfiltered) {
    if (!unfiltered) return unfiltered
    const brands = unfiltered.slice()
    for (const brand of unfiltered) {
      if (CoBrands.brands[brand]) {
        const index = brands.indexOf(CoBrands.brands[brand])
        if (~index) brands.splice(index, 1)
      }
    }

    // If the card should have only one brand take the first one
    const onlyOneBrand = Object.keys(CoBrands.onlyOneBrand)
    if (brands.some(brand => onlyOneBrand.includes(brand)))
      return [CoBrands.onlyOneBrand[brands[0]]]

    return brands
  }

  findType(rawType) {
    const _this = this

    rawType = `${rawType}`

    let values = this.tree.values
    if (!values) throw 'TypeCard tree has no values'

    if (values.hasOwnProperty(rawType)) {
      let brands = _.map(values[rawType].split('|'), i => {
        return _this.translate(i)
      })
      return brands
    }
    return null
  }

  isSkipDebugCard(formId, brands, pan) {
    const types = [
      'accepted',
      'acceptedWithStrongAuthentication',
      'acceptedWithStrongAuthenticationPASS',
      'refused',
      'refusedWithStrongAuthentication',
      'refusedWithStrongAuthenticationPASS'
    ]

    for (const brand of brands) {
      if (
        this.$store?.state?.[`cardForm_${formId}`]?.testCard
          ?.split('.')[0]
          .toUpperCase() === brand
      )
        return true
    }

    for (const brand of brands) {
      for (const type of types) {
        if (TestCards.brands[brand] && TestCards.brands[brand][type]) {
          if (_.contains(Object.keys(TestCards.brands[brand][type]), pan))
            return true
        }
      }
    }

    return false
  }

  computeBrands(formId, pan) {
    // Clean whitespaces
    pan = pan.replace(/\s/g, '')
    if (!this.isSkipDebugCard(formId, ['CONECS', 'OSB'], pan)) {
      const detectedOnTest = this.debugCards[pan] || null
      if (detectedOnTest) return detectedOnTest
    }

    let currentRange = this.tree.ranges
    if (!currentRange) throw 'TypeCard tree has no ranges'

    let value = pan.split('')
    let calculation = []

    while (value && value.length) {
      ;[value, currentRange, calculation] = this.iterateSearch(
        value,
        currentRange,
        calculation
      )
    }

    if (currentRange.hasOwnProperty('t')) {
      return this.findType(currentRange.t)
    }

    return null
  }

  find(formId, pan) {
    const computedBrands = this.computeBrands(formId, pan)
    return this.computeExceptions(computedBrands)
  }

  iterateSearch(value, range, calculation) {
    if (!value.length) return [null, range]
    let nextDigit = value[0]
    if (range.hasOwnProperty(nextDigit)) {
      let removedDigit = value.shift()
      calculation.push({
        removedDigit
      })
      return [value, range[nextDigit], calculation]
    }
    return [null, range, calculation]
  }

  getParents() {
    return this.tree.parents
  }

  /**
   * HACKS: Specific use case exceptions (to be removed)
   * @see KJS-3433
   * @param {Array} brands
   * @returns {Array} brands
   */
  computeExceptions(brands) {
    if (!brands) return brands
    const { dna, currency } = this.$store.state
    // Argentina + DINERS/DISCOVER should force to CABAL if it's available
    const cardBrands = Object.keys(dna?.cards ?? {})
    const hasDoD = brands.some(brand => ['DINERS', 'DISCOVER'].includes(brand))
    const isCabalAvail = cardBrands.some(brand =>
      ['CABAL', 'CABAL_DEBIT'].includes(brand)
    )
    if (currency === 'ARS' && hasDoD && isCabalAvail) {
      return ['CABAL']
    }

    // Exception not found, return the detected brands
    return brands
  }
}

export default TreeReader
