import { isNaN, isEqual, extend, clone, findIndex } from 'underscore'
import { mapState, mapGetters } from 'vuex'
import { dynamicMapState, dynamicMapActions } from '@/common/util/store'
import PreloadedAssets from '@/configuration/PreloadedAssets'

import Events from '@/configuration/Events'

/** MIXINS */
import { FieldMixin } from '@/host/components/mixins/Field'
import { ClickOutsideMixin } from '@/host/components/mixins/ClickOutside'
import { ShakeMixin } from '@/host/components/mixins/Shake'
import { TestCardMixin } from '@/host/components/mixins/TestCard'
import { PaymentTokenMixin } from '@/host/components/mixins/PaymentToken'
import { EventsMixin } from '@/host/components/mixins/Events'
import { ClearMixin } from '@/host/components/mixins/Clear'

export const SelectFieldMixin = {
  mixins: [
    FieldMixin,
    ClickOutsideMixin,
    ShakeMixin,
    TestCardMixin,
    PaymentTokenMixin,
    EventsMixin,
    ClearMixin
  ],
  data() {
    return {
      selectedOption: '',
      availableOptions: [],
      previousAvailableOptions: [],
      isSelectOpen: false,
      isOptionsTop: false,
      caret: PreloadedAssets.miscellaneous.caret,
      fieldType: 'select'
    }
  },
  computed: {
    ...mapGetters(['isDesktop']),
    ...mapState(['isUnitaryTest', 'formToken', 'language']),
    ...mapState({
      fieldsIcons: state => state.fields.icons
    }),
    ...dynamicMapState('context.namespace', ['selectedBrand']),
    isReadOnly() {
      return (
        this.isDisabled ||
        this.isUnitaryTest ||
        this.isDefaultBrand ||
        this.hasNoOptions
      )
    },
    hasNoOptions() {
      return (
        this.availableOptions.length === 1 && this.availableOptions[0].id < 1
      )
    },
    optionsClass() {
      if (this.availableOptions.length > 5) return 'kr-options-6'
      return `kr-options-${this.availableOptions.length}`
    },
    placeholder() {
      // If the placeholder has a dedicated generation (installments)
      if (this.generatePlaceholder) {
        const generatedPlaceholder = this.generatePlaceholder()
        if (generatedPlaceholder) return generatedPlaceholder
      }

      // General select
      for (const option of this.availableOptions) {
        if (this.selectedOption === option.id) {
          return option.label
        }
      }

      // Default use case
      if (this.formPlaceholder) return this.formPlaceholder
      return this.translate(`label_${this.domFieldName.replace(/\-/g, '_')}`)
    }
  },
  created() {
    this.startSubscriptions()
    this.resetData()
  },
  watch: {
    fieldsIcons() {
      this.caret = PreloadedAssets.miscellaneous.caret
    },
    selectedBrand: 'resetData',
    language: 'resetData',
    formToken: 'resetData',
    isReadOnly: 'resetData',
    formPlaceholder: 'resetData',
    isSelectOpen: 'setIsOptionsTop',
    selectedOption(newVal) {
      if (this.isInstallNum) this.manageSelectedOption(newVal)

      this.updateNonSensitiveValue({ [this.fieldName]: newVal })
      this.valid = true
      this.validate()

      // Report event
      if (
        newVal &&
        newVal.length &&
        (isNaN(parseInt(newVal)) ||
          (!isNaN(parseInt(newVal)) && parseInt(newVal) > 0))
      ) {
        this.$bus.$emit(Events.krypton.field.event, {
          formId: this.context.formId,
          name: this.fieldName,
          type: 'select'
        })
      } else {
        this.hasFocus = false
      }
    },
    clearFromMixin(newVal) {
      if (!newVal) return
      this.setDefaultValue()
      this.clearFromMixin = false
    }
  },
  methods: {
    ...dynamicMapActions('context.namespace', [
      'toggleSelectField',
      'validateField'
    ]),
    setInput() {
      this.$input = this.$refs.select
    },

    startSubscriptions() {
      // Keyboard Esc detection
      document.addEventListener('keyup', ({ key, keyCode }) => {
        if (key === 'Escape' || key === 'Esc' || keyCode === 27)
          this.closeSelect()
      })
    },
    /**
     * Recalculate the dynamic properties
     */
    resetData() {
      // Reset the installments data with the current brand config
      this.previousAvailableOptions = clone(this.availableOptions)
      this.availableOptions = []
      this.validate()
      this.setAvailableOptions()
      // If the new Options are different, set the default value
      if (!isEqual(this.previousAvailableOptions, this.availableOptions)) {
        this.setDefaultValue()
      }
    },

    /**
     * Initialize the setting of the options with the given brands. If no brands
     * are given, use the default brand configuration of the DNA.
     */
    setAvailableOptions() {
      if (this.configuration?.values) this.addOptions(this.configuration.values)
    },

    /**
     * By default, the element selected should be the one with count = 1
     */
    setDefaultValue() {
      const dnaDefaultValue = this.configuration?.value
      const dnaDefaultValues = this.configuration?.values
      if (dnaDefaultValues) {
        // If dna default value is define in values, set it as default value
        if (dnaDefaultValue && dnaDefaultValues[dnaDefaultValue]) {
          this.selectedOption = dnaDefaultValue
          // Else, take first dna values as default value
        } else if (this.selectedBrand !== 'DEFAULT') {
          this.selectedOption = Object.keys(dnaDefaultValues)[0]
        }
      }
    },

    /**
     * Checks if there are custom labels and adds the available options
     */
    addOptions(values) {
      for (const key in values) {
        const newOption = this.generateOption(values[key], key)
        this.includeOption(newOption)
      }
    },

    /**
     * Check if option is available
     */
    getOptionIndexWithId(id) {
      return findIndex(this.availableOptions, x => x.id === id)
    },

    /**
     * Include the given option if it's not already on the list
     */
    includeOption(option) {
      const index = this.getOptionIndexWithId(option.id)
      if (~index)
        this.availableOptions[index] = extend(
          option,
          this.availableOptions[index]
        )
      else this.availableOptions.push(option)
    },

    selectOption(option) {
      // Selected option refers to array position, which can be different from option id
      this.$bus.$emit(Events.krypton.field.event, {
        formId: this.context.formId,
        name: this.fieldName,
        type: 'userClick'
      })
      this.selectedOption = option.id
      this.validateField(this.fieldName)
      this.closeSelect()
    },

    closeSelect() {
      this.isSelectOpen = false
    },

    focusEvents({ which }) {
      if (this.availableOptions.length > 0) {
        if (which === 38 || which === 40) {
          if (this.selectedOption === '') {
            this.selectedOption = this.availableOptions[0].id
          } else {
            const index = this.availableOptions
              .map(x => x.id)
              .indexOf(this.selectedOption)
            if (which === 40 && index + 1 < this.availableOptions.length) {
              this.selectedOption = this.availableOptions[index + 1].id
            } else if (which === 38 && index - 1 >= 0) {
              this.selectedOption = this.availableOptions[index - 1].id
            }
            this.scrollToOption(this.selectedOption)
          }
        }

        // Enter
        if (which === 13) {
          this.toggleSelect()
          this.scrollToOption(this.selectedOption)
        }
        // Tab
        if (which === 9) this.closeSelect()
      }
    },

    scrollToOption(index) {
      if (index === '') return
      Zepto(
        `.kr-${this.domFieldName} .kr-options .kr-option[value="${index}"]`
      )[0].scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      })
    },

    toggleSelect() {
      if (!this.isReadOnly) {
        this.$bus.$emit(Events.krypton.field.event, {
          formId: this.context.formId,
          name: this.fieldName,
          type: 'userClick'
        })
        this.isSelectOpen = !this.isSelectOpen
        if (!this.hasFocus) this.hasFocus = true
      }
    },

    async setIsOptionsTop(isSelectOpen) {
      this.toggleSelectField({
        status: isSelectOpen,
        fieldName: this.fieldName
      })

      if (this.isDesktop && isSelectOpen) {
        const krSelectRect = document
          .querySelector(`.kr-${this.domFieldName} .kr-select`)
          .getBoundingClientRect()
        const distanceFromBottom =
          window.innerHeight - (krSelectRect.top + krSelectRect.height)

        const krOptionsHeight = parseInt(
          window
            .getComputedStyle(
              document.querySelector(`.kr-${this.domFieldName} .kr-options`)
            )
            .maxHeight.split('px')[0]
        )

        this.isOptionsTop = krOptionsHeight >= distanceFromBottom
      }
    }
  }
}
