import { mapGetters, mapState } from 'vuex'
import extend from 'deep-extend'
import { cleanupNulls, cleanupObjects } from '@/common/util/object'
import PreloadedAssets from '@/configuration/PreloadedAssets'

export default {
  computed: {
    ...mapGetters(['isSmallScreen']),
    ...mapState({
      modalStyles({ form }) {
        const regularStyles = form.redirection
        const baseStyles = this.isSmallScreen
          ? extend(regularStyles, form.redirection.fullscreen)
          : regularStyles
        return cleanupNulls(cleanupObjects(baseStyles))
      },
      headerStyles({ form }) {
        const regularStyles = form.redirection.header
        const baseStyles = this.isSmallScreen
          ? extend(regularStyles, form.redirection.fullscreen.header)
          : regularStyles
        return cleanupNulls(cleanupObjects(baseStyles))
      },
      logoSVG: ({ form }) => {
        const { type } = form.redirection.header.logo
        return PreloadedAssets[type === 'outline' ? 'lockOutline' : 'lock']
      },
      logoStyles: ({ form }) =>
        cleanupNulls(cleanupObjects(form.redirection.header.logo)),
      closeStyles: ({ form }) =>
        cleanupNulls(cleanupObjects(form.redirection.header.closeBtn)),
      labelStyles: ({ form }) =>
        cleanupNulls(cleanupObjects(form.redirection.header.label))
    })
  }
}
