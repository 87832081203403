import { mapActions, mapGetters, mapState } from 'vuex'

export const SmartFormMethodsList = {
  computed: {
    ...mapGetters(['hasSeveralPaymentMethods', 'isSinglePaymentButton'])
  },
  methods: {
    ...mapActions(['selectMethod', 'openMethod', 'openGroup']),
    setMethod(method) {
      if (this.isSinglePaymentButton) {
        this.selectMethod(method)
      } else {
        this.openMethod(method)
      }
    },
    loadGroup(groupName) {
      if (this.hasSeveralPaymentMethods) {
        this.openGroup(groupName)
      }
    },
    goBack() {
      this.$emit('back')
    }
  }
}
