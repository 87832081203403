<template lang="pug">
  button.kr-method-label(
    type="button"
    @click="openMethod(method)"
    :class="dynamicClasses"
    :style="styles.button"
    :kr-payment-method="method.toLowerCase()"
  )
    SmartFormRadioButton(v-if="hasRadioButton", :checked="isSelected", :hover="hover")
    // Regular label with icon
    .kr-method-label-wrapper
      .kr-method-icon(v-show="!isCardMethod(method)", v-html="methodIconContent")
      label(v-if="!isCardMethod(method)") {{getCustomPaymentMethodLabel(method) || methodLabel}}
      // Icons label for card
      SmartFormCardIcons(v-else :margin="5" :brands="cardBrands")
      // Arrow
      .kr-arrow-right(v-if="!loadingAnimation && !locked", v-html="rightArrow")
      // Loading animation
      KryptonLoadingAnimation(v-else-if="loadingAnimation")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import PreloadedAssets from '@/configuration/PreloadedAssets'
import { loadAssets } from '@/common/loader/assets'

import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'
import SmartFormCardIcons from '@/host/components/smartform/CardIcons'
import SmartFormRadioButton from '@/host/components/smartform/RadioButton'
import { ClickOutsideMixin } from '@/host/components/mixins/ClickOutside'
import { ApplePayMixin } from '@/host/components/mixins/ApplePay'
import { FlashWarningSubscriberMixin } from '@/host/components/mixins/FlashWarningSubscriber'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'

export default {
  name: 'SmartFormMethodLabel',
  components: {
    SmartFormCardIcons,
    SmartFormRadioButton,
    KryptonLoadingAnimation
  },
  mixins: [
    ClickOutsideMixin,
    ApplePayMixin,
    FlashWarningSubscriberMixin,
    SmartFormClickMixin
  ],
  props: {
    method: { type: String, default: '' },
    locked: { type: Boolean, default: false }
  },
  data() {
    return {
      hover: false,
      rightArrow: PreloadedAssets.arrows.right,
      icon: null
    }
  },
  computed: {
    ...mapGetters([
      'isAnyCardFormDisabled',
      'isAnyTokenActive',
      'translate',
      'isSelectedMethod',
      'isCardMethod',
      'isSinglePaymentButton',
      'hasDeadEndPaymentMethod',
      'getPaymentMethodLabel',
      'getCustomPaymentMethodLabel',
      'getCustomPaymentMethodIcon'
    ]),
    ...mapState([
      'allIFramesReady',
      'isPopinOpen',
      'isUnitaryTest',
      'redirectTransaction',
      'disabledForm',
      'redirectPopupOpen',
      'redirectIframeOpen'
    ]),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod,
      activeMethodMetadata: state => state.smartForm.activeMethodMetadata,
      isOpen: state => state.smartForm.isOpen,
      errorCode: state => state.error.errorCode,
      buttonConfig: state => state.form.smartform.embedded.button,
      radioButtonConfig: state => state.form.smartform.radioButton,
      cardBrands: state => state.smartForm.cardBrands
    }),
    styles() {
      const styles = {}
      styles.button = {}
      if (this.hover) {
        styles.button.border = this.buttonConfig.$hover.border
      }
      return styles
    },
    dynamicClasses() {
      return {
        'kr-method-label--spbtn': this.isSinglePaymentButton,
        'kr-method-label--selected': this.isSelected,
        'kr-method-label--warning': this.applyWarningStyle && !this.isApplePay,
        ['kr-' + this.method.toLowerCase()]: true,
        'kr-locked': this.isDisabled,
        'kr-loading': this.isLoading,
        'kr-no-icons': this.isCardMethod(this.method),
        'kr-method-label--grouped': this.isGrouped
      }
    },
    isSelected() {
      return this.isSelectedMethod(this.method)
    },
    isGrouped() {
      return this.$parent?.$options?.name === 'SmartFormMethodsGroup'
    },
    isDisabled() {
      return (
        !this.allIFramesReady ||
        this.locked ||
        this.disabledForm ||
        (this.method === 'CARDS' &&
          (this.isAnyCardFormDisabled || this.cardBrands.length === 0))
      )
    },
    methodLabel() {
      const key = `smartform_method_${this.method.toLowerCase()}`
      if (this.translate(key) !== key) return this.translate(key)
      return paymentMethodsConf.labels[this.method] || this.method
    },
    /**
     * isAnyTokenActive: when wallet is active a tokenized card is active,
     * CARDS is the activeMethod however this component is reserved for
     * new cards.
     */
    isLoading() {
      return (
        !this.isAnyTokenActive &&
        !this.errorCode &&
        this.activeMethod === this.method &&
        this.redirectTransaction === null &&
        !this.hasDeadEndPaymentMethod(this.method) &&
        !(this.method === 'CARDS' && this.activeMethodMetadata) // if metadata has any content it must come from wallet or branded smartbutton
      )
    },
    loadingAnimation() {
      return this.isLoading
    },
    hasRadioButton() {
      return (
        this.isSinglePaymentButton && this.radioButtonConfig.visibility === true
      )
    },
    methodIconContent() {
      const placeholder = '<span class="kr-placeholder"></span>'
      return (
        this.getCustomPaymentMethodIcon(this.method) || this.icon || placeholder
      )
    }
  },
  async created() {
    if (!this.isUnitaryTest) {
      const icons = await loadAssets()
      this.icon =
        icons.paymentMethods.regular[this.method] ||
        PreloadedAssets.regular.cardOutline
    }
  },
  methods: {
    async openMethod(method) {
      if (this.isDisabled || this.isLoading) return

      const action = this.method.startsWith('CARDS')
        ? 'openPopin'
        : 'beforePaymentStart'

      if (await this.interruptsExecution(this.method, action)) return false

      await this.$nextTick()
      this.$emit('selected', method)
    }
  }
}
</script>
