<template lang="pug">
  button.kr-smart-form-wallet-card.kr-method-label(
    v-on:click="clickHandler"
    v-on:mouseover="hover = true"
    v-on:mouseleave="hover = false"
    :class="dynamicClasses"
    :data-card="value"
    :data-token="method.token"
    type="button"
  )
    SmartFormRadioButton(v-if="hasRadioButton", :checked="isSelected", :hover="hover || hoverDelete")
    BrandIcon(:method="method.paymentMethodType")
    label.kr-wallet-method-label {{ value }}
    KryptonDeleteButton(
      v-if="!showLoading"
      v-on:click.stop="onClick"
      v-on:mouseover.stop="hoverDelete = true"
      v-on:mouseleave.stop="hoverDelete = false"
      :method="method.paymentMethodType"
      :token="method.token"
    )
    KryptonLoadingAnimation(v-else)
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import SmartFormRadioButton from '@/host/components/smartform/RadioButton'
import BrandIcon from '@/host/components/controls/BrandIcon'
import KryptonDeleteButton from '@/host/components/controls/KryptonDeleteButton'
import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'
import Modal from '@/configuration/sources/SimpleModal.yml'
import DeleteButtonMixin from '@/host/components/mixins/DeleteButtonMixin'
import { SmartFormClickMixin } from '@/host/components/mixins/SmartFormClick'

export default {
  name: 'SmartFormWalletMethod',
  components: {
    SmartFormRadioButton,
    BrandIcon,
    KryptonDeleteButton,
    KryptonLoadingAnimation
  },
  mixins: [DeleteButtonMixin, SmartFormClickMixin],
  provide() {
    return {
      context: this.context
    }
  },
  props: {
    method: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hover: false,
      hoverDelete: false,
      applyAlertStyle: false,
      context: {
        formId: null,
        namespace: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'isSelectedMethod',
      'isActiveMethod',
      'isSinglePaymentButton'
    ]),
    ...mapState(['allIFramesReady', 'redirectTransaction', 'amount']),
    ...mapState({
      errorCode: state => state.error.errorCode,
      flashDuration: state => state.form.smartform.methodLabel.flashDuration,
      radioButtonConfig: state => state.form.smartform.radioButton
    }),
    ...dynamicMapState('context.namespace', [
      'disabledForm',
      'disabledCardForm'
    ]),
    ...dynamicMapState('context.namespace', {
      paymentMethodToken: state => state?.nonSensitiveValues?.paymentMethodToken
    }),
    value() {
      return this.method.fields?.id?.value ?? ''
    },
    dynamicClasses() {
      return {
        [`kr-method--${this.method.paymentMethodType.toLowerCase()}`]: true,
        'kr-method-label--spbtn': this.isSinglePaymentButton,
        'kr-method-label--selected': this.isSelected,
        'kr-smart-form-wallet-card--hover-delete': this.hoverDelete,
        'kr-delete-button--create-token': !this.amount,
        'kr-smart-form-wallet-card--alert': this.applyAlertStyle,
        'kr-locked': this.isDisabled,
        'kr-loading': this.showLoading,
        'kr-method-label--create-token-selected':
          (this.isLoading || this.hover || this.hoverDelete) && !this.amount
      }
    },
    isDisabled() {
      return (
        !this.allIFramesReady ||
        this.locked ||
        this.disabledForm ||
        this.disabledCardForm ||
        (this.paymentMethodToken &&
          this.paymentMethodToken !== this.method.token)
      )
    },
    isLoading() {
      return (
        !this.errorCode &&
        this.redirectTransaction === null &&
        this.isActiveMethod(this.methodKey)
      )
    },
    showLoading() {
      return this.isLoading && !!this.amount
    },
    isSelected() {
      return this.isSelectedMethod(this.methodKey)
    },
    hasRadioButton() {
      return (
        this.isSinglePaymentButton && this.radioButtonConfig.visibility === true
      )
    },
    methodKey() {
      return `${this.method.paymentMethodType}:${this.method.token}`
    }
  },
  created() {
    const { forms } = this.$store.state
    this.context.formId = forms.main
    this.context.namespace = `cardForm_${this.context.formId}`
  },
  methods: {
    ...mapActions(['openModal']),
    openCard() {
      if (this.isDisabled || this.isLoading) return
      if (this.isSinglePaymentButton) {
        this.$emit('selected', this.methodKey)
        return
      }

      const layouts = Modal.layouts
      this.openModal({
        layout: layouts[this.resolveMethod()].confirmTokenPayment,
        method: this.method.paymentMethodType,
        paymentMethodToken: this.method.token
      })
    },
    deleteCardHandler() {
      this.deleteCard(this.method.paymentMethodType, this.method.token)
    },
    async clickHandler() {
      if (!this.amount) {
        this.deleteCardHandler()
        return
      }

      if (
        await this.interruptsExecution(
          `WALLET_${this.method.paymentMethodType}`,
          'beforePaymentStart'
        )
      )
        return false

      this.openCard()
    },
    resolveMethod() {
      const baseMethod = this.method.paymentMethodType
      return baseMethod.startsWith('PAYPAL') ? 'PAYPAL' : baseMethod
    },
    onClick() {
      this.applyAlertStyle = true
      setTimeout(() => {
        this.applyAlertStyle = false
      }, this.flashDuration * 1000)
    }
  }
}
</script>
