import { JSONPath } from 'jsonpath-plus';

/**
 * Rule abstract class.
 */
export default class Rule {
    constructor(definition) {
        this.tags = definition.tags;
        this.pattern = definition.pattern;
        this.jsonPaths = definition.jsonPaths;
    }

    /**
   * Apply the rule to the string.
   * @param stringToProcess the string to process
   * @returns {*}
   */
    // eslint-disable-next-line
  apply(stringToProcess) {
        let result = stringToProcess;
        if (this.pattern) {
            result = this.applyPattern(stringToProcess);
        }
        if (this.jsonPaths && this.jsonPaths.length > 0) {
            try {
                const parsedJson = JSON.parse(stringToProcess);
                this.jsonPaths.forEach((jsonPath) => {
                    // Get the array of paths for the given query
                    const paths = JSONPath({
                        path: jsonPath,
                        json: parsedJson,
                        resultType: 'all',
                    });
                    // Apply the changes to the results
                    paths.forEach((path) => {
                        const { parent, parentProperty, value } = path;
                        parent[parentProperty] = this.applyJsonPath(value);
                    });
                });
                result = JSON.stringify(parsedJson);
            } catch (e) {
                // Ignore
            }
        }
        return result;
    }

    /**
   * Apply Regex rule from string coming from Regex.
   * @param stringToProcess the string to process
   * @returns {*}
   */
    // eslint-disable-next-line
  applyPattern(stringToProcess) {
        return stringToProcess;
    }

    /**
   * Apply JsonPath rule.
   * @param jsonValue the jsonValue
   * @returns {*}
   */
    // eslint-disable-next-line
  applyJsonPath(jsonValue) {
        return jsonValue;
    }
}
