<template lang="pug">
i.fi-x-circle.icon-close-popup(
  @click="onClick", 
  v-html="closeIcon",
  :style="closeStyles",
  :class="{ 'kr-visible': isVisible }",
)

</template>

<script>
import PreloadedAssets from '@/configuration/PreloadedAssets'

/** MIXINS */
import PopinMixin from '@/common/components/mixins/styles/Popin'

export default {
  name: 'CloseButton',
  mixins: [PopinMixin],
  props: {
    isVisible: { type: Boolean, default: false }
  },
  emits: ['close'],
  data() {
    return {
      closeIcon: PreloadedAssets.close.close_icon
    }
  },
  methods: {
    onClick() {
      if (this.isVisible) {
        this.$emit('close')
      }
    }
  }
}
</script>
