<template lang="pug">
    extends templates/SelectField.pug
</template>

<script>
import { SelectFieldMixin } from '@/host/components/mixins/SelectField'

export default {
  name: 'KryptonIdentityDocType',
  mixins: [SelectFieldMixin],
  data() {
    return { fieldName: 'identityDocumentType' }
  },
  methods: {
    /**
     * Generate an available option from the given data
     */
    generateOption(value, key) {
      let id = key
      if (key.indexOf('REDEBAN_') !== 0) id = `REDEBAN_${key}`

      let label = this.translate(id)
      // If there is no translation, use the value on the DNA
      if (label === id) label = value

      return { id: key, label }
    }
  }
}
</script>
