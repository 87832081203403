import { difference, without } from 'underscore'
import extend from 'deep-extend'

import { formMutations } from '@/store/modules/form'
import { popinMutations } from '@/store/modules/popin'
import { iosMutations } from '@/store/modules/ios'
import { cssMutations } from '@/store/modules/css'
import { redirectMutations } from '@/store/modules/redirect'
import { environmentMutations } from '@/store/modules/environment'
import { smartFormMutations } from '@/store/modules/smartform'
import { navigationMutations } from '@/store/modules/navigation'
import { windowMutations } from '@/store/modules/window'
import { internalsMutations } from '@/store/modules/internals'
import { applePayMutations } from '@/store/modules/applePay'
import { googlePayMutations } from '@/store/modules/googlePay'
import { toolbarMutations } from '@/store/modules/toolbar'
import { extrasFormMutations } from '@/store/modules/extrasForm'
import { domMutations } from '@/store/modules/dom'
import { errorMutations } from '@/store/modules/error'
import { walletMutations } from '@/store/modules/wallet'

// import tokens from '@/configuration/sources/Tokens.yml'
// import endpointMap from '@/configuration/sources/EndpointMap.yml'

import { logSentry, setSentryBasicInfo } from '@/common/util/sentry'

// root mutations
export default {
  ...{
    UPDATE: (state, updateObject) => {
      const nonExtendableProps = ['dna']
      // Sets the object without extending for specific props
      for (const prop of nonExtendableProps) {
        if (updateObject[prop]) state[prop] = updateObject[prop]
      }

      // public key - set testKeys and shopId
      if ('publicKey' in updateObject) {
        const pKey = updateObject.publicKey
        updateObject.shopId = /^.+\:.+$/.test(pKey) ? pKey.split(':')[0] : null
        updateObject.testKeys = /testpublickey/i.test(pKey)
      }

      // Extend state
      state = extend(state, updateObject)
      setSentryBasicInfo(state)
    },
    F_UPDATE: (state, updateObject) => {
      state = extend(state, updateObject)
    },
    SYNC: (state, updateObject) => {
      const nonExtendableProps = ['dna']
      // Sets the object without extending for specific props
      nonExtendableProps.forEach(prop => {
        if (updateObject[prop]) state[prop] = updateObject[prop]
      })
      state = extend(state, updateObject)
      setSentryBasicInfo(state)
    },
    RESET: (state, defState) => {
      state.forms = {}
      state.allIFramesReady = defState.allIFramesReady
      state.synced = defState.synced
      state.smartForm = defState.smartForm
    },
    SYNCED: state => {
      state.synced = true
      state.allIFramesReady = true
      logSentry('Boot', 'Store synced')
    }
  },
  ...formMutations,
  ...popinMutations,
  ...iosMutations,
  ...cssMutations,
  ...redirectMutations,
  ...errorMutations,
  ...environmentMutations,
  ...smartFormMutations,
  ...navigationMutations,
  ...windowMutations,
  ...internalsMutations,
  ...applePayMutations,
  ...googlePayMutations,
  ...toolbarMutations,
  ...extrasFormMutations,
  ...domMutations,
  ...walletMutations
}
