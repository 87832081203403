import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

export const RequiredMixin = {
  inject: ['context'],
  computed: {
    ...mapState('field', ['required']),
    ...mapState(['dna']),
    ...dynamicMapState('context.namespace', ['selectedBrand'])
  },
  watch: {
    selectedBrand: 'requiredUpdate',
    dna: {
      deep: true,
      handler: 'requiredUpdate'
    }
  },
  methods: {
    requiredUpdate() {
      this.$store.dispatch('field/update', { required: this.isRequired() })
    },
    isRequired() {
      if (!this.dna?.cards) return true
      const config = this.dna.cards[this.selectedBrand]
      if (!config?.fields) return true
      const fieldConf = config.fields[this.fieldName]
      if (!fieldConf) return true
      return fieldConf.required === true && fieldConf.hidden !== true
    }
  }
}
