/**
 * The class below has been added to emulate polymorphism with regards to
 * payment methods name and their metadata by letting its factory methods handle
 * different type of parameter a returning a streamline object.
 *
 * For e.g: as of today (2023-12-19) some store actions (openMethod, openModal)
 * receive the payment name a string, but might be called from SimpleModal,
 * which will always send a object with different keys defined at the opening.
 *
 * @since KJS-3307
 */
export class MethodDescriptor {
  // Factory -------------------------------------------------------------------
  static fromString(params) {
    const [name, metadata] = params.split(':')
    return new MethodDescriptor(name, metadata)
  }

  static fromObject(params) {
    const name = params.name ?? params.method ?? null
    const metadata = params.metadata ?? params.paymentMethodToken ?? null
    return new MethodDescriptor(name, metadata)
  }

  static fromArray([name, metadata]) {
    return new MethodDescriptor(name, metadata)
  }

  static create(params) {
    if (params instanceof MethodDescriptor) return params
    else if (typeof params === 'string')
      return MethodDescriptor.fromString(params)
    else if (Array.isArray(params)) return MethodDescriptor.fromArray(params)
    else if (typeof params === 'object' && params)
      return MethodDescriptor.fromObject(params)

    throw new TypeError(
      `MethodDescriptor::create: Invalid parameter, expected string, Array, Object or MethodDescriptor`
    )
  }

  // Instance ------------------------------------------------------------------

  constructor(name, metadata = null) {
    this.name = name
    this.metadata = metadata ?? null

    if (!this.name) {
      throw TypeError('MethodDescriptor.constructor: method name is required')
    }
    if (typeof this.name !== 'string') {
      throw TypeError(
        'MethodDescriptor.constructor: method name is expected to be of type <string>'
      )
    }
  }

  toArray() {
    return [this.name, this.metadata]
  }

  toString() {
    if (this.metadata) {
      return `${this.name}:${this.metadata}`
    }
    return this.name
  }
}
