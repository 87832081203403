import Vue from 'vue'
import Zepto from 'zepto-webpack'
import { uniqueId, isString } from 'underscore'
import CustomTextField from '@/host/custom/Text'
import CustomCheckboxField from '@/host/custom/Checkbox'
import CustomRadioField from '@/host/custom/Radio'

const getComponent = fieldType => {
  switch (fieldType) {
    case 'text':
      return CustomTextField
    case 'checkbox':
      return CustomCheckboxField
    case 'radio':
      return CustomRadioField
    default:
      return
  }
}

const getFieldType = $field => {
  const fieldType = $field.getAttribute('type')
  if (isString(fieldType)) {
    return fieldType.toLowerCase()
  } else {
    $field.setAttribute('type', 'text')
    return 'text'
  }
}

const renderCustomField = $field => {
  const fieldType = getFieldType($field)
  const component = getComponent(fieldType)
  if (component) {
    // Create app wrapper
    const appId = uniqueId('kr-field-app-')

    // Create a single component dynamic
    const dynamicTag = uniqueId('kr-component-')

    component.$field = $field
    Vue.component(dynamicTag, { ...component })

    const field = Zepto($field)[0]
    const appDiv = document.createElement('div')
    const customField = document.createElement('div')
    customField.setAttribute('is', dynamicTag)

    appDiv.id = appId
    appDiv.appendChild(customField)

    field.parentNode.insertBefore(appDiv, field.nextSibling)
    field.parentNode.removeChild(field)
    customField.appendChild(field)
  }
}

/**
 * This function wraps the custom fields of the host view inside a Vue application
 * and is able to communicate a template configured to this element. The Vue
 * element should be created dinamycally as component
 *
 * @function applyRender
 * @public
 */
export default $wrapper => {
  const $customFields = $wrapper[0].querySelectorAll('input.kr-theme') || []

  // for..of of domElements has no iterablity
  for (let i = 0; i < $customFields.length; i++) {
    const $customField = $customFields[i]
    renderCustomField($customField)
  }
}
