import Events from '@/configuration/Events'
import { dynamicMapState } from '@/common/util/store'

export const EventsMixin = {
  computed: {
    ...dynamicMapState('context.namespace', ['visibleFields'])
  },
  methods: {
    setPermanentFocus() {
      this.$bus.$emit(Events.krypton.field.event, {
        name: this.fieldName,
        type: 'permanentFocus',
        formId: this.context.formId
      })
    },
    clearPermanentFocus(fieldName) {
      this.$bus.$emit(Events.krypton.field.event, {
        name: fieldName || this.fieldName,
        type: 'clearPermanentFocus',
        formId: this.context.formId
      })
    },
    clearPermanentFocusAllFields() {
      for (const fieldName of this.visibleFields) {
        this.clearPermanentFocus(fieldName)
      }
    }
  }
}
