import { isLoadedLocally } from '@/common/util/url'

// Proxy send (post message -> host)
const proxySender = (data, parentDomain) => {
  if (typeof parentDomain === 'string') {
    if (!isLoadedLocally(parentDomain)) {
      window.parent.postMessage(
        `/*krypton-client*/ ${JSON.stringify(data)}`,
        parentDomain
      )
    }
  }
}

// Slave ready communication
let slaveReadyInterval
const slaveReady = (state, parentDomain) => {
  // First send
  proxySender(buildEchoObj(state), parentDomain)
  // We set an interval to send the echo until we receive the response
  slaveReadyInterval = setInterval(() => {
    proxySender(buildEchoObj(state), parentDomain)
  }, 1000)
}

const buildEchoObj = state => {
  return {
    _name: 'echo',
    fieldName: state.field.name,
    awareId: state.field.awareId,
    formId: state.field.formId,
    source: 'slave',
    _class: 'ActionEcho'
  }
}

export default $locator => {
  return store => {
    let parentDomain

    // Common messages
    $locator.$bus.$on('proxy.send', message => {
      // Log
      if (message._name !== 'log') {
        $locator.logger.log('info', `postMessage from SLAVE`, { message })
      }
      proxySender(message, parentDomain)
    })

    // Ready message
    store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'field/BOOT':
          parentDomain = mutation.payload.parentDomain
          slaveReady(state, parentDomain)
          break
        case 'field/COMMUNICATION_ESTABLISHED':
          clearInterval(slaveReadyInterval)
          break
      }
    })
  }
}
