import { wait } from '@/common/util/async'
import Modal from '@/configuration/sources/SimpleModal.yml'

export const getPartialPaymentState = () => {
  return {
    partialPayment: {
      transactions: []
    }
  }
}

export const partialPaymentActions = ($locator, app) => {
  return {
    async addPartialTransaction({ state, commit, dispatch }, clientAnswer) {
      // Get required data from clientAnswer
      const { partialTransaction, updatedDna } = parseClientAnswer(clientAnswer)
      const initialAmount = state.amount

      // Update partial transaction list
      const { transactions } = state.partialPayment
      const newTransactions = [...transactions, partialTransaction]
      commit('UPDATE', {
        partialPayment: {
          transactions: newTransactions
        }
      })

      await dispatch('applyUpdatedDna', updatedDna)

      // For the first added transaction show a warning modal
      if (newTransactions.length === 1)
        await dispatch('showPartialPaymentWarning', {
          partialTransaction,
          initialAmount
        })
    },
    async removePartialTransaction(
      { state, commit, dispatch },
      { transactionUuid, clientAnswer }
    ) {
      const { transactions } = state.partialPayment
      const { updatedDna } = clientAnswer.orderDetails

      const newTransactions = transactions.filter(
        transaction => transaction.uuid !== transactionUuid
      )
      commit('UPDATE', {
        partialPayment: {
          transactions: newTransactions
        }
      })

      await dispatch('applyUpdatedDna', updatedDna)

      dispatch('closeModal')
    },
    startCancelPartialTransaction(
      {
        state: {
          partialPayment: { transactions }
        },
        dispatch,
        getters
      },
      { transactionUuid }
    ) {
      const transaction = transactions.find(
        transaction => transaction.uuid === transactionUuid
      )
      dispatch('openModal', {
        layout: Modal.layouts.CARDS.removePartialPayment,
        amount: getters.getAmountLabel(transaction.amount),
        id: transaction.id,
        method: transaction.brand,
        transactionUuid
      })
    },
    callCancelPartialTransaction({}, { transactionUuid }) {
      const { proxy, storeFactory } = $locator
      proxy.send(
        storeFactory.create('declinePartialPaymentCall', {
          transactionUuid
        })
      )
    },
    async showPartialPaymentWarning(
      { state, getters, dispatch },
      { partialTransaction, initialAmount }
    ) {
      const layout = JSON.parse(
        JSON.stringify(Modal.layouts.CARDS.partialPaymentWarning)
      )
      if (state.dna.orderId) {
        layout.title = 'partial_payment_warning_header_order'
      }
      const action = !state.isUnitaryTest ? 'openAwaitableModal' : 'openModal'
      await dispatch(action, {
        layout,
        paidAmount: getters.getAmountLabel(partialTransaction.amount),
        totalAmount: getters.getAmountLabel(initialAmount)
      })

      if (state.modal.selectedOption === 'cancel') {
        // Wait for closing animation to end
        await wait(250)
        dispatch('startCancelPartialTransaction', {
          transactionUuid: partialTransaction.uuid
        })
      }
    },
    async applyUpdatedDna(
      {
        state: {
          partialPayment: { transactions }
        }
      },
      updatedDna
    ) {
      const parsedUpdatedDna = JSON.parse(updatedDna)

      // We don't want to add tokens created via a partial payment, so we remove them from the returned DNA
      if (parsedUpdatedDna?.tokens?.cards)
        parsedUpdatedDna.tokens.cards = removeUsedPartialTokens({
          cardTokens: parsedUpdatedDna.tokens.cards,
          partialPaymentTransactions: transactions
        })
      /**
       * Please note that when we update the DNA with partial payments we want to keep the original amount label
       * For this check PropagateDna.js
       */
      await $locator.dna.parse(parsedUpdatedDna)
    }
  }
}

export const partialPaymentGetters = {
  hasPartialPayments: ({ partialPayment: { transactions } }) => {
    return transactions.length > 0
  }
}

// don't put to an utils file, the logic is only used here
function removeUsedPartialTokens({ cardTokens, partialPaymentTransactions }) {
  return cardTokens.filter(
    card =>
      !partialPaymentTransactions.some(
        tr => tr.paymentMethodToken === card.token
      )
  )
}

function parseClientAnswer(clientAnswer) {
  const newTransaction = clientAnswer.transactions[0]
  const { effectiveAmount, cardDetails, paymentMethodDetails } =
    newTransaction.transactionDetails
  const { pan, effectiveBrand } = cardDetails

  const id =
    newTransaction.paymentMethodType === 'CARD' ? pan : paymentMethodDetails.id
  const { updatedDna } = clientAnswer.orderDetails

  return {
    updatedDna,
    partialTransaction: {
      id,
      amount: effectiveAmount,
      brand: effectiveBrand,
      uuid: newTransaction.uuid,
      paymentMethodToken: newTransaction.paymentMethodToken,
      paymentMethodType: newTransaction.paymentMethodType
    }
  }
}
