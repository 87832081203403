var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kr-redirect", class: [_vm.formType] }, [
    _vm.redirectionType === "iframe"
      ? _c(
          "div",
          {
            staticClass: "kr-redirect-modal kr-fullscreen",
            style: _vm.dynamicStyles.modal,
          },
          [
            _vm.showingIFrame
              ? _c(
                  "div",
                  {
                    staticClass: "kr-redirect-container",
                    style: _vm.dynamicStyles.container,
                  },
                  [
                    _vm.iframeReady
                      ? _c(
                          "div",
                          {
                            staticClass: "kr-iframe-container",
                            style: _vm.dynamicStyles.container,
                          },
                          [
                            _vm.isV1
                              ? _c(
                                  "div",
                                  { style: _vm.dynamicStyles.container },
                                  [
                                    _c("iframe", {
                                      style: _vm.dynamicStyles.iframe,
                                      attrs: {
                                        id: _vm.browserId,
                                        src: _vm.redirectUrl,
                                        scrolling: "yes",
                                        allowtransparency: "true",
                                        frameborder: "0",
                                        name: "browser-frame",
                                        allow: _vm.allowedPermissions,
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }