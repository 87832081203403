<template lang="pug">
.kr-smart-form-modal-wrapper(v-show="open")
  .kr-smart-form-modal(role="dialog", :class="dynamicClasses.modal")
    KryptonPopinHeader(v-on:close="closeModal()", :is-smart-form="true")
    .kr-smart-form-modal-views-container(:class="dynamicClasses.viewsContainer", :style="dynamicStyles.viewsContainer")
      SmartFormModalView(
        v-for="(_, name) in $scopedSlots"
        :ref="name"
        :key="name" :name="name"
        :active="view === name"
        :class="dynamicClasses.views"
      )
        slot(:name="name")
    KryptonPopinFooter
</template>

<script>
import KryptonPopinHeader from '@/host/components/popin/KryptonPopinHeader'
import KryptonPopinFooter from '@/host/components/popin/KryptonPopinFooter'
import SmartFormModalView from '@/host/components/smartform/ModalView'
import { mapActions, mapGetters, mapState } from 'vuex'
import { defer, delay } from 'underscore'
import { PopinSizeMixin } from '@/host/components/mixins/PopinSize'

export default {
  name: 'SmartFormModal',
  components: {
    KryptonPopinHeader,
    KryptonPopinFooter,
    SmartFormModalView
  },
  mixins: [PopinSizeMixin],
  props: {
    view: {
      type: String,
      default: 'default'
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cardsView: false,
      isOpen: false
    }
  },
  computed: {
    ...mapState(['redirectIframeOpen', 'receiptOpen', 'redirectTransaction']),
    ...mapState({
      cardTokens: state => state.dna?.tokens?.cards || [],
      currentView: state => state.smartForm?.navigation?.currentView,
      activeMethod: state => state.smartForm.activeMethod
    }),
    ...mapGetters([
      'isSmartFormPopin',
      'isCardMethodActive',
      'isSmallScreen',
      'requireFullScreenRedirect',
      'isWalletSmartForm',
      'cardsFormExpanded',
      'abortedErrorCode',
      'hasDeadEndPaymentMethod'
    ]),
    activeLevel() {
      return Object.keys(this.$scopedSlots).indexOf(this.view)
    },
    dynamicClasses() {
      const classes = {
        background: {
          'kr-modal-open': this.isOpen
        },
        modal: {
          'kr-modal-open': this.isOpen,
          'kr-secure-iframe-visible': this.redirectIframeOpen,
          'kr-mobile': this.isSmallScreen,
          'kr-secure-iframe-open': this.redirectIframeOpen,
          'kr-fullscreen':
            this.redirectIframeOpen && this.requireFullScreenRedirect,
          'kr-smart-form-modal--scrollable-content': ['group', 'full'].includes(
            this.currentView
          )
        },
        viewsContainer: {
          'kr-smart-form-modal-view--select-open': this.isAnySelectOpen
        },
        views: {
          [`kr-smart-form-modal-view--level-${this.activeLevel}`]: true,
          'kr-smart-form-modal-view--popin': this.isSmartFormPopin
        }
      }
      if (this.cardsView) {
        classes.viewsContainer = {
          ...classes.viewsContainer,
          'kr-smart-form-modal-view-cards': true,
          'kr-secure-iframe-visible': this.redirectIframeOpen
        }
      }
      return classes
    },
    dynamicStyles() {
      const classes = {
        viewsContainer: {}
      }
      if (this.redirectIframeOpen) {
        const { width } = this.popinSize
        classes.viewsContainer = { width: `${width}px` }
      }
      return classes
    },
    walletMode() {
      return this.isWalletSmartForm && this.cardTokens.length > 0
    },
    isAnySelectOpen() {
      const { forms } = this.$store.state
      const formId =
        this.walletMode && this.cardsFormExpanded && forms.clone
          ? forms.clone
          : forms.main
      if (!this.$store.hasModule([`cardForm_${formId}`])) {
        return false
      }
      const { selectFields } = this.$store.state[`cardForm_${formId}`]
      return Object.values(selectFields).some(status => status)
    }
  },
  watch: {
    /**
     * Upon view change, make active ModalView set the focus.
     * Wait for the animation to finish and back-button (if relevant) to be
     * rendered/display and considered as focusable.
     *
     * @since KJS-4026
     */
    view() {
      // delay(() => {
      // this.initFocus()
      // }, 250)
    },
    open(val) {
      defer(() => {
        if (this.open) {
          this.isOpen = this.open
        }
      })
    },
    /**
     * @see KJS-4026 | On opening, make active ModalView set the focus.
     */
    isOpen(val) {
      if (val) {
        // this.initFocus()
        return
      }
      delay(() => {
        if (!this.isOpen) {
          if (this.receiptOpen) {
            this.$proxy.send(this.$storeFactory.create('closeReceipt', {}))
            return
          }
          if (this.activeMethod && !this.redirectIframeOpen) {
            this.error(this.abortedErrorCode)
            this.closeSmartFormPopin()
          } else {
            if (this.redirectIframeOpen) {
              if (!this.hasDeadEndPaymentMethod() && !this.redirectTransaction)
                this.error(this.abortedErrorCode)

              this.finishRedirection(true)
            }
            this.closePopin()

            const $ghost = this.$locator.iframeController.ghostContainer
            $ghost.hide()

            // Report by event the closing action of the popin
            this.forceFieldClear()
          }
        }
      }, 100)
    },
    isCardMethodActive(val) {
      if (val)
        delay(() => {
          this.cardsView = val
        }, 250)
      else this.cardsView = val
    }
  },
  methods: {
    ...mapActions([
      'error',
      'closeSmartFormPopin',
      'finishRedirection',
      'closePopin',
      'forceFieldClear'
    ]),
    /**
     * this.$refs[this.view][0] is a ModalView component
     * @since KJS-4026
     */
    initFocus() {
      this.$refs[this.view][0].onEnter()
    },
    closeModal() {
      this.isOpen = false
    }
  }
}
</script>
