import _ from 'underscore'

export default class FocusHandler {
  constructor($locator) {
    this.$locator = $locator

    // focus related information
    this.currentFieldFocus = null
    this.currentFormFocus = null
    this.previousFieldFocus = null
    this.anyFieldFocus = false
    this.fieldFocusState = {}
    this.runningVigilante = false
    this.vigilante = _.noop
    this.form = null
    this.store = null
    this.fieldNames = []

    this.startSubscriptions()
  }

  startSubscriptions() {
    this.$locator.$store.subscribe((mutation, state) => {
      const formId =
        mutation.type.indexOf('cardForm_') === 0
          ? mutation.type.split('/')[0].split('_')[1]
          : null
      if (formId && mutation.payload.forcedFieldBlur) {
        this.blurAll(formId)
      }
    })
  }

  shouldStartVigilance() {
    return this.$store.getters.isIos
  }

  execute(store, form, formId) {
    const fieldNames = form.getFieldNames()

    this.fieldNames = fieldNames
    this.form = form

    let fieldName = store.name
    this.currentFieldFocus = fieldName
    this.currentFormFocus = formId
    this.anyFieldFocus = false

    if (this.shouldStartVigilance()) this.startVigilance()
    this.notifyStatus()

    this.previousFieldFocus = fieldName
    // If no field focus, blurAll
    if (!this.anyFieldFocus) this.blurAll(formId)
  }

  startVigilance() {
    const _this = this

    if (!this.runningVigilante) {
      this.runningVigilante = true
      this.vigilante = setInterval(() => {
        _this.notifyStatus()
      }, 50)
    }
  }

  notifyStatus() {
    const storeState = this.$locator.$store.state
    let fieldNames = this.fieldNames

    if (this.currentFieldFocus !== this.previousFieldFocus) {
      fieldNames.forEach(fieldName => {
        let shouldBeFocused =
          this.currentFieldFocus == fieldName &&
          this.form.formId == this.currentFormFocus
        if (!_.has(this.fieldFocusState, fieldName)) {
          this.fieldFocusState[fieldName] = undefined
        }

        // Focus or blur
        if (
          shouldBeFocused &&
          !storeState.nonSensitiveValues.paymentMethodToken
        ) {
          this.anyFieldFocus = true
          this.$locator.$store.dispatch('setFakeFocus', fieldName)
        }
        this.fieldFocusState[fieldName] = shouldBeFocused
      })
    }
  }

  blurAll(formId) {
    this.currentFieldFocus = null
    this.currentFormFocus = null
    this.previousFieldFocus = null
    this.$locator.$store.dispatch(`cardForm_${formId}/update`, {
      forcedFieldBlur: false
    })
  }
}
