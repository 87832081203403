var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-smart-form-methods" },
    [
      _vm.renderLabels
        ? _c("SmartFormMethodsListLabel", {
            attrs: {
              mode:
                _vm.isGroupingActivated && _vm.mode === "groups"
                  ? "groups"
                  : "methods",
              "all-locked": _vm.paymentDone || _vm.hasAnyActiveMethod,
              "is-modal": true,
            },
            on: {
              "group-selected": _vm.loadGroup,
              selected: _vm.setMethod,
              back: _vm.goBack,
            },
          })
        : _vm._e(),
      _vm.renderContents
        ? _c(
            "SmartFormMethodsListContent",
            { attrs: { mode: "method" }, on: { back: _vm.goBack } },
            [_vm._t("cardForm")],
            2
          )
        : _vm._e(),
      _c("SmartFormFinishedPayment", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.paymentDone && _vm.isFormPopin,
            expression: "paymentDone && isFormPopin",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }