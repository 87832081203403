import EventTarget from '@ungap/event-target'

/**
 * Handle form specific operations while slaves are loading.
 *
 * @since KJS-3458
 */
export default class PendingForm extends EventTarget {
  static DEFAULT_TIMEOUT = 30000
  static EVENT_SLAVE_LOADED = 'slave:loaded'

  constructor(id) {
    super()
    this.id = id
    this.slaveNumber = null
    this.reportedSlaves = 0
    this.promise = null
    this.warningThreshold = PendingForm.DEFAULT_TIMEOUT
  }

  /**
   * Used by AllReadyQueue.
   *
   * - Count iframes in DOM for a specific formId
   * - Set promise to be fulfilled once every iframe is loaded
   *
   * A timeout is set (default 30s), if form is not fully loaded within that
   * timespan the promise if rejected.
   *
   * @returns {Promise<void>}
   */
  initialize() {
    if (this.promise instanceof Promise) {
      return this.promise
    }
    this.slaveNumber = this._countSlaves()

    this.promise = new Promise((resolve, reject) => {
      if (this.isLoaded()) {
        return resolve()
      }

      const t = setTimeout(() => {
        if (!this.isLoaded()) {
          const displayThreshold = `${this.warningThreshold / 1000} seconds`
          reject(new Error(`Form did not load within ${displayThreshold}`))
        }
      }, this.warningThreshold)

      this.addEventListener(PendingForm.EVENT_SLAVE_LOADED, () => {
        if (this.isLoaded()) {
          clearTimeout(t)
          resolve()
        }
      })
    })

    return this.promise
  }

  _countSlaves() {
    const selector = `iframe[name^="krfield-"][kr-form-slave="${this.id}"]`
    return document.querySelectorAll(selector).length
  }

  /**
   * Return promise to be fulfilled once every slaves is loaded.
   *
   * @returns {Promise<void>}
   */
  onLoaded() {
    return this.promise
  }

  /**
   * @returns {boolean}
   */
  isLoaded() {
    return this.reportedSlaves >= this.slaveNumber
  }

  /**
   * Report another iframe being loaded for specific formId.
   */
  reportLoadedSlave() {
    this.reportedSlaves += 1
    this.dispatchEvent(new Event(PendingForm.EVENT_SLAVE_LOADED))
  }
}
