/**
 * This utility object provides the hostname patterns to be used to filter
 * the hostname and pattern alloweds for the client library, and can be used
 * to extract information about it
 */
export default {
  get() {
    let rx = /^http[s]*.+\/kr.+(debug|min)\.js.*$/
    let path = /^(http[s]*:\/+)?\/?([^:\/\s]+)(.*)\/(.+\/)stable\/kr/

    return { rx, path }
  }
}
