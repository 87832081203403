<template lang="pug">
  .kr-smart-form-wallet(:class="dynamicClasses" v-onresize="onResize")
    SmartFormWalletCard(
      v-for="(card, key) in cardTokens",
      v-on:selected="onSelected"
      :key="card.token",
      :card="card"
      :locked="allLocked"
    )
    SmartFormWalletMethod(
      v-for="(method, key) in smartFormTokens",
      v-on:selected="onSelected"
      :key="method.token",
      :method="method"
      :locked="allLocked"
    )
    SmartFormWalletError
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SmartFormWalletError from '@/host/components/smartform/wallet/Error'
import SmartFormWalletCard from '@/host/components/smartform/wallet/WalletCard'
import SmartFormWalletMethod from '@/host/components/smartform/wallet/WalletMethod'
import DomReactMixin from '@/host/components/mixins/DomReact'

export default {
  name: 'SmartFormWallet',
  components: {
    SmartFormWalletCard,
    SmartFormWalletMethod,
    SmartFormWalletError
  },
  mixins: [DomReactMixin],
  props: {
    compact: {
      type: Boolean,
      default: false
    },
    allLocked: {
      type: Boolean,
      default: false
    },
    methods: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      compactFits: false
    }
  },
  computed: {
    ...mapState({
      cardTokens: state => state.dna?.tokens?.cards || [],
      smartFormTokens: state => state.dna?.tokens?.smartForm || []
    }),
    ...mapGetters(['isSinglePaymentButton']),
    dynamicClasses() {
      return {
        'kr-smart-form-wallet--compact': this.compact && this.compactFits
      }
    }
  },
  methods: {
    onSelected(method) {
      this.$emit('selected', method)
    },
    onResize() {
      const width = this.$el.clientWidth
      this.compactFits = this.isSinglePaymentButton ? width > 400 : width > 350
    }
  }
}
</script>
