import { pick, union, without } from 'underscore'
import Zepto from 'zepto-webpack'
import mimicConfiguration from '@/configuration/MimicConfiguration'

/**
 * Service to fetch the filtered styles and calculated from a element
 * on the DOM.
 *
 * @public
 */
export default class Mimic {
  /**
   * @public
   */
  constructor($store) {
    this.$store = $store
    this.attributesWhitelist = mimicConfiguration.whitelist
    this.mimicInputOmit = mimicConfiguration.inputOmit
  }

  /**
   * @public
   */
  calculateStyles(selector, attributesOmit = []) {
    const domElement = document.querySelector(selector)
    if (!domElement || !window.getComputedStyle) return {}

    const livingCSSObject = window.getComputedStyle(domElement)
    attributesOmit = ~selector.indexOf('input')
      ? union(this.mimicInputOmit, attributesOmit)
      : attributesOmit
    const attributesWhitelist = without(
      this.attributesWhitelist,
      ...attributesOmit
    )

    // Get only whitelisted attributes
    let filteredObject = pick(livingCSSObject, ...attributesWhitelist)
    // Override border to match with host inputs
    filteredObject.border = '0px solid black'
    if (attributesOmit.indexOf('width') === -1) filteredObject.width = '100%'

    // The outline is not computed properly in FFOX >= 80
    if (
      name === 'Firefox' &&
      parseInt(version, 10) >= 80 &&
      ~selector.indexOf('.kr-input-field') &&
      filteredObject.outline === ''
    ) {
      filteredObject.outline = '0px'
    }

    // if it's for disabled pick only color styles
    if (selector.slice(-8) === 'disabled')
      pick(filteredObject, 'background-color', 'color')

    return filteredObject
  }

  /**
   * Returns the CSS calculated for the input forms
   *
   * @public
   * @param {array} [ignoredAttributes=[]] List of attributes to be ignored when reading the CSS
   * @return {object}
   */
  calculateFormCSS(className, ignoredAttributes = []) {
    let css = {
      outerDefault: '#krFieldRepresentation .kr-outer-' + className,
      outerError: '#krFieldRepresentationError .kr-outer-' + className,
      outerDisabled: '#krFieldRepresentationDisabled .kr-outer-' + className,
      innerDefault:
        '#krFieldRepresentation .kr-outer-' + className + ' .kr-inner-wrapper',
      innerError: '#krFieldRepresentationError .kr-inner-wrapper',
      innerDisabled: '#krFieldRepresentationDisabled .kr-inner-wrapper',
      fieldContainer:
        '#krFieldRepresentation .kr-outer-' +
        className +
        ' .kr-field-container',
      fieldRelativeWrapper:
        '#krFieldRepresentation .kr-outer-' +
        className +
        ' .kr-field-relative-wrapper',
      inputRelativeWrapper:
        '#krFieldRepresentation .kr-outer-' +
        className +
        ' .kr-input-relative-wrapper',
      inputDefault:
        '#krFieldRepresentation .kr-outer-' + className + ' .kr-input-field',
      inputError: '#krFieldRepresentationError .kr-input-field',
      inputDisabled: '#krFieldRepresentationDisabled .kr-input-field'
    }

    for (const key in css)
      css[key] = this.calculateStyles(css[key], ignoredAttributes)

    return css
  }

  isValidSize(value) {
    return value.length && value !== '100%' && value !== '0px'
  }

  calculateSize(className) {
    const $form = document.querySelector(`.kr-embedded`)
    const $field = document.querySelector(`.kr-${className}`)

    // If the elements are not present, we can't calculate. See KJS-4313
    if (!$field || !$form) return { width: '100%', height: '100%' }

    // Flexbox - We can use 100%
    const fieldStyles = getComputedStyle($field)
    const formStyles = getComputedStyle($form)
    // There are values defined in the parent elements - fill all the space
    let width =
      this.isValidSize(fieldStyles.width) || this.isValidSize(formStyles.width)
        ? '100%'
        : null

    let height = this.isValidSize(fieldStyles.height) ? '100%' : null
    if (width && height) return { width, height }

    // First fallback (no flexbox) - Use representation elements (iframe content)
    const iframeStyles = getComputedStyle(
      document.querySelector(`#krFieldRepresentation .kr-outer-${className}`)
    )
    if (!width && iframeStyles && this.isValidSize(iframeStyles.width))
      width = iframeStyles.width
    if (!height && iframeStyles && this.isValidSize(iframeStyles.height))
      height = iframeStyles.height
    if (width && height) return { width, height }

    // Last fallback, don't use the default iframe height (150px)
    if (!height) height = '20px'

    return { width, height }
  }

  /**
   * In IE the size calculation is not properly done, the paddings are removed
   * from the width, height when they shouldn't
   */
  ieSizeFix(filteredObject) {
    if (
      filteredObject.width &&
      filteredObject.width.indexOf('px') !== -1 &&
      filteredObject['padding-left'] &&
      filteredObject['padding-left'].indexOf('px') !== -1 &&
      filteredObject['padding-right'] &&
      filteredObject['padding-right'].indexOf('px') !== -1
    ) {
      const width = parseInt(filteredObject.width.replace('px', ''))
      const paddingLeft = parseInt(
        filteredObject['padding-left'].replace('px', '')
      )
      const paddingRight = parseInt(
        filteredObject['padding-right'].replace('px', '')
      )
      filteredObject.width = `${width + paddingLeft + paddingRight}px`
    }
    if (
      filteredObject.height &&
      filteredObject.height.indexOf('px') !== -1 &&
      filteredObject['padding-bottom'] &&
      filteredObject['padding-bottom'].indexOf('px') !== -1 &&
      filteredObject['padding-top'] &&
      filteredObject['padding-top'].indexOf('px') !== -1
    ) {
      const height = parseInt(filteredObject.height.replace('px', ''))
      const paddingBottom = parseInt(
        filteredObject['padding-bottom'].replace('px', '')
      )
      const paddingTop = parseInt(
        filteredObject['padding-top'].replace('px', '')
      )
      filteredObject.height = `${height + paddingBottom + paddingTop}px`
    }
  }
}
