var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.visible,
        expression: "visible",
      },
    ],
    style: _vm.styles,
    attrs: {
      "kr-resource": "",
      src: _vm.iframeSrc,
      id: _vm.identifier,
      scrolling: "no",
      allowtransparency: "true",
      frameborder: "0",
      "kr-aware-id": _vm.awareId,
      name: _vm.identifier,
      title: _vm.identifier,
      allow: _vm.allow,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }