var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.renderGenericEmbeddedHeader || _vm.hasCardHeader
    ? _c(
        "header",
        { staticClass: "kr-sf-header" },
        [
          _vm.renderGenericEmbeddedHeader
            ? _c("SmartFormEmbeddedHeader")
            : _vm.hasCardHeader
            ? _c("SmartFormCardHeader")
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }