import { Queue } from '@/common/model'

export const wait = async time => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(null)
    }, time)
  })
}

export const nextTick = async () => {
  await wait(0)
}

/**
 * Return a queuable version of the passed function that will never be executed
 * in parallel more than a number of times lower or equal to the concurrency.
 *
 * @template T
 * @param {function(...args: any[]) => Promise<T> | T} fn Function to queue
 * @param {number} concurrency Number of job that can be executed in parallel
 * @returns {function(...args: any[]) => Promise<T>}
 */
export const queue = (fn, concurrency = 1) => {
  const q = new Queue(concurrency)

  return (...args) => {
    return q.push(() => fn(...args))
  }
}
