import _ from 'underscore'
import ApiBase from '@/common/base/ApiBase.js'

import { setupConfigurationEvents } from '@/host/service/ConfigurationUtils'
import Events from '@/configuration/Events'

export default class ApiHost extends ApiBase {
  constructor($locator) {
    super($locator)
    this.$locator = $locator
    this.$bus = $locator.$bus
    this.storeFactory = $locator.storeFactory
    this.ghostQueue = $locator.ghostQueue
    this.proxy = $locator.proxy
    this.dna = $locator.dna
    this.configurationHandler = $locator.configurationHandler
    this.interceptedForms = {}
    this.interceptedFormsRefused = {}

    this.openRoutes()
  }

  openRoutes() {
    const _this = this
    const $bus = this.$bus
    const configurationHandler = this.configurationHandler
    const endpointDetector = this.$locator.endpointDetector
    const interceptedForms = this.interceptedForms
    const interceptedFormsRefused = this.interceptedFormsRefused

    /**
     * Allows us to configure the form remotely through this
     * service
     *
     * @POST /formConfig
     */
    this.router.post['/formConfig'] = (route, data) => {
      // Whitelisting keys and checking data
      if (!_.isObject(data)) {
        return this.create500(
          'invalid post parameters / the input parameter is not an object'
        )
      }

      // Actions for specific properties on the config object
      if (data.hasOwnProperty('brand')) {
        // Don't update the store (let the component take the decision)
        $bus.$emit(Events.krypton.form.brand.forced, {
          brand: data.brand
        })
      }
      if (data.hasOwnProperty('endpoint')) {
        // Only possible to configure with setFormConfig
        endpointDetector.setForcedEndpoint(data.endpoint)
      }
      if (data.hasOwnProperty('kr-endpoint')) {
        // Only possible to configure with setFormConfig
        endpointDetector.setForcedEndpoint(data['kr-endpoint'])
      }
      if ('postUrl' in data) {
        // legacy name
        data['postUrlSuccess'] = data.postUrl
        delete data['postUrl']
      }
      if ('events' in data) setupConfigurationEvents($bus, data.events)

      if (Object.keys(data).length) configurationHandler.setConfig(data)

      return _this.create200({
        config: data
      })
    }

    /**
     * @POST /paymentDone/submit
     *
     * Intercepts the POST action when submits the form
     */
    this.router.post['/paymentDone/submit'] = (route, data) => {
      if (!data.hasOwnProperty('formId')) data.formId = '*'
      let key = _.isEmpty(data.formId) ? '*' : data.formId
      interceptedForms[key] = data.callback
      return _this.create200({ message: 'ok' })
    }

    /**
     * @POST /paymentRefused/submit
     *
     * Intercepts the POST action when submits the form for a
     * refused payment
     */
    this.router.post['/paymentRefused/submit'] = (route, data) => {
      if (!data.hasOwnProperty('formId')) data.formId = '*'
      let key = _.isEmpty(data.formId) ? '*' : data.formId
      interceptedFormsRefused[key] = data.callback
      return _this.create200({ message: 'ok' })
    }

    /**
     * Retro-compatibility hook. On the old client: 3.0, if the
     * KR_PAYMENT_HOOK was defined on the window then it simulates the
     * /paymentDone/submit to be able to keep consistency on the old
     * functionality.
     */
    if (
      typeof (
        window.KR_PAYMENT_HOOK && _.isFunction(window.KR_PAYMENT_HOOK)
      ) !== 'undefined'
    ) {
      interceptedForms['*'] = window.KR_PAYMENT_HOOK
    }

    /**
     * @GET /interceptedForms
     *
     * Gets intercepted forms
     */
    this.router.get['/interceptedSuccessForms'] = (route, data) => {
      return _this.create200({ interceptedForms })
    }

    /**
     * @GET /interceptedRefusedForms
     *
     * Gets intercepted forms for refused payments
     */
    this.router.get['/interceptedRefusedForms'] = (route, data) => {
      return _this.create200({
        interceptedForms: interceptedFormsRefused
      })
    }

    /**
     * Add a callback to one event
     *
     * @POST /event/listen
     */
    this.router.post['/event/listen'] = (route, data) => {
      let listenerCallback = data.callback
      let eventName = data.event

      $bus.$on(eventName, data => {
        listenerCallback(eventName, data)
      })
      return _this.create200({ success: 'ok' })
    }

    /**
     * Emit an event
     *
     * @POST /event/emit
     */
    this.router.post['/event/emit'] = (route, data) => {
      let eventData = data.data
      let eventName = data.event

      $bus.$emit(eventName, eventData)
      return _this.create200({ success: 'ok' })
    }

    return Promise.resolve()
  }
}
