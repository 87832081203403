<template lang="pug">
    .kr-visibility-button(:class="{'kr-visible':hasContent}", v-if="fieldName === 'securityCode'", v-show="hasContent", v-html="iconSVG", @click.stop="toggle", aria-hidden="true")
</template>

<script>
import _ from 'underscore'
import { dynamicMapState } from '@/common/util/store'

import Events from '@/configuration/Events'
import { loadAssets } from '@/common/loader/assets'

export default {
  name: 'KryptonVisibilityButton',
  inject: ['context'],
  props: {
    fieldName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hasContent: false,
      iconSVG: ''
    }
  },
  computed: {
    ...dynamicMapState('context.namespace', ['securedCvv'])
  },
  watch: {
    async securedCvv() {
      await this.setIcon()
    }
  },
  async created() {
    this.startSubscriptions()
    await this.setIcon()
  },
  methods: {
    startSubscriptions() {
      this.$busOn(Events.krypton.field.length, message => {
        if (
          this.context.formId === message.formId &&
          this.fieldName === message.name
        )
          this.hasContent = message.length > 0
      })
    },
    toggle() {
      this.$store.dispatch(`${this.context.namespace}/update`, {
        securedCvv: !this.securedCvv
      })
    },
    async setIcon() {
      const assets = await loadAssets()
      this.iconSVG = assets.visibility[this.securedCvv ? 'on' : 'off']
    }
  }
}
</script>
