import appLoader from '@/common/loader/app'

/** COMPONENTS */
import GhostApp from '@/ghost/GhostApp'

export default locator => {
  return appLoader(locator, 'GhostApp', {
    el: '#appGhost',
    components: { GhostApp }
  })
}
