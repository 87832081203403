<template lang="pug">
extends ../../controls/templates/FormError.pug
</template>

<script>
import FormError from '@/host/components/mixins/FormError'
import { MethodDescriptor } from '@/common/model/PaymentMethod'

export default {
  name: 'SmartFormPartialPaymentError',
  mixins: [FormError],
  computed: {
    extraClasses() {
      return {
        'kr-smart-form-partial-payment-error': true
      }
    },
    showFormError() {
      return this.hasData && this.isPartialPaymentError
    },
    // Check if the error is a partial payment relate issue
    isPartialPaymentError() {
      const method = this.error?.paymentMethod
      if (!method) return false
      const { metadata, name } = MethodDescriptor.fromString(method)
      return name === 'PARTIAL_TRANSACTION' && metadata
    }
  },
  methods: {}
}
</script>
