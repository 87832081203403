/**
 * Mixin used by all the components of the hostapp
 *
 * Components in Host must use the $busOn method to add listeners to events
 * On component destroy they will be automatically cleared
 */
export default Vue => {
  Vue.mixin({
    destroyed() {
      if (this.$locator) {
        const $bus = this.$locator.busListener
        $bus.$offComponent(this.$componentId)
      }
    },
    methods: {
      $busOn(event, cb, options = {}) {
        const $bus = this.$locator.busListener
        $bus.$on(event, cb, this.$componentId, options)
      },
      $busOnce(event, cb, options = {}) {
        const $bus = this.$locator.busListener
        $bus.$once(event, cb, this.$componentId, options)
      }
    }
  })
}
