import { isString } from 'underscore'
import { setSentryExtra } from '@/common/util/sentry'

export const getUrlParam = (url, param) => {
  if (!url) return null
  const regexp = url.match(/([^?=&]+)(=([^&]*))/g)
  if (!regexp) return null
  const params = regexp.reduce((a, v) => {
    return (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
  }, {})
  return params[param]
}

export const getHost = url => {
  const matches = url.match(
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/g
  )
  if (matches.length !== 1) return null
  return matches[0]
}

export const getDomainName = url => {
  return getHost(url).replace(/http:\/\/|https:\/\//g, '')
}

export const isLoadedLocally = URL => {
  return URL.indexOf('file://') === 0
}

export const readHash = () => {
  const hash = window.location.hash
  if (hash) {
    let hashInfo = hash.substring(1)
    hashInfo = decodeURIComponent(hashInfo)
    setSentryExtra('hash', hashInfo)
    window.FIELD_CONFIGURATION = JSON.parse(hashInfo)
  }
}

export const isValidUrl = url => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

export const isValidPath = path => {
  if (!isString(path)) return false
  return /^(\/[^\/]+){0,}\/?$/.test(path)
}

export const getValidUrl = url => {
  if (isValidUrl(url)) return new URL(url).href
  if (isValidPath(url)) return new URL(url, location.origin).href
  return null
}
