<template lang="pug">
  button.kr-method-btn.kr-method-btn--cards(
    type="button"
    @click="openMethod()"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="dynamicClasses"
    :style="styles.button"
    kr-payment-method="cards"
  )
    SmartFormRadioButton(v-if="hasRadioButton", :checked="isSelected", :hover="hover")
    SmartFormCardIcons(:margin="10" :brands="cardBrands")
    KryptonLoadingAnimation(v-if="isLoading")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SmartFormCardIcons from '@/host/components/smartform/CardIcons'
import MethodButtonMixin from '@/host/components/mixins/MethodButton'

export default {
  name: 'SmartFormGridCardsButton',
  components: {
    SmartFormCardIcons
  },
  mixins: [MethodButtonMixin],
  data() {
    return {
      method: 'CARDS',
      hover: false
    }
  },
  computed: {
    ...mapGetters([
      'isAnyCardFormDisabled',
      'isAnyTokenActive',
      'translate',
      'isSelectedMethod'
    ]),
    ...mapState(['allIFramesReady', 'disabledForm']),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod,
      activeMethodMetadata: state => state.smartForm.activeMethodMetadata,
      errorCode: state => state.error.errorCode,
      cardBrands: state => state.smartForm.cardBrands
    }),
    isSelected() {
      return this.isSelectedMethod(this.method)
    },
    isDisabled() {
      return (
        !this.allIFramesReady ||
        this.locked ||
        this.disabledForm ||
        this.isAnyCardFormDisabled ||
        this.cardBrands.length === 0
      )
    },
    /**
     * isAnyTokenActive: when wallet is active a tokenized card is active,
     * CARDS is the activeMethod however this component is reserved for
     * new cards.
     */
    isLoading() {
      return (
        !this.isAnyTokenActive &&
        !this.errorCode &&
        this.activeMethod === this.method &&
        !this.activeMethodMetadata // if metadata has any content it must come from wallet or branded smartbutton
      )
    }
  },
  methods: {
    async openMethod() {
      if (this.isDisabled || this.isLoading) return

      if (await this.interruptsExecution(this.method, 'openPopin')) return

      await this.$nextTick()
      this.$emit('selected', this.method)
    }
  }
}
</script>
