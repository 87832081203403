<template lang="pug">
.kr-layer-manager(kr-detachment-area, ref="content")
</template>

<script>
import { each } from 'underscore'
import DetachableMixin from '@/host/components/mixins/Detachable'

/**
 * This components is used as the default detachment-area to receive detachable
 * components' content and handle z-indexes to setup priority levels.
 *
 * Note: it observe node change via MutationObserver class which has been
 * supported for a while by browser. See browser compatibility table:
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver#browser_compatibility
 *
 * @since KJS-2162 (2022-09-15)
 */

export default {
  name: 'KryptonLayerManager',
  mixins: [DetachableMixin],
  props: {
    zReference: {
      type: [Number, String],
      default: 10
    }
  },
  data() {
    return {
      observer: null
    }
  },
  computed: {
    normalizedZReference() {
      return typeof this.zReference === 'string'
        ? parseInt(this.zReference)
        : this.zReference
    }
  },
  watch: {
    zReference() {
      this.updateChildrenStyles()
    }
  },
  mounted() {
    this.updateChildrenStyles()
    this.setObserver()
  },
  beforeDestroy() {
    this.observer.disconnect()
  },
  methods: {
    setObserver() {
      const config = {
        attributes: false,
        childList: true,
        subtree: false
      }
      this.observer = new MutationObserver(mutationList => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            this.updateChildrenStyles()
          }
        }
      })
      this.observer.observe(this.$el, config)
    },
    updateChildrenStyles() {
      each(this.$el.childNodes, (childNode, idx) => {
        childNode.style.zIndex = this.normalizedZReference + idx
      })
    }
  }
}
</script>
