import PaymentProcess from '@/ghost/workflow/payment/state/process/PaymentProcess'
import { logSentry, setSentryTag } from '@/common/util/sentry'

export default class SimpleProcess extends PaymentProcess {
  async process(values, procResp = null) {
    // Prepare the request object
    await this.buildRequest(values)
    // First PP call
    let response = await this.callProcessPayment(values)
    this.context.ecsPaymentId = response.headers['ecspaymentid']

    // Add ecsPaymentId to sentry tag
    setSentryTag('ecsPaymentId', response.headers['ecspaymentid'])

    if (!response.headers['ecspaymentid']) {
      logSentry('Payment', 'v2 ecsPaymentId null')
    }

    // Check if the popup should be open
    await this.checkResponse(response)

    // Check which kind of response it is
    procResp = await this.processResponse(response)

    return [response, procResp]
  }

  buildRequest(values) {
    values.challengePreference = 'NO_PREFERENCE'
  }
}
