import Rule from './Rule';

/**
 * Replace Rule.
 */
export default class ReplaceRule extends Rule {
    /**
     * Constructor.
     * @param definition the ruleDefinition
     */
    constructor(definition) {
        super(definition);
        this.replacement = definition.replacement;
    }

    /**
     * Apply the rule to the string.
     * @param stringToProcess the string to process
     * @returns {*}
     */
    applyPattern(stringToProcess) {
        return stringToProcess.replace(this.pattern, this.replacement);
    }

    /**
     * Apply the rule to the jsonValue.
     * @param jsonValue the jsonValue
     * @returns {*}
     */
    applyJsonPath(jsonValue) {
        return jsonValue.replace(jsonValue, this.replacement);
    }
}
