<template lang="pug">
.kr-method-content(:class='dynamicClasses')
  SmartFormModalBackButton(
    v-if="renderBackButton",
    @click="goBack",
    :label="methodLabel",
    :disabled="!isBackButtonEnabled",
    :visible="visible"
  )
  slot
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import Events from '@/configuration/Events'

import SmartFormModalBackButton from '@/host/components/smartform/ModalBackButton'

export default {
  name: 'SmartFormMethodContent',
  components: {
    SmartFormModalBackButton
  },
  props: {
    method: { type: String, default: '' },
    backEnabled: { type: Boolean, default: true },
    visible: { type: Boolean, default: true }
  },
  data() {
    return {
      leftArrow: PreloadedAssets.arrows.left,
      icon: null,
      redirectionBack: true
    }
  },
  computed: {
    ...mapState({
      currentFocus: state => state.navigation.currentFocus,
      hidden: state => state.smartForm.hidden
    }),
    ...mapState([
      'redirectIframeOpen',
      'redirectTransaction',
      'redirecting',
      'hasIframeCloseButton'
    ]),
    ...mapGetters([
      'translate',
      'isSmartFormPopin',
      'isSmartFormOpen',
      'isGroupActive',
      'isExactSmartButtonActive',
      'hasSeveralPaymentMethods',
      'getPaymentMethodLabel',
      'hasDeadEndPaymentMethod'
    ]),
    /**
     * The back button is rendered but also grayed out and cannot be clicked.
     */
    isBackButtonEnabled() {
      return (
        this.backEnabled &&
        (this.isSmartFormPopin || this.isGroupActive) &&
        this.isSmartFormOpen &&
        this.hasSeveralPaymentMethods &&
        !this.hidden &&
        !this.isBackButtonHidden &&
        this.redirectionBack
      )
    },
    /**
     * The back button is rendered but hidden by vertical translation.
     */
    isBackButtonHidden() {
      return !!this.redirectTransaction
    },
    renderBackButton() {
      // return true
      return (
        this.hasSeveralPaymentMethods &&
        !this.hidden &&
        !this.hasDeadEndPaymentMethod() &&
        !this.isExactSmartButtonActive
      )
    },
    dynamicClasses() {
      return {
        'kr-disabled-back-button':
          !this.renderBackButton || !this.isBackButtonEnabled,
        'kr-secure-iframe-visible': this.redirectIframeOpen,
        'kr-method-content--invisible': !this.visible
      }
    },
    methodLabel() {
      return this.getPaymentMethodLabel(this.method)
    }
  },
  watch: {
    redirecting(val) {
      this.redirectionBack = !val
    }
  },
  created() {
    this.$busOn(Events.krypton.message.popinLoad, msg => {
      this.onLoad()
    })
  },
  methods: {
    ...mapActions(['cleanError']),
    goBack() {
      if (!this.isBackButtonEnabled) return
      this.cleanError()
      this.$emit('back')
    },
    onLoad() {
      this.redirectionBack = this.hasIframeCloseButton
    }
  }
}
</script>
