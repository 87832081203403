export default ({ logger }) => {
  return store => {
    store.subscribe(({ type, payload }, state) => {
      if (payload?.redirecting) {
        store.dispatch('redirection')
      } else if (payload?.redirecting === false) {
        logger.log('info', 'Stop redirection')
        store.dispatch('finishRedirection')
        store.dispatch('redirection', false)
      }
    })
  }
}
