var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isRendered
    ? _c(
        "div",
        { staticClass: "kr-card-form", class: _vm.dynamicClasses },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }