<template lang="pug">
  .kr-loading-overlay(v-show="displayOverlay", :class="dynamicClasses.wrapper")
    .kr-overlay-background
    img.kr-overlay-logo(v-if="renderLogo && showOverlayMessage" :class="dynamicClasses.logo" :src="logoSrc")
    span.kr-overlay-message(v-if="showOverlayMessage") {{ message }}
    KryptonLoadingAnimation(v-if="showOverlayMessage")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import KryptonLoadingAnimation from '@/host/components/controls/KryptonLoadingAnimation'

export default {
  name: 'SmartFormLoadingOverlay',
  components: {
    KryptonLoadingAnimation
  },
  props: {
    /**
     * Since the overlay is also used as a popin background, use an specific one
     * for the case we need it above (popin + window redirection)
     */
    onlyWindowRedirection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logo: { name: '', file: '' },
      visible: false,
      displayed: false
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'isPopupRedirectionMethodActive',
      'isSmartFormOpen',
      'showOverlay',
      'showOverlayMessage'
    ]),
    ...mapState(['baseAddress', 'redirectPopupOpen']),
    ...mapState({
      visibility: state => state.form.smartform.overlay.logo.visibility,
      logoFile: state => state.form.smartform.overlay.logo.file || '',
      isAmountNull: ({ amount }) => amount === 0
    }),
    dynamicClasses() {
      const classes = {
        wrapper: {
          'kr-loading-overlay--visible': this.visible
        },
        logo: {}
      }
      if (this.renderLogo) {
        classes.logo[`kr-logo-${this.logo.name}`] = true
      }
      return classes
    },
    renderLogo() {
      return this.visibility && !!this.logo.name
    },
    logoSrc() {
      return `${this.baseAddress}/logos/overlay/${this.logo.file}`
    },
    message() {
      return this.redirectPopupOpen || this.isPopupRedirectionMethodActive
        ? this.translate('popup_click_to_go')
        : this.translate(
            this.isAmountNull ? 'registering' : 'processing_payment'
          )
    },
    displayOverlay() {
      if (!this.onlyWindowRedirection) return this.displayed
      return this.displayed && this.redirectPopupOpen && this.isSmartFormOpen
    }
  },
  watch: {
    logoFile: 'setLogo',
    showOverlay(val) {
      if (val) {
        this.displayed = true
      } else {
        // Wait 100ms for animation, but after 100ms the value must
        // remain false in case it changes many times quick and unvoid
        // unnecessary visual update
        setTimeout(() => {
          if (!this.showOverlay) {
            this.displayed = val
          }
        }, 100)
      }
      // Needs the next tick to avoid a bug with the transition
      this.$nextTick(() => {
        this.visible = val
      })
    }
  },
  mounted() {
    this.setLogo()
  },
  methods: {
    setLogo(logo = { file: '' }) {
      // If the file is not forced, use the config
      this.logo.file = logo.file
      if (!this.logo.file) this.logo.file = this.logoFile

      // If there is an image defined for this prefix, set name
      this.logo.name = this.logo.file
        ? this.logo.file
            .substring(0, this.logo.file.lastIndexOf('.'))
            .toLowerCase()
        : ''
    }
  }
}
</script>
