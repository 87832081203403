import regeneratorRuntime from 'regenerator-runtime' // NECESSARY to use async
import { noop } from 'underscore'
import Zepto from 'zepto-webpack'
import Vue from 'vue'
import Vuex from 'vuex'

// Host Vue components
import { checkLibraries } from '@/host/detectors/Library'
import WebpackPublicPathDetector from '@/host/WebpackPublicPathDetector'

// Utils
import { setupSentry, setDeviceData } from '@/common/util/sentry'
import { readHash } from '@/common/util/url'
import { checkHighConflict } from '@/common/util/check'

// Store Synchronizer
import Synchronizer from '@/common/comm/Synchronizer'

// Registry
import Registry from '@/Registry'

// Mixins
import LocatorMixin from '@/common/mixins/LocatorMixin'
import BusMixin from '@/common/mixins/BusMixin'
import ComponentIdMixin from '@/common/mixins/ComponentIdMixin'
import HostListenerMixin from '@/common/mixins/HostListenerMixin'
import FiltersMixin from '@/common/mixins/FiltersMixin'

// Loaders
import hostLoader from '@/host/Loader'
import ghostLoader from '@/ghost/Loader'
import slaveLoader from '@/slave/Loader'

import { KR } from '@/host/KR'

// Disables Vuejs messages
Vue.config.productionTip = false
Vue.config.devtools = !~process.env.NODE_ENV.indexOf('production')

window.kr_loader = {
  registeredBootstraps: [
    typeof window.KR_THEME_HOOK != 'undefined' ? window.KR_THEME_HOOK : noop
  ],
  bootstrap: callback => {
    window.kr_loader.registeredBootstraps.push(callback)
    if (window.KR) callback()
  }
}

// Bus creation
const bus = new Vue()

const loaders = {
  host: hostLoader,
  ghost: ghostLoader,
  slave: slaveLoader,
  redirect: () => {}
}

// App factory
let applicationFactory = async () => {
  const state = checkHighConflict()
  if (state.conflict === true) {
    alert(state.message)
    console.error(state.message)
    return false
  }

  // Compatibility between KR_THEME_CONFIGURATION and KR_CONFIGURATION
  if (
    window.hasOwnProperty('KR_THEME_CONFIGURATION') &&
    !window.hasOwnProperty('KR_CONFIGURATION')
  ) {
    window.KR_CONFIGURATION = window.KR_THEME_CONFIGURATION
    delete window.KR_THEME_CONFIGURATION
  }

  // Detect the current application
  let currentApplication = 'host'
  if (document.getElementById('slave-app')) currentApplication = 'slave'
  if (document.getElementById('appGhost')) currentApplication = 'ghost'
  if (document.getElementById('appRedirect')) currentApplication = 'redirect'

  __webpack_public_path__ = WebpackPublicPathDetector()

  // Sentry initialization
  setupSentry(currentApplication)

  // For done and poster, we only need sentry
  if (document.getElementById('only-sentry')) return

  // Read iframe hash and setup FIELD_CONFIGURATION
  if (currentApplication !== 'host') readHash()

  // Start vuex
  Vue.use(Vuex)

  // Registering common services
  const locator = await Registry(currentApplication, true, bus)
  const store = locator.$store

  // Setup device data for sentry
  setDeviceData(store)

  if (currentApplication === 'host') {
    // Mixin setup to create a unique ID for each component (For the moment only used by hostapp components)
    ComponentIdMixin(Vue)
    // Mixin to store and clear event listeners on Krypton.destroy or component destroy (uses ComponentIdMixin)
    HostListenerMixin(Vue)
  }

  // Bus mixin
  BusMixin(Vue, bus)
  locator.$bus = bus

  // Start the synchronizer
  locator.synchronizer = new Synchronizer(locator, currentApplication)

  // Inject locator on all instances (as this.$locator)
  LocatorMixin(Vue)

  // Common filters
  FiltersMixin(Vue)

  // Create app
  loaders[currentApplication](locator)
}

// KR instance creation
let krypton = new KR()

// Check if the library has already been loaded from another domain
if (!checkLibraries()) {
  // Expose some things
  window.__kr__$bus = bus
  window.__kr__Vue = Vue
  window.__kr__Vuex = Vuex
  window.__kr__$ = Zepto
  window.__kr__Factory = applicationFactory

  window.KR = krypton

  // Wait until page is ready to setup the app
  Zepto(document).ready(applicationFactory)
}

export default krypton
