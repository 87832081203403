import Tingle from 'tingle.js';
import Lyngle from './utils/Lyngle';
import translation from './utils/Translation.json';

/**
 * PostMessage Event type.
 *
 * @type {string}
 */
const LYRA_AUTH_INSTRUCTION_RESULT = 'LYRA_AUTH_INSTRUCTION_RESULT';

/* eslint no-underscore-dangle: 0 */

/* eslint no-console: 0 */
class LyraAuth {
    /**
     * Constructor.
     *
     * @param options
     */
    constructor(options = {}) {
        this.id = new Date().getTime();

        this.options = options;
        if (!this.options.debug) {
            this.options.debug = false;
        } else if (typeof this.options.debug !== 'boolean') {
            throw new Error("options.debug isn't a boolean");
        }
        if (!this.options.element) {
            this.options.element = document.body;
        }

        window.addEventListener(
            'message',
            (messageEvent) => {
                if (this.currentInstruction) {
                    const { data } = messageEvent;
                    try {
                        const messageData = JSON.parse(data);
                        if (messageData.eventName === LYRA_AUTH_INSTRUCTION_RESULT) {
                            try {
                                this.currentCallback(messageData.value);
                            } finally {
                                this._handleInstructionResult(messageData.value);
                            }
                        }
                    } catch (err) {
                        if (err instanceof SyntaxError) {
                            // do not send to Sentry
                        } else if ('Sentry' in window) {
                            // send to sentry if defined
                            window.Sentry.captureException(err);
                        }
                    }
                }
            },
            true
        );
    }

    /* ************************************* */
    /* ********    PUBLIC METHODS   ******** */

    /* ************************************* */

    /**
     * Delegate an Authentication Response to the lib.
     *
     * @param authenticationResponse
     * @param callback
     * @param selector
     * @param height force max possible height of iframe
     * @param isKrypton a boolean set to false but is true in the chargeAuthenticate
     */
    delegate(authenticationResponse, callback, selector = null, height = null, isKrypton = false) {
        const { protocol } = authenticationResponse;
        const instruction = authenticationResponse.value;
        this.currentProtocol = protocol;
        this.currentInstruction = instruction;
        this.currentCallback = callback;
        this.height = height;

        if (this.options.debug) {
            console.log(
                `Handle instruction ${JSON.stringify(
                    this.currentInstruction
                )} for protocol ${JSON.stringify(this.currentProtocol)} `
            );
        }
        this._addInstructionElement(selector, isKrypton);
        this._subscribeToInstructionResult();
    }

    /* ************************************* */
    /* ********   PRIVATE METHODS   ******** */

    /* ************************************* */

    /**
     * Get a unique ID for instruction elements.
     *
     * @returns {string}
     * @private
     */
    _getElementId() {
        return `LYRA_${this.currentInstruction.name}_${this.id}`;
    }

    /**
     * Check if a language is supported in the translation file
     * @param language the language
     * @returns {boolean} true iff supported
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    _isLanguageSupported(language) {
        return language && Object.keys(translation).indexOf(language) !== -1;
    }

    /**
     * Gte the language to use for translated message in the iframe. First check this.currentInstruction.language, then
     * browser language and fallback on english is necessary.
     * @returns {string|string|*}
     * @private
     */
    _getLanguage() {
        const browserLanguage = window.navigator.language || window.navigator.userLanguage;
        const defaultLanguage = browserLanguage ? browserLanguage.split('-')[0] : null;
        if (this._isLanguageSupported(this.currentInstruction.language)) {
            return this.currentInstruction.language;
        }
        if (this._isLanguageSupported(defaultLanguage)) {
            return defaultLanguage;
        }
        return 'en';
    }

    /**
     * Add an instruction element.
     *
     * @private
     */
    _addInstructionElement(selector = null, isKrypton = false) {
        const instructionDiv = document.createElement('div');
        let loaderDiv = null;
        let header = null;
        const instructionIframe = document.createElement('iframe');
        const instructionForm = document.createElement('form');
        const wrapper = document.createElement('div');
        this.showHeader =
            this.currentInstruction.target.showUrl === undefined ||
            this.currentInstruction.target.showUrl;
        this.selector = selector;

        // Create container
        instructionDiv.id = this._getElementId();
        instructionDiv.style.display = 'none';

        // Create iframe
        instructionIframe.id =
            isKrypton === true
                ? `${this._getElementId()}_kr-instruction-container`
                : `${this._getElementId()}_iframe`;
        instructionIframe.name = instructionIframe.id;
        instructionIframe.dataset.test =
            isKrypton === true ? 'KR-INSTRUCTION-CONTAINER' : 'LYRA-INSTRUCTION-IFRAME';
        instructionIframe.frameBorder = '0';
        instructionIframe.style.frameBorder = '0';

        this.instructionIframe = instructionIframe;
        this._setIframeSize();
        const _this = this;
        this.resizeHandler = function () {
            _this._setIframeSize();
        };
        window.addEventListener('resize', this.resizeHandler);

        instructionIframe.setAttribute('origin', window.origin);
        if (this.currentInstruction.target.visible) {
            loaderDiv = document.createElement('div');
            header = document.createElement('div');
            loaderDiv.classList.add('lyra_auth_loader');
            const language = this._getLanguage();
            loaderDiv.innerHTML = `
                <div class="auth-msg-wrapper">
                    <div class="auth-msg">${translation[language].authentication}</div>
                    <div class="wait-msg">${translation[language].authentication_wait}</div>
                </div>
                <div class="loading">
                    <div class="bullet"></div>
                    <div class="bullet"></div>
                    <div class="bullet"></div>
                    <div class="bullet"></div>
                </div>
            `;
            loaderDiv.style.display = 'block';

            if (this.showHeader) {
                header.classList.add('lyra_auth_header');
                header.innerHTML = `
                <input id="url" title="${this.currentInstruction.http.url}" value="${this.currentInstruction.http.url}" readonly/>
            `;
            }
        }
        instructionIframe.src = 'about:blank';

        // Create form
        instructionForm.method = this.currentInstruction.http.method;
        instructionForm.target = instructionIframe.id;
        instructionForm.action = this.currentInstruction.http.url;

        if (this.currentInstruction.http.body) {
            Object.keys(this.currentInstruction.http.body).forEach((formInputName) => {
                const formInput = document.createElement('input');
                formInput.name = formInputName;
                formInput.type = 'hidden';
                formInput.value = this.currentInstruction.http.body[formInputName];
                if (
                    this.options.removePadding &&
                    (formInputName === 'creq' ||
                        formInputName === 'threeDSSessionData' ||
                        formInputName === 'threeDSMethodData')
                ) {
                    console.log(`Processing ${formInputName}, looking for padding...`);
                    const parts = this.currentInstruction.http.body[formInputName].split('=');
                    [formInput.value] = parts;
                    if (parts.length > 1) {
                        console.log(
                            `Removed padding of ${parts.length - 1} "=" from ${formInputName}`
                        );
                    }
                }
                instructionForm.appendChild(formInput);
            });
        }

        // Add iframe & form
        if (loaderDiv) {
            wrapper.appendChild(loaderDiv);
        }
        if (header) {
            wrapper.appendChild(header);
        }
        wrapper.appendChild(instructionIframe);
        instructionDiv.appendChild(wrapper);
        instructionDiv.appendChild(instructionForm);
        this.options.element.appendChild(instructionDiv);

        // Visible? Show modal
        if (this.currentInstruction.target.visible) {
            // instanciate new modal
            /* eslint-disable-next-line */
            const onOpen = () => {
                const hideLoadingLayer = () => {
                    loaderDiv.style.display = 'none';
                };
                instructionIframe.onload = hideLoadingLayer;
                /**
                 * In some cases, if some secondary resources of the iframe are not loading (images, etc), the load event
                 * will not be triggered until the browser request of the resource times out (60sec). To mitigate this, we
                 * will hide the loading layer after 10sec. The iframe might not be fully loaded, but still usable in this
                 * cases.
                 *
                 * See: KJS-3989
                 */
                setTimeout(hideLoadingLayer, 10000);
                instructionForm.submit();
            };
            if (selector) {
                wrapper.style.display = 'flex';
                wrapper.style['flex-direction'] = 'column';
                wrapper.style.height = '100%';
                this.modal = new Lyngle({
                    selector,
                    onOpen,
                });
            } else {
                /* eslint new-cap: 0 */
                this.modal = new Tingle.modal({
                    closeMethods: [],
                    cssClass: [],
                    onOpen,
                });
                this.modal.modal.style.overflow = 'auto';
                this.modal.modalBoxContent.style.overflow = 'auto';
            }

            // Open modal & submit
            this.modal.setContent(wrapper);
            this.modal.open();

            // Hidden? Just submit form
        } else {
            instructionForm.submit();
        }
    }

    _setIframeSize() {
        if (this.currentInstruction.target.visible) {
            let { height } = this.currentInstruction.target;

            if (this.currentInstruction.target.fullscreen) {
                this.instructionIframe.style.width = `100vw`;
                height = window.innerHeight;
            } else {
                this.instructionIframe.style.width = `${Math.min(
                    window.innerWidth,
                    this.currentInstruction.target.width
                )}px`;
            }
            if (typeof this.height === 'string' && this.height.endsWith('%')) {
                this.instructionIframe.style.height = '100%';
            } else {
                let maxPossibleHeight = this.height ? this.height : window.innerHeight;
                if (this.selector) {
                    if (this.currentInstruction.target.fullscreen) maxPossibleHeight -= 67;
                } else if (this.showHeader) maxPossibleHeight -= this._em2Px() * 3;
                this.instructionIframe.style.height = `${Math.min(height, maxPossibleHeight)}px`;
            }
        } else {
            this.instructionIframe.style.width = '0px';
            this.instructionIframe.style.height = '0px';
        }
    }

    _handleInstructionResult(message) {
        this._removeInstructionElement(message);
        this.currentInstruction = null;
        this.currentProtocol = null;
        this.currentCallback = null;
    }

    /**
     * Remove an instruction element.
     *
     * @param message
     * @private
     */
    _removeInstructionElement(message) {
        if (this.options.debug) {
            console.log(`Instruction result received ${JSON.stringify(message)}`);
        }

        // Remove resize listener
        window.removeEventListener('resize', this.resizeHandler);

        // Remove timeout if exists
        clearTimeout(this.instructionTimeout);

        // Remove modal if exists
        if (this.currentInstruction.target.visible) {
            this.modal.destroy();
        }

        const instructionElement = document.getElementById(this._getElementId());
        if (instructionElement.parentNode !== null) {
            instructionElement.parentNode.removeChild(instructionElement);
        }
    }

    /**
     * Subscribe to an instruction result event.
     *
     * @private
     */
    _subscribeToInstructionResult() {
        if (this.currentInstruction.timeout) {
            this._setInstructionTimeout(this.currentInstruction.name);
        }
    }

    /**
     * Set instruction timeout.
     *
     * @private
     */
    _setInstructionTimeout(instructionName) {
        this.instructionTimeout = setTimeout(() => {
            const instructionResultTimeout = {
                eventName: 'LYRA_AUTH_INSTRUCTION_RESULT',
                value: {
                    name: instructionName,
                    value: 'TIMEOUT',
                    protocol: this.currentProtocol,
                },
            };
            window.postMessage(JSON.stringify(instructionResultTimeout), '*');
        }, this.currentInstruction.timeout * 1000);
    }

    /**
     * Converts an <code>em</code> into a <code>px</code>
     * @returns {number}
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    _em2Px() {
        return parseFloat(getComputedStyle(document.getElementsByTagName('body')[0]).fontSize);
    }
}

/* ************************************* */
/* ********      EXPORTS        ******** */
/* ************************************* */
export default LyraAuth;
