import { get } from 'underscore'

const convertStringToResolver = path => {
  return function () {
    return get(this, path.split('.'))
  }
}

export const dynamicMapState = (resolver, properties) => {
  if (typeof resolver === 'string') {
    resolver = convertStringToResolver(resolver)
  }
  const map = {}
  if (Array.isArray(properties)) {
    for (const name of properties) {
      map[name] = function () {
        const namespace = resolver.call(this)
        if (namespace && this.$store.hasModule(namespace)) {
          return this.$store.state[namespace][name]
        }
        return undefined
      }
      map[name].vuex = true
    }
  } else {
    for (const name in properties) {
      map[name] = function () {
        const namespace = resolver.call(this)
        if (namespace && this.$store.hasModule(namespace)) {
          return properties[name](this.$store.state[namespace])
        }
        return properties[name](undefined)
      }
      map[name].vuex = true
    }
  }
  return map
}

export const dynamicMapGetters = (resolver, getterNames) => {
  if (typeof resolver === 'string') {
    resolver = convertStringToResolver(resolver)
  }
  const map = {}
  if (Array.isArray(getterNames)) {
    for (const name of getterNames) {
      map[name] = function () {
        const namespace = resolver.call(this)
        if (namespace && this.$store.hasModule(namespace)) {
          return this.$store.getters[`${namespace}/${name}`]
        }
        return undefined
      }
      map[name].vuex = true
    }
  } else {
    for (const name in getterNames) {
      map[name] = function () {
        const namespace = resolver.call(this)
        if (namespace && this.$store.hasModule(namespace)) {
          return this.$store.getters[`${namespace}/${getterNames[name]}`]
        }
        return undefined
      }
      map[name].vuex = true
    }
  }
  return map
}

export const dynamicMapActions = (resolver, actionNames) => {
  if (typeof resolver === 'string') {
    resolver = convertStringToResolver(resolver)
  }
  const map = {}
  if (Array.isArray(actionNames)) {
    for (const name of actionNames) {
      map[name] = function (...args) {
        const namespace = resolver.call(this)
        if (namespace && this.$store.hasModule(namespace)) {
          return this.$store.dispatch(`${namespace}/${name}`, ...args)
        }
        return undefined
      }
      map[name].vuex = true
    }
  } else {
    for (const name in actionNames) {
      map[name] = function (...args) {
        const namespace = resolver.call(this)
        if (namespace && this.$store.hasModule(namespace)) {
          return this.$store.dispatch(
            `${namespace}/${actionNames[name]}`,
            ...args
          )
        }
        return undefined
      }
      map[name].vuex = true
    }
  }
  return map
}
