import whiteLabelMap from '@/configuration/sources/WhiteLabelsConfig.yml'
import { setValueToPath } from '@/common/util/object'
import { has, extend as shallowExtend } from 'underscore'
import extend from 'deep-extend'

export default store => {
  // Propagate whitelabel data
  store.subscribe(mutation => {
    if (mutation.payload?.formToken) {
      const { formToken } = mutation.payload
      let prefix = formToken.substring(0, 2)

      // Fix: If the prefix starts with a zero, cut it
      if (prefix.length === 2 && prefix.indexOf('0') === 0) {
        prefix = prefix.substr(1)
      }

      let config = {
        form: {
          popin: {
            footer: {
              logo: {
                default: (whiteLabelMap[prefix] || whiteLabelMap.default).logo
              }
            }
          },
          smartform: {
            overlay: {
              logo: {
                file:
                  (whiteLabelMap[prefix] || whiteLabelMap.default).logoLight ||
                  null
              }
            }
          }
        }
      }

      // If a config parameter is not define, use default
      const whitelabelConfig = Object.create(whiteLabelMap.default.config)
      if (whiteLabelMap[prefix]?.config) {
        shallowExtend(whitelabelConfig, whiteLabelMap[prefix].config)
      }
      for (const property in whitelabelConfig) {
        config = setValueToPath(config, property, whitelabelConfig[property])
      }

      if (has(window, 'KR_CONFIGURATION'))
        window.KR_CONFIGURATION = extend(window.KR_CONFIGURATION, config)
      store.dispatch('update', config)
    }
  })
}
