var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-extras-form-content kr-embedded", attrs: { krvue: "" } },
    [
      _vm._l(_vm.dna, function (conf, fieldName) {
        return _c(_vm.getComponentName(fieldName), {
          key: fieldName,
          tag: "div",
          attrs: { "kr-resource": "" },
        })
      }),
      _c("ExtrasFormError"),
      _c("ExtrasPaymentButton"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }