var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "kr-field-element",
      class: _vm.dynamicClasses.element,
      attrs: { "kr-resource": "" },
    },
    [
      _c(
        "div",
        {
          staticClass: "kr-field-wrapper",
          class: _vm.dynamicClasses.wrapper,
          attrs: { "kr-resource": "" },
        },
        [
          _c("krypton-visibility-button", {
            attrs: { "data-name": _vm.fieldName, "field-name": _vm.fieldName },
          }),
          _vm.activeToken && !_vm.isTokenForced
            ? _c("krypton-delete-button", {
                attrs: {
                  location: _vm.fieldName,
                  method: "CARDS:" + _vm.selectedBrand,
                  token: _vm.activeToken,
                },
              })
            : _vm._e(),
          _c("krypton-help-button", {
            attrs: { "data-name": _vm.fieldName, "field-name": _vm.fieldName },
          }),
          _c("krypton-icon", {
            attrs: { "data-name": _vm.fieldName, "field-name": _vm.fieldName },
          }),
          _c("krypton-label", {
            attrs: { "data-name": _vm.fieldName, "field-name": _vm.fieldName },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isVisible,
                  expression: "isVisible",
                },
              ],
              staticClass: "kr-field-component",
              class: _vm.dynamicClasses.component,
              style: _vm.configStyles,
              attrs: { "data-field-name": _vm.fieldName },
            },
            [
              _c(
                "div",
                {
                  staticClass: "kr-iframe-wrapper",
                  staticStyle: {
                    display: "block",
                    width: "100%",
                    height: "100%",
                  },
                },
                [
                  _c(
                    "div",
                    { ref: "slot-wrapper", staticStyle: { display: "none" } },
                    [_vm._t("default")],
                    2
                  ),
                  _vm.settedAtributes
                    ? _c(
                        "iframe",
                        _vm._b({}, "iframe", _vm.iframeAttributes, false)
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }