import _ from 'underscore'

export default {
  run(scriptSrc) {
    if (_.isEmpty(scriptSrc)) return null

    //Remove the ? information after the script
    let cleanScript = scriptSrc.split('?')[0]

    //Remove the # hash information after the script
    cleanScript = cleanScript.split('#')[0]

    return cleanScript
  }
}
