var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "kr-skeleton-wrapper" }, [
    _c("div", {
      staticClass: "kr-skeleton kr-smart-button",
      class: _vm.dynamicClasses,
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }