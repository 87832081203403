import { formatLongExpiryDate } from '@/slave/util/format'

export const AutofillMixin = {
  methods: {
    onAutofill({ fieldName, value }) {
      if (fieldName === 'expiryDate') {
        let spltVal = value.split('/')
        // iOS 12 + Russian keyboard - It uses '.' instead of '/'
        if (spltVal.length === 1) spltVal = spltVal[0].split('.')
        if (spltVal[0].length === 1) spltVal[0] = `0${spltVal[0]}`
        value = spltVal.join('/')
        // Chrome format (MM/YYYY)
        value = formatLongExpiryDate(value)
      }

      this.propagateAutofill(fieldName, value)
    },
    propagateAutofill(fieldName, value) {
      Zepto(this.$refs.input).trigger('blur', ['autofill'])
      this.hasError = false
      // Notify the ghost
      window.AUTOFILL({ fieldName, value })
    }
  }
}
