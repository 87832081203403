import { mapState } from 'vuex'
import PaymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

/**
 * Mixin to manage the grid configuration in the components that require it
 */
export default {
  data() {
    return {
      radioBtnWidth: 0
    }
  },
  computed: {
    ...mapState({
      paymentMethodGroups: state => state.smartForm.paymentMethodGroups,
      labelVisibility: ({ smartForm }) => {
        const { labelVisibility } = smartForm.otherPaymentMethods
        const validValues = ['show', 'hide', 'auto']

        if (!validValues.includes(labelVisibility)) {
          return 'show'
        }
        return labelVisibility
      },
      configCols: ({ smartForm }) => {
        const columns = smartForm.otherPaymentMethods.grid.columns
        if (columns === 'max') return columns
        return parseInt(columns) || 1
      },
      configJustify: ({ smartForm }) =>
        smartForm.otherPaymentMethods.grid.justify,
      configColMinWidth({ smartForm }) {
        const minWidth =
          parseInt(smartForm.otherPaymentMethods.grid.button.minWidth) || 100

        return minWidth + this.radioBtnWidth
      },
      configButtonPaddingRight: ({ smartForm }) => {
        const { paddingRight } = smartForm.otherPaymentMethods.grid.button
        return parseInt(paddingRight) || 0
      },
      configButtonSPBThresholdSmall: ({ smartForm }) => {
        const { threshold } =
          smartForm.otherPaymentMethods.grid.button.$singlePaymentButton
        return parseInt(threshold.small) || 0
      }
    }),
    columns() {
      const groupConf = PaymentMethodsConf.groups[this.group]
      if (this.group && groupConf) {
        const groupCols = groupConf.grid?.columns || this.configCols
        // Group config auto falls back to global config
        return groupCols === 'auto' ? this.configCols : groupCols
      }
      return this.configCols
    },
    justify() {
      const groupConf = PaymentMethodsConf.groups[this.group]
      if (this.group && groupConf) {
        return groupConf.grid?.justify ?? this.configJustify
      }
      return this.configJustify
    },
    colMinWidth() {
      const groupConf = PaymentMethodsConf.groups[this.group]
      if (this.group && groupConf) {
        return groupConf.grid?.minWidth ?? this.configColMinWidth
      }
      return this.configColMinWidth
    }
  }
}
