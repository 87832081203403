var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    staticClass: "fi-x-circle icon-close-popup",
    class: { "kr-visible": _vm.isVisible },
    style: _vm.closeStyles,
    domProps: { innerHTML: _vm._s(_vm.closeIcon) },
    on: { click: _vm.onClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }