var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.open,
          expression: "open",
        },
      ],
      staticClass: "kr-smart-form-modal-wrapper",
    },
    [
      _c(
        "div",
        {
          staticClass: "kr-smart-form-modal",
          class: _vm.dynamicClasses.modal,
          attrs: { role: "dialog" },
        },
        [
          _c("KryptonPopinHeader", {
            attrs: { "is-smart-form": true },
            on: {
              close: function ($event) {
                return _vm.closeModal()
              },
            },
          }),
          _c(
            "div",
            {
              staticClass: "kr-smart-form-modal-views-container",
              class: _vm.dynamicClasses.viewsContainer,
              style: _vm.dynamicStyles.viewsContainer,
            },
            _vm._l(_vm.$scopedSlots, function (_, name) {
              return _c(
                "SmartFormModalView",
                {
                  key: name,
                  ref: name,
                  refInFor: true,
                  class: _vm.dynamicClasses.views,
                  attrs: { name: name, active: _vm.view === name },
                },
                [_vm._t(name)],
                2
              )
            }),
            1
          ),
          _c("KryptonPopinFooter"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }