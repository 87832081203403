import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

export function initSmartformMethods() {
  const obj = {}
  paymentMethodsConf.whitelist.forEach(method => {
    obj[method] = {
      icon: null,
      label: null
    }
  })
  return obj
}

export function initPaymentMethodGroups() {
  const obj = {
    single: [] // for the list of ungrouped methods
  }

  Object.keys(paymentMethodsConf.groups).forEach(group => {
    obj[group] = []
  })

  return obj
}
