export const onPaymentTokenDeletedFormatter = paymentToken => {
  const commonResponse = {
    token: paymentToken?.token
  }
  if (paymentToken?.paymentMethod === 'CARDS') {
    return {
      ...commonResponse,
      paymentMethod: 'CARDS',
      fields: {
        partialPan: paymentToken?.fields?.pan?.value,
        expiryDate: paymentToken?.fields?.expiryDate?.value
      }
    }
  }
  if (paymentToken?.paymentMethodType?.startsWith('PAYPAL')) {
    return {
      ...commonResponse,
      paymentMethod: 'PAYPAL',
      email: paymentToken?.fields?.id?.value
    }
  }
  return commonResponse
}
