/* istanbul ignore file */
import _ from 'underscore'

/**
 * This class handles the form configuration
 */
export default class TimerManager {
  constructor({ $store }) {
    this.$store = $store
  }

  register(type, name, detail = null, hide = null) {
    const { appLoadTimeData } = this.$store.state
    if (type === 'start') {
      appLoadTimeData.push({
        name,
        detail,
        start: Date.now(),
        hide: !_.isNull(hide) ? hide : false,
        end: null,
        time: null
      })
    } else if (type === 'end') {
      for (const loadTimeItem of appLoadTimeData) {
        if (loadTimeItem.name === name) {
          loadTimeItem.end = Date.now()
          loadTimeItem.time = loadTimeItem.end - loadTimeItem.start
        }
      }
    }

    this.$store.dispatch('appLoadTimeData', appLoadTimeData)
  }
}
