var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.icons.all.length
    ? _c("div", { staticClass: "kr-card-icons" }, [
        _c("label", [
          _vm._v(
            _vm._s(
              _vm.translate(
                "smartform_no_" +
                  (_vm.brandFilter ? _vm.brandFilter.toLowerCase() + "_" : "") +
                  "card_available"
              )
            )
          ),
        ]),
      ])
    : _c(
        "div",
        {
          directives: [
            {
              name: "onresize",
              rawName: "v-onresize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          staticClass: "kr-card-icons",
          class: { "kr-single-icon": _vm.icons.all.length === 1 },
        },
        [
          _vm._l(_vm.icons.all, function (iconConf) {
            return _c("span", {
              key: iconConf.brand,
              ref: "icons",
              refInFor: true,
              class: [_vm.getDynamicClasses(iconConf)],
              domProps: { innerHTML: _vm._s(iconConf.icon) },
            })
          }),
          _c(
            "SmartFormMethodTooltip",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasOutstandingIcons,
                  expression: "hasOutstandingIcons",
                },
              ],
              ref: "tooltip",
              staticClass: "kr-extra-brands",
              attrs: { label: _vm.tooltipLabel, attach: !_vm.shouldDetach },
            },
            [
              _c(
                "div",
                { staticClass: "kr-card-tooltip" },
                _vm._l(_vm.icons.outstanding, function (iconConf) {
                  return _c("span", {
                    staticClass: "kr-brand-icon kr-cards",
                    class: "kr-" + iconConf.brand,
                    domProps: { innerHTML: _vm._s(iconConf.icon) },
                  })
                }),
                0
              ),
            ]
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }