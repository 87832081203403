import Rule from './Rule';
import MultiRegexp from '../util/MultiRegExp';

/**
 * Mask Rule.
 */
export default class MaskRule extends Rule {
    /**
     * Constructor.
     * @param definition the ruleDefinition
     */
    constructor(definition) {
        super(definition);
        this.mask = new MultiRegexp(definition.mask);
        this.maskChar = definition.maskChar;
    }

    /**
     * Apply the rule to the string.
     * @param stringToProcess the string to process
     * @returns {*}
     */
    applyPattern(stringToProcess) {
        let textToReturn = stringToProcess;

        let match = this.pattern.exec(stringToProcess);
        while (match !== null) {
            const matchingString = match[0];
            const maskedString = this.maskString(matchingString);
            textToReturn = textToReturn.replace(matchingString, maskedString);

            // Look for next match
            match = this.pattern.exec(stringToProcess);
        }

        return textToReturn;
    }

    /**
     * Apply the rule to the string.
     * @param jsonValue the jsonValue
     * @returns {*}
     */
    applyJsonPath(jsonValue) {
        return this.maskString(jsonValue);
    }

    /**
     * Apply a a mask to a string.
     * @param stringToMask the string to mask
     * @returns {string} masked string
     */
    maskString(stringToMask) {
        const toBeMasked = this.mask.exec(stringToMask);
        const textToReturnBefore = stringToMask.substring(0, toBeMasked['0'].index);
        const textToReturnMiddle = ''.padStart(toBeMasked['0'].text.length, '*');
        const textToReturnAfter = stringToMask.substring(toBeMasked['0'].index + toBeMasked['0'].text.length, stringToMask.length);
        return `${textToReturnBefore}${textToReturnMiddle}${textToReturnAfter}`;
    }
}
