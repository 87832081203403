var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "kr-field-element",
      class: _vm.dynamicClasses.element,
    },
    [
      _c("krypton-label", {
        attrs: {
          "data-name": _vm.fieldName,
          "field-name": _vm.fieldName,
          "available-options": _vm.availableOptions,
        },
      }),
      _vm.isDesktop
        ? _c(
            "div",
            {
              staticClass:
                "kr-field-wrapper kr-select-wrapper kr-custom-select",
              class: [
                _vm.wrapperClassName,
                _vm.errorAnimation ? "kr-error-animation" : "",
                _vm.isReadOnly ? "kr-read-only" : "",
              ],
              attrs: { "data-field-name": _vm.fieldName },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.closeSelect,
                      expression: "closeSelect",
                    },
                  ],
                  ref: "select",
                  staticClass: "kr-select",
                  attrs: { tabindex: _vm.isReadOnly ? -1 : 1 },
                  on: {
                    focus: _vm.onFocus,
                    blur: _vm.onBlur,
                    click: _vm.toggleSelect,
                    keyup: function ($event) {
                      if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                        return null
                      }
                      return _vm.tabReport($event)
                    },
                    keydown: function ($event) {
                      if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                        return null
                      }
                      return _vm.tabReport($event)
                    },
                  },
                },
                [
                  _c("span", {
                    staticClass: "kr-selected-option",
                    class: [
                      _vm.isReadOnly || !_vm.selectedOption
                        ? "kr-placeholder"
                        : "",
                    ],
                    domProps: { textContent: _vm._s(_vm.placeholder) },
                  }),
                  _c("span", {
                    staticClass: "kr-select-caret",
                    class: [_vm.isReadOnly ? "kr-read-only" : ""],
                    domProps: { innerHTML: _vm._s(_vm.caret) },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isSelectOpen,
                      expression: "isSelectOpen",
                    },
                  ],
                  staticClass: "kr-options",
                  class: [
                    _vm.isOptionsTop ? "top" : "bottom",
                    _vm.optionsClass,
                  ],
                },
                _vm._l(_vm.availableOptions, function (option, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "kr-option",
                      class:
                        option.id == _vm.selectedOption
                          ? "kr-active-option"
                          : "",
                      attrs: {
                        value: option.id,
                        "data-test": "test-kr-option",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.selectOption(option)
                        },
                        touchstart: function ($event) {
                          return _vm.selectOption(option)
                        },
                      },
                    },
                    [_vm._v(_vm._s(option.label))]
                  )
                }),
                0
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "kr-field-wrapper kr-select-wrapper kr-real-select",
              class: [
                _vm.wrapperClassName,
                _vm.errorAnimation ? "kr-error-animation" : "",
              ],
              attrs: { "data-field-name": _vm.fieldName },
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isReadOnly,
                      expression: "!isReadOnly",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedOption,
                      expression: "selectedOption",
                    },
                  ],
                  ref: "select",
                  attrs: {
                    disabled: _vm.isReadOnly,
                    "aria-label": _vm.fieldName,
                  },
                  on: {
                    focus: _vm.onFocus,
                    blur: _vm.onBlur,
                    keyup: function ($event) {
                      if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                        return null
                      }
                      return _vm.tabReport($event)
                    },
                    keydown: function ($event) {
                      if (!$event.type.indexOf("key") && $event.keyCode !== 9) {
                        return null
                      }
                      return _vm.tabReport($event)
                    },
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedOption = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isReadOnly,
                        expression: "isReadOnly",
                      },
                    ],
                    attrs: { value: "" },
                  }),
                  _vm._l(_vm.availableOptions, function (option) {
                    return _c(
                      "option",
                      {
                        attrs: { "data-test": "test-kr-option" },
                        domProps: { value: option.id },
                      },
                      [_vm._v(_vm._s(option.label))]
                    )
                  }),
                ],
                2
              ),
              _c("span", {
                staticClass: "kr-select-caret",
                class: [_vm.isReadOnly ? "kr-read-only" : ""],
                domProps: { innerHTML: _vm._s(_vm.caret) },
              }),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isReadOnly || !_vm.selectedOption,
                    expression: "isReadOnly || !selectedOption",
                  },
                ],
                staticClass: "kr-select-placeholder",
                domProps: { textContent: _vm._s(_vm.placeholder) },
              }),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }