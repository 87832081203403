import { find, findIndex } from 'underscore'
import { FormTemplateField } from '@/common/model'

/**
 * Add a layer on top of form element to help analyse and fill in missing
 * fields at rendering.
 *
 * @since KJS-2413
 */
export default class FormTemplate {
  /**
   * @param {HTMLElement} el
   */
  constructor(el) {
    this.el = el
    this.fields = []
    this.missing = {
      fields: [],
      controls: []
    }
    this.requiredFields = FormTemplateField.getRequiredFields()
  }

  /**
   * Search for all required field and controls inside form element.
   * Sort those already provided and the missing ones.
   */
  read() {
    this.fields = []
    this.missing.fields = []
    this.missing.controls = []

    this.requiredFields.forEach(field => {
      const fieldElement = this.search(field)
      if (fieldElement) {
        field.setElement(fieldElement)
        this.insertFieldIntoList(field)
      } else if (field.isTypeControl()) {
        this.missing.controls.push(field)
      } else {
        this.missing.fields.push(field)
      }
    })
  }

  /**
   * @param {FormTemplateField} field
   * @returns {Element | null}
   */
  search(field) {
    return this.el.querySelector(field.getSelector())
  }

  /**
   * Insert/sort fields provided in form template.
   *
   * @param {FormTemplateField} field
   */
  insertFieldIntoList(field) {
    const l = this.fields.length
    let idx = 0
    while (idx < l) {
      const currentField = this.fields[idx]
      if (currentField.isPositionedAfter(field)) {
        break
      }
      ++idx
    }
    this.fields.splice(idx, 0, field)
  }

  /**
   * Add missing control then missing fields.
   * See details of each called method.
   */
  fill(withControls = true) {
    if (withControls) {
      this.addMissingControls()
    }
    this.addMissingfields()
    if (withControls) {
      this.updateDOMControls()
    }
    this.updateDOM()
  }

  /**
   * Add missing controls to the end of the form.
   */
  addMissingControls() {
    while (this.missing.controls.length) {
      const field = this.missing.controls.pop()

      if (field.isPaymentButton()) {
        this.fields.push(field)
      } else {
        const referenceIdx = findIndex(this.fields, field =>
          field.isTypeControl()
        )
        this.fields.splice(referenceIdx, 0, field)
      }
    }
  }

  /**
   * Insert every missing fields (except controls) before the first control
   * element inside DOM.
   * By default at this step all controls should be present,
   * whether added by this class or provided in the original form template.
   */
  addMissingfields() {
    const topControl = find(this.fields, field => field.isTypeControl())

    while (this.missing.fields.length) {
      const field = this.missing.fields.shift()

      const configFieldIdx = this.requiredFields.indexOf(field)
      if (configFieldIdx === 0) {
        // Exception for pan
        this.fields.unshift(field)
        continue
      }
      const previousField = this.requiredFields[configFieldIdx - 1]
      const previousIdx = this.fields.indexOf(previousField)

      if (this.isCorrectlyLocated(previousField)) {
        this.fields.splice(previousIdx + 1, 0, field)
      } else {
        const referenceIdx = topControl
          ? this.fields.indexOf(topControl)
          : this.fields.length
        this.fields.splice(referenceIdx, 0, field)
      }
    }
  }

  /**
   * If previous field is located before given field, then return true
   * else return false
   *
   * @param {FormTemplateField} field
   * @returns {boolean}
   */
  isCorrectlyLocated(field) {
    const configFieldIdx = this.requiredFields.indexOf(field)

    if (configFieldIdx === 0) return true

    const fieldIdx = this.fields.indexOf(field)
    const previousField = this.requiredFields[configFieldIdx - 1]
    const previousIdx = this.fields.indexOf(previousField)
    return previousIdx < fieldIdx
  }

  updateDOMControls() {
    const paymentButton = find(this.fields, field => field.isPaymentButton())
    if (!paymentButton.hasElement()) {
      paymentButton.computeElement()
      this.append(paymentButton)
    }
    const controls = this.fields.filter(
      field =>
        field.isTypeControl() && !field.isPaymentButton() && !field.hasElement()
    )
    while (controls.length) {
      const control = controls.shift()
      control.computeElement()
      this.insertBefore(control, paymentButton)
    }
  }

  updateDOM() {
    this.fields.forEach((field, idx) => {
      if (!field.hasElement()) {
        field.computeElement()

        if (idx === 0) {
          this.prepend(field)
        } else {
          const referenceField = this.fields[idx - 1]
          this.insertAfterInRoot(field, referenceField)
        }
      }
    })
  }

  /**
   * @param {FormTemplateField} field
   */
  prepend(field) {
    if (this.el.firstElementChild) {
      this.el.insertBefore(field.el, this.el.firstElementChild)
    } else {
      this.el.append(field.el)
    }
  }

  /**
   * @param {FormTemplateField} field
   */
  append(field) {
    this.el.append(field.el)
  }

  /**
   * @param {FormTemplateField} field
   * @param {FormTemplateField} referenceField
   */
  insertAfterInRoot(field, referenceField) {
    let ref = referenceField.el

    while (ref.parentElement !== this.el) {
      ref = ref.parentElement
    }
    ref.insertAdjacentElement('afterend', field.el)
  }

  insertBefore(field, referenceField) {
    referenceField.el.insertAdjacentElement('beforebegin', field.el)
  }

  setMerchantElements() {
    this.fields.forEach(field =>
      field.el.setAttribute('kr-merchant-resource', '')
    )
  }
}
