import Zepto from 'zepto-webpack'
import appLoader from '@/common/loader/app'

/** COMPONENTS */
import KryptonPopinButton from '@/host/components/popin/KryptonPopinButton'
import KryptonPopinBackground from '@/host/components/popin/KryptonPopinBackground'

export default locator => {
  return ({ popinId }) => {
    const el = `.kr-popin-utils[kr-popin-utils-id="${popinId}"]`

    return appLoader(locator, Zepto(el), {
      el,
      components: {
        KryptonPopinButton,
        KryptonPopinBackground
      }
    })
  }
}
