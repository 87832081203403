<template lang="pug">
  .kr-card-header(
    kr-resource
    v-show="isVisible"
    v-on:click="onClick"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="dynamicClasses"
  )
    span.kr-card-header-label {{label}}
    SmartFormRadioButton(v-if="hasRadioButton", :checked="isSelected", :hover="hover")
    SmartFormCardIcons(:brands="cardBrands", @num-of-brands="syncLabel", :margin="-10")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Events from '@/configuration/Events'
import SmartFormCardIcons from '@/host/components/smartform/CardIcons'
import SmartFormRadioButton from '@/host/components/smartform/RadioButton'

export default {
  name: 'SmartFormCardHeader',
  components: {
    SmartFormCardIcons,
    SmartFormRadioButton
  },
  data() {
    return {
      numOfBrands: 1,
      hover: false
    }
  },
  computed: {
    ...mapGetters(['translate', 'isSinglePaymentButton', 'isSelectedMethod']),
    ...mapState(['dna', 'formMode']),
    ...mapState({
      radioButtonConfig: state => state.form.smartform.radioButton,
      cardBrands: state => state.smartForm.cardBrands
    }),
    label() {
      return this.translate(
        `card_header_${this.numOfBrands > 1 ? 'plural' : 'singular'}`
      )
    },
    isVisible() {
      return (
        this.formMode === 'default' ||
        this.formMode === 'wallet' ||
        (this.formMode === 'token' && this.isSinglePaymentButton)
      )
    },
    isSelected() {
      return this.isSinglePaymentButton && this.isSelectedMethod('CARDS')
    },
    hasRadioButton() {
      return (
        this.isSinglePaymentButton && this.radioButtonConfig.visibility === true
      )
    },
    dynamicClasses() {
      return {
        'kr-card-header--hover': this.hover,
        'kr-card-header--spbtn': this.isSinglePaymentButton,
        'kr-card-header--selected': this.isSelected
      }
    }
  },
  methods: {
    syncLabel(numOfBrands) {
      this.numOfBrands = numOfBrands
    },
    onClick() {
      if (this.isSinglePaymentButton && !this.isSelected) {
        this.$bus.$emit(Events.krypton.field.event, {
          type: 'focus',
          name: 'pan'
        })
      }
    }
  }
}
</script>
