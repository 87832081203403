import Hammer from 'hammerjs'
import _ from 'underscore'
import Events from '@/configuration/Events'
import { mapGetters, mapState } from 'vuex'

export const KeysMixin = {
  computed: {
    ...mapState(['browser'])
  },
  methods: {
    factoryEvent(name) {
      let evt = document.createEvent('Event')
      evt.initEvent(name, true, true)
      return evt
    },

    applyPosition(el, position) {
      // Apply new cursor position
      if (el === document.activeElement) {
        el.setSelectionRange(position, position)
        setTimeout(() => {
          el.setSelectionRange(position, position)
        }, 0)
      }
      el.dispatchEvent(this.factoryEvent('input'))
    },

    tabReport(e) {
      /**
       * For firefox browser keyup event doesn't work
       * and for the rest only keyup work.
       */
      e.preventDefault()
      if (e.type === 'keydown')
        this.fieldEvent(e.shiftKey ? 'reverseTab' : 'tab')
    }
  }
}

export const BlurMixin = {
  computed: {
    ...mapState('field', ['focus'])
  },
  created() {
    const _this = this
    const $bus = this.$bus

    $bus.$on(Events.slave.field.hideFakeCaret, () => {
      _this.blurReport()
    })
  },
  methods: {
    blurReport(event, $el = null) {
      if (this.focus) {
        this.$store.dispatch('field/update', {
          focus: false
        })
        this.fieldEvent('blur')
      }
    }
  }
}

export const BlurExpiryMixin = {
  computed: {
    ...mapState('field', ['focus'])
  },
  created() {
    const _this = this
    const $bus = this.$bus

    $bus.$on(Events.slave.field.hideFakeCaret, () => {
      _this.blurReport()
    })
  },
  methods: {
    blurReport(event, $el = null) {
      // Clean the expiry if the month has been partially inputted
      // Remove the whitespaces with logic of a single month
      let $input = this.$refs.input
      let currentValue = $input.value

      if (/^[1-9]{1}\s\/\d{2}$/.test(`${currentValue}`)) {
        // Usecase 1 : X /YY (with X greater than 1 -> 0X/YY
        this.inputValue = `0${currentValue.replace(' ', '')}`
      } else if (/^\s[1-9]{1}\/\d{2}$/.test(`${currentValue}`)) {
        // Usecase 2: " X/YY" -> 0X/YY
        this.inputValue = `0${currentValue.replace(' ', '')}`
      }

      if (this.focus) {
        this.$store.dispatch('field/update', {
          focus: false
        })
        this.fieldEvent('blur')
      }
    }
  }
}

export const FocusMixin = {
  computed: {
    ...mapState('field', ['focus'])
  },
  created() {
    const _this = this
    this.$bus.$on(Events.slave.field.focus.forced, message => {
      _this.$refs.input.focus()
      _this.focusReport(message)
    })
  },
  methods: {
    focusReport(event) {
      let $el = this.$refs.input

      /**
       * If the keyboard has been tagged as unreadable, then
       * the field should be cleaned if expiry date
       */
      if (this.unreadableKeyboard) {
        $el.value = ''
      }

      // Always send the focusReport event (is not focus), but is usable
      // in other usecases where is not important the status of the focus
      this.fieldEvent('focusReport')

      if (!this.focus && !this.settedValue) {
        this.$store.dispatch('field/update', {
          focus: true
        })
        this.fieldEvent('triggerFocusActions')
      }
      // Reset and unlock focus report
      this.settedValue = false
    }
  }
}

export const PreventEventsMixin = {
  computed: {
    ...mapGetters(['isAndroid'])
  },
  data() {
    return {
      input: null
    }
  },
  mounted() {
    this.input = this.$refs.input

    if (this.isAndroid) {
      let el = this.$el

      // Native events
      el.addEventListener('select', this.selectReport)
      el.addEventListener('paste', this.pasteReport)
      el.addEventListener('contextmenu', this.contextMenuReport)
      // Hammer events
      const hm = new Hammer.Manager(el, {})
      hm.add(
        new Hammer.Tap({
          event: 'doubletap',
          taps: 2
        })
      )
      hm.add(
        new Hammer.Press({
          time: 500
        })
      )
      hm.on('press', this.longTouchReport)
      hm.on('doubletap', this.doubleTapReport)
    }
  },
  methods: {
    selectReport(event, $el = null) {
      event.target.selectionStart = event.target.selectionEnd
      event.preventDefault()
    },
    pasteReport(event, $el = null) {
      event.preventDefault()
    },
    contextMenuReport(event, $el = null) {
      event.preventDefault()
      event.stopImmediatePropagation()
    },
    doubleTapReport(event, $el = null) {
      this.input.selectionStart = this.input.selectionEnd
    },
    longTouchReport(event, $el = null) {
      const _this = this
      _.delay(() => {
        _this.input.selectionStart = _this.input.selectionEnd
      }, 300)
    }
  }
}

export const EventsMixin = {
  computed: {
    ...mapState('field', ['name', 'formId'])
  },
  methods: {
    fieldEvent(type) {
      this.$bus.$emit('proxy.send', {
        _name: 'fieldEvent',
        name: this.name,
        type,
        formId: this.formId,
        _class: 'ActionFieldEvent'
      })
    }
  }
}
