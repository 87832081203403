var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.displayOverlay,
          expression: "displayOverlay",
        },
      ],
      staticClass: "kr-loading-overlay",
      class: _vm.dynamicClasses.wrapper,
    },
    [
      _c("div", { staticClass: "kr-overlay-background" }),
      _vm.renderLogo && _vm.showOverlayMessage
        ? _c("img", {
            staticClass: "kr-overlay-logo",
            class: _vm.dynamicClasses.logo,
            attrs: { src: _vm.logoSrc },
          })
        : _vm._e(),
      _vm.showOverlayMessage
        ? _c("span", { staticClass: "kr-overlay-message" }, [
            _vm._v(_vm._s(_vm.message)),
          ])
        : _vm._e(),
      _vm.showOverlayMessage ? _c("KryptonLoadingAnimation") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }