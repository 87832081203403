<template lang="pug">
  button.kr-method-btn(
    type="button"
    v-onresize="onResize"
    @click="openMethod(method)"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="[dynamicClasses, methodClasses, buttonLabelClasses]"
    :style="[styles.button, gridStyles]"
    :kr-payment-method="method.key.toLowerCase()"
  )
    SmartFormRadioButton(
      ref="radioButton",
      v-if="hasRadioButton", 
      :checked="isSelected", 
      :hover="hover"
    )
    .kr-icon(ref="icon")
      BrandIcon(:method="method.key", :compact="!showLabel")
    .kr-method-btn__label(:class="[labelClasses]")
      label(ref="label") {{getCustomPaymentMethodLabel(method.key) || methodLabel}}
    KryptonLoadingAnimation(ref="loading" v-if="isLoading")
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import PreloadedAssets from '@/configuration/PreloadedAssets'
import { loadAssets } from '@/common/loader/assets'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'

import MethodButtonMixin from '@/host/components/mixins/MethodButton'
import GridButtonMixin from '@/host/components/mixins/grid/Button'
import GridButtonLabelMixin from '@/host/components/mixins/grid/ButtonLabel'

export default {
  name: 'SmartFormGridOpmButton',
  mixins: [MethodButtonMixin, GridButtonMixin, GridButtonLabelMixin],
  props: {
    // method.type: 'method' || 'group'
    // method.key: string
    method: { type: Object, required: true },
    group: { type: String, default: '', required: false }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'isAnyTokenActive',
      'isSelectedMethod',
      'hasDeadEndPaymentMethod',
      'getCustomPaymentMethodLabel'
    ]),
    ...mapState([
      'allIFramesReady',
      'isUnitaryTest',
      'redirectTransaction',
      'disabledForm'
    ]),
    ...mapState({
      activeMethod: state => state.smartForm.activeMethod,
      errorCode: state => state.error.errorCode
    }),
    methodClasses() {
      return {
        ['kr-method-btn--' + this.method.key.toLowerCase()]: true
      }
    },
    isSelected() {
      return this.isSelectedMethod(this.method.key)
    },
    isDisabled() {
      return !this.allIFramesReady || this.locked || this.disabledForm
    },
    /**
     * isAnyTokenActive: when wallet is active a tokenized card is active,
     * CARDS is the activeMethod however this component is reserved for
     * new cards.
     */
    isLoading() {
      return (
        !this.isAnyTokenActive &&
        !this.errorCode &&
        this.activeMethod === this.method.key &&
        this.redirectTransaction === null &&
        !this.hasDeadEndPaymentMethod(this.method.key)
      )
    },
    methodLabel() {
      let key = `smartform_method_${this.method.key.toLowerCase()}`
      if (this.method.type === 'group')
        key = `smartform_group_${this.method.key.toLowerCase()}`

      if (this.translate(key) !== key) return this.translate(key)
      return paymentMethodsConf.labels[this.method.key] || this.method.key
    }
  },
  async created() {
    if (!this.isUnitaryTest) {
      const icons = await loadAssets()
      this.icon =
        icons.paymentMethods.regular[this.method.key] ||
        PreloadedAssets.regular.cardOutline
    }
  },
  methods: {
    async openMethod(method) {
      if (this.isDisabled || this.isLoading) return

      if (await this.interruptsExecution(method.key, 'beforePaymentStart'))
        return

      await this.$nextTick()
      this.$emit('selected', method)
    }
  }
}
</script>
