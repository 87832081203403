import AbstractState from '@/ghost/workflow/payment/state/AbstractState'
import ProcessState from '@/ghost/workflow/payment/state/ProcessState'
import tokens from '@/configuration/sources/Tokens.yml'

export default class DataFormatState extends AbstractState {
  constructor(...args) {
    super(...args)
    this.$store = this.$locator.$store
  }

  /**
   * Payment data format
   */
  async format(endpoint) {
    this.$store.dispatch('setRemoteId')

    const form = this.context.form
    const { formToken, riskAnalyser, language, publicKey, remoteId } =
      this.$store.state

    await this.validateToken()

    const paymentForm = this.formatValues(form.getValues())
    const formattedData = {
      url: {
        formToken,
        clientVersion: this.restAPI.getClientVersion(paymentForm),
        kryptonCallbackUrl: calculateCallbackUrl(),
        language,
        paymentForm,
        postUrl: '',
        publicKey,
        remoteId,
        wsUser: 'JS Client',
        refererUrl: this.context.payAction.refererUrl
      },
      paymentForm,
      endpoint
    }

    // If fingerPrintId is defined we add it
    if (riskAnalyser?.fingerPrintsId) {
      formattedData.url.fingerPrintId = riskAnalyser.fingerPrintsId
      this.$locator.logger.log(
        'info',
        'ProcessPayment request with fingerPrintId'
      )
    }
    this.context.changeState(this.factoryNextState())
    return formattedData
  }

  /**
   * Checks if the used formToken is the one injected when there is no token defined
   */
  validateToken() {
    const { formToken } = this.$store.state
    const { isDemoToken } = this.$store.getters

    if (tokens.noToken == formToken) {
      // No token, return an error
      return Promise.reject('CLIENT_305')
    } else if (isDemoToken) {
      return Promise.reject('CLIENT_998')
    }
    return Promise.resolve()
  }

  /**
   * Format the payment form values to match the expected by the server
   */
  formatValues(paymentForm) {
    if (paymentForm.pan) paymentForm.pan = paymentForm.pan.replace(/\s/g, '')
    if (paymentForm.securityCode)
      paymentForm.securityCode = paymentForm.securityCode.replace(/\s/g, '')
    if (paymentForm.expiryDate) {
      // Split the expiry date
      const [expiryMonth, expiryYear] = paymentForm.expiryDate.split('/')
      paymentForm.expiryMonth = expiryMonth.replace(/\s/g, '')
      paymentForm.expiryYear = expiryYear.replace(/\s/g, '')

      // If no century on year data, add 20+ as prefix
      if (paymentForm.expiryYear.length == 2) {
        paymentForm.expiryYear = `20${paymentForm.expiryYear}`
      }
      delete paymentForm.expiryDate
    }

    this.cleanValues(paymentForm)
    return paymentForm
  }

  /**
   * Clean the unnecessary/invalid data
   */
  cleanValues(paymentForm) {
    const form = this.context.form
    const { dna } = this.$store.state
    const { paymentBrand: brand, nonSensitiveValues: values } =
      this.$store.state[`cardForm_${form.id}`]
    paymentForm.brand = brand

    // Payment method token
    if (values.paymentMethodToken) {
      paymentForm.paymentMethodToken = values.paymentMethodToken
      if ('pan' in paymentForm) delete paymentForm.pan
      if ('expiryDate' in paymentForm) delete paymentForm.expiryDate
    }

    // Clean invalid values
    if (paymentForm?.brand === 'DEFAULT') delete paymentForm.brand
    if (paymentForm?.securityCode === '') delete paymentForm.securityCode
    if (paymentForm?.installmentNumber === '-1')
      delete paymentForm.installmentNumber

    // Clean unnecessary fields
    if (dna?.cards[brand]?.fields) {
      const fields = dna.cards[brand].fields
      for (const field in fields) {
        const fieldConf = fields[field]
        if (fieldConf.hidden && field in paymentForm) delete paymentForm[field]
      }
    }
  }

  factoryNextState() {
    return new ProcessState(this.getArgs())
  }
}

/**
 * Calculates the done.html target on callback
 */
export function calculateCallbackUrl() {
  if (window) {
    const splittedUrl = window.location.href.split('checkout/checkout.html?')
    return splittedUrl[0] + 'done/done.html'
  }
}
