/**
 * The Pii Pattern Service.
 * Responsible for pattern management
 */
export default class PiiPatternService {
    /**
     * Constructor.
     */
    constructor() {
        this.patterns = {};
    }

    /**
     * Add an array of patterns.
     * @param patternsToAdd the patterns to add
     */
    addPatterns(patternsToAdd) {
        Object.keys(patternsToAdd).forEach((patternNameToAdd) => {
            const patternToAdd = patternsToAdd[patternNameToAdd];
            this.patterns[patternNameToAdd] = {
                kind: patternToAdd.kind,
                tags: patternToAdd.tags,
            };

            // Pattern regex?
            if (patternToAdd.kind === 'regex') {
                this.patterns[patternNameToAdd].pattern = patternToAdd;
                this.patterns[patternNameToAdd].patternCompiled = new RegExp(patternToAdd.pattern);

            // Pattern JsonPath?
            } else if (patternToAdd.kind === 'json') {
                this.patterns[patternNameToAdd].path = patternToAdd.path;
            }
        });
    }

    /**
     * Return a pattern by name.
     * @param patternName the patternName
     * @returns {*}
     */
    getPattern(patternName) {
        return this.patterns[patternName];
    }

    /**
     * Return all patterns matching tags.
     * @param tags the tags
     * @returns {*[]} patterns
     */
    getPatterns(tags = []) {
        const patternsFiltered = {};
        Object.keys(this.patterns)
            .filter((patternName) => {
                const pattern = this.patterns[patternName];
                return pattern.tags
                    && pattern.tags.find((tagToMatch) => tags.find((tag) => tagToMatch === tag));
            }).forEach((patternNameFiltered) => {
                patternsFiltered[patternNameFiltered] = this.patterns[patternNameFiltered];
            });
        return patternsFiltered;
    }
}
