/**
 * Allow safe call of a provided loader:
 * - First call execute the loader.
 * - Any subsequent call shall skip the loader and return the same promise.
 *
 * @since KJS-2413
 */
export class SafeLoader {
  /**
   * @param {function: Promise} loader
   */
  constructor(loader) {
    this.loader = loader
    this.loaded = false
    this.loading = false
  }

  /**
   * @param {boolean} ignoreException
   * @returns {Promise}
   */
  load(ignoreException = true) {
    if (this.loaded || this.loading) return this.loading
    this.loading = new Promise((resolve, reject) => {
      this.loader()
        .then(() => {
          this.loaded = true
          resolve()
        })
        .catch(ignoreException ? resolve : reject)
    })
    return this.loading
  }
}
