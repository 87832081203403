import _ from 'underscore'
import { dynamicMapState } from '@/common/util/store'

export const MultipleBrandMixin = {
  inject: ['context'],
  computed: {
    ...dynamicMapState('context.namespace', [
      'selectedBrand',
      'detectedBrands'
    ]),
    hasMultipleBrands() {
      if (this.fieldName !== 'pan') return false

      // HACK KJS-1998, if OSB don't display multiple brands
      const isOsbBrand = this.selectedBrand?.split('_')[0] === 'PRV'

      return this.detectedBrands.length > 1 && !isOsbBrand
    }
  }
}
