// regular imports
import regular_card from '@/assets/images/icons/regular/fields/card.svg'
import regular_card_outline from '@/assets/images/icons/regular/fields/cardOutline.svg'
import regular_expiry from '@/assets/images/icons/regular/fields/expiry.svg'
import regular_securityCode from '@/assets/images/icons/regular/fields/securityCode.svg'
import regular_idCard from '@/assets/images/icons/regular/fields/idCard.svg'
import regular_cardName from '@/assets/images/icons/regular/fields/cardName.svg'
import regular_cardMail from '@/assets/images/icons/regular/fields/cardMail.svg'

// Loading
import loading_card from '@/assets/images/icons/regular/fields/loading/card.svg'

// Lock
import lock from '@/assets/images/icons/regular/fields/lock.svg'
import lockOutline from '@/assets/images/icons/regular/fields/lockOutline.svg'

// Help
import help_icon from '@/assets/images/icons/help_icon.svg'

// Delete
import delete_icon from '@/assets/images/icons/delete_icon.svg'

// Close
import close_icon from '@/assets/images/icons/close_icon.svg'

// Error popin
import alert_icon from '@/assets/images/icons/alert_icon.svg'

// Miscellaneous
import caret from '@/assets/images/icons/caret.svg'

// Arrows
import arrowLeft from '@/assets/images/icons/left_arrow.svg'
import arrowRight from '@/assets/images/icons/right_arrow.svg'
import arrowOutline from '@/assets/images/icons/arrow_outline.svg'

// Remove
import remove_icon from '@/assets/images/icons/remove_icon.svg'

// Check
import check from '@/assets/images/icons/check.svg'

export default {
  regular: {
    pan: regular_card,
    cardOutline: regular_card_outline,
    expiryDate: regular_expiry,
    securityCode: regular_securityCode,
    identityDocumentNumber: regular_idCard,
    cardHolderName: regular_cardName,
    cardHolderMail: regular_cardMail
  },
  loading: {
    pan: loading_card
  },
  error: {
    alert: alert_icon
  },
  help: {
    help_icon
  },
  delete: delete_icon,
  close: {
    close_icon
  },
  remove: {
    remove_icon
  },
  miscellaneous: {
    caret
  },
  arrows: {
    left: arrowLeft,
    right: arrowRight,
    outline: arrowOutline
  },
  lock,
  lockOutline,
  check
}
