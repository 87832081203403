import Events from '@/configuration/Events'
import { domAsFieldName } from '@/common/util/fieldName'

export default KR => {
  return {
    focus: KR._asyncWrapper('fields.focus', domName => {
      const { state, getters } = KR.$store
      const className = domAsFieldName(domName)
      if (!getters.isFieldVisible(className)) return Promise.reject()
      const formId = state.forms[state.activeForm]
      KR.$bus.$emit(Events.krypton.field.event, {
        formId,
        type: 'focus',
        name: className
      })
      return Promise.resolve()
    }),
    pan: {
      help: {
        button: {
          show: () =>
            KR.$store.dispatch('addFormPreset', {
              action: 'update',
              params: { cardBrandHelpVisibility: true }
            }),
          hide: () =>
            KR.$store.dispatch('addFormPreset', {
              action: 'update',
              params: { cardBrandHelpVisibility: false }
            }),
          restoreDefaultVisibility: () =>
            KR.$store.dispatch('addFormPreset', {
              action: 'update',
              params: { cardBrandHelpVisibility: undefined }
            })
        }
      }
    },
    cvv: {
      hide: () =>
        KR.$store.dispatch('addFormPreset', {
          action: 'update',
          params: { securedCvv: true }
        }),
      show: () =>
        KR.$store.dispatch('addFormPreset', {
          action: 'update',
          params: { securedCvv: false }
        })
    }
  }
}
