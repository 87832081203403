// import ToolbarInvoke from '@/store/plugins/ToolbarInvoke'
import EventInterface from '@/store/plugins/EventInterface'
import Brand from '@/store/plugins/Brand'
import RedirectController from '@/store/plugins/RedirectController'
import PropagateDna from '@/store/plugins/PropagateDna'
import WhiteLabel from '@/store/plugins/WhiteLabel'
import Communicator from '@/store/plugins/slave/Communicator'
import Viewport from '@/store/plugins/Viewport'

export default (locator, application) => {
  const plugins = []

  // Specific plugins per application
  switch (application) {
    case 'host':
      plugins.push(EventInterface(locator))
      plugins.push(Brand(locator))
      plugins.push(RedirectController(locator))
      plugins.push(PropagateDna)
      plugins.push(WhiteLabel)
      plugins.push(Viewport)
      break
    case 'ghost':
      break
    case 'slave':
      plugins.push(Communicator(locator))
      break
  }

  return plugins
}
