import { diff } from 'deep-object-diff'
import { isNumber, isObject } from 'underscore'
import Events from '@/configuration/Events'
import getAmountLabel from '@/common/util/currencyFormatter'
import { isValidUrl, isValidPath, getValidUrl } from '@/common/util/url'

export const getAmountState = () => ({
  binUpdateNotification: {
    url: null,
    data: {},
    timeout: 15000
  }
})

export const amountActions = ($locator, app) => {
  return {
    updatingAmount({ commit, dispatch }) {
      commit('UPDATE', { updating: true })
      dispatch('disableButton')
    },
    amountUpdated({ commit, dispatch }) {
      commit('UPDATE', { updating: false })
      dispatch('enableButton')
    },
    async amountUpdate({ commit, state, dispatch, getters }, { formToken }) {
      const { isInitialFormToken } = getters
      // If the formToken is the same as the initial one, set the initial amount
      if (isInitialFormToken(formToken)) {
        dispatch('setInitialAmount')
        return
      }
      // Set the initial values
      if (!state.initialFormToken && !state.initialAmount)
        commit('UPDATE', {
          initialFormToken: state.formToken,
          initialAmount: state.amount
        })
      // Decode token and parse dna
      const { $bus, tokenReader, dna } = $locator
      formToken = tokenReader.verifyToken(formToken, true, {
        invalidToken: 'CLIENT_512'
      })
      const rawDna = await tokenReader.decode(formToken)
      const parsedDna = await dna.parse(rawDna, false, true)
      // Update the data only if the amount is different
      const dnaDiff = diff(state.dna, parsedDna)
      if (dnaDiff.updatedAmount && Object.keys(dnaDiff).length === 1) {
        $bus.$emit(Events.krypton.discount)
        commit('UPDATE', {
          formToken,
          dna: parsedDna,
          amount: parsedDna.updatedAmount
        })
      }
      dispatch('amountUpdated')
    },
    async setInitialAmount({ commit, state, dispatch }) {
      const { formToken, initialFormToken } = state
      // Skip if it has not been previously updated, or if the value is already the initial
      const skipUpdate = !initialFormToken || formToken === initialFormToken
      if (!skipUpdate) {
        const rawDna = await $locator.tokenReader.decode(initialFormToken)
        const dna = await $locator.dna.parse(rawDna, false, true)
        $locator.$bus.$emit(Events.krypton.discount)
        commit('UPDATE', {
          formToken: state.initialFormToken,
          dna,
          amount: dna.amount
        })
      }
      dispatch('amountUpdated')
    },
    setBinUpdateNotification({ commit, dispatch }, { url, data, timeout }) {
      // Validate URL
      const validURL = isValidUrl(url) || isValidPath(url) || url === null
      // Invalid URL, throw error
      if (!validURL) {
        dispatch('error', {
          errorCode: 'CLIENT_510',
          metadata: {
            console: true,
            isHiddenOnForm: true,
            prefix: 'setBinUpdateNotificationUrl'
          }
        })
        return
      }

      // Format URL
      const formattedUrl = getValidUrl(url)
      const binUpdateNotification = { url: formattedUrl }
      // If params are valid, add them
      if (isObject(data)) binUpdateNotification.data = data
      if (isNumber(timeout)) binUpdateNotification.timeout = timeout

      commit('UPDATE', { binUpdateNotification })
    }
  }
}

export const amountGetters = {
  getAmountLabel:
    state =>
    (amount = state.amount) => {
      const { currency, language } = state
      return getAmountLabel(amount, currency, language)
    },
  discount: ({ amount, initialAmount }) => amount - (initialAmount || amount),
  isInitialFormToken:
    ({ initialFormToken }) =>
    formToken =>
      formToken === initialFormToken
}
