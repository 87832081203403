import { mapGetters } from 'vuex'

export const KeyboardTypeMixin = {
  computed: {
    ...mapGetters(['isIos']),
    pattern() {
      return this.isIos ? '[0-9]*' : null
    },
    type() {
      return this.isIos ? 'text' : 'tel'
    }
  }
}
