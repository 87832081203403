<template lang="pug">
.kr-smart-form-radio-button(:class="dynamicClasses")
  span
    svg(width="12px", height="9px", viewbox="0 0 12 9")
      polyline(points="1 5 4 8 11 1")
</template>

<script>
/**
 * This component is a visual checkbox added for Single Payment Button mode.
 * As of now it used in method labels and card form header.
 *
 * @since KJS-2662
 */
export default {
  name: 'SmartFormRadioButton',
  props: {
    checked: {
      type: [Boolean, String],
      default: false
    },
    hover: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    /**
     * For isChecked & isHovered, the three following cases are covered in
     * order to normalize its value:
     *   If used as a boolean prop (<radio-button  checked>)
     *   If bound to a boolean (<radio-button :checked="true">)
     *   If bound as boolean prop in pug (radio-button (checked))
     */
    isChecked() {
      return (
        this.checked === '' ||
        this.checked === true ||
        this.checked === 'checked'
      )
    },
    isHovered() {
      return this.hover === '' || this.hover === true || this.hover === 'hover'
    },
    dynamicClasses() {
      return {
        'kr-smart-form-radio-button--checked': this.isChecked,
        'kr-smart-form-radio-button--hover': this.isHovered
      }
    }
  }
}
</script>
