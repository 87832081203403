import { getDomainName } from '@/common/util/url'
import { loadScript } from '@/common/util/dom'

/**
 * This class handles the risk analyzer script
 */
export default class RiskManager {
  constructor($locator) {
    this.$locator = $locator
  }

  load(riskAnalyser) {
    if (riskAnalyser?.jsUrl) {
      const targetDomainName = getDomainName(riskAnalyser.jsUrl)
      const scriptSrc = `${riskAnalyser.jsUrl}?targetHost=${encodeURIComponent(
        targetDomainName
      )}`

      // Check if the risk analyser script is already created
      if (document.querySelector('script.kr-risk-analyser-script')) {
        const currScript = document.querySelector(
          'script.kr-risk-analyser-script'
        )
        // If it's the same, we do nothing
        if (currScript.getAttribute('src') === scriptSrc) return
        // If it's different, we remove the current one
        currScript.parentNode.removeChild(currScript)
      }

      // Risk analyser script creation and addition to the body
      loadScript(scriptSrc, 'kr-risk-analyser')
    }
  }
}
