import { mapActions, mapGetters, mapState } from 'vuex'
import { dynamicMapGetters } from '@/common/util/store'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import Modal from '@/configuration/sources/SimpleModal.yml'

/** MIXINS */
import ConfigurationMixin from '@/host/components/mixins/Configuration'

/**
 * This mixin is used to handle the action of the KryptonDeleteButton
 * @since KJS-3083
 */

export default {
  mixins: [ConfigurationMixin],
  inject: ['context'],
  computed: {
    ...mapGetters(['isSmartForm', 'getToken'])
  },
  methods: {
    ...mapActions(['openModal']),
    deleteCard(methodParam, tokenParam) {
      if (this.isDisabled && this.isSmartForm) return

      this.openModal({
        layout: this.resolveModalLayout(methodParam, tokenParam),
        method: this.resolveModalMethod(methodParam),
        paymentMethodToken: tokenParam,
        formId: this.context.formId
      })
    },
    resolveModalLayout(methodParam, tokenParam) {
      const layouts = Modal.layouts
      const method = methodParam.startsWith('CARDS:')
        ? 'CARDS'
        : this.resolveNotCardMethod(methodParam)
      return layouts[method][this.resolveLayoutType(tokenParam)]
    },
    resolveModalMethod(method) {
      return method.replace('CARDS:', '') || this.resolveNotCardMethod(method)
    },
    resolveNotCardMethod(method) {
      if (method.startsWith('PAYPAL')) return 'PAYPAL'
      return method
    },
    resolveLayoutType(tokenParam) {
      const token = this.getToken(tokenParam)
      const cancelable = token?.cancelable
      if (cancelable === undefined) return 'removeTokenSupport'
      if (cancelable === false) return 'removeTokenUsed'
      return 'removeToken'
    }
  }
}
