import lyraPii from './pii';

// Load default rules (rollup plugin)
import defaultRules from '../dist/pii.default.yml';

/**
 * Lyra Common PII main script (browser).
 */
export default {

    /**
     * Return default rules.
     */
    getDefaults() {
        return defaultRules;
    },

    /**
     * Build a PiiInstance.
     * @param options the options
     * @returns {PiiInstance}
     */
    build(options = { loadDefaultRules: true, rules: {} }) {
        return lyraPii.build(options.loadDefaultRules, defaultRules, options.rules);
    },
};
