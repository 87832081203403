import _ from 'underscore'
import AbstractStrategy from '@/ghost/workflow/strategy/AbstractStrategy'
import ErrorsConf from '@/configuration/sources/ErrorsConfiguration.yml'

/**
 * Class representing a strategy bridge between field classes in application
 * and real iframe hosted on parent window as internal iframes with same domain as
 * Ghost application domain
 */
export default class IFrameStrategy extends AbstractStrategy {
  constructor(fieldName, $locator, formId) {
    super(fieldName)
    this.$locator = $locator
    this.formId = formId
  }

  getFrameWindow() {
    const frameWindow = this.$locator.iframeHandler.getFrameWindow(
      this.fieldName,
      this.formId
    )

    if (frameWindow?.FORM_ID === this.formId) return frameWindow
  }

  shouldBeValidated(field) {
    const { $store } = this.$locator
    const { dna, forcedBrand } = $store.state
    const { selectedBrand } = $store.state[`cardForm_${this.formId}`]
    const fieldConf = dna.cards[selectedBrand]?.fields[this.fieldName]

    // If the field is locked, no value should be validated
    if (!field.locked || (forcedBrand && !fieldConf)) return true
  }

  validate(silent = false, field, fields) {
    // no error usecase
    if (
      this.shouldBeValidated(field) &&
      !this.getFrameWindow()?.VALID(silent)
    ) {
      return {
        errorCode: ErrorsConf.fields[this.fieldName],
        field: this.fieldName
      }
    }
  }

  /**
   * @method constraintToForm
   */
  constraintToForm(formId) {
    this.formId = formId
  }

  /**
   * Get the promised value of the field of the parent
   * iframe and refresh the internal value of this.value
   */
  getValue() {
    return this.getFrameWindow()?.GET_VALUE()
  }

  echo(store) {
    return this.getFrameWindow()?.ECHO(store)
  }

  focus() {
    this.getFrameWindow()?.FOCUS()
  }

  sync(payload) {
    this.getFrameWindow()?.SYNC(payload)
  }

  autofill(callback) {
    if (!this.getFrameWindow()) return
    this.getFrameWindow().AUTOFILL = callback
  }

  setAutofillValue(data) {
    this.getFrameWindow()?.SET_AUTOFILL_VALUE(data)
  }
}
