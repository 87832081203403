export default Vue => {
  Vue.mixin({
    beforeCreate() {
      const options = this.$options
      if (options.locator) {
        this.$locator = options.locator
      } else if (options.parent && options.parent.$locator) {
        this.$locator = options.parent.$locator
      }

      // Special injections and shortcuts
      if (this.$locator) {
        this.$proxy = this.$locator.proxy
        this.$storeFactory = this.$locator.storeFactory
      }
    }
  })
}
