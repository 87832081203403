<template lang="pug">
  button.kr-method-btn(
    v-on:click="clickHandler"
    v-on:mouseover="hover = true"
    v-on:mouseleave="hover = false"
    :class="[walletDynamicClasses, methodClass]"
    :data-card="value"
    :data-token="method.token"
    type="button"
  )
    SmartFormRadioButton(v-if="hasRadioButton", :checked="isSelected", :hover="hover || hoverDelete")
    .kr-icon
      BrandIcon(:method="method.paymentMethodType")
    label.kr-method-btn__label {{ value }}
    SmartFormGridWalletDelete(
      v-if="!isLoading"
      v-on:click.stop="onDeleteClick"
      v-on:mouseover.stop="hoverDelete = true"
      v-on:mouseleave.stop="hoverDelete = false"
      :token="method.token"
      :method="method.paymentMethodType"
    )
    KryptonLoadingAnimation(v-else)
</template>

<script>
import { mapActions } from 'vuex'

import WalletButtonMixin from '@/host/components/mixins/WalletButton'
import Modal from '@/configuration/sources/SimpleModal.yml'

export default {
  name: 'SmartFormGridWalletMethodButton',
  mixins: [WalletButtonMixin],
  computed: {
    methodClass() {
      return {
        [`kr-method-btn--method-${this.method.paymentMethodType.toLowerCase()}`]: true
      }
    },
    value() {
      return this.method.fields?.id?.value ?? ''
    },
    methodType() {
      return this.method.paymentMethodType
    },
    methodKey() {
      return `${this.methodType}:${this.method.token}`
    }
  },
  methods: {
    ...mapActions(['openModal']),
    async clickHandler() {
      if (!this.amount) {
        this.deleteCardHandler()
        return
      }

      if (
        await this.interruptsExecution(
          `WALLET_${this.method.paymentMethodType}`,
          'beforePaymentStart'
        )
      )
        return false

      this.openMethod()
    },
    deleteCardHandler() {
      this.deleteCard(this.method.paymentMethodType, this.method.token)
    },
    openMethod() {
      if (this.isDisabled || this.isLoading) return
      if (this.isSinglePaymentButton) {
        this.$emit('selected', this.methodKey)
        return
      }

      const layouts = Modal.layouts
      this.openModal({
        layout: layouts[this.resolveMethod()].confirmTokenPayment,
        method: this.method.paymentMethodType,
        paymentMethodToken: this.method.token
      })
    },
    resolveMethod() {
      const baseMethod = this.method.paymentMethodType
      return baseMethod.startsWith('PAYPAL') ? 'PAYPAL' : baseMethod
    }
  }
}
</script>
