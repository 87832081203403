var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isGridMode
    ? _c("SmartFormGrid", {
        scopedSlots: _vm._u(
          [
            {
              key: "cardForm",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
            {
              key: "extra",
              fn: function () {
                return [_vm._t("extra")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    : _c("SmartFormLegacy", {
        scopedSlots: _vm._u(
          [
            {
              key: "cardForm",
              fn: function () {
                return [_vm._t("default")]
              },
              proxy: true,
            },
            {
              key: "extra",
              fn: function () {
                return [_vm._t("extra")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }