module.exports = {
  fallback: 'en-EN',
  alias: {
    es: 'es-ES',
    fr: 'fr-FR',
    pt: 'pt-PT',
    en: 'en-EN',
    de: 'de-DE',
    it: 'it-IT',
    ja: 'ja-JP',
    nl: 'nl-NL',
    pl: 'pl-PL',
    ru: 'ru-RU',
    sv: 'sv-SE',
    tr: 'tr-TR',
    zh: 'zh-CN'
  },
  supportedLocales: [
    'zh-CN',
    'tr-TR',
    'sv-SE',
    'ru-RU',
    'pt-PT',
    'pt-BR',
    'pl-PL',
    'nl-NL',
    'ja-JP',
    'it-IT',
    'fr-FR',
    'es-AR',
    'es-CL',
    'es-CO',
    'es-ES',
    'es-MX',
    'es-PE',
    'es-UY',
    'en-EN',
    'de-DE'
  ]
}
