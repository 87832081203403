import PaymentProcess from '@/ghost/workflow/payment/state/process/PaymentProcess'

/**
 * Manages the third processPayment call (Challenge payment)
 */
export default class ChallengeProcess extends PaymentProcess {
  async process(response, procResp = null) {
    const { remoteId } = this.$store.state
    const ecsPaymentId = this.context.ecsPaymentId
    // Process the reponse to prepare the third request
    response = await this.getProcessedResponse(procResp, response)
    // Build the request object
    let values = this.buildRequest(response)
    values.remoteId = remoteId
    // Third PP call
    response = await this.callProcessPayment(values, { ecsPaymentId })
    // Check which kind of response it is
    procResp = await this.processResponse(response)

    return [response, procResp]
  }
}
