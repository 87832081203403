<template lang="pug">
  .kr-partial-payment(:class="[hover ? 'kr-hover' : '']")
    .kr-partial-payment-remove-icon-container(        
      @mouseover="hover = true"
      @mouseleave="hover = false"
      @click="cancelPartialPayment()"
      role="button"
    )
      .kr-partial-payment-remove-icon(v-html="removeIcon")
    BrandIcon(:method="transaction.brand")
    SmartFormMaskedCardLabel(v-if="transaction.paymentMethodType === 'CARD'" :value="formatCardNumber(transaction.id, transaction.brand)")    
    label.kr-partial-payment-opm-label(v-else)
      span {{transaction.id}}
    span.kr-partial-payment-amount {{getAmountLabel(transaction.amount)}}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import BrandIcon from '@/host/components/controls/BrandIcon'
import SmartFormMaskedCardLabel from '@/host/components/smartform/MaskedCardLabel'
import { formatCardNumber } from '@/common/util/card'

export default {
  name: 'PartialPaymentEntry',
  components: { BrandIcon, SmartFormMaskedCardLabel },
  props: {
    transaction: { type: Object, required: true }
  },
  data() {
    return {
      hover: false,
      removeIcon: PreloadedAssets.remove.remove_icon
    }
  },
  computed: {
    ...mapGetters(['getAmountLabel'])
  },
  methods: {
    ...mapActions(['startCancelPartialTransaction']),
    cancelPartialPayment() {
      this.startCancelPartialTransaction({
        transactionUuid: this.transaction.uuid
      })
    },
    formatCardNumber(card, brand) {
      return formatCardNumber(card, brand)
    }
  }
}
</script>
