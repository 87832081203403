import _ from 'underscore'
import { mapGetters, mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

export const ClearMixin = {
  computed: {
    ...mapState('field', ['formId', 'name']),
    ...mapState(['dna', 'clearOnError']),
    ...mapGetters(['isIphone', 'isIpad']),
    ...dynamicMapState('context.namespace', ['forcedFieldClear', 'fieldClear'])
  },
  watch: {
    forcedFieldClear(newVal) {
      if (newVal) {
        this.clear()
        this.$store.dispatch(`${this.context.namespace}/update`, {
          forcedFieldClear: false
        })
      }
    },
    fieldClear(newVal) {
      // We check if this field is configured to be cleaned. If not
      // this action should be ignored
      if (!newVal || !this.dna || this.clearOnError === false) return

      // HACK: If any brand is configured to be cleaned, we clean it
      // Should come correctly configured in the DNA
      for (const brand in this.dna.cards) {
        const brandConfig = this.dna.cards[brand].fields[this.name]
        if (brandConfig.clearOnError === true) {
          this.clear()
          break
        }
      }

      this.$store.dispatch(`${this.context.namespace}/update`, {
        fieldClear: false
      })
    }
  },
  methods: {
    clear() {
      if (this.isDisabled) return
      if (this.isIphone || this.isIpad) this.$store.dispatch('lockFakeCursor')
      this.inputValue = ''
    }
  }
}
