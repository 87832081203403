import _ from 'underscore'
import AbstractQueue from '@/host/service/queues/AbstractQueue'
import { PendingForm } from '@/host/model'
import Events from '@/configuration/Events'
import { logSentry, logSentryException } from '@/common/util/sentry'

export default class AllReadyQueue extends AbstractQueue {
  constructor($locator) {
    super($locator)
    this.forms = new Map()
    this.additionalForms = []
  }

  reset() {
    super.reset()
    this.forms = new Map()
    this.additionalForms = []
  }

  registerEvents() {
    this.$bus.$on(Events.krypton.iframes.allReady, ({ formId }) =>
      this.onAllReady(formId)
    )

    this.$bus.$on(
      Events.krypton.message.loaded,
      ({ formId }) => {
        this.onIframeLoaded(formId)
      },
      'NO-COMPONENT'
    )
  }

  isAdditionalForm(formId) {
    return this.additionalForms.includes(formId)
  }

  /**
   * Sends the store in 2 parts. Immediately after the iframes ready,
   * we send the first load required data. After that we wait
   * until everything is visibly ready to send the rest of the data.
   */
  sendStoreData({ formId, isFirstLoad = true }) {
    const proxy = this.proxy
    const firstLoadProps = ['css', 'language', 'placeholders', 'disabledFields']

    if (this.queue.length === 0) return

    const requireForce = this.isAdditionalForm(formId)
    const store = requireForce
      ? this.queue.find(store => !store?.metadata?.namespace)
      : this.queue[0]
    const storeData = _.clone(this.$locator?.$store?.state)
    store.data = _[isFirstLoad ? 'pick' : 'omit'](storeData, ...firstLoadProps)
    if (requireForce) {
      store.force = true
    }
    proxy.send(store)

    if (!isFirstLoad) {
      // Removes the first store (already sent)
      this.queue.shift()
      // Send other pending messages
      if (this.queue.length > 0) this.sendAccumulatedMessages()
      // Clean the queue
      this.queue = []
    }
  }

  /**
   * @param {string} formId
   * @see KJS-3458 FormID is not specified in order to adjust event handling
   * and form initialization. Additional forms require a force sync in order
   * to force mutation that would have no change in the ghost.
   */
  onAllReady(formId) {
    const form = this.ensureForm(formId)
    this.ready = true
    this.sendStoreData({ formId })

    form
      .onLoaded()
      .then(() => {
        this.sendStoreData({ formId, isFirstLoad: false })
        this.$bus.$emit(Events.krypton.sync.ready)
        this.setFormVisible(formId)
        logSentry('Boot', `Slave iframes visible (form ${formId})`)
      })
      .catch(err => {
        console.warn(err)
        logSentryException(err, 'AllReadyQueue.onAllReady', `formId: ${formId}`)
      })
  }

  onIframeLoaded(formId) {
    const form = this.ensureForm(formId)
    form.reportLoadedSlave()
  }

  /**
   * Given a formId, add an instance of PendingForm to the list and
   * initilize it. If it is not the first form, flag it in order to force
   * synchronization as the ghost is already synchronized and will not transfer
   * mutation to new slaves if there are no update.
   *
   * @param {string} formId
   * @returns {PendingForm}
   */
  ensureForm(formId) {
    if (!this.forms.has(formId)) {
      if (this.forms.size > 0) {
        this.additionalForms.push(formId)
      }
      const form = new PendingForm(formId)
      this.forms.set(formId, form)
      form.initialize()
    }
    return this.forms.get(formId)
  }

  setFormVisible(formId) {
    const elements = document.querySelectorAll(
      `.kr-embedded[kr-form="${formId}"] [kr-aware-id]`
    )
    for (const element of elements) {
      element.style.visibility = 'visible'
    }
    const $embedded = document.querySelector(
      `.kr-embedded[kr-form="${formId}"]`
    )
    $embedded?.setAttribute('kr-form-ready', '')
  }
}
