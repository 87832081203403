import { mapActions } from 'vuex'

export const SmartFormClickMixin = {
  methods: {
    ...mapActions(['intercept', 'createApplePaySession']),
    async interruptsExecution(paymentMethod, action, formId = null) {
      try {
        // For Apple Pay, create the session initially. See KJS-3770
        if (paymentMethod === 'APPLE_PAY') this.createApplePaySession()
        // Intercept callback
        await this.intercept({
          name: 'onSmartFormClick',
          args: [{ paymentMethod, action, formId }]
        })
        return false
      } catch (e) {
        // Execution interrupted
        console.error(e)
        return true
      }
    }
  }
}
