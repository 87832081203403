var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enabled
    ? _c(
        "div",
        {
          staticClass: "kr-cursor-mirror",
          class: _vm.animated ? "animated" : "",
          style: { position: "fixed", top: "-10000px", left: "-10000px" },
        },
        [
          _c(
            "div",
            { staticClass: "kr-input-mirror" },
            _vm._l(_vm.cleanedInputValues, function (cleanedInputValue) {
              return _c("span", {
                style: _vm.$parent.inputCSS,
                domProps: { innerHTML: _vm._s(cleanedInputValue) },
              })
            }),
            0
          ),
          _c(
            "span",
            { staticClass: "mirror-initial", style: _vm.$parent.inputCSS },
            [_vm._v("A")]
          ),
          _c("div", { staticClass: "fake-cursor", style: _vm.caretStyle }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }