var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.shouldBeDisplayed,
          expression: "shouldBeDisplayed",
        },
      ],
      ref: "content",
      staticClass: "kr-layer",
      class: _vm.dynamicClasses,
    },
    [_c("div", { staticClass: "kr-layer-background" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }