import _ from 'underscore'
import Field from '@/ghost/workflow/Field'
import testCards from '@/configuration/sources/TestCards.yml'

export default class Form {
  constructor(id, dna, $locator) {
    const { $store, validationManager, validator, storeFactory } = $locator
    this.$store = $store
    this.validationManager = validationManager
    this.id = id
    this.dna = dna
    this.fields = {}

    // Generates the field objects with the form info
    for (const fieldName of this.getFieldNames()) {
      if (!this.fields[fieldName]) {
        this.fields[fieldName] = new Field(fieldName, {
          validator,
          storeFactory
        })
      }
    }

    const _this = this
    // Use the store info when it's available
    this.$store.subscribe((mutation, state) => {
      if (mutation.payload.dna) _this.dna = state.dna
    })
  }

  match(namespace) {
    return namespace === `cardForm_${this.id}`
  }

  getFields() {
    const { isExtrasFormVisible, extrasFields } = this.$store.getters
    // If the extras form is visible, return only the extras fields
    if (isExtrasFormVisible) {
      const fieldEntries = Object.entries(this.fields)
      const filteredFieldEntries = fieldEntries.filter(
        ([key, value]) => extrasFields[key]
      )
      return Object.fromEntries(filteredFieldEntries)
    }
    return this.fields
  }

  getField(name) {
    return this.fields[name]
  }

  /**
   * Returns the field names of the current form configuration
   */
  getFieldNames() {
    return Object.keys(this.dna.cards.DEFAULT.fields)
  }

  /**
   * Get field values
   */
  getValues() {
    const values = {}
    for (const fieldName in this.getFields()) {
      values[fieldName] = this.getFields()[fieldName].getValue()
    }
    return values
  }

  validate(promise = false, silent = false, extra = null) {
    const result = this.validationManager.validate(
      this.id,
      this.getFields(),
      silent,
      extra
    )
    if (promise) return Promise[result ? 'reject' : 'resolve'](result)

    return result
  }

  /**
   * Calls the given method name of field objects
   */
  reportFields(methodName, store, byFieldName = false, fieldName = '') {
    // Only one field
    if (byFieldName && fieldName && this.fields[fieldName]) {
      return this.fields[fieldName][methodName](store)
    } else {
      // All fields
      for (const fieldName in this.fields) {
        if (this.fields[fieldName][methodName]) {
          this.fields[fieldName][methodName](store)
        }
      }
    }
  }

  fieldEvent(store) {
    this.reportFields('onFieldEvent', store, true, store.name)
  }

  echo(store) {
    this.reportFields('echo', store, true, store.fieldName)
  }

  sync(payload) {
    this.reportFields('sync', payload)
  }

  setupAutofill() {
    if (this.fields.pan) {
      this.fields.pan.autofill(data => {
        this.fields[data.fieldName].setAutofillValue(data)
      })
    }
  }

  getPaymentType() {
    if (!this.getFields().hasOwnProperty('pan')) return
    const pan = this.getFields()['pan'].getValue()
    const testBrands = testCards.brands
    const { selectedBrand } = this.$store.state[`cardForm_${this.id}`]

    const brandConf = testBrands[selectedBrand]
    for (const type in brandConf) {
      if (~type.indexOf('PASS')) {
        if (~Object.keys(brandConf[type]).indexOf(pan)) {
          return 'v2'
        }
      }
    }

    return 'v1'
  }
}
