import Vuex from 'vuex'
import getState from '@/store/state'
import getPlugins from '@/store/plugins'
import getActions from '@/store/actions'
import mutations from '@/store/mutations'
import getGetters from '@/store/getters'

export default async (locator, app) => {
  return new Vuex.Store({
    state: getState(app),
    actions: getActions(locator, app),
    mutations,
    getters: await getGetters(app),
    plugins: getPlugins(locator, app)
  })
}
