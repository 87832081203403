import { sha256 } from 'js-sha256';
import Rule from './Rule';

const DEFAULT_KEY = '3zNm$fAuQ-(TG{z';

/**
 * Hash Rule.
 */
export default class HashRule extends Rule {
    /**
     * Constructor.
     * @param definition the ruleDefinition
     */
    constructor(definition) {
        super(definition);
        this.key = definition.key || DEFAULT_KEY;
    }

    /**
     * Apply the rule to the string.
     * @param stringToProcess the string to process
     * @returns {*}
     */
    applyPattern(stringToProcess) {
        return stringToProcess.replace(this.pattern, this.hash(stringToProcess));
    }

    /**
     * Apply JsonPath rule.
     * @param jsonValue the string to process
     * @returns {*}
     */
    applyJsonPath(jsonValue) {
        return jsonValue.replace(jsonValue, this.hash(jsonValue));
    }

    /**
     * HMAC SHA256 of a string.
     * @param stringToHash the string to hash
     * @returns {string} the hashed string
     */
    hash(stringToHash) {
        return Buffer.from(sha256.hmac(this.key, stringToHash)).toString('base64');
    }
}
