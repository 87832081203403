<template lang="pug">
input.autofill(
  :name="name"
  :autocomplete="autocomplete"
  type="text"
  tabindex="0"
  v-model="value"
  style="position:fixed;top:-9999px;left:-9999px;display:inline;"
)
</template>

<script type="text/javascript">
export default {
  name: 'AutofillInput',
  props: {
    autocomplete: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value(newValue) {
      this.$emit('autofill', {
        fieldName: this.fieldName,
        value: newValue
      })
    }
  }
}
</script>
