<template lang="pug">
    extends templates/DoRegister.pug
</template>

<script>
import { uniqueId } from 'underscore'
import { mapState } from 'vuex'
import { dynamicMapActions } from '@/common/util/store'

import Events from '@/configuration/Events'

/** MIXINS */
import { FieldMixin } from '@/host/components/mixins/Field'

export default {
  name: 'KryptonDoRegister',
  mixins: [FieldMixin],
  props: ['label'],
  data() {
    return {
      value: false,
      name: 'kr-do-register',
      fieldName: 'doRegister',
      appliedLabel: ''
    }
  },
  computed: {
    ...mapState({
      checkboxType: state => `kr-checkbox-type-${state.fields.checkbox.type}`,
      forcedValue: state => state._internals.doRegister.forced
    }),
    isReadOnly() {
      return this.isDisabled || this.forcedValue
    }
  },
  watch: {
    forcedValue: 'checkForcedStatus',
    value(newVal) {
      this.$bus.$emit(Events.krypton.field.event, {
        formId: this.context.formId,
        name: this.fieldName,
        type: 'userClick'
      })
      this.updateNonSensitiveValue({ doRegister: newVal })
    }
  },
  created() {
    this.appliedLabel = this.label
    this.seed = uniqueId('kr-field-app-')
    this.viableField(this.fieldName)
    this.checkForcedStatus()
  },
  methods: {
    ...dynamicMapActions('context.namespace', [
      'viableField',
      'inviableField',
      'updateNonSensitiveValue'
    ]),
    checkForcedStatus() {
      if (this.forcedValue) {
        this.value = true
      }
    },
    setInput() {
      // Warning the input value is used for focusing, the true input is not focusable because of the display none
      // hence passing the focusable element instead
      this.$input = this.$refs.checkboxControlIndicator
    },
    focusEvents({ which }) {
      if (which === 32) {
        this.value = !this.value
      }
    }
  }
}
</script>
