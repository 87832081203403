<template lang="pug">
  section.kr-sf-wallet
    label.kr-sf-wallet__label {{ label | capitalize | colon }}
    .kr-sf-wallet__methods
      SmartFormGridWalletGroup(
        :methods="walletTokens",
        v-on:selected="onSelected",
        :locked="allLocked"
      )
    SmartFormWalletError
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import SmartFormGridWalletGroup from '@/host/components/smartform/grid/wallet/Group'
import SmartFormWalletError from '@/host/components/smartform/wallet/Error'

export default {
  name: 'SmartFormGridWallet',
  components: {
    SmartFormGridWalletGroup,
    SmartFormWalletError
  },
  computed: {
    ...mapGetters(['translate', 'hasAnyActiveMethod']),
    ...mapState(['amount']),
    ...mapState({
      cardTokens: state => state.dna?.tokens?.cards || [],
      smartFormTokens: state => state.dna?.tokens?.smartForm || [],
      paymentDone: state => state.smartForm.paymentDone
    }),
    walletTokens() {
      return [...this.cardTokens, ...this.smartFormTokens]
    },
    label() {
      if (!this.amount)
        return this.translate('smartform_wallet_remove_payment_method')

      return this.translate('smartform_wallet_mycard')
    },
    allLocked() {
      return this.paymentDone || this.hasAnyActiveMethod
    }
  },
  methods: {
    onSelected(method) {
      this.$emit('selected', method)
    }
  }
}
</script>
