var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "kr-sf-wallet" },
    [
      _c("label", { staticClass: "kr-sf-wallet__label" }, [
        _vm._v(_vm._s(_vm._f("colon")(_vm._f("capitalize")(_vm.label)))),
      ]),
      _c(
        "div",
        { staticClass: "kr-sf-wallet__methods" },
        [
          _c("SmartFormGridWalletGroup", {
            attrs: { methods: _vm.walletTokens, locked: _vm.allLocked },
            on: { selected: _vm.onSelected },
          }),
        ],
        1
      ),
      _c("SmartFormWalletError"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }