<template lang="pug">  
  .kr-smart-form-embedded-header(:style="styles.wrapper" v-show="headerVisible" :class="dynamicClasses")
    .kr-amount-wrapper
      span.kr-amount-label {{amountLabelText}}
      span.kr-amount-figure(:style="styles.amount.figure") {{totalAmountLabel}}
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SmartFormEmbeddedHeader',
  computed: {
    ...mapGetters(['translate', 'hasPartialPayments']),
    ...mapState(['totalAmountLabel']),
    ...mapState({
      headerConfig: state => state.form.smartform.embedded.header
    }),
    headerVisible() {
      return this.headerConfig.visibility
    },
    styles() {
      const styles = {}

      styles.wrapper = {}
      styles.wrapper.borderBottom = this.headerConfig.borderBottom

      styles.amount = {
        figure: {}
      }
      styles.amount.figure.color = this.headerConfig.amount.figure.color
      return styles
    },
    dynamicClasses() {
      return {
        [`kr-partial-payment-header`]: this.hasPartialPayments
      }
    },
    amountLabelText() {
      return this.hasPartialPayments
        ? this.translate('partial_payment_total_amount')
        : this.translate('amount')
    }
  }
}
</script>
