<template lang="pug">
  .kr-popin-background(v-show="show")
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'KryptonPopinBackground',
  computed: {
    ...mapState(['isPopinOpen', 'redirectIframeOpen']),
    show() {
      return this.isPopinOpen && !this.redirectIframeOpen
    }
  }
}
</script>
