<script>
import DetachableMixin from '@/host/components/mixins/Detachable'
import ToggleableMixin from '@/host/components/mixins/Toggleable'
import { mapActions } from 'vuex'

/**
 * Wrapper dialog popin to handle detachment, stacking context & zIndex priority
 * and avoid styles colision with parent components styles.
 *
 * @example
 *   krypton-dialog(v-model="open", content-class="kr-help-button-wrapper")
 *     .kr-help-modal-wrapper(@click="closeModal()")
 *       .kr-help-modal-background
 *       .kr-help-modal(:style="[modalSize]", role="dialog")
 *         // ...
 *
 * Should render as such:
 *   .kr-dialog
 *     .kr-dialog-content.kr-help-button-wrapper(ref="content")
 *      .kr-help-modal-wrapper(@click="closeModal()")
 *        .kr-help-modal-background
 *        .kr-help-modal(:style="[modalSize]", role="dialog")
 *          // ...
 *
 * Then after being mounted, the (ref="content") subtree shall be move to
 * the detachment area.
 *
 * @since KJS-2162 (2022-09-15)
 */
export default {
  name: 'KryptonDialog',
  mixins: [DetachableMixin, ToggleableMixin],
  props: {
    contentClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActive: !!this.value
    }
  },
  computed: {
    classes() {
      return {
        'kr-dialog': true,
        'kr-dialog--active': this.isActive
      }
    },
    contentClasses() {
      return {
        [this.contentClass.trim()]: true,
        'kr-dialog-content': true,
        'kr-dialog-content--active': this.isActive
      }
    }
  },
  watch: {
    isActive(newVal) {
      if (newVal) this.addDialogToActiveList(this.$componentId)
      else this.removeDialogFromActiveList(this.$componentId)
    }
  },
  methods: {
    ...mapActions(['addDialogToActiveList', 'removeDialogFromActiveList']),
    /**
     * Proxy object to bind "value" property within slots to "isActive"
     * @return {object} Proxy
     */
    getValueProxy() {
      const self = this
      return {
        get value() {
          return self.isActive
        },
        set value(isActive) {
          self.isActive = isActive
        }
      }
    },

    /**
     * Get named slot and bind values
     *
     * @param  {object}  vm       components
     * @param  {String}  name     Slot name
     * @param  {object}  data     values to bind
     * @param  {Boolean} optional
     * @return {Array<VNode>}     slot elements
     */
    getSlot(vm, name = 'default', data, optional = false) {
      if (vm.$scopedSlots[name]) {
        return vm.$scopedSlots[name](data instanceof Function ? data() : data)
      } else if (vm.$slots[name] && (!data || optional)) {
        return vm.$slots[name]
      }

      return undefined
    },

    genContent() {
      const slot = this.getSlot(this, 'default', this.getValueProxy(), true)
      return this.$createElement(
        'div',
        {
          class: this.contentClasses,
          ref: 'content'
        },
        slot
      )
    }
  },

  /**
   * PUG Equivalent of the targeted rendering
   * .kr-dialog
   *   .kr-dialog-content(ref="content")
   *     slot(name="default")
   *
   * As this components is Detachable, once mounted the (ref="content") element
   * is being taken out to a detachment-area.
   *
   * @since KJS-2162 (2022-09-14)
   */
  render(h) {
    return h(
      'div',
      {
        class: this.classes,
        attrs: { role: 'dialog' }
      },
      [this.genContent()]
    )
  }
}
</script>
