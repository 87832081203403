<template lang="pug">
  .kr-discount-panel-wrapper(kr-resource, v-show="!hasDetached && showContent")
    .kr-discount-panel(v-show="isCardFormVisible(context.formId)", :class="dynamicClasses.panel")
      .kr-discount-panel-content(ref="content", :class="dynamicClasses.content")
        .kr-discount-panel-subtotal
          span.kr-discount-panel-subtotal-label {{ translate('discount_subtotal') }}:
          span.kr-discount-panel-subtotal-value {{ getAmountLabel(subtotal) }}
        .kr-discount-panel-discount(:class="dynamicClasses.discount")
          span.kr-discount-panel-discount-label {{ discountLabel }}:
          span.kr-discount-panel-discount-value(v-if="!updating") {{ getAmountLabel(discount) }}
          span.kr-discount-panel-discount-updating(v-else) {{translate('discount_updating')}}
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DetachableMixin from '@/host/components/mixins/Detachable'

export default {
  name: 'KryptonDiscountPanel',
  mixins: [DetachableMixin],
  inject: ['context'],
  props: {
    default: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'getAmountLabel',
      'discount',
      'isCardFormVisible'
    ]),
    ...mapState(['initialAmount', 'amount', 'updating']),
    ...mapState({
      binUpdateNotificationUrl: state => state.binUpdateNotification.url
    }),
    subtotal() {
      return this.initialAmount || this.amount
    },
    dynamicClasses() {
      const classes = {
        panel: {
          'kr-discount-panel--default': this.isDefaultPanel,
          'kr-discount-panel--insider': true
        },
        content: {
          'kr-discount-panel-content--visible': this.showContent,
          'kr-discount-panel-content--negative': this.discount < 0,
          'kr-discount-panel-content--positive': this.discount > 0
        },
        discount: {
          'kr-discount-panel-discount--negative': this.discount < 0,
          'kr-discount-panel-discount--positive': this.discount > 0
        }
      }
      return classes
    },
    showContent() {
      return !this.isDefaultPanel || this.binUpdateNotificationUrl
    },
    discountLabel() {
      return this.discount <= 0
        ? this.translate('discount')
        : this.translate('additional_cost')
    },
    isDefaultPanel() {
      return (
        this.default === '' ||
        this.default === true ||
        this.default === 'default'
      )
    }
  }
}
</script>
