import { setSentryExtra, logSentryException } from '@/common/util/sentry'

/**
 * Used to detect version & domain conflicts between theme JS, CSS and
 * the KJS library.
 *
 * For a single parsing, accumulate every error and report to sentry only once
 * to limitate the quantity of event sent.
 *
 * If not valid theme name is found no further check need to be performed
 * as the KR_CONFIGURATION must have been set the wrong way and this is
 * enough to raise/report an error.
 *
 * As the KR_CONFIGURATION is set by the theme JS, we shall report an error
 * if JS is not found on the DOM. Same if domain is different than library's.
 *
 * Stylesheet is not required however if present the domain shall absolutely
 * match the library's.
 *
 * @since KJS-3565
 */
export default class ThemeHandler {
  /**
   * Key library.domain must match baseDomain in store value however we must be
   * able to set it for testing purpose, hence the getter/setter.
   *
   * @param {Locator}
   */
  constructor({ $store }) {
    let forcedDomain = null

    this.sentry = true
    this.library = {
      get domain() {
        const d = forcedDomain ?? $store.state.baseDomain
        if (!d) return ''
        return d.split('.').slice(-2).join('.')
      },
      set domain(forcedValue) {
        forcedDomain = forcedValue
      },
      version: '%%library_version%%'
    }

    this.errors = []
  }

  /**
   * Check:
   * - theme name
   * - theme version
   * - CSS domain
   * - JS domain
   *
   * @param {Object} config Shallow copy of window.KR_CONFIGURATION
   */
  checkConflicts(config) {
    const name = config?.theme?.name
    const version = config?.theme?.version

    if (!['neon', 'classic', 'material'].includes(name)) {
      this.addError(`Invalid theme name "${name}"`)
    } else {
      if (version !== this.library.version) {
        this.addError(
          `Theme "${name}" version mismatch (client: ${this.library.version}; theme ${version})`
        )
      }
      this.checkCSSDomain(name)
      this.checkJSDomain(name)
    }

    if (this.errors.length) {
      this.reportToSentry()
    }
  }

  /**
   * @param {string} name neon|classic|material
   */
  checkCSSDomain(name) {
    const stylesheet = document.querySelector(
      `link[href$="${name}.css"],link[href$="${name}.min.css"],link[href$="${name}-reset.css"],link[href$="${name}-reset.min.css"]`
    )

    if (!stylesheet) return
    try {
      const stylesheetDomain = this.getDomainName(stylesheet.href)
      if (stylesheetDomain !== this.library.domain) {
        this.addError(
          `Stylesheet for theme "${name}" is included from a different domain than the library (${stylesheet.href})`
        )
      }
    } catch (e) {
      this.addError(
        `Stylesheet for theme "${name}" included with an invalid URL: ${stylesheet.href}`
      )
    }
  }

  /**
   * @param {string} name neon|classic|material
   */
  checkJSDomain(name) {
    const script = document.querySelector(`script[src$="${name}.js"]`)

    if (!script) {
      return this.addError(
        `Script for theme "${name}" not found yet a configuration was provided`
      )
    }
    try {
      const scriptDomain = this.getDomainName(script.src)
      if (scriptDomain !== this.library.domain) {
        this.addError(
          `Script for theme "${name}" is included from a different domain than the library (${script.src})`
        )
      }
    } catch (e) {
      this.addError(
        `Script for theme "${name}" included with an invalid URL: ${script.src}`
      )
    }
  }

  /**
   * Given a URL, return the domain name without the subdomain.
   * E.g: for https://static.payzen.io/path/to/theme.js
   *      return payzen.io
   *
   * If URL is invalid, let the URL constructor throw
   *
   * @param {string} url
   * @returns {string}
   */
  getDomainName(url) {
    return new URL(url).hostname.split('.').slice(-2).join('.')
  }

  /**
   * Collect error messages to be sent in on sentry notification
   * once parsing is done.
   *
   * @param {string} errorMessage
   */
  addError(errorMessage) {
    this.errors.push(errorMessage)
    console.warn(errorMessage)
  }

  reportToSentry() {
    if (!this.sentry) return

    setSentryExtra('themeConflicts', this.errors)
    logSentryException(
      new Error('KR_CONFIGURATION: Invalid theme configuration')
    )
  }
}
