import Zepto from 'zepto-webpack'
import Events from '@/configuration/Events'
import { uniqueId } from 'underscore'
import { Base64 } from 'js-base64'
import { innerText, getFormElement } from '@/common/util/dom'

export default class SmartFormRenderer {
  constructor($bus, $store) {
    this.$bus = $bus
    this.$store = $store
    this.$getters = $store.getters
    this.$state = $store.state
  }

  render() {
    // Skip the render if the smartform is not supported
    if (!this.checkSupport()) return
    this.generateButtons()
    this.generateWidget()
    return true
  }

  /**
   * Checks if there is need to add the form inside the smartform
   *
   * @see KJS-3973
   *  Search for:
   *   1 - kr-embedded inside smartform
   *   2 - kr-embedded[kr-element] outside smartform
   */
  checkSmartFormContent() {
    const { getSmartFormElement } = this.$getters

    let $smartForm = getSmartFormElement()

    if (!getFormElement($smartForm) && !this.$getters.getFormElement()) {
      $smartForm = Zepto($smartForm)
      const sfContent = $smartForm.html()
      $smartForm.empty()
      $smartForm.append(`
        <div class="kr-embedded" style="display:none">${sfContent}</div>`)
    }
  }

  /**
   * SmartForm generation
   */
  generateWidget() {
    const { getFormElement, getSmartFormElement, hasSmartElements } =
      this.$getters

    // If there is neither smartform nor smartbutton elements
    if (!hasSmartElements()) return

    // Embedded form outside of smart form
    const $form = getFormElement()
    const $smartform = getSmartFormElement()

    if ($form && !getFormElement($smartform)) {
      // Remove the cards from the list
      this.$store.dispatch('removePaymentMethod', 'CARDS')
      $form.classList.add('kr-outside-smartform')
      this.$store.dispatch('update', {
        smartForm: {
          outsideCardsForm: true,
          displayOptions: { cardsIntegrated: false }
        }
      })
    }

    // If there are smartbuttons but no smartform, generate it
    if (!$smartform) this.generateHiddenSmartForm()
    else $smartform.setAttribute('kr-merchant-resource', '')

    this.checkSmartFormContent()

    // Must get the smartform again as we may have have generate it in the previous step
    const $smartForm = Zepto(getSmartFormElement())
    const sfContent = $smartForm.html()
    const { cardsFormExpanded } = this.$getters

    // Add smartform component with a smart button inside by default
    $smartForm.empty()
    $smartForm.append(
      `<smart-form>
        ${sfContent}
        <template v-slot="extra"></template>
      </smart-form>
      <skeleton element="${
        cardsFormExpanded ? 'smartForm' : 'cardsForm'
      }"></skeleton>
      <div class="kr-smart-button-wrapper">
        <fallback-smart-button></fallback-smart-button>
        <smart-button-error></smart-button-error>
      </div>
      <smart-form-single-payment-button></smart-form-single-payment-button>
      `
    )
  }

  /**
   * SmartButtons generation
   */
  generateButtons() {
    const { hasCardBrand, getSmartButtonElements } = this.$getters
    const $smartBtns = getSmartButtonElements()

    for (const smartBtn of $smartBtns) {
      let paymentMethod = smartBtn
        .getAttribute('kr-payment-method')
        .toUpperCase()

      // If smartbutton has card payment method, replace it with cards
      if (paymentMethod === 'CARD') {
        paymentMethod = 'CARDS'
        smartBtn.setAttribute('kr-payment-method', 'cards')
      }

      // If smartbutton has a card brand as a payment method, replace it
      // with cards and set kr-brand attribute
      if (hasCardBrand(paymentMethod)) {
        smartBtn.setAttribute('kr-payment-method', 'cards')
        smartBtn.setAttribute('kr-brand', paymentMethod.toLowerCase())
        paymentMethod = 'CARDS'
      }

      if (paymentMethod) {
        const $smartBtn = Zepto(smartBtn)
        const smartBtnId = uniqueId('kr-smart-button-')
        $smartBtn.attr('kr-smart-button-id', smartBtnId)
        const brands =
          $smartBtn.attr('kr-brands') || $smartBtn.attr('kr-brand') || ''
        if (!!brands) {
          this.$store.dispatch('removeCardBrands', brands)
          // Add encoded brand data to identify the button
          paymentMethod = `${paymentMethod}:${Base64.encode(
            brands.toUpperCase()
          )}`
        }
        const cleanPaymentMethod = paymentMethod.split(':')[0]

        let brandFilter =
          smartBtn.getAttribute('kr-brand-filter')?.toUpperCase() || ''
        if (brandFilter !== 'CREDIT' && brandFilter !== 'DEBIT')
          brandFilter = ''
        else if (!brands)
          paymentMethod = `${paymentMethod}:${Base64.encode(brandFilter)}`

        if (!brandFilter && !brands)
          this.$store.dispatch('removePaymentMethod', cleanPaymentMethod)

        const label = innerText(smartBtn)
        $smartBtn.empty()
        $smartBtn
          .removeClass('kr-smart-button')
          .addClass('kr-smart-button-wrapper').append(`
            <smart-button method="${paymentMethod}" brands="${brands.toUpperCase()}" custom-label="${label}" brand-filter="${brandFilter}"></smart-button>
            <krypton-layer mode="unified" method="${paymentMethod}"></krypton-layer>
            <smart-button-error reference="${paymentMethod}"></smart-button-error>
          `)

        this.$bus.$emit(Events.krypton.data.newSmartFormButton, {
          $el: $smartBtn,
          id: smartBtnId
        })
        this.$store.dispatch('addSmartButton', paymentMethod)
      }
    }
  }

  /**
   * Generates a hidden SmartForm
   */
  generateHiddenSmartForm() {
    const smartFormHtml = this.$store.state.dom.onlyTaggedElements
      ? '<div class="kr-smart-form" kr-shadow kr-element></div>'
      : '<div class="kr-smart-form" kr-shadow></div>'

    Zepto('.kr-smart-button-wrapper').first().before(smartFormHtml)
    this.$store.dispatch('update', {
      formWidget: 'smartForm',
      smartForm: {
        type: 'popin',
        hidden: true
      }
    })
  }

  /**
   * Checks if there are SmartButtons in the page
   */
  shouldGenerateSmartForm() {
    return !!document.querySelector('.kr-smart-button')
  }

  /**
   * Checks if the smartform is supported and shows the error modal
   * in case it's not
   */
  checkSupport() {
    const { isSmartForm, isSmartFormSupported } = this.$getters
    if (isSmartForm && !isSmartFormSupported) {
      this.$store.dispatch('error', 'CLIENT_505')
      this.$bus.$emit(Events.krypton.data.errorAlert)
    }
    return isSmartFormSupported
  }
}
