var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    directives: [
      { name: "show", rawName: "v-show", value: _vm.show, expression: "show" },
    ],
    staticClass: "kr-popin-background",
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }