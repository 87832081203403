import { mapState, mapActions, mapGetters } from 'vuex'

import Events from '@/configuration/Events'

/**
 * Shared logic between Grid's SmartForm & SmartFormLegacy in regards to
 * 3DS cancellation with the expanded card form.
 * In this very case when the user cancel the 3DS, we need to close the popin.
 *
 * @since KJS-4014
 */
export const RedirectCancelMixin = {
  computed: {
    ...mapGetters(['isSmartFormOpen', 'isSmartFormPopin']),
    ...mapState(['redirectIframeOpen']),
    ...mapState({
      activeMethod: ({ smartForm }) => smartForm.activeMethod,
      activeGroup: ({ smartForm }) => smartForm.activeGroup
    })
  },
  watch: {
    redirectIframeOpen(val) {
      // Handle popin closure upon 3DS termination with expanded card form
      if (
        !val &&
        !this.activeMethod &&
        !this.activeGroup &&
        this.isSmartFormOpen &&
        !this.isSmartFormPopin
      ) {
        this.closeSmartFormPopin()
      }
    }
  },
  created() {
    this.$busOn(Events.krypton.message.navigateBack, () => {
      this.navigateBack()
    })
  },
  methods: {
    ...mapActions(['closeSmartFormPopin', 'navigateBack'])
  }
}
