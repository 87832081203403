<template lang="pug">
    extends templates/SelectField.pug
</template>

<script>
import _ from 'underscore'
import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

import { SelectFieldMixin } from '@/host/components/mixins/SelectField'
import { InstallmentMixin } from '@/host/components/mixins/Installment'

export default {
  name: 'KryptonFirstInstallmentDelay',
  mixins: [SelectFieldMixin, InstallmentMixin],
  inject: ['context'],
  data() {
    return { fieldName: 'firstInstallmentDelay' }
  },
  computed: {
    ...mapState(['hasInterests', 'contentLabels']),
    ...dynamicMapState('context.namespace', {
      binDelay: state => state.binOptions.delay
    }),
    ...dynamicMapState('context.namespace', [
      'selectedBrand',
      'nonSensitiveValues'
    ]),
    isReadOnly() {
      const { installmentNumber } = this.nonSensitiveValues
      return (
        this.isDisabled ||
        this.isUnitaryTest ||
        this.isDefaultBrand ||
        this.hasNoOptions ||
        !installmentNumber ||
        installmentNumber == -1
      )
    }
  },
  watch: {
    binDelay: 'resetData',
    isReadOnly(isReadOnly) {
      // If the field should not be opened, set the default value
      if (isReadOnly) this.selectedOption = '0'
      this.setDefaultValue()
    }
  },
  methods: {
    /**
     * Generate an available option from the given data
     */
    generateOption(value, key) {
      return { id: key, count: value, label: this.generateLabel(value) }
    },

    /**
     * Generate the label with the given data
     */
    generateLabel(count) {
      count = parseInt(count)

      // Label format
      const format = !count ? 'without' : count > 1 ? 'plural' : 'singular'

      // Label
      const customLabel = this.contentLabels.firstInstallmentDelay[format]
      let label =
        customLabel || this.translate(`first_installment_delay_${format}`)
      // Add the count number
      label = label.replace('[COUNT]', count)

      return label
    },

    generatePlaceholder() {
      // For detected brands without options, show the 'without' label
      if (this.hasNoOptions && !this.isDefaultBrand) {
        return (
          this.contentLabels.firstInstallmentDelay.without ||
          this.translate('first_installment_delay_without')
        )
      }

      // For default brand, show the placeholder
      if (this.isDefaultBrand) {
        return (
          this.formPlaceholder ||
          this.translate('placeholder_first_installment_delay')
        )
      }
    }
  }
}
</script>
