import _ from 'underscore'

/**
 * @public
 */
export default class Field {
  constructor(fieldName, { validator, storeFactory }) {
    this.fieldName = fieldName
    this.validator = validator
    this.storeFactory = storeFactory
    this.locked = false
  }

  /**
   * Sets the strategy of the field
   * The strategy is in charge of get/set the value with the
   * HTML, memory, iframe, ... any source
   */
  setStrategy(strategy) {
    this.strategy = strategy
  }

  validate(silent, fields) {
    return this.strategy.validate(silent, this, fields)
  }

  onFieldEvent(store) {
    switch (store.type) {
      case 'focus':
        return this.strategy.focus()
    }
  }

  getValue() {
    if (this.locked) return undefined
    return this.strategy.getValue()
  }

  echo(store) {
    return this.strategy.echo(store)
  }

  /**
   * @see KJS-3458 A deep copy shall be passed to every iframe in order to avoid
   * unwanted changes to be transferred to all of them.
   */
  sync(payload) {
    this.strategy.sync(JSON.parse(JSON.stringify(payload)))
  }

  autofill(callback) {
    return this.strategy.autofill(callback)
  }

  setAutofillValue(data) {
    this.strategy.setAutofillValue(data)
  }
}
