import { mapGetters } from 'vuex'

export const ShakeMixin = {
  data() {
    return {
      errorAnimation: false
    }
  },
  computed: {
    ...mapGetters(['isOldBrowser'])
  },
  watch: {
    isInvalid(newVal) {
      const _this = this

      if (newVal) {
        if (this.isOldBrowser) {
          this.shakeOldBrowser()
        } else {
          _this.errorAnimation = true
          setTimeout(() => {
            _this.errorAnimation = false
          }, 501)
        }
      }
    }
  },
  methods: {
    /**
     * Shake animation for old browsers
     */
    shakeOldBrowser() {
      let element
      if (this.isIos || this.isAndroid) {
        element = Zepto(this.$el).find(
          '.kr-select-wrapper select, .kr-text-wrapper input'
        )[0]
      } else {
        element = Zepto(this.$el).find(
          '.kr-select-wrapper .kr-select, .kr-text-wrapper input'
        )[0]
      }
      let previousDisplayValue = element.style.display
      element.style.display = 'block'

      let x = -1
      let interval = setInterval(() => {
        if (x === 10) {
          element.style.marginLeft = '0px'
          clearInterval(interval)
          element.style.display = previousDisplayValue
        } else if (x === 1 || x === 9) {
          element.style.marginLeft = '-1px'
        } else if (x === 2 || x === 8) {
          element.style.marginLeft = '2px'
        } else if (x === 3 || x === 5 || x === 7) {
          element.style.marginLeft = '-4px'
        } else {
          element.style.marginLeft = '4px'
        }
        x++
      }, 50)
    }
  }
}
