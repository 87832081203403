import { mapState } from 'vuex'

export const ShakeMixin = {
  data() {
    return {
      errorAnimation: false
    }
  },
  computed: {
    ...mapState(['isOldBrowser']),
    ...mapState({
      animation: state => state.fields.error.animation
    })
  },
  watch: {
    hasError(newVal, oldVal) {
      const _this = this

      if (newVal && this.animation === 'shake') {
        if (this.isOldBrowser) {
          this.shakeOldBrowsers()
        } else {
          // Enable the class 501 ms (the animation ends in 500ms)
          this.errorAnimation = true
          setTimeout(() => {
            _this.errorAnimation = false
          }, 501)
        }
      }
    }
  },
  methods: {
    shakeOldBrowsers() {
      let interval
      let x = -1
      let element = document.querySelector('.kr-input-relative-wrapper')
      element.style.display = 'block'

      this.errorAnimation = true

      interval = setInterval(() => {
        if (x === 10) {
          element.style.marginLeft = '0px'
          this.errorAnimation = false
          clearInterval(interval)
        } else if (x === 1 || x === 9) {
          element.style.marginLeft = '-1px'
        } else if (x === 2 || x === 8) {
          element.style.marginLeft = '2px'
        } else if (x === 3 || x === 5 || x === 7) {
          element.style.marginLeft = '-4px'
        } else {
          element.style.marginLeft = '4px'
        }
        x++
      }, 50)
    }
  }
}
