var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-modal", class: _vm.dynamicClasses },
    [
      _c("KryptonPopinHeader", {
        attrs: { "is-smart-form": true },
        on: { close: _vm.closeModal },
      }),
      _c("SmartFormGridModalContent", {
        style: _vm.dynamicStyles.modal,
        attrs: { "active-view": _vm.currentView },
        scopedSlots: _vm._u(
          [
            {
              key: "full",
              fn: function () {
                return [
                  _vm.hasLabel && !_vm.hasPartialPayments
                    ? _c("label", { staticClass: "kr-modal-view-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("colon")(_vm._f("capitalize")(_vm.label))
                          )
                        ),
                      ])
                    : _vm._e(),
                  _vm.open
                    ? _c(
                        "div",
                        { staticClass: "kr-modal-view-content" },
                        [
                          _c("PartialPaymentPanel"),
                          _vm.hasWallet
                            ? _c("SmartFormGridWallet", {
                                on: { selected: _vm.openMethod },
                              })
                            : _vm._e(),
                          _vm.hasCards
                            ? _c("SmartFormGridCards", {
                                attrs: { "only-button": true },
                                on: { selected: _vm.openMethod },
                              })
                            : _vm._e(),
                          _vm.hasOtherMethods
                            ? _c("SmartFormGridModalOpmMain", {
                                on: { selected: _vm.openMethod },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "group",
              fn: function () {
                return [
                  _vm.isSmartFormPopin
                    ? _c("SmartFormModalBackButton", {
                        attrs: { visible: true },
                      })
                    : _vm._e(),
                  _vm.open
                    ? _c(
                        "div",
                        { staticClass: "kr-modal-view-content" },
                        [
                          _c("SmartFormGridModalOpmGroup", {
                            attrs: { group: _vm.activeGroup },
                            on: { selected: _vm.openMethod },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _vm.isSmartFormPopin
                    ? _c("SmartFormModalBackButton", {
                        attrs: { visible: true },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "kr-modal-view-content",
                      class: {
                        "kr-modal-view-content--no-button":
                          !_vm.isSmartFormPopin,
                      },
                    },
                    [!_vm.renderEmbeddedForm ? _vm._t("cardForm") : _vm._e()],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "redirection",
              fn: function () {
                return [
                  !_vm.isCardMethodActive && _vm.isSmartFormPopin
                    ? _c("SmartFormModalBackButton", {
                        attrs: { visible: true },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.redirectIframeOpen,
                          expression: "redirectIframeOpen",
                        },
                      ],
                      staticClass: "kr-modal-content__redirection",
                      style: _vm.dynamicStyles.container,
                    },
                    [_c("KryptonGhostContainer")],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "extra",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "kr-modal-view-content" },
                    [_vm._t("extra")],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "extras",
              fn: function () {
                return [
                  _vm.isSmartFormPopin
                    ? _c("ExtrasForm", {
                        attrs: { "display-mode": "embedded" },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _vm.currentView !== "content"
        ? _c(
            "div",
            { staticClass: "kr-modal__error" },
            [_c("SmartFormError")],
            1
          )
        : _vm._e(),
      _c("KryptonPopinFooter"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }