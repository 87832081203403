import Zepto from 'zepto-webpack'
import appLoader from '@/common/loader/app'

/** COMPONENTS */
import KryptonBrandButtons from '@/host/components/controls/KryptonBrandButtons'

export default locator => {
  return ({ brandButtonsId }) => {
    const el = `.kr-brand-buttons-wrapper[kr-brand-buttons-id="${brandButtonsId}"]`
    return appLoader(locator, Zepto(el), {
      el,
      components: { KryptonBrandButtons }
    })
  }
}
