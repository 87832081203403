/**
 * recieves:
 *  popup size -> {height: x, width: y}
 *  window object
 *
 * returns:
 *  coordinates for popup top left corner
 *
 *  centered horizontally by the content (ignore scrollbar) - innerWidth
 *  centered vertically by the entire window (taking into account browser header) - outerHeight
 */
export const calcTopLeftCorner = (
  { height, width },
  { screenY, outerHeight, screenX, innerWidth }
) => {
  return {
    top: screenY + (outerHeight - height) / 2,
    left: screenX + (innerWidth - width) / 2
  }
}
