import { getHost } from '@/common/util/url'
import { getScriptElement } from '@/common/util/dom'
import { logSentryException } from '@/common/util/sentry'
import { isFunction } from 'underscore'
import Events from '@/configuration/Events'
import BaseDetector from '@/host/service/BaseDetector'
import EnvironmentsNotSupported from '@/configuration/sources/EnvironmentsNotSupported.yml'

export default class Checker {
  constructor({ $bus, $store, logger }) {
    this.$bus = $bus
    this.$store = $store
    this.logger = logger

    try {
      this.mainInfo()
      this.environment()
      this.frameworks()
      this.checkParams('script')
      this.timing()
    } catch (error) {
      logSentryException(error)
    }
  }

  /**
   * Checks the main library info
   */
  mainInfo() {
    const $script = getScriptElement()
    if (!$script) return
    const msg = {
      header: 'Krypton Javascript Client %%release_version%%',
      logs: []
    }

    // Version
    const domain = getHost(BaseDetector.read($script, 'V4.0'))
    if (domain) {
      msg.logs.push(`Loaded from ${domain}`)
      this.logger.addInitialInfo(msg)
    }
  }

  /**
   * Checks the info about the environment
   */
  environment() {
    const msg = {
      header: 'Environment',
      logs: []
    }

    let { browser, os, platform } = this.$store.state
    // If platform is not supported raise an error
    if (this._isEnvironmentNotSupported(browser, os, platform)) {
      this.$store.dispatch('error', {
        errorCode: 'CLIENT_994',
        metadata: { browserName: browser?.name, console: true }
      })
    }
    msg.logs.push(`OS: ${os.name} ${os.version}`)
    msg.logs.push(`Browser: ${browser.name} ${browser.version}`)
    msg.logs.push(`Charset: ${document.characterSet}`)
    msg.logs.push(`Language: ${navigator.language}`)
    this.logger.addInitialInfo(msg)
  }

  /**
   * Tries to detect the frameworks loaded in the page
   */
  frameworks() {
    const msg = {
      header: 'Frameworks detected',
      logs: []
    }

    // Vue
    if (!!window.Vue || !!window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
      let log = 'Vue.js'
      // If there is a react browser extension active
      if (window.__VUE_DEVTOOLS_GLOBAL_HOOK__?.Vue) {
        log = `${log}: ${__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue.version}`
      }
      msg.logs.push(log)
    }
    // Nuxt.js
    if (!!window.$nuxt) msg.logs.push('Nuxt.js')
    // React
    if (!!window.React || !!window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      let log = 'React'
      // If there is a react browser extension active
      if (
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__?.renderers?.values()?.next()
          ?.value
      ) {
        log = `${log}: ${
          __REACT_DEVTOOLS_GLOBAL_HOOK__.renderers.values().next().value.version
        }`
      }
      msg.logs.push(log)
    }

    // Angular
    if (!!window.angular || !!document.querySelector('[ng-version]')) {
      let log = 'Angular'
      const $el = document.querySelector('[ng-version]')
      if ($el) log = `${log}: ${$el.getAttribute('ng-version')}`
      msg.logs.push(log)
    }

    // jQuery
    if (!!window.jQuery) {
      let log = 'jQuery'
      const version = isFunction(window.jQuery) ? window.jQuery().jquery : null
      if (version) log += `: ${version}`
      msg.logs.push(log)
    }
    // Zepto
    if (!!window.Zepto) msg.logs.push('Zepto.js')

    // Ember.js
    if (!!window.Ember) {
      let log = 'Ember.js'
      const version = window.Ember?.VERSION
      if (version) log += `: ${version}`
      msg.logs.push(log)
    }
    // Backbone.js
    if (!!window.Backbone) {
      let log = 'Backbone.js'
      const version = window.Backbone?.VERSION
      if (version) log += `: ${version}`
      msg.logs.push(log)
    }
    // Svelte
    if (!!document.querySelector('[class^="svelte-"]')) {
      msg.logs.push('Svelte')
    }

    this.logger.addInitialInfo(msg)
  }

  /**
   * Checks the kr params of the indicated element
   */
  checkParams(el = 'script') {
    const { getFormElement } = this.$store.getters

    const $el = el === 'script' ? getScriptElement() : getFormElement()
    if (!$el) return
    const blackList = ['kr-parsed', 'kr-resource']
    const msg = {
      header:
        el === 'script'
          ? 'Static parameters defined by the merchant:'
          : 'Initial parameters:',
      logs: []
    }

    Array.prototype.forEach.call($el.attributes, attr => {
      if (
        attr.name.indexOf('kr-') === 0 &&
        blackList.indexOf(attr.name) === -1
      ) {
        msg.logs.push(`${attr.name}: ${attr.value}`)
      }
    })
    this.logger.addInitialInfo(msg)
  }

  /**
   * Gets the timing info (network/app)
   */
  timing() {
    this.$bus.$on(Events.krypton.iframes.allReady, message => {
      // Network logs
      const networkLogs = this.$store.state.networkLoadTimeData
      let msg = {
        header: 'Timing information (Network)',
        logs: []
      }
      networkLogs.forEach(log => {
        if (log) msg.logs.push(`${log.detail}: ${log.time}ms`)
      })
      this.logger.addInitialInfo(msg)
      // App logs
      const appLogs = this.$store.state.appLoadTimeData
      msg = {
        header: 'Timing information (App)',
        logs: []
      }
      appLogs.forEach(log => {
        if (log) msg.logs.push(`${log.detail}: ${log.time}ms`)
      })
      // Total app load time
      const totalApp = this.$store.state.totalAppLoadTime
      if (totalApp) msg.logs.push(`${totalApp.detail}: ${totalApp.time}ms`)
      this.logger.addInitialInfo(msg)

      // Trigger initial log output
      this.logger.initialLog()
    })
  }

  _isEnvironmentNotSupported(browser, os, platform) {
    for (const {
      browser: envBrowser,
      os: envOs,
      platform: envPlatform
    } of EnvironmentsNotSupported) {
      if (
        (!envOs?.name || envOs.name === os.name) &&
        (!envOs?.version || parseFloat(os.version) <= envOs.version) &&
        (!envBrowser?.name || envBrowser.name === browser.name) &&
        (!envBrowser?.version ||
          parseFloat(browser.version) <= envBrowser.version) &&
        (!envPlatform?.type || envPlatform.type === platform.type) &&
        (!envPlatform?.model || envPlatform.model === platform.model)
      )
        return true
    }
    return false
  }
}
