var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "krypton-alert-popin-error" }, [
        _c("div", { staticClass: "kr-alert-icon" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "50",
                height: "50",
                viewBox: "0 0 24 24",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z",
                },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "kr-alert-text" }, [
          _c("span", { staticClass: "kr-alert-text-header" }, [
            _vm._v(_vm._s(_vm.translate("error_alert_title"))),
          ]),
          _c("span", [
            _vm._v(_vm._s(_vm.translate("error_alert_type")) + ": "),
            _c("span", { staticClass: "kr-alert-text-info kr-error-code" }, [
              _vm._v(_vm._s(_vm.errorCode)),
            ]),
          ]),
          _c("span", [
            _vm._v(_vm._s(_vm.translate("error_alert_description")) + ": "),
            _c("span", { staticClass: "kr-alert-text-info kr-error-msg" }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "kr-alert-close",
            on: {
              click: function ($event) {
                _vm.visible = false
              },
            },
          },
          [_c("div", { staticClass: "kr-close-icon" })]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }