import { mapState } from 'vuex'

/**
 * This mixin is used to handle the opening & closing animation for the modal,
 * given modal.isOpen value in store.
 *
 * Hence it is extended by SimpleModal & KryptonLayer components.
 *
 * @since KJS-2617
 */
export default {
  data() {
    return {
      show: false,
      animation: {
        open: {
          active: false,
          delay: 0,
          duration: 150
        },
        close: {
          active: false,
          delay: 0,
          duration: 150
        }
      }
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.modal.isOpen
    })
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.animate('open')
      } else {
        this.animate('close')
      }
    }
  },
  methods: {
    /**
     * @param {String} type (open|close)
     */
    animate(type) {
      setTimeout(() => {
        this.animation[type].active = true
        if (type === 'open') this.show = true
        setTimeout(() => {
          this.animation[type].active = false
          if (type === 'close') {
            this.show = false
            this.finishClosing()
          }
        }, this.animation[type].duration)
      }, this.animation[type].delay)
    },

    finishClosing() {
      this.$store.commit('UPDATE', { modal: { isClosing: false } })
      this.$store.dispatch('finishModalClosing')
    }
  }
}
