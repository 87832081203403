export const slaveConfig = {
  networkSelectionMode: {
    1: {
      values: { visa: 'cb', mastercard: 'cb' }
    },
    2: {
      values: { visa: 'cb', mastercard: 'mastercard' }
    },
    3: {
      values: { visa: 'visa', mastercard: 'cb' }
    },
    4: {
      values: { visa: 'visa', mastercard: 'mastercard' }
    }
  }
}
