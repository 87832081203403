var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    staticClass: "kr-smart-form-modal-button",
    class: { "kr-loading": _vm.loading, "kr-disabled": _vm.disabledForm },
    style: _vm.styles,
    attrs: { type: "button", disabled: _vm.isDisabled },
    domProps: { innerHTML: _vm._s(_vm.payBtnHTML) },
    on: {
      click: function ($event) {
        return _vm.open()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }