'use strict'

import AbstractState from '@/ghost/workflow/payment/state/AbstractState'
import DataFormatState from '@/ghost/workflow/payment/state/DataFormatState'

export default class InitialState extends AbstractState {
  constructor(...args) {
    super(...args)
    this.dataFormatClass = null
  }

  validate(...methodArgs) {
    const form = this.context.form
    const payAction = this.context.payAction
    const context = this.context

    let resultsInstance = form.validate(...methodArgs)

    this.dataFormatClass = DataFormatState

    let newState = this.factoryNextState(payAction)
    context.changeState(newState)

    return Promise.resolve(resultsInstance)
  }

  factoryNextState(payAction) {
    const DataFormatState = this.dataFormatClass
    return new DataFormatState(this.getArgs())
  }
}
