import { mapGetters, mapState } from 'vuex'

export const SmartFormThemeMixin = {
  data() {
    return {
      observer: null,
      height: 0,
      width: 0
    }
  },
  computed: {
    ...mapState({
      sizeConfig: state => state.form.smartform.size
    })
  },
  watch: {
    width: 'onResize'
  },
  mounted() {
    this.setResizeObserver()
  },
  beforeDestroy() {
    this.unsetResizeObserver()
  },
  methods: {
    getSizeLabel() {
      const { thresholds } = this.sizeConfig

      for (const label in thresholds) {
        if (this.width < thresholds[label]) {
          return label
        }
      }
      return 'XXL'
    },

    /**
     * Detect root element width update in order to set the corresponding
     * size label in store according the configuration thresholds.
     *
     * @since KJS-2846
     */
    onResize() {
      const size = this.getSizeLabel()
      this.$store.dispatch('update', {
        smartForm: { size }
      })
    },

    /**
     * Set resize observer to dynamically update root element width & height
     *
     * @since KJS-2846
     */
    setResizeObserver() {
      this.width = this.$el.offsetWidth
      this.height = this.$el.offsetHeight

      if (!global.ResizeObserver) return
      this.observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const { width, height } = entry.contentRect
          this.height = height
          this.width = width
        }
      })
      this.observer.observe(this.$el)
    },

    /**
     * Unset observer before element is destroyed.
     *
     * @since KJS-2846
     */
    unsetResizeObserver() {
      if (!global.ResizeObserver || !(this.observer instanceof ResizeObserver))
        return
      this.observer.unobserve(this.$el)
    }
  }
}
