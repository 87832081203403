import _ from 'underscore'
import Events from '@/configuration/Events'

export const getNavigationState = () => {
  return {
    navigation: {
      currentFocus: '',
      available: [],
      closed: false
    }
  }
}

export const navigationActions = $locator => {
  return {
    setNavigableElements({ commit, state, getters }) {
      const { smartForm } = state
      const { activeMethod, availablePaymentMethods } = smartForm
      const { hasCardFormExpandedInside } = getters
      // can't navigate via this module to CARDS if it's expanded card form
      const filteredPaymentMethods = !hasCardFormExpandedInside
        ? availablePaymentMethods
        : availablePaymentMethods.filter(pm => pm !== 'CARDS')

      let available = []

      if (!activeMethod) {
        available = filteredPaymentMethods
      } else if (getters.isCardMethod() && getters.isSmartFormPopin) {
        available = ['back']
      }

      commit('UPDATE', { navigation: { available } })
    },
    focusNext({ commit, state, getters, dispatch }) {
      const { currentFocus, available } = state.navigation
      const { isSmartFormOpen, hasCardFormExpandedInside, activeFormId } =
        getters
      // If the popin is open, move the focus to the content
      if (
        !available.length ||
        (currentFocus === 'back' &&
          available.length === 1 &&
          available[0] === 'back')
      ) {
        if (!isSmartFormOpen) return
        else {
          const $tabHandler = document.querySelector(
            '.krypton-tab-handler input.krypton-focus-handler'
          )
          $tabHandler.focus()
          dispatch('resetFocus')
          return
        }
      }

      const currentIndex = available.indexOf(currentFocus)
      if (currentIndex === available.length - 1 && hasCardFormExpandedInside) {
        dispatch('resetFocus')
        const { fieldsOrder } = state[`cardForm_${activeFormId}`]
        const nextFieldFieldName = fieldsOrder[0]
        $locator.$bus.$emit(Events.krypton.field.focus, {
          formId: activeFormId,
          name: nextFieldFieldName
        })
        return
      }
      const nextIndex =
        currentIndex === available.length - 1 || currentIndex === -1
          ? 0
          : currentIndex + 1
      const toFocus = `${available[nextIndex]}`

      commit('UPDATE', {
        navigation: { currentFocus: toFocus }
      })
    },
    focusPrevious({
      commit,
      state,
      dispatch,
      getters: { hasCardFormExpandedInside, activeFormId }
    }) {
      const { currentFocus, available } = state.navigation
      if (!available.length) return
      const currentIndex = available.indexOf(currentFocus)
      if (currentIndex === 0 && hasCardFormExpandedInside) {
        dispatch('resetFocus')
        const { fieldsOrder } = state[`cardForm_${activeFormId}`]
        const previousFieldName = fieldsOrder[fieldsOrder.length - 1]
        $locator.$bus.$emit(Events.krypton.field.focus, {
          formId: activeFormId,
          name: previousFieldName
        })
        return
      }

      const previousIndex =
        currentIndex <= 0 ? available.length - 1 : currentIndex - 1
      const toFocus = `${available[previousIndex]}`
      commit('UPDATE', {
        navigation: { currentFocus: toFocus }
      })
    },
    focus({ commit, state }, element) {
      if (!~state.navigation.available.indexOf(element)) return
      commit('UPDATE', {
        navigation: { currentFocus: element }
      })
    },
    resetFocus({ commit }) {
      commit('UPDATE', { navigation: { currentFocus: '' } })
    }
  }
}
export const navigationMutations = {}
export const navigationGetters = {}
