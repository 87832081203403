var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCardToken
    ? _c("SmartFormGridWalletCardButton", {
        attrs: { method: _vm.method, locked: _vm.locked },
        on: { selected: _vm.onSelected },
      })
    : _c("SmartFormGridWalletMethodButton", {
        attrs: { method: _vm.method, locked: _vm.locked },
        on: { selected: _vm.onSelected },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }