var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "kr-back-button",
      class: { "kr-focus": _vm.hasFocus },
      attrs: { type: "button", disabled: _vm.disabled || !_vm.visible },
      on: {
        click: function ($event) {
          return _vm.goBack()
        },
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.goBack()
        },
      },
    },
    [
      _c("div", {
        staticClass: "kr-arrow-left",
        domProps: { innerHTML: _vm._s(_vm.leftArrow) },
      }),
      _c("label", [_vm._v(_vm._s(_vm.renderedLabel))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }