<template lang="pug">
  .krypton-tab-handler(style="position: fixed;left: -9999px;top: -9999px;")
    input.kr-smart-form-focus-handler(type="text", @focus="onFocus")
</template>

<script>
const POSITION = Object.freeze({
  FIRST: 'first',
  LAST: 'last'
})
const DIRECTION = Object.freeze({
  PREVIOUS: 'previous',
  NEXT: 'next'
})

/**
 * ONLY FOR POPIN
 *
 * Added for cycling tab navigation within modal.
 * Requires a parent component to register and find its siblings.
 * E.g, given:
 *   <ParentComponent>
 *    <TabGuard position="first">
 *    <button class="b1">First</button>
 *    <button class="b2">Second</button>
 *    <button class="b3">Third</button>
 *    <TabGuard position="last">
 *   </ParentComponent>
 *
 * Then:
 *  ParentComponent will be set the key "_tabLoop.default" with properties:
 *    - first: the reference to the first TabGuard
 *    - last: the reference to the last TabGuard
 *
 * Hence:
 *  TabGuard(first).sibling === TagGuard(last)
 *  TabGuard(last).sibling === TagGuard(first)
 *
 * It is also possible to specify a "pair" name in order to identify the
 * both guards within the same trap/loop. This allow to add different tab
 * traps within the same component, like it is the case with modal which has
 * different views.
 *
 * E.g, given:
 *   <ParentComponent>
 *     <div class="view view-01">
 *       <TabGuard pair="view-01" position="first">
 *       <button class="b1">First</button>
 *       <button class="b2">Second</button>
 *       <button class="b3">Third</button>
 *       <TabGuard pair="view-01" position="last">
 *     </div
 *     <div class="view view-02">
 *       <TabGuard pair="view-02" position="first">
 *       <button class="b1">First</button>
 *       <button class="b2">Second</button>
 *       <button class="b3">Third</button>
 *       <TabGuard pair="view-02" position="last">
 *     </div
 *   </ParentComponent>
 *
 * Then:
 *  ParentComponent will be set the key `_tabLoop["view-01"]`
 *    & `_tabLoop["view-02"]` with properties first & last.
 *
 * @since KJS-3722
 * @see KJS-4026 | Add "pair" property to identify the pair
 */
export default {
  name: 'SmartFormTabGuard',
  props: {
    pair: {
      type: String,
      default: 'default'
    },
    position: {
      type: String,
      default: POSITION.LAST
    }
  },
  computed: {
    sibling() {
      return this.position === POSITION.FIRST ? this.last : this.first
    },
    first() {
      return this.$parent._tabLoop[this.pair].first
    },
    last() {
      return this.$parent._tabLoop[this.pair].last
    }
  },
  created() {
    this.$parent._tabLoop = this.$parent._tabLoop || {}
    if (this.$parent._tabLoop[this.pair] == null) {
      this.$parent._tabLoop[this.pair] = {}
    }
    this.$parent._tabLoop[this.pair][this.position] = this
  },
  methods: {
    /**
     * @param {FocusEvent} e
     */
    onFocus(e) {
      const direction = this.getDirection(e)
      this.handback(direction)
    },
    /**
     * @param {FocusEvent} e
     * @returns {string} "previous" | "next"
     */
    getDirection(e) {
      return e.relatedTarget instanceof Node &&
        this.$el.compareDocumentPosition(e.relatedTarget) ===
          Node.DOCUMENT_POSITION_FOLLOWING
        ? DIRECTION.PREVIOUS
        : DIRECTION.NEXT
    },
    /**
     * @param {string} direction "previous" | "next"
     */
    handback(direction) {
      if (this.position === POSITION.LAST) {
        if (direction === DIRECTION.NEXT) {
          return this.sibling.handback(direction)
        } else {
          return this.findAndFocus(direction)
        }
      } else {
        if (direction === DIRECTION.PREVIOUS) {
          return this.sibling.handback(direction)
        } else {
          return this.findAndFocus(direction)
        }
      }
    },
    findAndFocus(direction) {
      const target = this.findTarget(direction)
      target?.focus()
      return target
    },
    /**
     * @param {string} direction "previous" | "next"
     * @returns {HTMLElement}
     */
    findTarget(direction) {
      const focusableElements = Array.prototype.slice.call(
        this.$parent.$el.querySelectorAll(
          'input:enabled:not(.kr-input-field), select:enabled, button:enabled, textarea:enabled'
        )
      )
      const idx = focusableElements.findIndex(el => this.$el.contains(el))
      return focusableElements[direction === DIRECTION.NEXT ? idx + 1 : idx - 1]
    }
  }
}
</script>
