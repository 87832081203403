import _ from 'underscore'
import Form from '@/ghost/workflow/Form'
import { mapState } from 'vuex'
import Events from '@/configuration/Events'
import PaymentContext from '@/ghost/workflow/payment/PaymentContext'
import { logSentryException } from '@/common/util/sentry'

export default {
  data() {
    return {
      forms: [],
      context: null,
      events: [
        'echo',
        'validate',
        'fieldEvent',
        'focus',
        'actionNewForm',
        'popinLoad'
      ]
    }
  },
  computed: {
    ...mapState(['allIFramesReady'])
  },
  watch: {
    allIFramesReady(val) {
      if (!val) return
      this.reportForm('setupAutofill')
    }
  },
  created() {
    // Start subscriptions
    for (const event of this.events) {
      this.$bus.$on(Events.krypton.message[event], this[event])
    }
  },
  methods: {
    /**
     * Register a workflow for the given form identifier.
     */
    registerWorkflow(formId) {
      const form = this.getForm(formId)
      this.context = new PaymentContext(this.$locator, form)
      this.$locator.context = this.context
      return this.context
    },

    /**
     * Returns the context of the form
     */
    getWorkflow() {
      return this.context
    },

    /**
     * Returns the form object with the given formId
     */
    getForm(id) {
      const form = this.forms.find(form => form.id === id)
      if (form) return form
      // Shouldn't happen but KJS-3505
      if (!form && this.forms.length === 1) {
        const error = new Error(`Form not found`)
        logSentryException(
          error,
          'FormMaster.getForm',
          `formId: ${id}, available: ${this.forms.map(form => form.id)}`
        )
        return this.forms[0]
      }
      return null
    },

    /**
     * Creates a new form on the view
     */
    actionNewForm({ _name, formId, dna }) {
      if (this[_name]) {
        const newForm = new Form(formId, dna, this.$locator)

        // Set the strategies
        for (const fieldName of newForm.getFieldNames()) {
          const field = newForm.getField(fieldName)
          const createdStrategy = this.$locator.strategyDetector.get(fieldName)
          createdStrategy.formId = formId
          field.setStrategy(createdStrategy)
        }

        this.$store.dispatch('registerCardFormModule', { id: formId })
        this.forms.push(newForm)
      }
    },

    /**
     * Validates the form with the given id
     */
    validate({ formId, silent = true, throwError = true }, isRequest = true) {
      const { proxy, storeFactory } = this.$locator
      const error = this.getForm(formId).validate(false, silent)

      // Regular validation call, return errors
      if (!isRequest) return error

      // JS Client side call, return the error as request
      if (error && throwError) this.$store.dispatch('error', error)
      proxy.send(storeFactory.create('validationResponse', { error }))
    },

    /**
     * Calls the given method from form object
     */
    reportForm(methodName, store = {}) {
      if (store.namespace && this.$store.hasModule(store.namespace)) {
        const form = this.forms.find(form => form.match(store.namespace))
        if (!form) return null
        form[methodName](store)
      } else if (
        store.formId &&
        this.$store.hasModule(`cardForm_${store.formId}`)
      ) {
        const form = this.getForm(store.formId)
        if (!form) return null
        form[methodName](store)
      } else {
        this.forms.forEach(form => {
          form[methodName](store)
        })
      }
    },

    echo(store) {
      this.reportForm('echo', store)
    },

    fieldEvent(store) {
      if (this[store._name]) this.reportForm('fieldEvent', store)
    },

    focus(store) {
      if (this[store._name])
        this.reportForm('fieldEvent', { type: 'focus', ...store })
    },

    popinLoad() {
      const { proxy, storeFactory } = this.$locator
      proxy.send(storeFactory.create('popinLoad'))
    }
  }
}
