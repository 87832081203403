<template lang="pug">
.kr-smart-form-modal-view(:class="dynamicClasses")
  SmartFormTabGuard(position="first")
  slot
  SmartFormTabGuard(position="last")
</template>

<script>
import SmartFormTabGuard from '@/host/components/smartform/TabGuard'

/**
 * Wrapper every Modal view into seperate instance of the same component.
 * This is used to allow each to handle its own data related to the last
 * focused element. This way when changing view (e.g: by selecting CARDS in the
 * popin), if we hit the back button, we come back to the view with CARDS btn
 * focused.
 *
 * This component also allow each view to have its own separate data related to
 * the TabGuards in order to handle to focus trap within the same view.
 *
 * @since KJS-4026
 */
export default {
  name: 'SmartFormModalView',
  components: { SmartFormTabGuard },
  props: {
    name: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      lastElementFocused: null
    }
  },
  computed: {
    dynamicClasses() {
      return {
        [`kr-smart-form-modal-view-${this.name}`]: true,
        'kr-smart-form-modal-view--active': this.active
      }
    }
  },
  watch: {
    active(val) {
      if (!val) {
        this.onLeave()
      }
    }
  },
  methods: {
    onEnter() {
      if (this.lastElementFocused instanceof HTMLElement) {
        this.lastElementFocused.focus()
      } else {
        this.lastElementFocused = this._tabLoop.default.first.handback('next')
      }
    },
    onLeave() {
      this.lastElementFocused = document.activeElement
    }
  }
}
</script>
