import { dynamicMapGetters } from '@/common/util/store'

export const StateMixin = {
  computed: {
    ...dynamicMapGetters('context.namespace', ['isFieldDisabled']),
    isDisabled() {
      return this.isFieldDisabled(this.name)
    },
    hasError: {
      get() {
        const { namespace } = this.context
        return namespace && this.$store.hasModule(namespace)
          ? this.$store.getters[`${namespace}/isFieldInvalid`](this.name)
          : undefined
      },
      set(hasError) {
        this.sendToProxy(hasError ? 'fieldInvalid' : 'fieldValid')
      }
    },
    isViable: {
      get() {
        const { namespace } = this.context
        return namespace && this.$store.hasModule(namespace)
          ? this.$store.getters[`${namespace}/isFieldViable`](this.name)
          : undefined
      },
      set(isViable) {
        this.sendToProxy(isViable ? 'fieldViable' : 'fieldInviable')
      }
    }
  },
  methods: {
    sendToProxy(name) {
      this.$bus.$emit('proxy.send', {
        _name: name,
        name: this.name,
        formId: this.context.formId
      })
    }
  }
}
