import baseStyle from '@/styles/dist/no-theme.min.css'
import errorPanelStyle from '@/styles/dist/error-panel.min.css'

export default class FormStyler {
  getBaseStyles() {
    return baseStyle
  }

  getErrorPanelStyles() {
    return errorPanelStyle
  }
}
