import { ViewArea } from '@/common/model'

/**
 * Used to dynamically detect viewport size update in slaves.
 * Initially added for SecurityCode to be able to detect wether French
 * placeholder "Code de sécurité" fit and if not, revert to "CVV".
 *
 * @since KJS-3895
 */
export const ViewportMixin = {
  data() {
    return {
      viewportWidth: 0
    }
  },
  mounted() {
    this.setViewportWidth()
    this.$el.__vp_resize_handler = () => {
      this.setViewportWidth()
    }
    window.addEventListener('resize', this.$el.__vp_resize_handler)
  },
  destroyed() {
    window.removeEventListener('resize', this.$el.__vp_resize_handler)
  },
  methods: {
    setViewportWidth() {
      const vp = ViewArea.create()
      this.viewportWidth = vp.width()
    }
  }
}
