var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-extras-form-modal-wrapper kr-smart-form-modal-wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "kr-extras-form-modal kr-smart-form-modal",
          class: _vm.dynamicClasses.modal,
        },
        [
          _c("KryptonPopinHeader", {
            attrs: { "is-smart-form": true },
            on: {
              close: function ($event) {
                return _vm.closeModal()
              },
            },
          }),
          _vm._t("default"),
          _c("KryptonPopinFooter"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }