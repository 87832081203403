var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-method-content", class: _vm.dynamicClasses },
    [
      _vm.renderBackButton
        ? _c("SmartFormModalBackButton", {
            attrs: {
              label: _vm.methodLabel,
              disabled: !_vm.isBackButtonEnabled,
              visible: _vm.visible,
            },
            on: { click: _vm.goBack },
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }