var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-modal-content", class: _vm.wrapperDynamicClasses },
    _vm._l(_vm.$scopedSlots, function (_, name) {
      return _c(
        "div",
        {
          staticClass: "kr-modal-content__view",
          class: _vm.dynamicClasses(name),
        },
        [_vm._t(name)],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }