var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.method
    ? _c(
        "button",
        {
          staticClass: "kr-grid-back-button kr-method-btn",
          class: _vm.dynamicClasses,
          on: {
            mouseover: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
            click: function ($event) {
              return _vm.$emit("back")
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "kr-icon" },
            [_c("BrandIcon", { attrs: { method: _vm.method } })],
            1
          ),
          _c("div", { staticClass: "kr-method-btn__label" }, [
            _c("label", [_vm._v(_vm._s(_vm.methodLabel))]),
          ]),
          _c("div", {
            staticClass: "kr-back-button__icon",
            domProps: { innerHTML: _vm._s(_vm.iconHtml) },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }