import _ from 'underscore'
import Zepto from 'zepto-webpack'

export default function () {
  let nodes = Zepto('script')
  let path = '/'

  _.each(nodes, node => {
    let src = node.getAttribute('src')
    if (/\/stable\/kr-payment-form\.min\.js/.test(src)) {
      src = src.split('#')[0].split('?')[0]
      src = src.split('/stable/kr-payment-form.min.js')[0]
      path = `${src}/stable/`
    }
  })

  return path
}
