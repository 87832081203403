import _ from 'underscore'

export const FocusHelperMixin = {
  methods: {
    /**
     * -- iOS only --
     * Forced blur
     */
    focusHelper() {
      if (this.$store.getters.isIos) {
        const $focusHunter = document.querySelector('.kr-focus-hunter')
        if ($focusHunter) {
          $focusHunter.focus()
          document.activeElement.blur()
          $focusHunter.blur()
        }

        // Blur all the fields
        this.$store.dispatch('forceFieldBlur')
      }
    }
  }
}
