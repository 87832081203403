import Events from '@/configuration/Events'
import formDomElements from '@/configuration/sources/FormDomElements.yml'
import { removeNodes } from '../common/util/dom'
import { spacedToKebabCase } from '@/common/util/string'

export default class FormCleaner {
  constructor({ $bus, $store }) {
    this.$bus = $bus
    this.$store = $store
  }

  resetDom() {
    this.resetSmartButtons()
    this.resetSmartForm()
    this.resetEmbeddedForm()
    this.$bus.$emit(Events.krypton.destroy)
  }

  resetSmartButtons() {
    const { getSmartButtonWrapperElements } = this.$store.getters
    const { removeSmartButtonAttributes } = formDomElements
    const buttonWrappers = getSmartButtonWrapperElements()

    if (buttonWrappers) {
      buttonWrappers.forEach($smartButton => {
        // Change kr-smart-button-wrapper -> kr-smart-button
        $smartButton.classList.replace(
          'kr-smart-button-wrapper',
          'kr-smart-button'
        )

        removeSmartButtonAttributes.forEach(attr =>
          $smartButton.removeAttribute(attr)
        )
        $smartButton.style.display = ''
        $smartButton.textContent = ''
      })
    }
  }

  resetSmartForm() {
    const { getSmartFormElement, getFormElement } = this.$store.getters
    const { removeSmartformAttributes } = formDomElements
    const $smartform = getSmartFormElement()
    if ($smartform) {
      if (!$smartform.hasAttribute('kr-shadow')) {
        removeSmartformAttributes.forEach(attr =>
          $smartform.removeAttribute(attr)
        )

        const $embedded = getFormElement($smartform)
        $smartform.textContent = ''

        if ($embedded?.hasAttribute('kr-merchant-resource')) {
          $smartform.appendChild($embedded)
        }
      } else {
        $smartform.remove()
      }
    }
  }

  resetEmbeddedForm() {
    const {
      removeEmbeddedAttributes,
      removeEmbeddedClasses,
      removeExternal,
      removeFieldAttributes,
      removeFieldClasses
    } = formDomElements
    const { getFormElement, isSmartForm } = this.$store.getters

    removeNodes(removeExternal.join(','))

    const $form = getFormElement()
    if ($form) {
      const krType = $form.getAttribute('kr-type')

      // non smartform popin -> remove wrapper move kr-embedded to root
      if (krType === 'popin') {
        const $parent = $form.parentElement
        $parent.parentElement.appendChild($form)
        $parent.remove()

        // in embedded popin an extra wrapper is added to the contents
        const $innerWrapper =
          $form.querySelector('.kr-inner-popin-wrapper') ?? $form
        Array.from($innerWrapper.children).forEach($child =>
          $form.appendChild($child)
        )
      }

      if (this.$store.state.renderMode === 'addForm') {
        $form.remove()
      } else {
        // remove/clean OUR internal elements
        $form.querySelectorAll('[kr-resource]').forEach($el => {
          if (!$el.hasAttribute('kr-merchant-resource')) {
            $el.remove()
          } else {
            $el.textContent = ''

            removeFieldAttributes.forEach(attr => {
              $el.removeAttribute(attr)
            })

            $el.classList.remove(...removeFieldClasses)
          }
        })

        const error = $form.querySelector('.kr-form-error')
        if (error) error.style.display = 'none'

        removeEmbeddedAttributes.forEach(attr => $form.removeAttribute(attr))
        $form.classList.remove(
          ...removeEmbeddedClasses,
          ...this.getPlatformDependantClasses()
        )

        if (isSmartForm) {
          $form.style.display = 'none'
        }
      }
    }
  }

  getPlatformDependantClasses() {
    const { browser, os } = this.$store.state
    const { isIos } = this.$store.getters

    const classes = []

    const osNameClass = `kr-${spacedToKebabCase(os.name)}`
    classes.push(osNameClass)
    classes.push(`kr-${spacedToKebabCase(browser.name)}`)
    if (isIos) classes.push(`${osNameClass}-${os.version.split('.')[0]}`)

    return classes
  }
}
