<template lang="pug">
  button.kr-grid-back-button.kr-method-btn(
    v-if="method",
    @mouseover="hover = true",
    @mouseleave="hover = false",
    @click="$emit('back')",
    :class="dynamicClasses"
  )
    .kr-icon
      BrandIcon(:method="method")
    .kr-method-btn__label
      label {{ methodLabel}}
    .kr-back-button__icon(v-html="iconHtml")
</template>

<script>
import { mapGetters } from 'vuex'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import paymentMethodsConf from '@/configuration/sources/smartform/paymentMethodsConf.yml'
import BrandIcon from '@/host/components/controls/BrandIcon'

export default {
  name: 'SmartFormGridBackButton',
  components: {
    BrandIcon
  },
  props: {
    method: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    ...mapGetters(['getCustomPaymentMethodLabel', 'translate']),
    iconHtml() {
      return PreloadedAssets.arrows.outline
    },
    methodLabel() {
      const customLabel = this.getCustomPaymentMethodLabel(this.method)
      if (customLabel) return customLabel
      let key = `smartform_group_${this.method.toLowerCase()}`

      if (this.translate(key) !== key) return this.translate(key)
      return paymentMethodsConf.labels[this.method] || this.method
    },
    dynamicClasses() {
      return {
        'kr-method-btn--hover': this.hover
      }
    }
  }
}
</script>
