import _ from 'underscore'

import Events from '@/configuration/Events'

/**
 * Service layer to handle to events related to form
 * to ghost container
 */
export default class FormEvents {
  constructor({ $bus, $store }) {
    // Register events
    $bus.$on(Events.krypton.message.fieldEvent, message => {
      $bus.$emit(Events.krypton.field.event, message)
    })
    $bus.$on(Events.krypton.message.fieldLength, message => {
      $bus.$emit(Events.krypton.field.length, message)
    })
    $bus.$on(Events.krypton.message.fieldInvalid, ({ name, formId }) => {
      $store.dispatch(`cardForm_${formId}/invalidateField`, name)
    })
    $bus.$on(Events.krypton.message.fieldValid, ({ name, formId }) => {
      $store.dispatch(`cardForm_${formId}/validateField`, name)
    })
    $bus.$on(Events.krypton.message.fieldViable, ({ name, formId }) => {
      $store.dispatch(`cardForm_${formId}/viableField`, name)
    })
    $bus.$on(Events.krypton.message.fieldInviable, ({ name, formId }) => {
      $store.dispatch(`cardForm_${formId}/inviableField`, name)
    })

    // [iOS] fake cursor animation control
    if ($store.getters.isIphone || $store.getters.isIpad) {
      $bus.$on(Events.krypton.util.cursor.animation, ({ animation }) => {
        $store.dispatch('update', { fakeCursorAnimation: animation })
      })
    }
  }
}
