<template lang="pug">
  .kr-sf-wrapper(v-show="hasValidToken" :class="dynamicClasses")
    PaymentTokenSmartButton(v-if="isSmartFormForcedToken", :paymentMethodToken="smartFormPaymentToken")
    // Embedded mode
    template(v-if="!displayPopinButton && !isFallbackSmartButtonVisible")
      SmartFormHeader
      PartialPaymentPanel
      SmartFormGridWallet(v-if="hasWallet", v-on:selected="setMethod")
      SmartFormGridCards(v-if="hasCards", v-on:selected="setMethod")
        slot(name="cardForm")
      SmartFormGridOtherMethods(v-if="hasOtherMethods", v-on:selected="setMethod")
    // Popin mode
    SmartFormModalButton(
      v-if="displayPopinButton", 
      v-on:open="openSmartFormPopinWrapper"
    )
    // Modal
    SmartFormLoadingOverlay
    SmartFormGridModalWrapper(:open="isSmartFormOpen", v-on:selected="setMethod")
      template(v-slot:cardForm="")
        slot(name="cardForm")
      template(v-slot:extra="")
        slot(name="extra")
    // Simple modal
    SimpleModal
    // Extras form - modal
    ExtrasForm(v-if="!isSmartFormPopin", display-mode="modal")
    SmartFormLoadingOverlay(:only-window-redirection="true")
    KryptonLayer(:mode="isSmartFormPopin ? 'box' : 'unified'")
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import SmartFormHeader from '@/host/components/smartform/grid/Header'
import SmartFormGridWallet from '@/host/components/smartform/grid/Wallet'
import SmartFormGridCards from '@/host/components/smartform/grid/Cards'
import SmartFormGridOtherMethods from '@/host/components/smartform/grid/OtherMethods'
import SmartFormGridModalWrapper from '@/host/components/smartform/grid/modal/Wrapper'
import SmartFormLoadingOverlay from '@/host/components/smartform/LoadingOverlay'
import KryptonLayer from '@/host/components/controls/KryptonLayer'
import SmartFormModalButton from '@/host/components/smartform/ModalButton'
import SimpleModal from '@/host/components/controls/SimpleModal'
import ExtrasForm from '@/host/components/extrasform/ExtrasForm'
import PaymentTokenSmartButton from '@/host/components/smartbutton/PaymentToken'
import PartialPaymentPanel from '@/host/components/smartform/partialPayment/PartialPaymentPanel'

/** MIXINS */
import { CardSelectorMixin } from '@/host/components/mixins/smartform/CardSelector'
import { RedirectCancelMixin } from '@/host/components/mixins/smartform/RedirectCancel'
import PaymentMethodGroup from '@/host/components/mixins/PaymentMethodGroup'

export default {
  name: 'SmartFormGrid',
  components: {
    SmartFormHeader,
    SmartFormGridWallet,
    SmartFormGridCards,
    SmartFormGridOtherMethods,
    SmartFormGridModalWrapper,
    SmartFormLoadingOverlay,
    KryptonLayer,
    SmartFormModalButton,
    SimpleModal,
    ExtrasForm,
    PaymentTokenSmartButton,
    PartialPaymentPanel
  },
  mixins: [CardSelectorMixin, RedirectCancelMixin, PaymentMethodGroup],
  computed: {
    ...mapGetters([
      'isSinglePaymentButton',
      'isSmartFormPopin',
      'hasPaymentMethods',
      'isSmartFormForcedToken',
      'isSmartFormOpen',
      'isWalletSmartForm',
      'hasTokens',
      'hasValidToken',
      'outsideCardsForm',
      'hasAnyCardBrand',
      'hasSeveralPaymentMethods',
      'renderGenericEmbeddedHeader',
      'isFallbackSmartButtonVisible',
      'smartFormPaymentToken',
      'hasCardMethodOption'
    ]),
    ...mapState({
      hidden: ({ smartForm }) => smartForm.hidden
    }),
    displayPopinButton() {
      return (
        this.isSmartFormPopin &&
        this.hasPaymentMethods &&
        !this.hidden &&
        !this.isSmartFormForcedToken
      )
    },
    hasWallet() {
      return this.isWalletSmartForm && this.hasTokens
    },
    hasCards() {
      return (
        !this.outsideCardsForm &&
        this.hasAnyCardBrand &&
        this.hasCardMethodOption &&
        !this.isSmartFormForcedToken
      )
    },
    hasOtherMethods() {
      return this.hasSeveralPaymentMethods
    },
    dynamicClasses() {
      return {
        'kr-sf-wrapper--cards-expanded': !this.renderGenericEmbeddedHeader
      }
    }
  },
  created() {
    // @see KJS-4160 - With Grid, card form default layout is compact
    this.$store.dispatch('update', { form: { layout: 'compact' } })
  },
  methods: {
    ...mapActions(['selectMethod', 'openMethod', 'openSmartFormPopinWrapper']),
    setMethod(method) {
      if (this.isSinglePaymentButton) {
        this.selectMethod(method)
      } else {
        this.openMethod(method)
      }
    }
  }
}
</script>
