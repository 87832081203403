var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.open,
          expression: "open",
        },
      ],
      staticClass: "kr-sf-modal-wrapper",
    },
    [
      _c("SmartFormGridModal", {
        attrs: { open: _vm.open },
        on: { close: _vm.closeModal, selected: _vm.openMethod },
        scopedSlots: _vm._u(
          [
            {
              key: "cardForm",
              fn: function () {
                return [_vm._t("cardForm")]
              },
              proxy: true,
            },
            {
              key: "extra",
              fn: function () {
                return [_vm._t("extra")]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }