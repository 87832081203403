import { logSentryException } from '@/common/util/sentry'
import Bowser from 'bowser'

export default class Environment {
  static #instance = null

  constructor({ $store }) {
    this.$store = $store
  }

  static async init($locator) {
    const env = new Environment($locator)
    await env.init()
    Environment.#instance = env
    return env
  }

  async init() {
    try {
      await this.detect()
    } catch (error) {
      logSentryException(error)
    }
  }

  /**
   * Checks the info about the environment
   */
  async detect() {
    const parser = Bowser.getParser(window.navigator.userAgent)
    let { browser, os, platform } = parser.getResult()

    // If NavigatorUIData API is available, check full OS version
    if (navigator.userAgentData) {
      const hev = await navigator.userAgentData.getHighEntropyValues([
        'platformVersion'
      ])
      os.version = parseInt(hev.platformVersion).toString()
    }

    // iPadOS detection (same userAgent as macOS)
    if (os?.name === 'macOS') {
      if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        platform.type = 'tablet'
        platform.model = 'ipad'
      }
    }

    this.$store.dispatch('update', {
      browser,
      os,
      platform,
      isWebView: this.isWebView()
    })
  }

  isWebView() {
    const iosWebViewRegex = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i
    const userAgent = window.navigator.userAgent

    // iOS WebView || Android webView
    return iosWebViewRegex.test(userAgent) || ~userAgent.indexOf('; wv')
  }
}
