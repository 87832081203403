<template lang="pug">
  .kr-skeleton-wrapper
    .kr-skeleton.kr-smart-button(:class="dynamicClasses")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SmartButtonSkeleton',
  props: {
    isFallback: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isSinglePaymentButton']),
    dynamicClasses() {
      return {
        'kr-smart-button--spbtn': this.isSinglePaymentButton,
        'kr-smart-button--fallback': this.isFallback
      }
    }
  }
}
</script>
