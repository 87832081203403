import { mapState, mapGetters } from 'vuex'
import {
  dynamicMapActions,
  dynamicMapState,
  dynamicMapGetters
} from '@/common/util/store'

import Events from '@/configuration/Events'
import { fieldNameAsClassName } from '@/common/util/fieldName'

/** COMPONENTS */
import KryptonLabel from '@/host/components/controls/KryptonLabel'

/** MIXINS */
import ConfigurationMixin from '@/host/components/mixins/Configuration'
import { VisibilityMixin } from '@/host/components/mixins/Visibility'
import RegexFields from '@/configuration/sources/RegexFields.yml'
import { FlashWarningSubscriberMixin } from '@/host/components/mixins/FlashWarningSubscriber'

export const FieldMixin = {
  components: { KryptonLabel },
  mixins: [ConfigurationMixin, VisibilityMixin, FlashWarningSubscriberMixin],
  inject: ['context'],
  data() {
    return {
      $input: null,
      hasFocus: false,
      domFieldName: '',
      wrapperClassName: ''
    }
  },
  computed: {
    ...mapGetters(['translate']),
    ...mapState({
      formPlaceholder(state) {
        return state.form.placeholders[this.fieldName]
      },
      configuration(state) {
        return state.dna?.cards[this.selectedBrand]?.fields[this.fieldName]
      }
    }),
    ...dynamicMapGetters('context.namespace', [
      'isFieldViable',
      'lastVisibleField'
    ]),
    ...dynamicMapState('context.namespace', ['selectedBrand']),
    dynamicClasses() {
      return {
        element: {
          [`kr-${this.domFieldName}`]: true,
          'kr-error': this.isInvalid,
          'kr-disabled': this.isDisabled,
          'kr-read-only': this.isReadOnly,
          'kr-field-element--warning': this.applyWarningStyle,
          'kr-field-element--last-visible':
            this.lastVisibleField === this.fieldName
        }
      }
    }
  },
  created() {
    // Set data
    this.domFieldName = fieldNameAsClassName(this.fieldName)
    this.wrapperClassName = `kr-field-wrapper-${this.fieldName}`

    // Start subscriptions
    this.$busOn(
      Events.krypton.field.focus,
      async ({ formId, name, backwards }) => {
        if (formId !== this.context.formId) return
        if (name === this.fieldName && this.isReadOnly) {
          // wait for all field listeners to be executed to avoid inconsistency see KJS-3655
          await this.$nextTick()
          this.$bus.$emit(Events.krypton.field.event, {
            formId: this.context.formId,
            name,
            type: backwards ? 'reverseTab' : 'tab'
          })
        } else {
          this[name === this.fieldName ? 'onFocus' : 'onBlur']()
        }
      }
    )
  },
  mounted() {
    this.setInput()
  },
  watch: {
    hasFocus(val, oldVal) {
      this.focusing(val, oldVal)
      if (!val && !this.isFieldViable(this.fieldName))
        this.invalidateField(this.fieldName)
    }
  },
  methods: {
    ...dynamicMapActions('context.namespace', [
      'updateNonSensitiveValue',
      'viableField',
      'inviableField',
      'invalidateField'
    ]),

    setInput() {},

    onFocus() {
      if (!this.isReadOnly) this.hasFocus = true
    },

    onBlur() {
      if (!this.isReadOnly) this.hasFocus = false
    },

    focusing(hasFocus, hadFocus) {
      // Get type
      let type
      if (hasFocus && !hadFocus) type = 'focus'
      else if (!hasFocus && hadFocus) type = 'blur'

      if (!this.isReadOnly && type) {
        if (this.$input) {
          this.$input[type]()
          this.$input[`${type === 'focus' ? 'add' : 'remove'}EventListener`](
            'keydown',
            this.focusEvents
          )
        }
        this.$bus.$emit(Events.krypton.field.event, {
          formId: this.context.formId,
          type,
          name: this.fieldName
        })
      }
    },

    focusEvents() {},

    tabReport(e) {
      e.preventDefault()
      if (e.type === 'keydown') {
        this.$bus.$emit(Events.krypton.field.event, {
          formId: this.context.formId,
          name: this.fieldName,
          type: e.shiftKey ? 'reverseTab' : 'tab'
        })
      }
    },

    validate() {
      if (!this.configuration || this.configuration.hidden) return
      const value =
        this.fieldType === 'select' ? this.selectedOption : this.fieldValue
      let isRequired =
        this.configuration?.required ||
        ~['identityDocumentType', 'identityDocumentNumber'].indexOf(
          this.fieldName
        )

      // Allow installment number = -1
      if (this.fieldName === 'installmentNumber' && value === '-1')
        isRequired = false

      // Regex validation
      const regexValidator = RegexFields.validators[this.fieldName]
      if (regexValidator && value in regexValidator) {
        const regex = new RegExp(regexValidator[value])
        if (!regex.test(value)) {
          this.inviableField(this.fieldName)
          return
        }
      }

      // HACK: KJS-2096. Email validation
      if (this.fieldName === 'cardHolderMail' && this.isEmailInvalid()) {
        this.inviableField(this.fieldName)
        return
      }

      this[`${isRequired && !value ? 'in' : ''}viableField`](this.fieldName)
    }
  }
}
