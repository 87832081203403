import { mapState } from 'vuex'

export const FocusReporterMixin = {
  data() {
    return {
      el: null
    }
  },
  computed: {
    ...mapState('field', ['name'])
  },
  mounted() {
    const el = this.$refs.input

    el.addEventListener('focus', this.focusReportEvent)
    el.addEventListener('focusin', this.focusReportEvent)
    el.addEventListener('mousedown', this.focusReportEvent)
    el.addEventListener('mouseup', this.focusReportEvent)
    el.addEventListener('blur', this.blurReportEvent)
    el.addEventListener('focusout', this.blurReportEvent)
    if (this.needsHelp) {
      el.addEventListener('touchstart', this.focusReportEvent)
      el.addEventListener('touchend', this.focusReportEvent)
    }
    /**
     * Force the events (e2e tests)
     *
     * @see KJS-2274 | IE11: window.focus and window.blur methods are read-only
     * therefore cannot be reassigned in strict mode.
     * Need to set a different key.
     */
    window._focus = this.focusReportEvent
    window._blur = this.blurReportEvent
  },
  methods: {
    blurReportEvent(evt) {
      if (this.needsHelp) this.helpedBlurReport(evt)
      this.blurReport(evt)
    },
    focusReportEvent(evt) {
      if (!this.isDisabled) {
        if (this.needsHelp) this.helpedFocusReport(evt)
        this.focusReport(evt)
      }
    }
  }
}
