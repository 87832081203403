<template lang="pug">
.krypton-alert-popin-error(v-if="visible")
  .kr-alert-icon
    svg(xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24")
      path(d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z")
  .kr-alert-text
    span.kr-alert-text-header {{ translate('error_alert_title') }}
    span {{ translate('error_alert_type') }}: 
      span.kr-alert-text-info.kr-error-code {{ errorCode }}
    span {{ translate('error_alert_description') }}: 
      span.kr-alert-text-info.kr-error-msg {{ errorMessage }}
  .kr-alert-close(@click="visible = false")
    .kr-close-icon
</template>

<script>
import _ from 'underscore'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'KryptonAlertPopin',
  data() {
    return {
      visible: false
    }
  },
  computed: {
    ...mapState(['hiddenToolbar']),
    ...mapGetters(['translate', 'isSmartForm', 'isSmartFormSupported']),
    ...mapState({
      errorMessage: state => state.error.errorMessage,
      errorCode: state => state.error.errorCode
    })
  },
  watch: {
    errorCode(code) {
      if (code) this.syncLabel()
    }
  },
  created() {
    this.syncLabel()
  },
  methods: {
    syncLabel() {
      if (!KR.toolbarReady || (this.isSmartForm && !this.isSmartFormSupported))
        this.visible = true
    }
  }
}
</script>
