var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "kr-methods",
      class: _vm.dynamicClasses.wrapper,
      style: _vm.dynamicStyles,
    },
    [
      !_vm.hasCardMethodOption
        ? _c(
            "SmartFormMethodContent",
            {
              key: "CARDS",
              attrs: {
                visible:
                  (!_vm.hasCardMethodOption && _vm.isActiveMethod("CARDS")) ||
                  _vm.paymentDone,
              },
              on: { back: _vm.closeMethod },
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._l(_vm.paymentMethods, function (method) {
        return _c(
          "SmartFormMethodContent",
          {
            key: method,
            attrs: { visible: _vm.isActiveMethod(method), method: method },
            on: { back: _vm.closeMethod },
          },
          [_vm.isCardMethod(method) ? _vm._t("default") : _vm._e()],
          2
        )
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showIframe,
              expression: "showIframe",
            },
          ],
          staticClass: "kr-smart-form-iframe-container",
          class: _vm.dynamicClasses.iframe,
        },
        [_c("KryptonGhostContainer")],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }