export default {
  whitelist: [
    'background-color',
    'border',
    'border-color',
    'border-radius',
    'box-shadow',
    'box-sizing',
    'color',
    'font-family',
    'font-size',
    'font-style',
    'font-weight',
    'height',
    'letter-spacing',
    'margin-bottom',
    'margin-left',
    'margin-right',
    'margin-top',
    'max-height',
    'min-height',
    'min-height',
    'min-width',
    'opacity',
    'outline',
    'padding-bottom',
    'padding-left',
    'padding-right',
    'padding-top',
    'text-align',
    'text-decoration',
    'text-shadow',
    'transition',
    'user-select',
    'vertical-align',
    'visibility',
    'white-space',
    'width'
  ],
  inputOmit: ['border', 'border-color', 'border-radius']
}
