var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-finish-page", class: "kr-" + _vm.orderStatus },
    [
      _vm.orderStatus === "paid"
        ? _c("div", { staticClass: "kr-animation" }, [_vm._m(0)])
        : _vm.orderStatus === "running"
        ? _c("KryptonLoadingAnimation")
        : _vm._e(),
      _c("div", { staticClass: "kr-finish-label" }, [
        _c("label", [_vm._v(_vm._s(_vm.translate(_vm.label)))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "kr-check-icon" }, [
      _c("div", { staticClass: "kr-icon-circle" }),
      _c("span", { staticClass: "kr-icon-line kr-line-tip" }),
      _c("span", { staticClass: "kr-icon-line kr-line-long" }),
      _c("div", { staticClass: "kr-icon-fix" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }