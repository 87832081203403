import { uniq } from 'underscore'
import Zepto from 'zepto-webpack'
import BaseDetector from '@/host/service/BaseDetector'

export const multipleOrigins = () => {
  const domains = []

  Zepto('script').forEach(element => {
    const detectedScript = BaseDetector.read(element, 'V4.0')
    if (detectedScript) {
      const linkElement = document.createElement('a')
      linkElement.href = detectedScript
      domains.push(`${linkElement.hostname}`)
    }
  })

  if (domains.length > 1) return uniq(domains)
  return null
}

export const checkLibraries = () => {
  const domains = multipleOrigins()
  const isAlreadyLoaded = !!window.KR
  if (domains && isAlreadyLoaded && typeof console !== 'undefined') {
    // We should warn the develop if the console is enabled
    const domainList = domains.join(', ')
    const isPlural = domains.length > 1
    console.error(
      `[Krypton-Client %%library_version%%] Krypton Client has been loaded multiple times from ${
        isPlural ? '[' + domainList + ']' : domainList
      } endpoint${
        isPlural ? 's' : ''
      }. This will break payments. Please fix it.`
    )
  }

  return isAlreadyLoaded
}
