import { Base64 } from 'js-base64'

const repeat = (str, count) => {
  if (str.length === 0 || count === 0) return ''

  let res = ''
  for (let i = 0; i < count; i++) res += str
  return res
}

export const fillSuffixZeros = inputString => {
  // Ensure the polyfill is on the String object
  let qty = 4 - (inputString.length % 4)
  if (qty === 4) qty = 0
  return inputString + repeat('=', qty)
}

/**
 * Unzips a zipped string
 *
 * @param {String} zippedString
 */
export const unzip = zippedString => {
  // String replacement and decode
  zippedString = fillSuffixZeros(zippedString)
    .replace('-', '+')
    .replace('_', '/')
  return Base64.decode(zippedString)
}
