var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kr-brand-buttons" },
    _vm._l(_vm.brandOptions, function (brandOption) {
      return _c(
        "div",
        {
          staticClass: "kr-brand-button",
          class: [
            brandOption === _vm.selectedOption ? "kr-selected" : "",
            "kr-" + brandOption.class,
          ],
          style: _vm.buttonStyle,
          on: {
            click: function ($event) {
              _vm.selectedOption = brandOption
            },
          },
        },
        [
          _c("div", {
            staticClass: "kr-brand-button-icon",
            domProps: { innerHTML: _vm._s(brandOption.icon) },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.krShowLabels == "true",
                  expression: "krShowLabels == 'true'",
                },
              ],
              staticClass: "kr-brand-button-label",
              style: { visibility: brandOption.label ? "visible" : "hidden" },
            },
            [_vm._v(_vm._s(brandOption.label))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }