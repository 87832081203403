<template lang="pug">
extends ../../controls/templates/FormError.pug
</template>

<script>
import MethodsListError from '@/host/components/mixins/MethodsListError'
import { mapGetters } from 'vuex'

export default {
  name: 'SmartFormGroupError',
  mixins: [MethodsListError],
  computed: {
    ...mapGetters([
      'isGroupActive',
      'isSinglePaymentButton',
      'isAnyPopinOpen',
      'isGroupingActivated',
      'isExtrasFormVisible'
    ]),
    showFormError() {
      return (
        this.hasData &&
        !this.isExtrasFormVisible &&
        (this.isRegularError || this.hasWarning) &&
        (this.isGroupActive || !this.isGroupingActivated || this.isSPBError)
      )
    },
    // Condition to display the error in single payment button mode
    isSPBError() {
      return (
        this.isSinglePaymentButton &&
        !this.isAnyPopinOpen &&
        this.error?.paymentMethod
      )
    }
  }
}
</script>
