<template lang="pug">
.kr-popin-modal-footer(kr-resource v-show="!redirectIframeOpen", :class="{ 'kr-secure-iframe-visible': redirectIframeOpen }")
  .kr-whitelabel-logo(v-show="isLogoVisible", aria-hidden="true")
    img(v-if="logo.name" :class="`kr-logo-${logo.name}`" :src="`${baseAddress}/logos/footer/${logo.file}`")
</template>

<script>
import { defer } from 'underscore'
import Zepto from 'zepto-webpack'
import { mapState } from 'vuex'
import Events from '@/configuration/Events'

export default {
  name: 'KryptonPopinFooter',
  data() {
    return {
      logo: { name: '', file: '' }
    }
  },
  computed: {
    ...mapState(['redirectIframeOpen', 'baseAddress']),
    ...mapState({
      errorCode: state => state.error.errorCode,
      isLogoVisible: state => state.form.popin.footer.logo.visibility,
      defaultLogo: state => state.form.popin.footer.logo.default
    })
  },
  watch: {
    defaultLogo: 'setLogo',
    errorCode: 'scrollDown',
    isLogoVisible: 'setSmallPopinMode'
  },
  created() {
    this.startSubscriptions()
  },
  mounted() {
    this.setLogo()
  },
  methods: {
    startSubscriptions() {
      // Allow to change the logo on runtime
      this.$busOn(Events.krypton.popin.footer.logo, this.setLogo)
    },
    setLogo(logo = null) {
      if (!logo) logo = { file: '' }
      // If the file is not forced, use the config
      this.logo.file = logo.file
      if (!this.logo.file) this.logo.file = this.defaultLogo

      // If there is an image defined for this prefix, set name
      this.logo.name = this.logo.file
        ? this.logo.file
            .substring(0, this.logo.file.lastIndexOf('.'))
            .toLowerCase()
        : ''

      // If logo name is not defined, then logo is not visible, force small popin mode
      this.setSmallPopinMode(this.isLogoVisible && this.logo.name)
    },
    setSmallPopinMode(isLogoVisible) {
      Zepto(this.$el.parentNode)[`${isLogoVisible ? 'remove' : 'add'}Class`](
        'kr-small-popin'
      )
    },
    // Scrolls the page in case the device is small
    scrollDown() {
      defer(() => {
        if (this.$el) this.$el.scrollIntoView()
      })
    }
  }
}
</script>
