/**
 * In case the expiry date received is in the format MM/YYYY, it will be formatted to MM/YY.
 * This is necessary for Chrome autofill as it uses the MM/YYYY format in some versions.
 * @param {String} value
 * @returns {String}
 * @see KJS-3848
 */
export const formatLongExpiryDate = value => {
  if (!/^\d{2}\/\d{4}$/.test(value)) return value
  const splitValue = value.split('/')
  splitValue[1] = splitValue[1].slice(2)
  return splitValue.join('/')
}
