<template lang="pug">
  .kr-card-icons(v-if="!icons.all.length")
    label {{ translate(`smartform_no_${brandFilter ? `${brandFilter.toLowerCase()}_`: ''}card_available`)}}
  .kr-card-icons(v-else, v-onresize="onResize", :class="{'kr-single-icon': icons.all.length === 1}")
    span(v-for="iconConf in icons.all", ref="icons", :key="iconConf.brand", v-html="iconConf.icon", :class="[getDynamicClasses(iconConf)]")
    SmartFormMethodTooltip.kr-extra-brands(
      v-show="hasOutstandingIcons",
      ref="tooltip",
      :label="tooltipLabel",
      :attach="!shouldDetach"
    )
      .kr-card-tooltip
        span.kr-brand-icon.kr-cards(v-for="iconConf in icons.outstanding", v-html="iconConf.icon", :class="'kr-' + iconConf.brand")
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

import { loadAssets } from '@/common/loader/assets'
import PreloadedAssets from '@/configuration/PreloadedAssets'
import { areEqual } from '@/common/util/array'

import SmartFormMethodTooltip from '@/host/components/smartform/MethodTooltip'
import { IconContainerMixin } from '@/host/components/mixins/IconContainer'
import DomReactMixin from '@/host/components/mixins/DomReact'
import { resolveBrand } from '@/common/util/brand.js'

export default {
  name: 'SmartFormCardIcons',
  components: { SmartFormMethodTooltip },
  mixins: [IconContainerMixin, DomReactMixin],
  props: {
    brands: { type: Array, default: () => [] },
    brandFilter: { type: String, default: '' },
    limit: { type: Number, default: 5 },
    deltaWidth: { type: Number, default: 0 },
    margin: { type: Number, default: 0 },
    isSmartButton: { type: Boolean, default: false }
  },
  data() {
    return {
      assetsLoading: true,
      initDone: false
    }
  },
  computed: {
    ...mapGetters([
      'translate',
      'isCardMethod',
      'isSmartFormPopin',
      'brandSorter',
      'isDebitBrand'
    ]),
    ...mapState(['isUnitaryTest', 'testKeys', 'dna']),
    ...mapState({
      brandPriority: state => state.dna.brandPriority,
      cardBrands: state => state.smartForm.cardBrands,
      isModalOpen: state => state.smartFormModal.isOpen
    }),
    shouldDetach() {
      return this.isSmartFormPopin && !this.isSmartButton
    }
  },
  watch: {
    async limit(val, old) {
      if (!areEqual(val, old)) await this.loadCardIcons()
    },
    async brandPriority(val, old) {
      await this.loadCardIcons()
    },
    dna: {
      deep: true,
      async handler(val) {
        await this.loadCardIcons()
        this.onResize()
      }
    }
  },
  async mounted() {
    await this.loadCardIcons()
    this.onResize()
    this.initDone = true
  },
  methods: {
    ...mapActions(['focusPrevious', 'resetFocus', 'warning']),
    getDynamicClasses(iconConf) {
      const outstandingBrands = this.icons.outstanding.map(({ brand }) => brand)
      const hideout = !!~outstandingBrands.indexOf(iconConf.brand)
      return {
        ['kr-' + iconConf.brand]: true,
        'kr-brand-icon': true,
        'kr-cards': true,
        'kr-hideout': hideout,
        'kr-loading-assets': this.assetsLoading
      }
    },
    openMethod(method) {
      if (this.locked || this.loading) return
      this.loading = true
      this.$emit('selected', method)
    },
    async loadCardIcons() {
      this.preloadIcons()
      await this.loadIcons()
    },
    preloadIcons() {
      // Filter, Sort and Icon listing
      const brands = (this.brands.length ? this.brands : this.cardBrands)
        .map(this.replaceWithParentBrand)
        .filter(
          (brand, index, brands) =>
            this.uniqFilter(brand, index, brands) &&
            this.blackListFiler(brand) &&
            this.debitCreditFilter(brand)
        )
        .sort(this.brandSorter)

      this.icons.all = brands.map(this.formatBrand())
      if (!this.icons.number) {
        this.setIconsNumber(this.limit)
      }

      if (!this.icons.all.length) this.warning('CLIENT_504')

      this.$emit('num-of-brands', this.icons.all.length)
    },
    async loadIcons() {
      const assets = await loadAssets()

      this.icons.all.forEach(icon => {
        icon.icon =
          assets.outline.branded[icon.brand.toUpperCase()] ||
          assets.regular.branded[icon.brand.toUpperCase()]
      })

      this.assetsLoading = false
    },
    replaceWithParentBrand(brand) {
      if (this.brandFilter && this.isDebitBrand(brand)) return brand

      return resolveBrand(brand, true)
    },
    uniqFilter(brand, index, brands) {
      return brands.indexOf(brand) === index
    },
    debitCreditFilter(brand) {
      const isDebit = this.isDebitBrand(brand)
      if (this.brandFilter === 'CREDIT') return !isDebit
      else if (this.brandFilter === 'DEBIT') return isDebit
      return brand
    },
    blackListFiler(brand) {
      const blackListedBrands = ['DEFAULT', 'CONECS', 'OSB', 'EDENRED']
      return !~blackListedBrands.indexOf(brand)
    },
    formatBrand() {
      return brand => {
        return {
          brand: brand.toLowerCase(),
          icon: PreloadedAssets.loading.pan
        }
      }
    },
    onResize(size) {
      if (!size) size = { width: this.getElementWidth() }
      if (size.width === 0) return
      this.setIconsNumber(this.getIconsLimit(size.width))
    },
    /**
     * Extracted for testing purpose
     *
     * @since KJS-2793
     */
    getElementWidth() {
      return this.$el.offsetWidth
    },
    /**
     * Upon label-wrapper resize, evaluate the best card icons limit.
     * The limit width is the button width, minus the tooltip width.
     *
     * CardIcons width can be calculated as below:
     * Given
     *   - the number of cards:             n
     *   - The card icon width:             30px
     *   - Gap between each icon:           6px
     *
     * Then the total width is (30 + 6)n
     *
     * @since KJS-2355
     */
    getIconsLimit(width) {
      const limitWidth = width - this.deltaWidth
      const iconsRef = this.$refs.icons
      if (!iconsRef) return
      const iconW = iconsRef[0].clientWidth
      const gap = this.calculateGap(iconW)
      const calculator = n => {
        const tooltipW =
          n === this.icons.all.length ? 0 : this.$refs.tooltip.$el.clientWidth
        let iconWTotal = 0
        for (let i = 0; i < n; ++i) {
          iconWTotal += this.$refs.icons[i].clientWidth
        }
        return iconWTotal + gap * n + tooltipW + this.margin
      }
      return this.calculateMaximumIcons(
        limitWidth,
        this.icons.all.length,
        calculator
      )
    }
  }
}
</script>
