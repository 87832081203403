<template lang="pug">
  label(:data-card="dataCard" v-onresize="onResize", v-html="content")
</template>

<script>
import DomReactMixin from '@/host/components/mixins/DomReact'

/**
 * Components use to display the card label (masked pan) - wallet & split payments
 *
 * Given value property "5100 01XX XXXX 0114",
 * dynamically adjust to the available width with the following sizes:
 * - full: 5100 01XX XXXX 0114
 * - half: ···· 0114
 * - tiny: ·· 0114
 *
 * @since KJS-2901
 */
export default {
  name: 'SmartFormMaskedCardLabel',
  mixins: [DomReactMixin],
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      requiredWidth: {
        full: null,
        half: null,
        tiny: null
      },
      size: 'full' // full, half, tiny
    }
  },
  computed: {
    content() {
      return this.getContent(this.size)
    },
    /**
     * Remove all spaces for data-card attribute. Used to identify full masked
     * card number in tests regardless of ellipsis.
     *
     * @since KJS-4213
     */
    dataCard() {
      return this.value.replace(/\s/g, '')
    }
  },
  mounted() {
    this.setSize()
  },
  methods: {
    onResize() {
      this.setSize()
    },
    getCurrentWidth() {
      return this.$el.offsetWidth
    },
    calculateRequiredWidth() {
      const ellipsis = document.createElement('span')
      const pan = document.createElement('span')
      ellipsis.classList.add('kr-ellipsis')
      ellipsis.style.opacity = 0
      ellipsis.style.position = 'fixed'
      ellipsis.style.top = '-9999px'
      ellipsis.style.left = '-9999px'
      pan.style.opacity = 0
      pan.style.position = 'fixed'
      pan.style.top = '-9999px'
      pan.style.left = '-9999px'
      this.$el.appendChild(ellipsis)
      this.$el.appendChild(pan)

      // tiny
      ellipsis.innerText = '·· '
      pan.innerText = this.value.slice(-4)
      this.requiredWidth.tiny = ellipsis.offsetWidth + pan.offsetWidth + 6

      // half
      ellipsis.innerText = '···· '
      this.requiredWidth.half = ellipsis.offsetWidth + pan.offsetWidth + 6

      // full
      pan.innerText = this.value
      this.requiredWidth.full = pan.offsetWidth + 2

      this.$el.removeChild(ellipsis)
      this.$el.removeChild(pan)
    },
    /**
     * Based on label font, measure the necessary width for every size and
     * compare it to the current one.
     * Starting from the largest size to the smallest and
     * using the largest fitting.
     */
    setSize() {
      const sizeList = ['full', 'half', 'tiny']

      this.calculateRequiredWidth()

      for (const size of sizeList) {
        this.size = size
        if (size === sizeList[sizeList.length - 1]) {
          break
        }
        if (this.requiredWidth[size] < this.getCurrentWidth()) {
          break
        }
      }
    },
    /**
     * @param {string} size full|half|tiny
     * @returns {string} Html content to inject into label
     */
    getContent(size) {
      if (size === 'tiny') {
        return `<span class="kr-ellipsis">·· </span><span>${this.value.slice(
          -4
        )}</span>`
      } else if (size === 'half') {
        return `<span class="kr-ellipsis">···· </span><span>${this.value.slice(
          -4
        )}</span>`
      }
      return `<span>${this.value}</span>`
    },
    htmlToText(html) {
      const el = document.createElement('label')
      el.innerHTML = html
      return el.innerText
    }
  }
}
</script>
