import { debounce } from '@/common/util/debounce'
import { mapActions, mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

export default {
  data() {
    return {
      storedBin: '',
      storedBrand: ''
    }
  },
  computed: {
    ...mapState([
      'initialAmount',
      'amount',
      'currency',
      'orderId',
      'formToken',
      'initialFormToken'
    ]),
    ...mapState({
      updateUrl: state => state.binUpdateNotification.url,
      updateData: state => state.binUpdateNotification.data,
      updateTimeout: state => state.binUpdateNotification.timeout
    }),
    ...dynamicMapState('context.namespace', ['bin8', 'selectedBrand'])
  },
  watch: {
    bin8(bin) {
      this.debouncedUrlCall(this)
    },
    selectedBrand() {
      if (this.bin8) this.debouncedUrlCall(this)
    }
  },
  created() {
    if (this.bin8 && this.selectedBrand) this.debouncedUrlCall(this)
  },
  methods: {
    ...mapActions([
      'amountUpdate',
      'setInitialAmount',
      'updatingAmount',
      'error'
    ]),
    debouncedUrlCall: debounce(_this => {
      // check changes like 11111111 -> 11111112 -> 11111111 in quick succession
      _this.verifyAmount()
    }, 400),

    verifyAmount() {
      if (
        this.bin8 !== this.storedBin ||
        this.selectedBrand !== this.storedBrand
      ) {
        const { browserRequests, storeFactory } = this.$locator
        // Store the current bin and brand
        this.storedBin = this.bin8
        this.storedBrand = this.selectedBrand
        if (!this.bin8) this.setInitialAmount()
        else {
          this.updatingAmount()
          browserRequests
            .post(
              storeFactory.create('requestData', {
                url: this.updateUrl,
                objectData: {
                  bin: this.bin8,
                  brand: this.selectedBrand,
                  amount: this.initialAmount || this.amount,
                  currency: this.currency,
                  orderId: this.orderId,
                  formToken: this.initialFormToken || this.formToken,
                  ...this.updateData
                },
                headers: {
                  'Content-type': 'application/json'
                },
                timeout: this.updateTimeout
              }),
              { returnResponseOnError: true }
            )
            .then(({ response }) => {
              if (
                !response.formToken ||
                typeof response.formToken !== 'string'
              ) {
                console.warn(
                  "The BinUpdateNotificationUrl response should be {formToken: 'generatedTokenString'}"
                )
                throw 'CLIENT_513'
              }

              // Merchant server returns updated formtoken
              // Need to set the new formtoken and apply discounts
              this.amountUpdate({ formToken: response.formToken })
            })
            .catch(err => {
              // Show a warning if the request timed out
              if (this.isTimeoutError(err)) {
                console.warn('Bin update request timed out')
              }
              // Error on the request
              if (err?.response) {
                const { status } = err.response
                this.error({
                  errorCode: 'CLIENT_511',
                  detailledErrorCode: status,
                  detailledErrorMessage: 'returned by the merchant'
                })
              }

              // err may be a custom error code string
              // i.e. throw 'CLIENT_513'
              if (typeof err === 'string') this.error(err)

              this.setInitialAmount()
            })
        }
      }
    },

    isTimeoutError(error) {
      return error?.errorCode === 'CLIENT_103'
    }
  }
}
