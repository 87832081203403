var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "kr-field-element kr-checkbox",
      class: _vm.dynamicClasses.element,
    },
    [
      _c(
        "div",
        {
          staticClass: "kr-field kr-checkbox",
          class: [{ "kr-visible": _vm.isVisible }, _vm.checkboxType],
        },
        [
          _c("div", { staticClass: "kr-field-wrapper" }, [
            _c("div", { staticClass: "kr-checkbox-input-wrapper" }, [
              _c(
                "label",
                {
                  staticClass:
                    "kr-field-control kr-checkbox kr-checkbox-layout",
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.value,
                        expression: "value",
                      },
                    ],
                    staticClass: "kr-field kr-theme",
                    attrs: {
                      "kr-field": "",
                      "kr-field-name": _vm.fieldName,
                      type: "checkbox",
                      "kr-resource": "",
                      name: _vm.name,
                      tabindex: "-1",
                      disabled: _vm.isReadOnly,
                    },
                    domProps: {
                      checked: Array.isArray(_vm.value)
                        ? _vm._i(_vm.value, null) > -1
                        : _vm.value,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.value,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.value = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.value = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.value = $$c
                        }
                      },
                    },
                  }),
                  _c("div", {
                    ref: "checkboxControlIndicator",
                    staticClass: "kr-checkbox-control-indicator",
                    attrs: { tabindex: "1" },
                    on: {
                      focus: _vm.onFocus,
                      blur: _vm.onBlur,
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          $event.keyCode !== 9
                        ) {
                          return null
                        }
                        return _vm.tabReport($event)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          $event.keyCode !== 9
                        ) {
                          return null
                        }
                        return _vm.tabReport($event)
                      },
                    },
                  }),
                  _c("krypton-label", {
                    attrs: {
                      "data-name": _vm.fieldName,
                      "field-name": _vm.fieldName,
                      "forced-visibility": true,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }