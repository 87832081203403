import PaymentProcess from '@/ghost/workflow/payment/state/process/PaymentProcess'
import Events from '@/configuration/Events'
import _ from 'underscore'

export default class RedirectProcess extends PaymentProcess {
  constructor(...args) {
    super(...args)
  }

  async process(response, procResp) {
    const { remoteId } = this.$store.state
    // Create the iframe for redirection and wait for answer
    await this.createRedirect(procResp)
    this.$store.dispatch('displaySmartFormModal')

    // Save the payment ID
    const ecsPaymentId = this.context.ecsPaymentId
    const data = await this.getResponse()

    // New PP call
    let values = this.buildRequest(response)
    values.redirectRequestData = data
    values.remoteId = remoteId
    response = await this.callProcessPayment(values, { ecsPaymentId })

    // Check if the popup should be open
    await this.checkResponse(response)

    // Check which kind of response it is
    procResp = await this.processResponse(response)

    return [response, procResp]
  }

  async createRedirect(billingOrRedirect) {
    billingOrRedirect.formId = this.context.form.id
    this.$bus.$emit(Events.krypton.payment.redirect, billingOrRedirect)
  }

  getResponse() {
    const { $bus, $store } = this

    return new Promise((resolve, reject) => {
      function redirectMessage(event) {
        if (event.data.action === 'cancel') {
          window.removeEventListener('message', redirectMessage)
          if (!$store.getters.hasDeadEndPaymentMethod()) reject('CLIENT_101')
        } else if (event.data.action === 'choice') {
          window.removeEventListener('message', redirectMessage)
          $store.dispatch('finishRedirection')
          resolve(event.data.data)
        }
      }

      window.addEventListener('message', redirectMessage, false)
    })
  }
}
