import _ from 'underscore'
import { diff } from 'deep-object-diff'
import { mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'

export const PaymentTokenMixin = {
  inject: ['context'],
  computed: {
    ...mapState(['formMode']),
    ...mapState({
      dnaTokensCards: state => state.dna?.tokens?.cards || []
    }),
    ...dynamicMapState('context.namespace', {
      paymentMethodToken: state => state?.nonSensitiveValues?.paymentMethodToken
    })
  },
  watch: {
    dnaTokensCards: {
      deep: true,
      handler(val, old) {
        // KJS-4205 - The watches maybe be called if reference has changed even
        // if object contains the same data.
        const tokensCardsDiff = diff(old, val)
        if (Object.keys(tokensCardsDiff).length > 0) {
          this.setInputValue(val)
        }
      }
    },
    paymentMethodToken(value) {
      this.setInputValue(value)
    }
  },
  mounted() {
    if (this.paymentMethodToken) this.setInputValue()
  },
  methods: {
    setInputValue(val) {
      let value = '' // By default, clean value if the token or config changes
      if (this.paymentMethodToken && this.dnaTokensCards) {
        const isTokenForced = this.formMode === 'token'
        const card = isTokenForced
          ? this.dnaTokensCards[0]
          : this.getSelectedToken(this.dnaTokensCards)
        const fieldConf = card.fields[this.fieldName]
        if (fieldConf && fieldConf.value) {
          value = fieldConf.value
        }
      }

      // Set the right input value
      if (this.hasOwnProperty('selectedOption')) {
        this.selectedOption = value || this.configuration?.value || ''
      } else if (this.hasOwnProperty('fieldValue')) {
        this.fieldValue = value || this.configuration?.value || ''
      }
    },
    getSelectedToken(cards) {
      for (const card of cards) {
        if (card.token === this.paymentMethodToken) return card
      }
    }
  }
}
