<template lang="pug">
  .kr-wallet-group(:class="dynamicClasses" v-onresize="onResize")
    SmartFormGridWalletButton(
      v-for="conf in methods"
      :key="conf.token"
      :method="conf"
      v-on:selected="onSelected"
      :locked="allLocked"
    )
</template>

<script>
import SmartFormGridWalletButton from '@/host/components/smartform/grid/wallet/Button'
import { mapGetters } from 'vuex'
import DomReactMixin from '@/host/components/mixins/DomReact'

export default {
  name: 'SmartFormGridWalletGroup',
  components: {
    SmartFormGridWalletButton
  },
  mixins: [DomReactMixin],
  props: {
    methods: {
      type: Array,
      required: true
    },
    allLocked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      compactFits: false
    }
  },
  computed: {
    ...mapGetters(['isCompactWallet', 'isSinglePaymentButton']),
    dynamicClasses() {
      return {
        'kr-wallet-group--compact': this.isCompactWallet && this.compactFits
      }
    }
  },
  methods: {
    onSelected(method) {
      this.$emit('selected', method)
    },
    onResize() {
      const width = this.$el.clientWidth
      this.compactFits = this.isSinglePaymentButton ? width > 400 : width > 340
    }
  }
}
</script>
