<template lang="pug">
.kr-input-relative-wrapper(:style="fieldCss.inputRelativeWrapper")
  .kr-input-relative-wrapper-inner
    input#inputField.kr-input-field(
      ref="input"
      :disabled="isDisabled || !ready"
      @keyup.9="tabReport"
      @keydown.9="tabReport"
      autocorrect="off"
      autocomplete="cc-exp"
      spellcheck="false"
      tabindex="1"
      :class="{'kr-on-error': hasError, 'on-error-animation': errorAnimation}"
      :placeholder="placeholder"
      :style="inputCSS"
      :type="type"
      :pattern="pattern"
      v-model="inputValue"
      :aria-label="fieldLabel"
      :aria-invalid="hasError ? 'true' : 'false'"
      required
    )
    CursorMirror(:input-value="inputValueCaret", ref="caret", v-if="!isUIWebView && !paymentMethodToken")
    input.kr-focus-hunter(
      v-if="isIos"
      tabindex="-1"
      aria-hidden="true"
      :pattern="pattern"
      :style="{position:'fixed',top:0,left:0,width:'0px',height:'0px','background-color':'transparent',border:'0px solid transparent',padding:'1px',display:'inline-block'}"
    )
</template>

<script type="text/javascript">
import { mapGetters, mapState } from 'vuex'
import { dynamicMapState } from '@/common/util/store'
import Events from '@/configuration/Events'
import { StyleMixin } from '@/slave/components/mixins/Style'
import { ClearMixin } from '@/slave/components/mixins/Clear'
import CursorMirror from '@/slave/components/CursorMirror'
import { ExpiryValidator } from '@/slave/components/mixins/Validator'
import { KeyboardTypeMixin } from '@/slave/components/mixins/KeyboardType'
import { FocusHelperMixin } from '@/slave/components/mixins/FocusHelper'
import { FocusReporterMixin } from '@/slave/components/mixins/FocusReporter'
import { ExpiryDateInputEditionMixin } from '@/slave/components/mixins/InputEdition'
import {
  KeysMixin,
  BlurExpiryMixin,
  FocusMixin,
  PreventEventsMixin,
  EventsMixin
} from '@/slave/components/mixins/Event'
import { PlaceholderMixin } from '@/slave/components/mixins/Placeholder'
import { FieldLabelMixin } from '@/slave/components/mixins/FieldLabel'
import { ShakeMixin } from '@/slave/components/mixins/Shake'
import { TestCardsMixin } from '@/slave/components/mixins/TestCards'
import { PaymentTokenMixin } from '@/slave/components/mixins/PaymentToken'
import { RequiredMixin } from '@/slave/components/mixins/Required'
import { StateMixin } from '@/slave/components/mixins/State'
import { formatLongExpiryDate } from '@/slave/util/format'

// Expiry date component
export default {
  /**
   * Name of the component and the html tag as : <ExpiryDate />
   */
  name: 'ExpiryDate',
  components: { CursorMirror },
  mixins: [
    BlurExpiryMixin,
    ExpiryValidator,
    ClearMixin,
    FocusMixin,
    PreventEventsMixin,
    EventsMixin,
    KeyboardTypeMixin,
    FocusHelperMixin,
    FocusReporterMixin,
    ExpiryDateInputEditionMixin,
    KeysMixin,
    PlaceholderMixin,
    FieldLabelMixin,
    ShakeMixin,
    StyleMixin,
    TestCardsMixin,
    PaymentTokenMixin,
    RequiredMixin,
    StateMixin
  ],
  inject: ['context'],
  data() {
    return {
      fieldName: 'expiryDate',
      inputValue: '',
      inputValueCaret: '',
      prevValue: '',
      settedValue: false
    }
  },
  computed: {
    ...mapState('field', ['formId', 'isUIWebView', 'ready']),
    ...mapState(['css']),
    ...mapGetters(['isIos']),
    ...dynamicMapState('context.namespace', ['testCard']),
    ...mapState('field', {
      autofill: state => state.autofill.expiryDate
    })
  },
  watch: {
    inputValue(newVal, oldVal) {
      if (newVal.length) this.cleanPlaceholder()
      const value = formatLongExpiryDate(newVal)
      // Value not setted by token
      if (!this.paymentMethodToken) {
        this.inputValueCaret = value
        this.prevValue = value
        const cleanValue = value.replace(/\s/g, '')
        this.$bus.$emit(Events.slave.field.change, {
          cleanValue,
          value: value,
          oldValue: oldVal,
          el: this.$el
        })
      } else {
        if (this.isIos) this.fieldEvent('permanentFocus')
      }
    },
    testCard(newVal) {
      if (newVal) {
        const _this = this
        this.settedValue = true
        if (!this.isDisabled) {
          let expiryValue = this.getExpiryDateValue()
          this.cleanPlaceholder()
          this.inputValue = expiryValue
        }
      }
    },
    autofill(newVal) {
      if (newVal) this.inputValue = newVal
      this.fieldEvent('permanentFocus')
    }
  }
}
</script>
