import piiFactory from './piiFactory';

/**
 * Lyra Common PII Factory facade.
 * Merge default & extra rules
 */
export default {

    /**
     * Return a PiiInstance.
     * @param loadDefaultRules if the default rules must be loaded
     * @param defaultRules the default rules
     * @param extraRules the extra rules
     * @returns {PiiInstance}
     */
    /* eslint-disable default-param-last */
    build(loadDefaultRules = true, defaultRules, extraRules) {
        const rules = {
            patterns: extraRules.patterns,
            rules: extraRules.rules,
        };

        // Merge with default patterns
        if (loadDefaultRules) {
            rules.patterns = {
                ...defaultRules.patterns,
                ...extraRules.patterns,
            };
        }

        // Merge with default patterns
        if (loadDefaultRules) {
            rules.rules = {
                ...defaultRules.rules,
                ...extraRules.rules,
            };
        }
        return piiFactory.build(rules);
    },
};
