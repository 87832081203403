import PaymentProcess from '@/ghost/workflow/payment/state/process/PaymentProcess'

/**
 * Manages the second processPayment call (Fingerprint payment)
 */
export default class FingerprintProcess extends PaymentProcess {
  async process(response, procResp = null) {
    const { remoteId } = this.$store.state
    // Save the payment ID
    const ecsPaymentId = this.context.ecsPaymentId
    // Server date time and remote id propagation:
    this.sendServerData(response)
    // Process the reponse to prepare the second request
    response = await this.getProcessedResponse(procResp, response)
    // Build the request object
    let values = this.buildRequest(response)
    values.remoteId = remoteId
    // Second PP call
    response = await this.callProcessPayment(values, { ecsPaymentId })

    // Check if the popup should be open
    await this.checkResponse(response)
    // Check which kind of response it is
    procResp = await this.processResponse(response)

    return [response, procResp]
  }

  /**
   * Propagates the server data
   */
  sendServerData(res) {
    const $store = this.$locator.$store

    if (res.hasOwnProperty('response')) {
      const rawResponse = res.response
      // The response can contain the serverDateTime or serverDate variable
      if (rawResponse.hasOwnProperty('serverDate')) {
        $store.dispatch('update', { serverDateTime: rawResponse.serverDate })
      }
    }
  }
}
