import { union, isArray } from 'underscore'

import {
  fieldNameAsSelector,
  fieldNameAsClassName
} from '@/common/util/fieldName'
import fieldTypes from '@/configuration/sources/FieldTypes.yml'

/**
 * FormTemplateField instances represent fields inside form at rendering.
 * The point is to add a layer to easily find the one already provided in
 * form template and add the missing ones.
 *
 * @since KJS-2413
 */
export default class FormTemplateField {
  /**
   * @returns {FormTemplateField[]}
   */
  static getRequiredFields() {
    return union(
      fieldTypes.iframe.map(name => new FormTemplateField('iframe', name)),
      fieldTypes.regular.map(name => new FormTemplateField('regular', name)),
      fieldTypes.control.map(name => new FormTemplateField('control', name))
    )
  }

  /**
   * @param {string} type
   * @param {string} name
   */
  constructor(type, name) {
    this.type = type
    this.name = name
    this.el = null
  }

  /**
   * @returns {string}
   */
  getSelector() {
    return fieldNameAsSelector(this.name)
  }

  /**
   * @returns {string}
   */
  getClassName() {
    return fieldNameAsClassName(this.name)
  }

  /**
   * @returns {string}
   */
  getTag() {
    return this.name === 'paymentButton' ? 'button' : 'div'
  }

  /**
   * @param {FormTemplateField} field
   * @returns {boolean}
   */
  isPositionedAfter(field) {
    const result = this.el.compareDocumentPosition(field.el)
    return result === Node.DOCUMENT_POSITION_PRECEDING
  }

  isTypeControl() {
    return this.type === 'control'
  }

  isPaymentButton() {
    return this.name === 'paymentButton'
  }

  hasElement() {
    return this.el instanceof Element
  }

  /**
   * @returns {HTMLButtonElement | HTMLDivElement}
   */
  computeElement(autoSet = true) {
    const el = document.createElement(this.getTag())
    el.classList.add('kr-' + this.getClassName())
    if (autoSet) {
      this.setElement(el)
    }
    return el
  }

  /**
   * @param {HTMLElement} el
   */
  setElement(el) {
    this.el = el
  }
}
